<div class="create-message-container">
<form [formGroup]="createForm" class="create-message-form">
    <div class="message-subject-container">
        <input type="text" formControlName = "subject" placeholder="Subject:" class="message-subject">
        <span *ngIf="createForm?.value?.subject" class="subject-char-counter">
            {{createForm.value.subject.length}}/250
        </span>

        <span *ngIf="!createForm?.value?.subject" class="subject-char-counter">
            0/250
        </span>
    </div>
    <div class="message-placeholder" *ngIf="showPlaceHolder">
Compose message
    </div>
   <div class="message-expire-date">
   
    <input type="date" formControlName = "expireDate" placeholder=" Expiration (Optional)" >
   </div>
    <angular-editor [placeholder]="'Compose Message'" formControlName="htmlContent" [config]="editorConfig"></angular-editor>

</form>
</div>

<div *ngIf="!createForm?.value?.htmlContent && createForm.get('htmlContent')?.dirty" class="create-message-error">
    This field cannot be empty.
        </div>


<div class="btn-add-message-container">
    <button class="btn btn-success" (click)="onAddMsg()">Add new</button>
</div>

<div
  id="AddModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="AddModalConfirmation"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div
          class="ameren__modal__failure-title"
          *ngIf="addMessageApiFailure && isAddApiMessageResponse"
        >
          Failed to Add the New Message.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="AddMsgApiSuccess && isAddApiMessageResponse"
        >
          New Message has been Added successfully.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="!isAddApiMessageResponse"
        >
          Confirm New Post
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeAddModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="!isAddApiMessageResponse">
          <span
            >Are you sure that you want to submit this new post to the board?</span
          >
        </div>
        <div class="row mt-4" *ngIf="!isAddApiMessageResponse">
          <div class="col-md-4"></div>
          <div class="col-md-2">
            <button
              (click)="onAddMsgCancel()"
              class="btn btn-secondary create_campaign_button"
            >
              Cancel
            </button>
          </div>
          <div class="col-md-2">
            <button
              (click)="postMessage()"
              class="btn btn-success create_campaign_button"
            >
              Submit
            </button>
          </div>

          <div class="col-md-4"></div>
          <div class="text-center" *ngIf="isAddLoading">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
        </div>
        <div
          class="ameren__modal-image text-center"
          *ngIf="isAddApiMessageResponse"
        >
          <img
            *ngIf="!isAddAPIFailure"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="isAddAPIFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

