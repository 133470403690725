export enum PaymentAgreementOptionType {
    MA = "MA",
    EX = "EX",
    PG = "PG",
    LI = "LI", 
    LA = "LA", 
    WR = "WR", 
    CW = "CW", 
    FB = "FB", 
    SC = "SC", 
    NR = "NR", 
    None = "None"
  }

  export enum PaymentAgreementOptionPhase {
    Normal = "Normal",
    RE = "RE",
    RG = "RG",
    RD = "RD"
  }