import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { IGlobalAPIResponse } from '../../../../../src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { AdministrationApiService } from './administration-api.service';
import { EAPUsers } from 'src/app/shared/models/EAPUsers.model';
import * as _ from 'lodash';
import { IActivateOrDeactivateBody } from '../interfaces/IActivateOrDeactivateBody';
import { IAdminApprovalBody } from '../interfaces/IAdminApprovalBody';
import { isNull } from 'lodash';
import { INotificationEmailBody } from '../interfaces/INotificationEmailBody';
import { AgencyInfo } from 'src/app/shared/models/AgencyInformationByPIN.model';

export interface IEAPUsersAPIResponse {
  _id: string;
  _rev: string;
  mail: string;
  sn: string;
  givenName: string;
  userName: string;
  isVerified: boolean;
  organizationName: string;
  portalNameList: any;
  telephoneNumber: string;
  isMigrated: boolean;
  agencyRoleMap?: IAgencyRoleMapDetails[];
  stateProvince: string;
  accountStatus: string;
  pwdReset: string;
  isOtpEnabled: boolean;
  passwordChanged: string;
}

export interface IAgencyRoleMapDetails {
  agencyID?: string;
  role?: string;
  status?: string;
  AgencyID?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdministrationManagerService {

  public eventRefresh = new BehaviorSubject<any>({});
  user$ = this.eventRefresh.asObservable();
  eapUsers: EAPUsers[];

  constructor(private administrationService: AdministrationApiService) {
    this.eapUsers = [];
  }

  getEAPUsersList() {
    return this.administrationService.getEAPUsersList().pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Getting Users list has failed',
            400,
            null,
            null,
            null
          );
        }

        const users = res.data;
        this.eapUsers = [];

        if (users instanceof Array) {
          users.forEach((user: IEAPUsersAPIResponse) => {
            if (!_.isNil(user.agencyRoleMap)) {
              user.agencyRoleMap.forEach((r: IAgencyRoleMapDetails) => {
                this.eapUsers.push(
                  new EAPUsers(
                    user.accountStatus,
                    r.AgencyID || r.agencyID,
                    r.role,
                    r.status,
                    !_.isNil(user.givenName) ? user.givenName : '',
                    user.isMigrated,
                    user.isOtpEnabled,
                    user.isVerified,
                    !_.isNil(user.mail) ? user.mail : '',
                    !_.isNil(user.organizationName)
                      ? user.organizationName
                      : '',
                    user.passwordChanged === 'false' ? false : true,
                    user.portalNameList[0],
                    user.pwdReset === 'false' ? false : true,
                    !_.isNil(user.sn) ? user.sn : '',
                    !_.isNil(user.stateProvince) ? user.stateProvince : '',
                    !_.isNil(user.telephoneNumber) ? user.telephoneNumber : '',
                    !_.isNil(user.userName) ? user.userName : ''
                  )
                );
              });
            }
          });
        }

        return this.eapUsers;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  submitEAPUserStatus(userStatus: any) {
    return this.administrationService.submitEAPUserStatus(userStatus).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Submit User has failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      })
    );
  }

  submitAdminApproval(
    activateOrDeactivateBody: IActivateOrDeactivateBody,
    approvalBody: IAdminApprovalBody
  ) {
    return this.administrationService.unlockUser(activateOrDeactivateBody).pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
      switchMap((res: IGlobalAPIResponse) => {
        if (!res.success) {
          return throwError(
            () =>
              new AmerenError(
                'Admin approval has failed',
                500,
                null,
                null,
                null
              )
          );
        }
        if (activateOrDeactivateBody.active) {
          return this.submitEAPUserStatus(approvalBody);
        }

        return of({});
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  getAgencyInformationById(agencyId: any) {
    return this.administrationService.getAgencyInformationById(agencyId).pipe(
      map((res: any) => {
        if (isNull(res) || !res.success) {
          throw new AmerenError(
            'Getting agency information has failed',
            500,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getAgencyInformationByPin(agencyPin: any) {
    return this.administrationService.getAgencyInformationByPin(agencyPin).pipe(
      map((res: IGlobalAPIResponse) => {
        if (isNull(res) || !res.success) {
          throw new AmerenError(
            'Getting agency information has failed',
            500,
            null,
            null,
            null
          );
        }

        const result: any[] = [];

        res.data.forEach((d: any) => {
          result.push(new AgencyInfo(d));
        });

        return result;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  sendEmailNotification(emailBody: INotificationEmailBody) {
    return this.administrationService.sendEmailNotification(emailBody).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Send Email Notification has failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      })
    );
  }

  getUserMessages(userId: any) {
    return this.administrationService.getUserMessages(userId).pipe(
      map((res: any) => {
        if (isNull(res) || !res.success) {
          throw new AmerenError(
            'Getting user messages has failed',
            500,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  createMessage(body: any){
    return this.administrationService.createUserMessage(body).pipe(
      map((res: any) => {
        if (isNull(res) || !res.success) {
          throw new AmerenError(
            'Create user messages has failed',
            500,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  updateMessage(body: any){
    return this.administrationService.updateUserMessage(body).pipe(
      map((res: any) => {
        if (isNull(res) || !res.success) {
          throw new AmerenError(
            'Create user messages has failed',
            500,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  deleteMessage(id: string){
    return this.administrationService.deleteUserMessage(id).pipe(
      map((res: any) => {
        if (isNull(res) || !res.success) {
          throw new AmerenError(
            'Delete user message has failed',
            500,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

}
