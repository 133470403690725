import { Component, OnInit } from '@angular/core';
import { Profile } from 'src/app/shared/models/profile';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { IEAPAgencyInformation } from '../interfaces/IEAPAgencyInformation';
import { AdministrationManagerService } from '../services/administration-manager.service';

@Component({
  selector: 'app-agencyinformation',
  templateUrl: './agencyinformation.component.html',
  styleUrls: ['./agencyinformation.component.scss'],
})
export class AgencyinformationComponent implements OnInit {
  agencyName: string;
  agencyStreetName: string;
  agencyCity: string;
  agencyState: string;
  agencyZip: number;
  agencyInitials: string;
  isLoading: boolean = true;
  isAPIFailure: boolean = false;
  profile: Profile[];
  agencyID: any;
  selectedAgency: IEAPAgencyInformation;

  constructor(
    private administrationManager: AdministrationManagerService,
    private profileManager: ProfileManagerService,
    private agencyService: AgencyService
  ) {
    this.profile = this.profileManager.profile;
    this.selectedAgency = this.agencyService.getSelectedAgency();
    if (this.selectedAgency) {
      this.agencyID = this.selectedAgency.agencyID;
    } else {
      this.profileManager.profile.forEach((a: Profile) => {
        if (a && a.agencyList?.length != 0 && !a.isUserWithMultiAgency) {
          a.agencyList?.forEach((a) => {
            this.agencyID = a.AgencyID;
          });
        }
      });
    }
  }

  getAgencyInfo() {
    this.administrationManager
      .getAgencyInformationById(this.agencyID.toString())
      .subscribe({
        next: (res: any) => {
          this.isLoading = false;
          this.agencyName = res.agencyName;
          this.agencyStreetName = res.mailingStreetAddress;
          this.agencyCity = res.mailingCity;
          this.agencyState = res.mailingState;
          this.agencyZip = res.mailingZipCode;
          this.agencyInitials = res.agencyName.substring(0, 1);
          this.agencyService.setSelectedAgency(res);
        },
        error: (error) => {
          this.isAPIFailure = true;
          this.isLoading = false;
        },
      });
  }

  ngOnInit(): void {
    this.getAgencyInfo();
  }
}
