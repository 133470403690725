import * as _ from 'lodash';

export class AgenciesByState {
  agencyCSSCode: string;
  agencyName: string;
  constructor(agencyList?: any) {
    this.agencyCSSCode = !_.isNil(agencyList.agencyCSSCode)
      ? agencyList.agencyCSSCode
      : '';
    this.agencyName = !_.isNil(agencyList.agencyName)
      ? agencyList.agencyName.toUpperCase()
      : '';
  }
}
