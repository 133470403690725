import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormControlDirective,
  ControlContainer,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ConfirmPasswordValidation,
  PasswordValidation,
} from '../../shared/directives/password';
import { AmerenError } from '../../shared/models/amerenError';
import { RegistrationManagerService } from './services/registration-manager.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { states } from 'src/app/shared/constants/statesList';
import {
  EnumSplunkLevels,
  EnumSplunkSourceType,
} from 'src/app/shared/services/splunk-api.service';
import { SplunkManager } from 'src/app/shared/services/splunk-manager.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Input() options: any[];
  @Input() value: string;
  @Input() text: string;
  @Input() selectedValue: string;
  @Input() optionLabel: string;

  @Input() frmControl: FormControl;
  @Input() frmControlName: string;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective?: FormControlDirective;

  public isPhoneNumberValidFlg: boolean = true;
  registrationForm!: FormGroup;
  submitted = false;
  user: IUser;
  pwdErrors: any[] = [];
  public mailmatch: boolean = false;
  public pwdmatch: boolean = false;
  public showPassword: boolean = false;
  public showPasswordOnPress: boolean;
  public showConfirmPassword: boolean = false;
  public isCheckboxSelected: boolean = false;
  integrationErrorMsg: string;
  isAPIFailure: boolean = false;
  isLoading: boolean = false;
  states: any[];
  isPinSearchLoading: boolean = false;
  agencySearchResults: any[] = [];
  selectedAgency: any;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private registrationManagerService: RegistrationManagerService,
    private _utility: UtilitiesService,
    private recaptchaService: ReCaptchaV3Service,
    private splunkManager: SplunkManager
  ) {
    this.user = {} as IUser;
  }

  ngOnInit(): void {
    this.initalizeForm();
    this.registrationForm.valueChanges.subscribe(() => {
      this.isCheckboxSelected =
        this.acceptprivacy.value && this.acceptterms.value ? true : false;
    });
  }
  initalizeForm() {
    this.states = states;
    this.registrationForm = new FormGroup({
      firstname: new FormControl(this.user.firstname, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern('[a-zA-Z ]*'),
      ]),
      lastname: new FormControl(this.user.lastname, [
        Validators.required,

        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern('[a-zA-Z ]*'),
      ]),
      email: new FormControl(this.user.email, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator(),
      ]),

      confirmemail: new FormControl(this.user.confirmemail, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator(),
      ]),
      password: new FormControl(this.user.password, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(250),
        PasswordValidation.PasswordRules,
      ]),

      confirmpassword: new FormControl(this.user.confirmpassword, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(250),
        ConfirmPasswordValidation.ConfirmPasswordRules,
      ]),
      agencypin: new FormControl(this.user.agencypin, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$'),
      ]),

      stateprovince: new FormControl(this.user.stateprovince, [
        Validators.required,
      ]),
      phonenumber: new FormControl(this.user.phonenumber, []),
      acceptprivacy: new FormControl(this.user.acceptprivacy, [
        Validators.required,
      ]),

      acceptterms: new FormControl(this.user.acceptterms, [
        Validators.required,
      ]),
    });
  }

  public verifyRecaptchaForRegistration(): void {
    this.isLoading = true;
    this.recaptchaService
      .execute('registrationAction')
      .subscribe((token) => this.handleToken(token));
  }

  handleToken(token: any) {
    const recaptchaBody = {
      recaptchaResponse: token,
    };

    this.registrationManagerService
      .recaptchaVerification(recaptchaBody)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          if (res.score > 0.5) {
            this.submitRegistration();
          } else {
            this.isAPIFailure = true;
            this.integrationErrorMsg =
              'Sorry, something went wrong. Please try again later.';
            this.isLoading = false;
          }
        },
        error: () => {
          this.isAPIFailure = true;
          this.isLoading = false;
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        },
      });
  }

  get firstname() {
    return this.registrationForm.get('firstname')!;
  }

  get lastname() {
    return this.registrationForm.get('lastname')!;
  }

  get email() {
    return this.registrationForm.get('email')!;
  }

  get confirmemail() {
    return this.registrationForm.get('confirmemail')!;
  }

  get password() {
    return this.registrationForm.get('password')!;
  }

  get confirmpassword() {
    return this.registrationForm.get('confirmpassword')!;
  }

  get phonenumber() {
    return this.registrationForm.get('phonenumber')!;
  }

  get agencypin() {
    return this.registrationForm.get('agencypin')!;
  }

  get acceptprivacy() {
    return this.registrationForm.get('acceptprivacy')!;
  }
  get acceptterms() {
    return this.registrationForm.get('acceptterms')!;
  }

  get stateprovince() {
    return this.registrationForm.get('stateprovince')!;
  }

  submitRegistration() {
    if (this.registrationForm.invalid) {
      for (const control of Object.keys(this.registrationForm.controls)) {
        this.registrationForm.controls[control].markAsTouched();
      }
      return;
    }
    this.isLoading = true;
    this.isAPIFailure = false;
    let phone = this.registrationForm.controls['phonenumber'].value;
    let role;
    let pinNumber = this.registrationForm.controls['agencypin'].value;
    if (
      pinNumber === '00017' ||
      pinNumber === '00018' ||
      pinNumber === '00019' ||
      pinNumber === '00021'
    )
      role = 'eapAmerenSupport';
    else if (this.selectedAgency?.isFirstUserForAgency) role = 'eapAgencyAdmin';
    else role = 'eapRead';

    if (phone != undefined && phone != null) {
      var trimphone = phone.replace(/[^0-9 ]/g, '').replace(/\s/g, '');
    }

    let body = {
      id: this.registrationForm.controls['email'].value,
      password: this.registrationForm.controls['password'].value,
      firstname: this.registrationForm.controls['firstname'].value,
      lastname: this.registrationForm.controls['lastname'].value,
      phone: +trimphone,
      stateProvince: this.registrationForm.controls['stateprovince'].value,
      roles: [
        {
          AgencyID:
            role == 'eapAmerenSupport'
              ? this.registrationForm.controls['agencypin'].value
              : this.selectedAgency.agencyID,
          role: role,
          status: 'eapPending',
        },
      ],
    };

    body = this.getBody(body, role);
    this._utility.SetPdfObject(JSON.stringify(body));

    this.registrationManagerService.submitRegistration(body).subscribe(
      (res) => {
        this.isLoading = false;

        if (!res.success) {
          this.isAPIFailure = true;
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';

          let loginMessage =
            'User ' +
            body.firstname +
            ' ' +
            body.lastname +
            ' ' +
            ' ( ' +
            body.id +
            ' ) ' +
            ' has failed with registeration';

          this.submitSplunk(
            EnumSplunkLevels.ERROR,
            EnumSplunkSourceType.EAPUILOGS,
            loginMessage
          );
        } else {
          let loginMessage =
            'User ' +
            body.firstname +
            ' ' +
            body.lastname +
            ' ' +
            ' ( ' +
            body.id +
            ' ) ' +
            ' has registered successfully';

          this.submitSplunk(
            EnumSplunkLevels.INFO,
            EnumSplunkSourceType.EAPUILOGS,
            loginMessage
          );

          this.isAPIFailure = false;
          this._router.navigateByUrl('/registration-success');
        }
      },
      (error: AmerenError) => {
        let loginMessage =
          'User ' +
          body.firstname +
          ' ' +
          body.lastname +
          ' ' +
          ' ( ' +
          body.id +
          ' ) ' +
          ' has failed with registeration';

        this.submitSplunk(
          EnumSplunkLevels.ERROR,
          EnumSplunkSourceType.EAPUILOGS,
          loginMessage
        );

        this.isLoading = false;
        this.isAPIFailure = true;
        if (error.status === 400) {
          this.integrationErrorMsg =
            'Sorry, something is not right. Please try again.';
        } else if (error.status === 409) {
          this.integrationErrorMsg = 'Sorry. User already exists.';
        } else {
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        }
      }
    );
  }

  getBody(objBody: any, role: any): any {
    if (!objBody.phone) {
      objBody = {
        id: this.registrationForm.controls['email'].value,
        password: this.registrationForm.controls['password'].value,
        firstname: this.registrationForm.controls['firstname'].value,
        lastname: this.registrationForm.controls['lastname'].value,
        stateProvince: this.registrationForm.controls['stateprovince'].value,
        roles: [
          {
            AgencyID:
              role == 'eapAmerenSupport'
                ? this.registrationForm.controls['agencypin'].value
                : this.selectedAgency.agencyID,
            role: role,
            status: 'eapPending',
          },
        ],
      };
    }
    return objBody;
  }

  submitSplunk(
    level: EnumSplunkLevels,
    sourceType: EnumSplunkSourceType,
    message: any,
    elapsed?: number,
    method?: string,
    apiName?: string
  ) {
    this.splunkManager
      .logSplunk(level, sourceType, message, elapsed, method, apiName)
      .subscribe({
        next: (data: any) => {},
        error: (error) => {
          console.log('error', error);
        },
      });
  }

  passwordValidate() {
    var pwd = this.registrationForm.controls['password'].value;
    if (pwd != null || pwd != '') {
      let isNoLeadingTrailingSpacesValid = !/^[ \s]+|[ \s]+$/.test(pwd);
      let isLetterAndNumberAndSpecialCharValid =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*([~`!@#$%\^\(\)_*+=\-\[\]\\';,/{}|\\"":\?])).+$/.test(
          pwd
        );
      let isRepeatingCharactersValid = !/(\S)\1\1+/.test(pwd);
      let isNoControlCharactersValid = /^[\S ]+$/.test(pwd);
      let isNoTwoContinuousSpacesValid = !/\s\s/.test(pwd);
      let invalidCharacters = !/[<]|[>]|[&]|[-]/.test(pwd);
      let isMinMaxLimitExceeded = !/^(?=.{8,250}$).*/.test(pwd);
      let errors = {
        NoLeadingTrailingSpaces: false,
        InvalidCharacters: false,
        LetterAndNumberAndSpecialChar: false,
        RepeatingCharacters: false,
        NoControlCharacters: false,
        NoTwoContinuousSpaces: false,
        WeakPassword: false,
      };

      let confirmPassworderrors = { IsNotPasswordMatching: false };
      if (!isNoLeadingTrailingSpacesValid) {
        errors.NoLeadingTrailingSpaces = true;
      }
      if (!invalidCharacters) {
        errors.InvalidCharacters = true;
      }
      if (!isLetterAndNumberAndSpecialCharValid) {
        errors.LetterAndNumberAndSpecialChar = true;
      }
      if (!isRepeatingCharactersValid) {
        errors.RepeatingCharacters = true;
      }
      if (!isNoControlCharactersValid) {
        errors.NoControlCharacters = true;
      }
      if (!isNoTwoContinuousSpacesValid) {
        errors.NoTwoContinuousSpaces = true;
      }
      if (
        !invalidCharacters ||
        !isNoControlCharactersValid ||
        !isNoTwoContinuousSpacesValid ||
        !isNoLeadingTrailingSpacesValid ||
        !isLetterAndNumberAndSpecialCharValid ||
        !isRepeatingCharactersValid ||
        isMinMaxLimitExceeded
      ) {
        this.pwdErrors = [];

        this.pwdErrors.push(errors);
      } else this.pwdErrors = [];
    }
  }
  emailmatch() {
    this.mailmatch = false;
    if (
      this.registrationForm.controls['email'].value !=
      this.registrationForm.controls['confirmemail'].value
    ) {
      this.mailmatch = true;
    }
  }

  passwordmatch() {
    this.pwdmatch = false;
    if (
      this.registrationForm.controls['password'].value !=
      this.registrationForm.controls['confirmpassword'].value
    ) {
      this.pwdmatch = true;
    }
  }

  dpChange() {
    this.registrationForm.controls['stateprovince'].value;
  }

  onBackClick() {
    this._router.navigate(['/']);
  }

  validatePhoneNo(event: any) {
    let phoneNumDigits = event.value.replace(/\D/g, '');
    this.isPhoneNumberValidFlg =
      phoneNumDigits.length == 0 || phoneNumDigits.length == 10;
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length > 6)
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ') ' +
        phoneNumDigits.substring(3, 6) +
        '-' +
        phoneNumDigits.substring(6);
    else if (phoneNumDigits.length > 3)
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ') ' +
        phoneNumDigits.substring(3);
    event.value = formattedNumber;
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  validatePin() {
    this.selectedAgency = null;
    this.agencySearchResults = [];
    if (this.registrationForm.get('agencypin')?.valid) {
      let pin = this.registrationForm.get('agencypin')?.value;
      if (pin == '00017') {
        this.selectedAgency = {
          agencyID: '00017',
          agencyName: 'Ameren Missouri EAP Administration & Support',
        };
        return;
      } else if (pin == '00018') {
        this.selectedAgency = {
          agencyID: '00018',
          agencyName: 'Ameren Illinois EAP Administration & Support',
        };
        return;
      } else {
        this.isPinSearchLoading = true;
        this.registrationManagerService
          .validateAgencyPin(this.registrationForm.get('agencypin')?.value)
          .subscribe({
            next: (data: any) => {
              this.isPinSearchLoading = false;
              if (Array.isArray(data.data)) {
                data.data.forEach((el: any) => {
                  if (el.AgencyID > 0 && el.isPinNumberValid) {
                    this.agencySearchResults.push({
                      isFirstUserForAgency: el.isFirstUserForAgency,
                      agencyID: el.AgencyID,
                      address: `${el.agencyAddress.addressLines.join(' ')} ${
                        el.agencyAddress.state
                      } ${el.agencyAddress.city} ${el.agencyAddress.zip}`,
                      agencyName: el.agencyName,
                      shortName: el.agencyName.split(' ')[0].charAt(),
                    });
                  }
                });
              }
              if (this.agencySearchResults.length == 1)
                this.selectedAgency = this.agencySearchResults[0];
            },
            error: (error) => {
              this.isPinSearchLoading = false;
              if (
                error?.status == '400' &&
                error?.error?.data?.AgencyID == '0' &&
                !error?.error?.data?.isPinNumberValid
              )
                this.registrationForm
                  .get('agencypin')
                  ?.setErrors({ invalidPin: 'This agency pin is invalid.' });
              else
                this.registrationForm.get('agencypin')?.setErrors({
                  invalidPin:
                    'Sorry, something went wrong. Please try again later.',
                });
            },
          });
      }
    }
  }

  selectAgency(agency: any) {
    this.selectedAgency = agency;
  }
}

interface IUser {
  firstname: string;
  lastname: string;
  email: string;
  confirmemail: string;
  password: string;
  confirmpassword: string;
  agencypin: string;
  phonenumber: string;
  acceptprivacy: boolean;
  stateprovince: string;
  acceptterms: boolean;
}

export function emailValidator(): ValidatorFn {
  const EMAIL_REGEXP =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = EMAIL_REGEXP.test(control.value);

    if (isValid) {
      return null;
    } else {
      return {
        emailValidator: {
          valid: false,
        },
      };
    }
  };
}
