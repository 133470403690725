import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { contains } from 'jquery';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { ClientPledge, pledgeObject } from './model/clientpledge.model';
import { ClientpledgeManagerService } from './services/clientpledge-manager.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

declare var window: any;

@Component({
  selector: 'app-client-pledge',
  templateUrl: './client-pledge.component.html',
  styleUrls: ['./client-pledge.component.scss'],
})
export class ClientPledgeComponent implements OnInit {
  searchPledge: any;
  isLoading: boolean;
  isAPIFailure: boolean;
  clientpledgelist: ClientPledge[];
  clientPledgesOriginal: ClientPledge[];
  selectedClient: Client;
  showCalendarFilter: boolean = false;
  calendarModal: any;
  calendarModalMobile: any;
  checkAllBoxes: Boolean = false;
  selectedPledges: pledgeObject[] = [];
  clientPledgeParameters: any = {};
  MissouriPledge: boolean;
  isClientPledge: boolean = true;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;

  constructor(
    private clientPledgeManager: ClientpledgeManagerService,
    private clientService: ClientService,
    private _utility: UtilitiesService
  ) {
    this.selectedClient = this.clientService.getSelectedClient();
  }

  ngOnInit() {
    this.setClientPledgeDefaultCriteria();
    this.getClientPledgesByAccount();
  }

  searchFilterforClientPledge() {
    if (this.searchPledge && this.searchPledge.trim() != '') {
      this.clientpledgelist = this.clientPledgesOriginal.filter(
        (data) =>
          data.campaignName
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase()) ||
          data.pledgeDate
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase()) ||
          data.pledgeTypeDecode
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase()) ||
          data.agencyName
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase()) ||
          data.pledgeTypeCode
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase()) ||
          data.pledgeAmount === this.searchPledge ||
          data.pledgeStatusDecode
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase()) ||
          data.pledgeEndDate
            .toLowerCase()
            .match(this.searchPledge.trim().toLowerCase())
      );
    } else {
      this.clientpledgelist = this.clientPledgesOriginal;
    }
    this.uncheckAll();
  }

  exportToExcelClientPledges() {
    var clientAccountNumber = this.selectedClient.billAccountNumber;
    if (this.selectedPledges.length <= 0) {
      this.checkAllBoxes = false;
      this.checkAll();
    }

    for (let i = 0; i < this.selectedPledges.length; i++) {
      if (this.selectedPledges[i].clockImage == 1) {
        this.selectedPledges[i].clockImage = '⏰';
      }
    }

    let excelfilename =
      'Pledges For ' +
      this._utility.transformAccountNumber(clientAccountNumber);

    let workbook: XLSX.WorkBook = this._utility.exportJsonToExcelBook(
      this.selectedPledges,
      excelfilename,
      false,
      [
        'campaignName',
        'clockImage',
        'pledgeDate',
        'pledgeTypeDecode',
        'agencyName',
        'pledgeAmount',
        'pledgeStatusDecode',
        'pledgeEndDate',
      ],
      [
        'Campaign Name',
        '',
        'Pledge Date',
        'Pledge Type',
        'Agency Name',
        'Pledge Amount',
        'Status',
        'Date Satisfied',
      ]
    );
    let worksheet: XLSX.WorkSheet;
    worksheet = workbook.Sheets['data'];

    let data = XLSX.utils.sheet_to_json(worksheet);
    let ws = XLSX.utils.aoa_to_sheet([[this.selectedClient.fullName]]);
    XLSX.utils.sheet_add_aoa(
      ws,
      [[this.selectedClient.address.streetAddress]],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          this.selectedClient.address.city +
            ' ' +
            this.selectedClient.address.state +
            this.selectedClient.address.zip,
        ],
      ],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          this._utility.transformAccountNumber(
            this.selectedClient.billAccountNumber.toString()
          ),
        ],
      ],
      { origin: -1 }
    );

    XLSX.utils.sheet_add_json(ws, data, { origin: 'A6' });

    var wscols = [
      { wch: 20 },
      { wch: 3 },
      { wch: 10 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 18 },
      { wch: 18 },
    ];

    ws['!cols'] = wscols;
    this._utility.formatColumn(ws, 5, '$0.00', 'n');
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, `${excelfilename}.xlsx`);
  }

  exportToPdfClientPledges() {
    let clockImg: any = this._utility.getBase64Image(
      document.getElementById('clock')
    );
    var clientAccountNumber = this.selectedClient.billAccountNumber;
    var clientState = this.selectedClient.address.state;
    if (this.selectedPledges.length <= 0) {
      this.checkAllBoxes = false;
      this.checkAll();
    }
    if (clientState === 'MO') {
      this.MissouriPledge = true;
    } else {
      this.MissouriPledge = false;
    }
    this.selectedPledges.forEach(
      (x) => (x.pledgeAmount = this._utility.transformCurrency(x.pledgeAmount))
    );

    this._utility.exportJsonToPdfFile(
      this.selectedPledges,
      'Pledges For ' +
        this._utility.transformAccountNumber(clientAccountNumber),
      [
        'campaignName',
        'clockImage',
        'pledgeDate',
        'pledgeTypeDecode',
        'agencyName',
        'pledgeAmount',
        'pledgeStatusDecode',
        'pledgeEndDate',
      ],
      [
        'Campaign Name',
        '',
        'Pledge Date',
        'Pledge Type',
        'Agency Name',
        'Pledge Amount',
        'Status',
        'Date Satisfied',
      ],
      'Pledge List For ' +
        this._utility.transformAccountNumber(clientAccountNumber) +
        '\n' +
        this.selectedClient.fullName +
        '\n' +
        this.selectedClient.address.streetAddress +
        '\n' +
        this.selectedClient.address.city +
        ' ' +
        this.selectedClient.address.state +
        this.selectedClient.address.zip,
      true,
      this.MissouriPledge,
      clockImg,
      this.isClientPledge
    );
  }

  exportAllPledges() {
    this.selectedPledges = [];
    for (let i = 0; i < this.clientpledgelist.length; i++) {
      var pledgesObject = {
        index: i,
        campaignName: this.clientpledgelist[i].campaignName,
        pledgeDate: this._utility.transformDate(
          this.clientpledgelist[i].pledgeDate
        ),
        pledgeTypeDecode: this.clientpledgelist[i].pledgeTypeDecode,
        agencyName: this.clientpledgelist[i].agencyName,
        pledgeAmount: this.clientpledgelist[i].pledgeAmount,
        pledgeStatusDecode: this.clientpledgelist[i].pledgeStatusDecode,
        pledgeEndDate: this._utility.transformDate(
          this.clientpledgelist[i].pledgeEndDate
        ),
        clockImage: this.clientpledgelist[i].clockImage,
      };
      this.selectedPledges.push(pledgesObject);
    }

    return;
  }

  checkAll() {
    if (this.checkAllBoxes === false) {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;
      });
      this.checkAllBoxes = true;
      this.exportAllPledges();
      return;
    } else {
      this.uncheckAll();
    }
  }
  uncheckAll() {
    this.selectedPledges = [];
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.checkAllBoxes = false;
  }

  toggleData(
    index: any,
    campaignName: any,
    pledgeDate: any,
    pledgeTypeDecode: any,
    agencyName: any,
    pledgeAmount: any,
    pledgeStatusDecode: any,
    pledgeEndDate: any,
    clockImage: any
  ) {
    var rowIndex = index;

    var pledgesObject = {
      index: index,
      campaignName: campaignName,
      pledgeDate: pledgeDate,
      pledgeTypeDecode: pledgeTypeDecode,
      agencyName: agencyName,
      pledgeAmount: pledgeAmount,
      pledgeStatusDecode: pledgeStatusDecode,
      pledgeEndDate: pledgeEndDate,
      clockImage: clockImage,
    };
    var found = this.selectedPledges.findIndex((x) => x.index == rowIndex);
    if (found !== -1) {
      this.selectedPledges.splice(found, 1);
    } else {
      this.selectedPledges.push(pledgesObject);
    }
  }

  getClientPledgesByAccount() {
    this.isLoading = true;
    return this.clientPledgeManager
      .getClientPledgeList(this.clientPledgeParameters)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          res.forEach((el: any) => {
            el.pledgeDate = moment(el.pledgeDate).format('MM/DD/YY');
          });
          this.clientpledgelist = res;
          this.clientpledgelist = this.clientpledgelist.sort((a, b) =>
            b.pledgeDate.localeCompare(a.pledgeDate)
          );
          this.clientPledgesOriginal = this.clientpledgelist;
        },
        error: (error: any) => {
          console.log('err', error);
          this.isAPIFailure = true;
          this.isLoading = false;
        },
      });
  }

  dateRangeFilter(event: any, isFromDate: boolean, isMobile: boolean) {
    if (event.target.value) {
      let todayDate = new Date();
      let minDate = new Date();
      minDate.setDate(todayDate.getDate() - 365); // can search upto past 1 year

      let selectedDate = new Date(event.target.value.split('-').join('/'));
      if (selectedDate >= minDate && selectedDate <= todayDate) {
        let otherDt: any = '';
        if (isMobile) {
          otherDt = isFromDate
            ? $('#pledgeToDateMobile').val()
            : $('#pledgeFromDateMobile').val();
        } else {
          otherDt = isFromDate
            ? $('#pledgeToDate').val()
            : $('#pledgeFromDate').val();
        }

        if (otherDt) {
          let otherDate = new Date(otherDt.split('-').join('/'));
          let isValidDate: boolean = isFromDate
            ? selectedDate >= minDate && selectedDate < otherDate
            : selectedDate > otherDate && selectedDate <= todayDate;

          if (isValidDate) {
            let filterFromDate =
              String(otherDate.getMonth() + 1).padStart(2, '0') +
              '/' +
              String(otherDate.getDate()).padStart(2, '0') +
              '/' +
              otherDate.getFullYear();

            let filterToDate =
              String(selectedDate.getMonth() + 1).padStart(2, '0') +
              '/' +
              String(selectedDate.getDate()).padStart(2, '0') +
              '/' +
              selectedDate.getFullYear();

            let selectedDateString =
              selectedDate.getFullYear() +
              '-' +
              String(selectedDate.getMonth() + 1).padStart(2, '0') +
              '-' +
              String(selectedDate.getDate()).padStart(2, '0');
            let otherDateString =
              otherDate.getFullYear() +
              '-' +
              String(otherDate.getMonth() + 1).padStart(2, '0') +
              '-' +
              String(otherDate.getDate()).padStart(2, '0');

            if (isMobile) {
              $('.dropdown-mobile').removeClass('show');
              $('#btnCalendarFilterMobile').attr('aria-expanded', 'false');
              $('#btnCalendarFilterMobile').removeClass('show');
            } else {
              $('.dropdown-desktop').removeClass('show');
              $('#btnCalendarFilter').attr('aria-expanded', 'false');
              $('#btnCalendarFilter').removeClass('show');
            }

            this.clientPledgeParameters = {
              accountnumber: this.selectedClient.billAccountNumber.toString(),
              pledgestatuscode: '',
              pledgetypecode: '',
              pledgestartdate:
                selectedDate < otherDate ? selectedDateString : otherDateString,
              pledgeenddate:
                selectedDate < otherDate ? otherDateString : selectedDateString,
            };
          }
        }
      }

      this.showFilterCriteriaByDateRange();
    }
  }

  filterByPastDays(pastDays: number) {
    let toDate = new Date();
    let toDateString =
      toDate.getFullYear() +
      '-' +
      String(toDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(toDate.getDate()).padStart(2, '0');

    let fromDate = new Date();
    fromDate.setDate(toDate.getDate() - pastDays);
    let fromDateString =
      fromDate.getFullYear() +
      '-' +
      String(fromDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(fromDate.getDate()).padStart(2, '0');

    this.clientPledgeParameters = {
      accountnumber: this.selectedClient.billAccountNumber.toString(),
      pledgestatuscode: '',
      pledgetypecode: '',
      pledgestartdate: fromDateString,
      pledgeenddate: toDateString,
    };
    this.showFilterCriteriaByPastDays(pastDays);
  }

  setClientPledgeDefaultCriteria() {
    let toDate = new Date();
    let toDateString =
      toDate.getFullYear() +
      '-' +
      String(toDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(toDate.getDate()).padStart(2, '0');

    let fromDate = new Date();
    fromDate.setDate(toDate.getDate() - 60);
    let fromDateString =
      fromDate.getFullYear() +
      '-' +
      String(fromDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(fromDate.getDate()).padStart(2, '0');

    this.clientPledgeParameters = {
      accountnumber: this.selectedClient.billAccountNumber.toString(),
      pledgestatuscode: '',
      pledgetypecode: '',
      pledgestartdate: fromDateString,
      pledgeenddate: toDateString,
    };
  }

  applyFilters() {
    this.getClientPledgesByAccount();
    this.uncheckAll();
  }

  clearFilters() {
    $('#pledgeFromDate').val('');
    $('#pledgeToDate').val('');
    $('#lblDate').text('').hide();
    $('#lblPastDays').text('').hide();
    $('#lblSelectedCriteria').hide();
    this.setClientPledgeDefaultCriteria();
    this.getClientPledgesByAccount();
    this.uncheckAll();
  }

  showFilterCriteriaByDateRange() {
    let _fromDate: any = '';
    _fromDate = $('#pledgeFromDate').val();
    let _fromDateFromatted = new Date(_fromDate.split('-').join('/'));
    let fromDate =
      String(_fromDateFromatted.getMonth() + 1).padStart(2, '0') +
      '/' +
      String(_fromDateFromatted.getDate()).padStart(2, '0') +
      '/' +
      _fromDateFromatted.getFullYear();

    let _toDate: any = '';
    _toDate = $('#pledgeToDate').val();
    let _toDateFromatted = new Date(_toDate.split('-').join('/'));
    let toDate =
      String(_toDateFromatted.getMonth() + 1).padStart(2, '0') +
      '/' +
      String(_toDateFromatted.getDate()).padStart(2, '0') +
      '/' +
      _toDateFromatted.getFullYear();
    if (!isNaN(Date.parse(_fromDate)) && !isNaN(Date.parse(_toDate))) {
      $('#lblDate')
        .text(fromDate + '  to  ' + toDate)
        .show();
      $('#lblSelectedCriteria').show();
      $('#lblPastDays').text('').hide();

      this.clientPledgeParameters = {
        accountnumber: this.selectedClient.billAccountNumber.toString(),
        pledgestatuscode: '',
        pledgetypecode: '',
        pledgestartdate:
          _fromDate,
        pledgeenddate:
          _toDate,
      };
    }
  }

  showFilterCriteriaByPastDays(pastDays: number) {
    $('#pledgeFromDate').val('');
    $('#pledgeToDate').val('');
    $('#lblDate').text('').hide();
    if (pastDays != 365)
      $('#lblPastDays')
        .text('Past ' + pastDays + ' days')
        .show();
    else $('#lblPastDays').text('Past 12 months').show();
    $('#lblSelectedCriteria').show();
    if (pastDays == 30) {
      $('#btnPastThirtyDays').addClass('calendar-day-button-active');
    } else if (pastDays == 60) {
      $('#btnPastSixtyDays').addClass('calendar-day-button-active');
    } else if (pastDays == 90) {
      $('#btnPastNinetyDays').addClass('calendar-day-button-active');
    } else $('#btnPastYear').addClass('calendar-day-button-active');
  }
}
