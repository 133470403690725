import { EnumRole } from './../../../shared/enums/role';
import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AdministrationManagerService } from '../services/administration-manager.service';
declare var window: any;

@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.scss'],
})
export class MessageBoardComponent implements OnInit {
  userMessages: any = [];
  isLoading: boolean = true;
  isAPIFailure: boolean = false;
  userProfile: Profile[];
  stateCode: string;
  isAmerenAdmin:boolean = false;
  isMessageEditing: boolean =false;

  
  



  constructor(
    private administrationService: AdministrationManagerService,
    private profileManager: ProfileManagerService
  ) {
    
  }

  ngOnInit(): void {
    if (
      this.profileManager.profile[0].agencyList?.some(
        (a) =>
          a.role == EnumRole.EAPAMERENADMIN ||
          a.role == EnumRole.EAPAMERENSUPPORT
      )
    ) {
      this.isAmerenAdmin = true;
    }
    this.userProfile = this.profileManager.profile;
    this.stateCode = this.userProfile[0].stateProvince
      ? this.userProfile[0].stateProvince
      : '';
    this.getUserMessages(this.userProfile[0].email);

  }

  
  getUserMessages(email: any) {
    this.isLoading = true;
    this.isAPIFailure = false;
    this.administrationService.getUserMessages(email).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.userMessages = data.filter(
          (r: any) => r.operatingState === this.stateCode
        );
      },
      error: (err) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        console.log('error', err);
      },
    });
  }

  onMessageEdit(index: number){
    this.isMessageEditing = true;
    this.userMessages.forEach((msg:any) => {
        msg.isBeingEdited = false;
      });
this.userMessages[index].isBeingEdited = true;
  }

  onEditCancel(){
    this.userMessages.forEach((msg:any) => {
      msg.isBeingEdited = false;
    });
    this.isMessageEditing = false;
  }
}
