import { IGlobalAPIResponse } from './../../../shared/interfaces/iglobal-api-response';
import { environment } from './../../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainingApiService {

  constructor(private http: HttpClient) { }

  getTrainingList(stateCode: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private');

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/documents?state=${stateCode}`,
      { headers }
    );
  }

  createDocument(data: any) {
    const headers = new HttpHeaders()
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);
    const body = data;
    return this.http.post<any>(
      `${environment.apig}/document`,
      body,
      { headers }
    );
  }

  editDocument(data: any, documentId: number) {
    const headers = new HttpHeaders()
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);
    const body = data;
    return this.http.put<any>(
      `${environment.apig}/document?documentId=${documentId}`,
      body,
      { headers }
    );
  }

  getDocument(documentId: string) {
    const headers = new HttpHeaders()
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/document?documentId=${documentId}`, {
      headers: headers });
  }

  deleteDocument(documentId: string) {
    const headers = new HttpHeaders()
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
    return this.http.delete<IGlobalAPIResponse>(`${environment.apig}/document?documentId=${documentId}`, {
      headers: headers });
  }

}
