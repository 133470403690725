import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CorrelationService } from '../services/correlation.service';

@Injectable()
export class CorrelationInterceptor implements HttpInterceptor {
constructor(private correlationService: CorrelationService) {}

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers = req.headers
        .set('X-Correlation-Id', this.correlationService.getCorrelationId());
    const authReq = req.clone({ headers });

    if(this.checkRequest(req)) {
        return next.handle(authReq);
    }

    return next.handle(req);
}

checkRequest(req: HttpRequest<any>): boolean {
    return !/splunkcloudhec/.test(req.url);
}

}
