import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AgencyCampaign } from '../model/campaignbyagency.model';
import { CampaignsManagerService } from '../services/campaigns-manager.service';
import { DatePipe, formatDate } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
  providers: [DatePipe],
})
export class CampaignListComponent implements OnInit {
  listMoreThanFive = false;
  agencyCampaignList: AgencyCampaign[];
  activeCampaignList: AgencyCampaign[];
  pendingCampaignList: AgencyCampaign[];
  inActiveCampaignList: AgencyCampaign[];
  isAPIFailure: boolean;
  isLoading: boolean;
  selectedAgency: any;
  searchStringforActiveCampaign: any;
  searchStringforPendingCampaign: any;
  searchStringforInactiveCampaign: any;
  showData: boolean = false;

  currentDate = new Date();
  constructor(
    private campaignManager: CampaignsManagerService,
    private router: Router,
    private agencyService: AgencyService,
    private datePipe: DatePipe
  ) {
    this.selectedAgency = this.agencyService.getSelectedAgency();
  }

  ngOnInit(): void {
    this.getCampaignsByState();
  }

  getCampaignsByState() {
    this.isAPIFailure = false;
    this.isLoading = true;
    this.agencyCampaignList = [];
    this.activeCampaignList = [];
    this.pendingCampaignList = [];
    this.inActiveCampaignList = [];
    this.campaignManager.getCampaignByState().subscribe({
      next: (resp: any[]) => {
        // remove duplicates
        this.agencyCampaignList = resp.filter((value1, i, arr) => {
          return (
            arr.findIndex(
              (value2) => value2.campaignID === value1.campaignID
            ) === i
          );
        });

        const dateValue = new Date();
        let currentDate = moment().tz('America/Chicago').format('YYYY-MM-DD');

        this.agencyCampaignList = this.sortData(this.agencyCampaignList);

        this.activeCampaignList = this.agencyCampaignList.filter(
          (data) =>
            data.campaignStatus.toLowerCase() === 'active' &&
            moment(
              moment(data.campaignToDate).format('YYYY-MM-DD')
            ).isSameOrAfter(currentDate) &&
            moment(
              moment(data.campaignFromDate).format('YYYY-MM-DD')
            ).isSameOrBefore(currentDate)
        );

        this.activeCampaignList.forEach((e) => {
          const currentDate = new Date();
          const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

          const days =
            Math.round(Date.parse(e['campaignToDate']) - Date.parse(cValue)) /
            (1000 * 60 * 60 * 24);

          e['timeRemaining'] = days || 0;
          e['timeRemainingDayHour'] =
            days < 0 ? 'Campaign is expired' : days.toString() + ' Days';
        });

        this.pendingCampaignList = this.agencyCampaignList.filter(
          (data) =>
            data.campaignStatus.toLowerCase() === 'active' &&
            moment(moment(data.campaignToDate).format('YYYY-MM-DD')).isAfter(
              currentDate
            ) &&
            moment(moment(data.campaignFromDate).format('YYYY-MM-DD')).isAfter(
              currentDate
            )
        );

        this.inActiveCampaignList = this.agencyCampaignList.filter(
          (data) =>
            data.campaignStatus.toLowerCase() === 'active' &&
            moment(moment(data.campaignToDate).format('YYYY-MM-DD')).isBefore(
              currentDate
            ) &&
            moment(moment(data.campaignFromDate).format('YYYY-MM-DD')).isBefore(
              currentDate
            )
        );

        this.isAPIFailure = false;
        this.isLoading = false;
      },
    });
  }

  getCampaignByAgency() {
    this.isAPIFailure = false;
    this.isLoading = true;
    this.activeCampaignList = [];
    this.pendingCampaignList = [];
    this.inActiveCampaignList = [];
    this.campaignManager
      .getCampaignByAgency(this.selectedAgency.agencyCSSCode)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.agencyCampaignList = this.sortData(res);
          this.activeCampaignList = this.agencyCampaignList.filter(
            (data) =>
              data.campaignStatus.toLowerCase() === 'active' &&
              data.campaignAgencyStatus?.toLowerCase() != 'inactive'
          );

          this.activeCampaignList.forEach((e) => {
            const currentDate = new Date();
            const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

            const days =
              Math.round(Date.parse(e['campaignToDate']) - Date.parse(cValue)) /
              (1000 * 60 * 60 * 24);

            e['timeRemaining'] = days || 0;
            e['timeRemainingDayHour'] =
              days < 0 ? 'Campaign is expired' : days.toString() + ' Days';
          });
          this.pendingCampaignList = this.agencyCampaignList.filter(
            (data) =>
              data.campaignStatus.toLowerCase() === 'pending' &&
              data.campaignAgencyStatus?.toLowerCase() != 'inactive'
          );
          this.inActiveCampaignList = this.agencyCampaignList.filter(
            (data) => data.campaignAgencyStatus?.toLowerCase() === 'inactive'
          );
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error && error.error.errorDetails.code === 400) {
            this.agencyCampaignList = [];
          } else {
            this.isAPIFailure = true;
          }
          console.log('error', error);
        },
      });
  }

  toggleInActiveCampaignDetailsHandler(c: AgencyCampaign) {
    this.inActiveCampaignList.forEach((campaign: AgencyCampaign) => {
      if (campaign.campaignID == c.campaignID && c.toggleStatus) {
        c.toggleStatus = false;
      } else if (campaign.campaignID == c.campaignID && !c.toggleStatus) {
        c.toggleStatus = true;
      }
    });
  }

  togglePendingCampaignDetailsHandler(c: AgencyCampaign) {
    this.pendingCampaignList.forEach((campaign: AgencyCampaign) => {
      if (campaign.campaignID == c.campaignID && c.toggleStatus) {
        c.toggleStatus = false;
      } else if (campaign.campaignID == c.campaignID && !c.toggleStatus) {
        c.toggleStatus = true;
      }
    });
  }

  toggleActiveCampaignDetailsHandler(c: AgencyCampaign) {
    this.activeCampaignList.forEach((campaign: AgencyCampaign) => {
      if (campaign.campaignID == c.campaignID && c.toggleStatus) {
        c.toggleStatus = false;
      } else if (campaign.campaignID == c.campaignID && !c.toggleStatus) {
        c.toggleStatus = true;
      }
    });
  }

  sortData(res: any[]) {
    let sortingData: any[] = res;
    return sortingData.sort((a: any, b: any) => {
      return a.campaignName
        .toLowerCase()
        .localeCompare(b.campaignName.toLowerCase());
    });
  }
}
