import * as _ from 'lodash';

export class maintenance {
  isMaintenance: boolean;

  constructor(data?: any) {
    this.isMaintenance =
      !_.isNil(data.underMaintenance) && data.underMaintenance === 'true'
        ? true
        : false;
  }
}
