import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumRole } from 'src/app/shared/enums/role';
import { AgencyRoleDetails, Profile } from 'src/app/shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isAuthenticated: boolean;
  currentUser: any;
  _profile: Profile[];
  isAdmin: boolean = false;


  @Input() login_menu: boolean;
  constructor(private authService: AuthService, 
    private profileManager: ProfileManagerService,
    private router: Router) {
    this.authService.loginChanged.subscribe((t) => {
      this.isAuthenticated = t;
    });

    this.authService.userLoaded.subscribe((user) => {
      this.currentUser = user;
      this._profile = [];
      if(this.currentUser) {
        this._profile.push(new Profile(this.currentUser.profile.firstname, this.currentUser.profile.telephoneNumber, this.currentUser.profile.auditTrackingId, this.currentUser.profile.isverified, this.currentUser.profile.agencyRoleMap, this.currentUser.profile.azp, this.currentUser.profile.email, this.currentUser.profile.isMigrated, this.currentUser.profile.stateProvince, this.currentUser.profile.lastname, this.currentUser.profile.lastLoginTime, this.currentUser.profile.isOtpEnabled, this.currentUser.profile.pwdChangedTime));
        this.profileManager.profile = this._profile;
      }
    });

    this.authService.getUser().then((user: any) => {
      let userRole: AgencyRoleDetails[] = [];
      userRole = user?.profile?.agencyRoleMap;
      if(userRole) {
        userRole.forEach(a => {
          a.role === EnumRole.EAPAMERENADMIN ||
          a.role === EnumRole.EAPAGENCYADMIN ||
          a.role === EnumRole.EAPAMERENSUPPORT ? this.isAdmin = true : this.isAdmin = false
        }
        );
      }
    })

   
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().then((flag) => (this.isAuthenticated = flag));
  }

  login() {
    this.authService.Login();
  }
  logout() {
    this.authService.Logout();
  }

  goToPath() {
    if(this.isAdmin) {
      this.router.navigate(['administration']);
    } else  {
      this.router.navigate(['agency-pledges']);

    }
  }
}
