import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dollarAmount'
})

export class DollarAmountPipe implements PipeTransform {
    transform(value: string): string {
        if (value == null)
            return '';
        else
            return "$" + value;
    }
} 