import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { EAPUserRole } from 'src/app/shared/enums/eap-userrole';
import { ManageprofileApiManagerService } from './services/manageprofile-api-manager.service';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { Profile } from 'src/app/shared/models/profile';

@Component({
  selector: 'app-manageprofile',
  templateUrl: './manageprofile.component.html',
  styleUrls: ['./manageprofile.component.scss'],
})
export class ManageprofileComponent implements OnInit {
  @Input() IsAmerenAdmin: boolean;
  @Input() CurrentUser: any;
  userInitials: string;
  userRole: string;
  firstName: any;
  lastName: any;
  emailAddress: any;
  agencyEFTFlag: string;

  constructor(
    private _authService: AuthService,
    private _manageProfileAPIManager: ManageprofileApiManagerService,
    private _router: Router
  ) {
    this._authService.getUser().then((user) => {
      this.CurrentUser = user?.profile;
    });
  }

  ngOnInit(): void {
    try {
      this.firstName = Array.isArray(this.CurrentUser.firstname)
        ? this.CurrentUser.firstname[0]
        : this.CurrentUser.firstname;
      this.lastName = Array.isArray(this.CurrentUser.lastname)
        ? this.CurrentUser.lastname[0]
        : this.CurrentUser.lastname;

      this.userInitials =
        this.firstName.substr(0, 1) + '' + this.lastName.substr(0, 1);

      this.emailAddress = Array.isArray(this.CurrentUser.email)
        ? this.CurrentUser.email[0]
        : this.CurrentUser.email;

      this.userRole = '';
      if (this.CurrentUser.agencyRoleMap.length > 0) {
        type roleValue = keyof typeof EAPUserRole;
        let _roleValue: roleValue = this.CurrentUser.agencyRoleMap[0].role;
        this.userRole = EAPUserRole[_roleValue];
      } else {
        //log the missing user role that came from user profile for any exceptions
      }

      if (!this.IsAmerenAdmin) {
        this._manageProfileAPIManager
          .retrieveAgencyInfo(this.CurrentUser.agencyRoleMap[0].AgencyID)
          .subscribe({
            next: (res) => {
              if (res.success) {
                this.agencyEFTFlag = res.data.isEFTAllowed ? 'Yes' : 'No';
              }
            },
            error: (e: AmerenError) => {
              //log the error for retrieving agency info
            },
          });
      }
    } catch {
      //log the error that came from user profile for any exceptions
    }
  }

  EditUserProfile() {
    this._router.navigate(['edit-profile']);
  }
}
