import * as _ from 'lodash';

export class CampaignDetailsModel {
  campaignId: number;
  campaignName: string;
  state: string;
  pledgeCode: string;
  pledgeDecode: string;
  campaignAmount: number;
  adminFeePercent: any;
  maximumPledgeAmount: number;
  minimumPledgeAmount: number;
  fixedPledgeAmount: number;
  isFixedIndividualPledge: string;
  startDate: string;
  endDate: string;
  pledgeAmount: number;
  timelinePercentComplete: number;
  financialPercentComplete: number;
  timeRemainingDays: number;
  campaignAgencies: Array<ParticipatingAgenciesModel>;

  constructor() {}
}

export class ParticipatingAgenciesModel {
  campaignAgencyID: number;
  campaignAgencyName: string;
  campaignID: number;
  agencyCode: string;
  campaignAmount: number;
  agencyAllocation: AgencyAllocationModel;
  pledge: PledgeAmountModel;
  remaining: RemainingAmountModel;
  adminFee: 0;
  isEdited: boolean;

  constructor() {}
}

export class AgencyAllocationModel {
  amount: number;
  fee: number;
  toCustomerAmount: number;
  constructor() {}
}

export class PledgeAmountModel {
  amount: number;
  fee: number;
  toCustomerAmount: number;
  constructor() {}
}

export class RemainingAmountModel {
  amount: number;
  fee: number;
  toCustomerAmount: number;
  constructor() {}
}
