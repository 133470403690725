import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, throwError, map, forkJoin } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { Profile } from 'src/app/shared/models/profile';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { pledgeType } from '../models/pledgetype.model';
import { PledgeEntryApiService } from './pledge-entry-api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PledgeEntryManagerService {
  pledgeTypeList: any[] = [];
  userProfile: Profile;
  currentUser: any;
  constructor(
    private pledgeEntryApiService: PledgeEntryApiService,
    private profileManager: ProfileManagerService,
    private _authService: AuthService
  ) {
    this.profileManager.profile.forEach((a: Profile) => {
      this.userProfile = a;
    });

    this._authService.getUser().then((user) => {
      this.currentUser = user?.profile;
    });
  }

  submitPledge(pledgeDetails: any) {
    return this.pledgeEntryApiService.submitPledge(pledgeDetails).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Submit pledge has failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      })
    );
  }

  getPledgeTypeListByState() {
    const statecode: any = this.currentUser.stateProvince;
    return this.pledgeEntryApiService.getPledgeTypeByState(statecode).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Submit pledge has failed',
            500,
            null,
            null,
            null
          );
        }

        return res.data;
      })
    );
  }

  getPledgeTypeList(agencycsscode: string) {
    const statecode: any = this.currentUser.stateProvince;
    return forkJoin([
      this.pledgeEntryApiService.getPledgeTypeByAgencyCSSCode(agencycsscode),
      this.pledgeEntryApiService.getPledgeTypeByState(statecode),
    ]).pipe(
      map((res: IGlobalAPIResponse[]) => {
        this.handleResponse(res);

        return this.pledgeTypeList;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getAgencyPledgeTypeList(agencycsscode: string, agencyID: string) {
    return forkJoin([
      this.pledgeEntryApiService.getPledgeTypeByAgencyCSSCode(agencycsscode),
      this.pledgeEntryApiService.getPledgeTypeByAgencyId(agencyID),
    ]).pipe(
      map((res: IGlobalAPIResponse[]) => {
        this.handleResponse(res);

        return this.pledgeTypeList;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  handleResponse(res: IGlobalAPIResponse[]) {
    this.pledgeTypeList = [];

    if (res[0].success) {
      let pledgeTypeByCssCode = [];
      pledgeTypeByCssCode = res[0].data;
      if (pledgeTypeByCssCode.length > 0) {
        pledgeTypeByCssCode.forEach((data: any) => {
          const dateValue = new Date();
          const currentDate = formatDate(dateValue, 'yyyy-MM-dd', 'en-US');
          if (
            new Date(data.endDate) >= new Date(currentDate) &&
            new Date(data.startDate) <= new Date(currentDate)
          ) {
            const CssPledgetype = new pledgeType();
            CssPledgetype.pledgeTypeCode = data.pledgeTypeCode;
            CssPledgetype.pledgeTypeDescription = data.pledgeTypeDescription;
            CssPledgetype.minPledge = data.minPledge;
            CssPledgetype.maxPLedge = data.maxPledge;
            CssPledgetype.isFixedPledge = data.isFixedPledge == 'Y';
            CssPledgetype.fixPledgeAmount = data.fixPledgeAmount;
            CssPledgetype.remainingBalanceAmount = data.remainingBalanceAmount;
            CssPledgetype.isCampaignPledgeType =
              data.pledgeTypeCode.length > 3 ? true : false;
            CssPledgetype.endDate = data.endDate;
            CssPledgetype.startDate = data.startDate;
            CssPledgetype.isValidMissouri = !_.isNil(data.isValidMissouri)
              ? data.isValidMissouri
              : false;
            CssPledgetype.isValidIllinois = !_.isNil(data.isValidIllinois)
              ? data.isValidIllinois
              : false;
            this.pledgeTypeList.push(CssPledgetype);
          }
        });
      }
    }

    if (res[1].success) {
      let pledgeTypeByState = [];
      pledgeTypeByState = res[1].data;

      if (pledgeTypeByState.length > 0) {
        pledgeTypeByState.forEach((data: any) => {
          const statePledgetype = new pledgeType();
          statePledgetype.pledgeTypeCode = data.pledgeTypeCode;
          statePledgetype.pledgeTypeDescription = data.pledgeTypeDescription;
          statePledgetype.minPledge = data.minPledge;
          statePledgetype.maxPLedge = data.maxPledge;
          statePledgetype.isFixedPledge = data.isFixedPledge == 'Y';
          statePledgetype.fixPledgeAmount = data.fixPledgeAmount;
          statePledgetype.remainingBalanceAmount = data.remainingBalanceAmount;
          statePledgetype.isCampaignPledgeType =
            data.pledgeTypeCode.length > 3 ? true : false;
          statePledgetype.endDate = data.endDate;
          statePledgetype.startDate = data.startDate;
          statePledgetype.isValidMissouri = !_.isNil(data.isValidMissouri)
            ? data.isValidMissouri
            : false;
          statePledgetype.isValidIllinois = !_.isNil(data.isValidIllinois)
            ? data.isValidIllinois
            : false;
          this.pledgeTypeList.push(statePledgetype);
        });
      }

      return this.pledgeTypeList;
    } else {
      return this.pledgeTypeList;
    }
  }
}
