<div class="view-none-mobile">
  <div id="activecampaign" class="review__campaign__list-container container">
    <div class="row mb-4">
      <div class="review__campaign__list-title col-md-7">Active Campaigns</div>
      <div id="activecampaignlist" class="col-md-5">
        <div class="input-group campaign_list-info ms-auto">
          <img
            src="assets/icon/clock-icon.svg"
            alt="This pledge is within 30 days of expiration"
          />
          <span class="alert-text"
            >Indicates campaign is within 30 days of expiration</span
          >
        </div>
      </div>
    </div>
    <div class="input-group mb-3 ms-auto w-25">
      <input
        type="search"
        class="form-control mt-0"
        placeholder="search"
        aria-label="searchstring"
        [(ngModel)]="searchStringforActiveCampaign"
        aria-describedby="searchstringforactivecampaign"
      />
      <span class="input-group-text" id="searchstringforactivecampaign"
        ><em class="fa fa-search"></em
      ></span>
    </div>

    <div
      id="agency__review__pending__Loading__container"
      class="review_agency-list"
      *ngIf="isLoading"
    >
      <div class="row select__agency-row select__agency-header">
        <div class="col-3 select__agency-header">Campaign Title</div>
        <div class="col select__agency-header">Start Date</div>
        <div class="col select__agency-header">End Date</div>
        <div class="col select__agency-header" *ngIf="showData">
          Time Remaining
        </div>
        <div class="col select__agency-header" *ngIf="showData">Allocated</div>
        <div class="col select__agency-header" *ngIf="showData">Percentage</div>
        <div class="col select__agency-header" *ngIf="showData">Used</div>
        <div class="col select__agency-header" *ngIf="showData">Remaining</div>
      </div>
      <div class="row select__agency-row">
        <div class="text-center col select__agency-column">
          <ameren-loading
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
      </div>
    </div>

    <div
      id="agency__review__pending__failure__container"
      class="review_agency-list"
      *ngIf="isAPIFailure"
    >
      <div class="row select__agency-row select__agency-header">
        <div class="col-3 select__agency-header">Campaign Title</div>
        <div class="col select__agency-header">Start Date</div>
        <div class="col select__agency-header">End Date</div>
        <div class="col select__agency-header" *ngIf="showData">
          Time Remaining
        </div>
        <div class="col select__agency-header" *ngIf="showData">Allocated</div>
        <div class="col select__agency-header" *ngIf="showData">Percentage</div>
        <div class="col select__agency-header" *ngIf="showData">Used</div>
        <div class="col select__agency-header" *ngIf="showData">Remaining</div>
      </div>
      <div class="row select__agency-row">
        <div class="col select__agency-column text-danger">
          <em class="far fa-times-circle"></em>
          Failed to get the agency registration list. Please try again later.
        </div>
      </div>
    </div>

    <div
      id="activecampaign__list__view"
      class="review_agency-list select_agency-border"
      *ngIf="!isLoading && !isAPIFailure"
    >
      <div
        id="agency__review__pending__header"
        class="row select__agency-row select__agency-header select__agency-header-main table-header static-header"
      >
        <div class="col-5 select__agency-header">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="campaignName"
          >
            <span class="column-name">Campaign Title</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="campaignFromDate"
          >
            <span class="column-name">Start Date</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="campaignToDate"
          >
            <span class="column-name">End Date</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="timeRemainingDayHour"
          >
            <span class="column-name">Time Remaining</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="allocated"
          >
            <span class="column-name">Allocated</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="percentage"
          >
            <span class="column-name">Percentage</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="used"
          >
            <span class="column-name">Used</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="activeCampaignList"
            data-order="asc"
            data-name="remainingAmount"
          >
            <span class="column-name">Remaining</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
      </div>
      <div>
        <div
          class="row select__agency-row"
          *ngIf="
            activeCampaignList.length === 0 ||
            (activeCampaignList | searchFilter : searchStringforActiveCampaign)
              .length === 0
          "
        >
          <div class="col-12 select__agency-column">
            No active campaign records found!
          </div>
        </div>

        <div
          id="agency__review__pending__list"
          class="row select__agency-row"
          *ngFor="
            let activeCampaign of activeCampaignList
              | searchFilter : searchStringforActiveCampaign;
            let i = index
          "
        >
          <div class="col-5 select__agency-column">
            <div class="agency__name justify-content-center">
              <img
                *ngIf="activeCampaign.isNearExpiration"
                src="assets/icon/clock-icon.svg"
                alt="This pledge is within 30 days of expiration"
              />
              <span
                class="m-1"
                [routerLink]="['/campaign-details', activeCampaign.campaignID]"
              >
                {{ activeCampaign.campaignName }}
              </span>
            </div>
          </div>

          <div class="col select__agency-column select__agency-column-desktop">
            <span> {{ activeCampaign.campaignFromDate }} </span>
          </div>

          <div class="col select__agency-column select__agency-column-desktop">
            <span> {{ activeCampaign.campaignToDate }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span
              [ngClass]="
                activeCampaign.timeRemaining < 30 ? 'isNearingExpiryDate' : ''
              "
              >{{ activeCampaign.timeRemainingDayHour }}</span
            >
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ activeCampaign.allocated | currency }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ activeCampaign.percentage + '%' }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ activeCampaign.used | currency }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ activeCampaign.remainingAmount | currency }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="pendingCampaign" class="review__campaign__list-container container">
    <div class="row mb-4">
      <div class="review__campaign__list-title col">Pending Campaigns</div>
    </div>

    <div class="input-group mb-3 ms-auto w-25">
      <input
        type="search"
        class="form-control mt-0"
        placeholder="search"
        aria-label="searchstring"
        [(ngModel)]="searchStringforPendingCampaign"
        aria-describedby="searchStringforPendingCampaign"
      />
      <span class="input-group-text" id="searchStringforPendingCampaign"
        ><em class="fa fa-search"></em
      ></span>
    </div>

    <div
      id="agency__review__pending__Loading__container"
      class="review_agency-list"
      *ngIf="isLoading"
    >
      <div class="row select__agency-row select__agency-header">
        <div class="col-3 select__agency-header">Campaign Title</div>
        <div class="col select__agency-header">Start Date</div>
        <div class="col select__agency-header">End Date</div>
        <div class="col select__agency-header" *ngIf="showData">
          Time Remaining
        </div>
        <div class="col select__agency-header" *ngIf="showData">Allocated</div>
        <div class="col select__agency-header" *ngIf="showData">Percentage</div>
        <div class="col select__agency-header" *ngIf="showData">Used</div>
        <div class="col select__agency-header" *ngIf="showData">Remaining</div>
      </div>
      <div class="row select__agency-row">
        <div class="text-center col select__agency-column">
          <ameren-loading
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
      </div>
    </div>

    <div
      id="agency__review__pending__failure__container"
      class="review_agency-list"
      *ngIf="isAPIFailure"
    >
      <div class="row select__agency-row select__agency-header">
        <div class="col-3 select__agency-header">Campaign Title</div>
        <div class="col select__agency-header">Start Date</div>
        <div class="col select__agency-header">End Date</div>
        <div class="col select__agency-header" *ngIf="showData">
          Time Remaining
        </div>
        <div class="col select__agency-header" *ngIf="showData">Allocated</div>
        <div class="col select__agency-header" *ngIf="showData">Percentage</div>
        <div class="col select__agency-header" *ngIf="showData">Used</div>
        <div class="col select__agency-header" *ngIf="showData">Remaining</div>
      </div>
      <div class="row select__agency-row">
        <div class="col select__agency-column text-danger">
          <em class="far fa-times-circle"></em>
          Failed to get the agency registration list. Please try again later.
        </div>
      </div>
    </div>

    <div
      id="review__agency__list"
      class="review_agency-list select_agency-border"
      *ngIf="!isLoading && !isAPIFailure"
    >
      <div
        id="pendingcampaign__header"
        class="row select__agency-row select__agency-header select__agency-header-main table-header static-header"
      >
        <div class="col-5 select__agency-header">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="campaignName"
          >
            <span class="column-name">Campaign Title</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="campaignFromDate"
          >
            <span class="column-name">Start Date</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="campaignToDate"
          >
            <span class="column-name">End Date</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="allocated"
          >
            <span class="column-name">Allocated</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="percentage"
          >
            <span class="column-name">Percentage</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="used"
          >
            <span class="column-name">Used</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="pendingCampaignList"
            data-order="asc"
            data-name="remainingAmount"
          >
            <span class="column-name">Remaining</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
      </div>
      <div>
        <div
          class="row select__agency-row"
          *ngIf="
            pendingCampaignList.length === 0 ||
            (
              pendingCampaignList
              | searchFilter : searchStringforPendingCampaign
            ).length === 0
          "
        >
          <div class="col-12 select__agency-column">
            No pending campaign records found!
          </div>
        </div>
        <div
          id="agency__review__pending__list"
          class="row select__agency-row"
          *ngFor="
            let pendingCampaign of pendingCampaignList
              | searchFilter : searchStringforPendingCampaign;
            let i = index
          "
        >
          <div class="col-5 select__agency-column">
            <div class="agency__name justify-content-center">
              <span
                [routerLink]="['/campaign-details', pendingCampaign.campaignID]"
              >
                {{ pendingCampaign.campaignName }}
              </span>
            </div>
          </div>

          <div class="col select__agency-column select__agency-column-desktop">
            <span> {{ pendingCampaign.campaignFromDate }} </span>
          </div>

          <div class="col select__agency-column select__agency-column-desktop">
            <span> {{ pendingCampaign.campaignToDate }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ pendingCampaign.allocated | currency }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ pendingCampaign.percentage + '%' }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ pendingCampaign.used | currency }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ pendingCampaign.remainingAmount | currency }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="expiredcampaign" class="review__campaign__list-container container">
    <div class="row mb-4">
      <div class="review__campaign__list-title col">Expired Campaigns</div>
    </div>

    <div class="input-group mb-3 ms-auto w-25">
      <input
        type="search"
        class="form-control mt-0"
        placeholder="search"
        aria-label="searchstring"
        [(ngModel)]="searchStringforInactiveCampaign"
        aria-describedby="searchStringforInactiveCampaign"
      />
      <span class="input-group-text" id="searchStringforInactiveCampaign"
        ><em class="fa fa-search"></em
      ></span>
    </div>

    <div
      id="agency__review__pending__Loading__container"
      class="review_agency-list"
      *ngIf="isLoading"
    >
      <div class="row select__agency-row select__agency-header">
        <div class="col-3 select__agency-header">Campaign Title</div>
        <div class="col select__agency-header">Start Date</div>
        <div class="col select__agency-header">End Date</div>
        <div class="col select__agency-header" *ngIf="showData">Allocated</div>
        <div class="col select__agency-header" *ngIf="showData">Percentage</div>
        <div class="col select__agency-header" *ngIf="showData">Used</div>
      </div>
      <div class="row select__agency-row">
        <div class="text-center col select__agency-column">
          <ameren-loading
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
      </div>
    </div>

    <div
      id="agency__review__pending__failure__container"
      class="review_agency-list"
      *ngIf="isAPIFailure"
    >
      <div class="row select__agency-row select__agency-header">
        <div class="col-3 select__agency-header">Campaign Title</div>
        <div class="col select__agency-header">Start Date</div>
        <div class="col select__agency-header">End Date</div>
        <div class="col select__agency-header" *ngIf="showData">Allocated</div>
        <div class="col select__agency-header" *ngIf="showData">Percentage</div>
        <div class="col select__agency-header" *ngIf="showData">Used</div>
      </div>
      <div class="row select__agency-row">
        <div class="col select__agency-column text-danger">
          <em class="far fa-times-circle"></em>
          Failed to get the agency registration list. Please try again later.
        </div>
      </div>
    </div>

    <div
      id="review__agency__list"
      class="review_agency-list select_agency-border"
      *ngIf="!isLoading && !isAPIFailure"
    >
      <div
        id="pendingcampaign__header"
        class="row select__agency-row select__agency-header select__agency-header-main table-header static-header"
      >
        <div class="col-5 select__agency-header">
          <span
            class="table-head"
            [appSort]="inActiveCampaignList"
            data-order="asc"
            data-name="campaignName"
          >
            <span class="column-name">Campaign Title</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header">
          <span
            class="table-head"
            [appSort]="inActiveCampaignList"
            data-order="asc"
            data-name="campaignFromDate"
          >
            <span class="column-name">Start Date</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header">
          <span
            class="table-head"
            [appSort]="inActiveCampaignList"
            data-order="asc"
            data-name="campaignToDate"
          >
            <span class="column-name">End Date</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="inActiveCampaignList"
            data-order="asc"
            data-name="allocated"
          >
            <span class="column-name">Allocated</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="inActiveCampaignList"
            data-order="asc"
            data-name="percentage"
          >
            <span class="column-name" *ngIf="showData">Percentage</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
        <div class="col select__agency-header" *ngIf="showData">
          <span
            class="table-head"
            [appSort]="inActiveCampaignList"
            data-order="asc"
            data-name="used"
          >
            <span class="column-name">Used</span>
            <em class="fa-solid fa-sort"></em
          ></span>
        </div>
      </div>
      <div>
        <div
          class="row select__agency-row"
          *ngIf="
            inActiveCampaignList.length === 0 ||
            (
              inActiveCampaignList
              | searchFilter : searchStringforInactiveCampaign
            ).length === 0
          "
        >
          <div class="col-12 select__agency-column">
            No inactive campaign records found!
          </div>
        </div>
        <div
          id="agency__review__pending__list"
          class="row select__agency-row"
          *ngFor="
            let inactiveCampaign of inActiveCampaignList
              | searchFilter : searchStringforInactiveCampaign;
            let i = index
          "
        >
          <div class="col-5 select__agency-column">
            <div class="agency__name justify-content-center">
              <span
                class="p-2"
                [routerLink]="[
                  '/campaign-details',
                  inactiveCampaign.campaignID
                ]"
              >
                {{ inactiveCampaign.campaignName }}
              </span>
            </div>
          </div>

          <div class="col select__agency-column select__agency-column-desktop">
            <span> {{ inactiveCampaign.campaignFromDate }} </span>
          </div>

          <div class="col select__agency-column select__agency-column-desktop">
            <span> {{ inactiveCampaign.campaignToDate }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ inactiveCampaign.allocated | currency }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ inactiveCampaign.percentage + '%' }} </span>
          </div>

          <div
            class="col select__agency-column select__agency-column-desktop"
            *ngIf="showData"
          >
            <span> {{ inactiveCampaign.used | currency }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-------mobile------------>
<div class="view-mobile">
  <div id="activecampaignmobile" class="manageview__profile_container">
    <p class="pendingagency__users-title campaign-list-title">
      Active Campaigns
    </p>
    <div class="input-group campaign_list-info ms-auto">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
      >
        <path
          fill-rule="evenodd"
          d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M12,6 C12.5522847,6 13,6.44771525 13,7 L13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,7 C11,6.44771525 11.4477153,6 12,6 Z"
        />
      </svg>
      <span class="alert-text"
        >Indicate pledge is within 30 days of expiration</span
      >
    </div>
    <div class="input-group mb-3 w-50">
      <input
        type="search"
        class="form-control mt-0"
        placeholder="search"
        aria-label="searchstring"
        [(ngModel)]="searchStringforActiveCampaign"
        aria-describedby="searchstringforactivecampaign"
      />
      <span class="input-group-text" id="searchstringforactivecampaign"
        ><em class="fa fa-search"></em
      ></span>
    </div>

    <div class="view__profile__container view_profile">
      <div>
        <div class="ameren_user_container">
          <div class="login_list_container">
            <div *ngIf="isLoading">
              <ameren-loading
                class="d-flex p-10 justify-content-center"
              ></ameren-loading>
            </div>
            <div
              *ngIf="
                activeCampaignList.length === 0 && !isAPIFailure && !isLoading
              "
            >
              No active campaigns found!
            </div>
            <div *ngIf="isAPIFailure">
              <em class="far fa-times-circle"></em> Failed to get the agency
              registration list. Please try again later.
            </div>
            <div
              *ngFor="let activeCampaign of activeCampaignList; let i = index"
            >
              <div class="user_details">
                <div class="info">
                  <span class="info_data">
                    <img
                      *ngIf="activeCampaign.isNearExpiration"
                      src="assets/icon/clock-icon.svg"
                      alt="This pledge is within 30 days of expiration"
                    />
                  </span>
                </div>
                <div class="info">
                  <span class="label">Campaign Title</span>
                  <span
                    class="info_data campaign-name-color"
                    [routerLink]="[
                      '/campaign-details',
                      activeCampaign.campaignID
                    ]"
                    >{{ activeCampaign.campaignName }}
                  </span>
                </div>
                <div class="info">
                  <span class="label">Start Date</span>
                  <span class="info_data">
                    {{ activeCampaign.campaignFromDate }}
                  </span>
                </div>
                <div class="info">
                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        toggleActiveCampaignDetailsHandler(activeCampaign)
                      "
                      *ngIf="activeCampaign.toggleStatus == true"
                      class="icon-svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="#1b6cb5"
                        d="M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z"
                      /></svg
                  ></span>

                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        toggleActiveCampaignDetailsHandler(activeCampaign)
                      "
                      *ngIf="!activeCampaign.toggleStatus"
                      class="icon-svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="#1b6cb5"
                        d="m 24 30.75 l -12 -12 l 2.15 -2.15 L 24 26.5 l 9.85 -9.85 L 36 18.8 Z"
                      /></svg
                  ></span>
                </div>
              </div>
              <div *ngIf="activeCampaign.toggleStatus === true">
                <div class="user_details_exp">
                  <div class="info">
                    <span class="label">End Date</span>
                    <span class="info_data">{{
                      activeCampaign.campaignToDate
                    }}</span>
                  </div>
                  <div class="info" *ngIf="showData">
                    <span class="label">Allocated</span>
                    <span class="info_data">{{
                      activeCampaign.allocated | currency
                    }}</span>
                  </div>
                </div>
                <div class="user_details_exp" *ngIf="showData">
                  <div class="info">
                    <span class="label">Percentage</span>
                    <span class="info_data">{{
                      activeCampaign.percentage + '%'
                    }}</span>
                  </div>
                  <div class="info">
                    <span class="label">Used</span>
                    <span class="info_data">{{
                      activeCampaign.used | currency
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="pendingcampaignmobile" class="manageview__profile_container">
    <p class="pendingagency__users-title campaign-list-title header-margin">
      Pending Campaigns
    </p>
    <div class="view__profile__container view_profile">
      <div>
        <div class="ameren_user_container">
          <div class="login_list_container">
            <div *ngIf="isLoading">
              <ameren-loading
                class="d-flex p-10 justify-content-center"
              ></ameren-loading>
            </div>
            <div
              *ngIf="
                pendingCampaignList.length === 0 && !isAPIFailure && !isLoading
              "
            >
              No pending campaigns found!
            </div>
            <div *ngIf="isAPIFailure">
              <em class="far fa-times-circle"></em> Failed to get the agency
              registration list. Please try again later.
            </div>
            <div
              *ngFor="let pendingCampaign of pendingCampaignList; let i = index"
            >
              <div class="user_details">
                <div class="info">
                  <span class="label">Campaign Title</span>
                  <span
                    class="info_data campaign-name-color"
                    [routerLink]="[
                      '/campaign-details',
                      pendingCampaign.campaignID
                    ]"
                  >
                    {{ pendingCampaign.campaignName }}
                  </span>
                </div>
                <div class="info">
                  <span class="label">Start Date</span>
                  <span class="info_data">
                    {{ pendingCampaign.campaignFromDate }}</span
                  >
                </div>
                <div class="info">
                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        togglePendingCampaignDetailsHandler(pendingCampaign)
                      "
                      *ngIf="pendingCampaign.toggleStatus == true"
                      class="icon-svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="#1b6cb5"
                        d="M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z"
                      /></svg
                  ></span>

                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        togglePendingCampaignDetailsHandler(pendingCampaign)
                      "
                      *ngIf="!pendingCampaign.toggleStatus"
                      class="icon-svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="#1b6cb5"
                        d="m 24 30.75 l -12 -12 l 2.15 -2.15 L 24 26.5 l 9.85 -9.85 L 36 18.8 Z"
                      /></svg
                  ></span>
                </div>
              </div>
              <div *ngIf="pendingCampaign.toggleStatus === true">
                <div class="user_details_exp">
                  <div class="info">
                    <span class="label">End Date</span>
                    <span class="info_data">{{
                      pendingCampaign.campaignToDate
                    }}</span>
                  </div>
                  <div class="info">
                    <span class="label">Allocated</span>
                    <span class="info_data">{{
                      pendingCampaign.allocated | currency
                    }}</span>
                  </div>
                </div>
                <div class="user_details_exp">
                  <div class="info">
                    <span class="label">Percentage</span>
                    <span class="info_data">{{
                      pendingCampaign.percentage + '%'
                    }}</span>
                  </div>
                  <div class="info">
                    <span class="label">Used</span>
                    <span class="info_data">{{
                      pendingCampaign.used | currency
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="expiredcampaignmobile" class="manageview__profile_container">
    <p class="pendingagency__users-title campaign-list-title header-margin">
      Expired Campaigns
    </p>
    <div class="view__profile__container view_profile">
      <div>
        <div class="ameren_user_container">
          <div class="login_list_container">
            <div *ngIf="isLoading">
              <ameren-loading
                class="d-flex p-10 justify-content-center"
              ></ameren-loading>
            </div>
            <div
              *ngIf="
                inActiveCampaignList.length === 0 && !isAPIFailure && !isLoading
              "
            >
              No inactive campaigns found!
            </div>
            <div *ngIf="isAPIFailure">
              <em class="far fa-times-circle"></em> Failed to get the agency
              registration list. Please try again later.
            </div>

            <div
              *ngFor="
                let inActiveCampaign of inActiveCampaignList;
                let i = index
              "
            >
              <div class="user_details_exp">
                <div class="info">
                  <span class="label">Campaign Title</span>
                  <span
                    class="info_data campaign-name-color"
                    [routerLink]="[
                      '/campaign-details',
                      inActiveCampaign.campaignID
                    ]"
                    >{{ inActiveCampaign.campaignName }}</span
                  >
                </div>
                <div class="info">
                  <span class="label">Start Date</span>
                  <span class="info_data">
                    {{ inActiveCampaign.campaignFromDate }}</span
                  >
                </div>
                <div class="info">
                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        toggleInActiveCampaignDetailsHandler(inActiveCampaign)
                      "
                      *ngIf="inActiveCampaign.toggleStatus === true"
                      class="icon-svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="#1b6cb5"
                        d="M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z"
                      /></svg
                  ></span>

                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        toggleInActiveCampaignDetailsHandler(inActiveCampaign)
                      "
                      *ngIf="!inActiveCampaign.toggleStatus"
                      class="icon-svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="#1b6cb5"
                        d="m 24 30.75 l -12 -12 l 2.15 -2.15 L 24 26.5 l 9.85 -9.85 L 36 18.8 Z"
                      /></svg
                  ></span>
                </div>
              </div>
              <div *ngIf="inActiveCampaign.toggleStatus == true">
                <div class="user_details_exp">
                  <div class="info">
                    <span class="label">End Date</span>
                    <span class="info_data">{{
                      inActiveCampaign.campaignToDate
                    }}</span>
                  </div>
                  <div class="info">
                    <span class="label">Allocated</span>
                    <span class="info_data">{{
                      inActiveCampaign.allocated | currency
                    }}</span>
                  </div>
                </div>
                <div class="user_details_exp">
                  <div class="info">
                    <span class="label">Percentage</span>
                    <span class="info_data">{{
                      inActiveCampaign.percentage + '%'
                    }}</span>
                  </div>
                  <div class="info">
                    <span class="label">Used</span>
                    <span class="info_data">{{
                      inActiveCampaign.used | currency
                    }}</span>
                  </div>
                </div>
                <div class="user_details_exp">
                  <div class="info">
                    <span class="label">Action</span>
                    <span class="info_data"
                      ><button class="btn btn-success">Re-Open</button></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
