import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpBackend,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';

@Injectable({
  providedIn: 'root',
})
export class RegistrationApiService {
  private httpClientwithoutinterceptor: HttpClient;
  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpClientwithoutinterceptor = new HttpClient(handler);
  }

  submitRegistration(data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);
    const body = data;
    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/registration`,
      body,
      { headers }
    );
  }

  submitAgencyRegistration(data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);
    const body = data;
    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/agencyregistration`,
      body,
      { headers }
    );
  }

  updateProfile(data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    const body = data;
    return this.http.patch<IGlobalAPIResponse>(
      `${environment.apig}/registration`,
      body,
      { headers }
    );
  }

  private buildHttpParams(arrOfParams: Array<any>) {
    let httpParams: HttpParams = new HttpParams();
    arrOfParams.forEach((param, i) => {
      const name = Object.getOwnPropertyNames(param)[0];
      httpParams = httpParams.set(name, arrOfParams[i][name]);
    });

    return httpParams;
  }

  changePassword(email: string, currentPass: string, newPass: string) {
    let headers = new HttpHeaders({
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'Content-Type': 'application/json',
    });

    let body = {
      username: email,
      currentpassword: currentPass,
      userpassword: newPass,
    };

    let params = this.buildHttpParams([{ _action: 'changePassword' }]);
    const encodedEmail = encodeURI(email).replace(/#/g, '%23');

    return this.httpClientwithoutinterceptor.post<any>(
      environment.changePasswordUrl + `/${encodedEmail}`,
      body,
      {
        headers,
        params,
        withCredentials: true,
      }
    );
  }

  recaptchaVerification(data: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const body = data;

    return this.http.post(`${environment.apig}/recaptcha`, body, { headers });
  }

  validateAgencyPin(pin: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('agencypin', `${pin}`);

    return this.http.get(`${environment.apig}/validateagencypin`, {
      headers: headers,
    });
  }
}
