<div class="container admin__dashboard mt-3">
  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-auto manage__profile">
      <div>
        <app-manageprofile
          [IsAmerenAdmin]="isAmerenAdmin"
          [CurrentUser]="currentUser"
        ></app-manageprofile>
      </div>
      <div class="agencyinformation">
        <app-agencyinformation
          *ngIf="!isAmerenAdmin || isAgencyView"
        ></app-agencyinformation>
      </div>
    </div>
    <div
      class="col-md-8 col-lg-8 col-sm-auto admin__users-list"
      *ngIf="isAmerenAdmin && !isAgencyView"
    >
      <div class="admin-container">
        <app-pendingagencyadmin></app-pendingagencyadmin>
      </div>

      <div class="all__ameren__users-list">
        <p class="ameren__users-title">Pending Ameren Logins</p>
      </div>
      <app-pending-amerenuser class="ameren__users-component"></app-pending-amerenuser>

      <div class="all__ameren__users-list">
        <p class="ameren__users-title">Ameren Logins</p>
      </div>
      <app-amerenuser class="ameren__users-component"></app-amerenuser>
    </div>
    <div
      class="col-md-8 col-lg-8 col-sm-auto agency__admin__users-list"
      *ngIf="!isAmerenAdmin || isAgencyView"
    >
      <div class="pending__agency-users">
        <app-agencyuser></app-agencyuser>
      </div>
      <div  class="pending__agency-users">
        <app-pending-agency-user></app-pending-agency-user>
      </div>
    </div>
  </div>
</div>
