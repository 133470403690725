import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';

export class AgencyCampaign {
  campaignID: any;
  campaignName: string;
  campaignStatus: string;
  campaignFromDate: string;
  campaignToDate: string;
  timeRemaining: number;
  timeRemainingDayHour: string;
  allocated: number;
  percentage: number;
  used: number;
  remainingAmount: number;
  campaignAgencyStatus?: string;
  toggleStatus?: boolean;
  campaignAgencyID: any;
  campaignFundSpentPercentage?: any;
  timeRemainingDays?: any;
  isNearExpiration: boolean;

  constructor(agencyCampaign?: any) {
    this.campaignID = !_.isNil(agencyCampaign.campaignID)
      ? agencyCampaign.campaignID
      : 0;
    this.campaignName = !_.isNil(agencyCampaign.campaignName)
      ? agencyCampaign.campaignName.toUpperCase()
      : '';
    this.campaignStatus = !_.isNil(agencyCampaign.campaignStatus)
      ? agencyCampaign.campaignStatus.toLowerCase()
      : '';
    this.campaignFromDate = !_.isNil(agencyCampaign.campaignFromDate)
      ? agencyCampaign.startDate
      : !_.isNil(agencyCampaign.startDate)
      ? agencyCampaign.startDate
      : '';
    this.campaignToDate = !_.isNil(agencyCampaign.campaignToDate)
      ? agencyCampaign.campaignToDate
      : !_.isNil(agencyCampaign.endDate)
      ? agencyCampaign.endDate
      : '';
    this.timeRemaining = !_.isNil(agencyCampaign.timeRemaining)
      ? agencyCampaign.timeRemaining
      : 0;
    this.timeRemainingDayHour = !_.isNil(agencyCampaign.timeRemainingDayHour)
      ? agencyCampaign.timeRemainingDayHour
      : '';
    this.allocated = !_.isNil(agencyCampaign.allocated)
      ? agencyCampaign.allocated
      : 0;
    this.percentage = !_.isNil(agencyCampaign.percentage)
      ? agencyCampaign.percentage
      : 0;
    this.used = !_.isNil(agencyCampaign.used) ? agencyCampaign.used : 0;
    this.remainingAmount = !_.isNil(agencyCampaign.remainingAmount)
      ? agencyCampaign.remainingAmount
      : 0;
    this.campaignAgencyStatus = !_.isNil(agencyCampaign.campaignAgencyStatus)
      ? agencyCampaign.campaignAgencyStatus
      : '';
    this.campaignAgencyID = !_.isNil(agencyCampaign.campaignAgencyID)
      ? agencyCampaign.campaignAgencyID
      : 0;

    this.campaignFundSpentPercentage = Math.round(
      (this.used / this.allocated) * 100
    );

    let currentDate = moment().tz('America/Chicago').format('YYYY-MM-DD');
    let campaignToDateValue = moment(this.campaignToDate);
    let expirationDays = campaignToDateValue.diff(currentDate, 'days');
    this.isNearExpiration = expirationDays <= 30 ? true : false;
  }
}
