import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-program-banner',
  templateUrl: './program-banner.component.html',
  styleUrls: ['./program-banner.component.scss']
})
export class ProgramBannerComponent implements OnInit,OnChanges {

  constructor() { }
  ngOnChanges(changes:any) {  
    this.state =this.selectedState==''||this.selectedState==null|| this.selectedState==undefined?'': this.selectedState;
  }
  @Input() selectedState='';
  state: string =this.selectedState==''||this.selectedState==null|| this.selectedState==undefined?'': this.selectedState;

  ngOnInit(): void {
  }
  Links: any[] = 
  [
    {
      "illinois":{
        href: 'https://www.warmneighborscoolfriends.org/',
        long_text:'Warm Neighbor Cool Friends',
        short_text:'WNCF',
        icon: 'assets/images/banner-images/WNCF.svg'
      },
      "missouri":{
        href: environment.amerenUrl + '/missouri/residential/energy-assistance/keeping-current-cool',
        long_text:'Keeping Current',
        short_text:'KC',
        icon: 'assets/images/banner-images/WNCF.svg'
      }
    },
    {
      "illinois":{
        href: 'https://www.ameren.com/illinois/residential/energy-assistance/verify-income-2',
        long_text:'Income Eligible Status',
        short_text:'IES',
        icon: 'assets/images/banner-images/Flexpay.svg'
      },
      "missouri":{
        href: environment.amerenUrl + '/missouri/residential/energy-assistance/dollar-more',
        long_text:'Dollar More',
        short_text:'Dollar More',
        icon: 'assets/images/banner-images/Dollar More.svg'
      }
    },
    {
      "illinois":{
        href: environment.amerenUrl +'/illinois/account/customer-service/bill/budget-billing',
        long_text:'Budget Billing',
        short_text:'Budget Billing',
        icon: 'assets/images/banner-images/Budget Billing.svg'
      },
      "missouri":{
        href: environment.amerenUrl +'/missouri/account/customer-service/bill/budget-billing',
        long_text:'Budget Billing',
        short_text:'Budget Billing',
        icon: 'assets/images/banner-images/Budget Billing.svg'
      }
    },
    {
      "illinois":{
        href: environment.amerenUrl +'/illinois/account/customer-service/bill/pick-a-due-date',
        long_text:'Pick A Due Date',
        short_text:'PADD',
        icon: 'assets/images/banner-images/Calendar.svg'
      },
      "missouri":{
        href: environment.amerenUrl +'/missouri/account/customer-service/bill   ',
        long_text:'Pick A Due Date',
        short_text:'PADD',
        icon: 'assets/images/banner-images/Calendar.svg'
      }
    },   
  ]
}