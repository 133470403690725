import { Serializable } from '../../../app/shared/models/Serializable';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnumState } from '../enums/enumstate';

export class SplunkApiSuccessResponse extends Serializable {
  url?: string;
  apiResponse: any;
  status: any;
  statusText: string;

  /*istanbul ignore next*/
  constructor(values: Object = {}) {
    super(values);
  }
}

export class SplunkApiFailureResponse extends Serializable {
  url?: string;
  apiResponse: any;
  status: any;
  statusText: string;
  error?: any;

  /*istanbul ignore next*/
  constructor(values: Object = {}) {
    super(values);
  }
}

export enum EnumSplunkLevels {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum EnumSplunkSourceType {
  CLOUDHUBAPISUCCESSLOGS = 'api:success:logs',
  CLOUDHUBAPIFAILURELOGS = 'api:failure:logs',
  EAPUILOGS = 'ui:logs',
}

export class SplunkBodyInformation {
  sourcetype?: string;
  event?: SplunkEventInformation;
}

export class SplunkEventInformation {
  timestamp: any;
  state?: EnumState;
  userName?: string;
  level: string;
  message?: any;
  elapsed?: number;
  method?: string;
  apiName?: string;
  profile?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SplunkLoggerService {
  constructor(private http: HttpClient) {}

  logSplunk(payload: any): Observable<any> {
    const config = {
      token: environment.splunkToken,
      url: environment.splunkUrl,
    };

    const headers = new HttpHeaders()
      .set('Authorization', `Splunk ${config.token}`)
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body = payload;

    return this.http.post(`${config.url}`, body, { headers });
  }
}
