import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
constructor(private authService: AuthService) {}

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(
        this.checkRequest(req) ? 
        
        this.authService.getAccessToken().then((token: any) => {
            if(token) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return next.handle(req);
            }
        }).catch(() => {
            Promise.resolve();
        }) : Promise.resolve()
    ).pipe(switchMap(() => next.handle(req)));
    }

checkRequest(req: HttpRequest<any>): boolean {
    return !/splunkcloudhec/.test(req.url);
}

}
