import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { SelectAgencyApiService } from './select-agency-api.service';
import * as _ from 'lodash';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectAgencyManagerService {
  constructor(private selectAgencyAPIService: SelectAgencyApiService) {}

  getEAPAgenciesList() {
    return this.selectAgencyAPIService.getEAPAgenciesList().pipe(
      map((res: IGlobalAPIResponse) => {
        if (_.isNil(res) || !res.success) {
          throw new AmerenError(
            'Getting agency list has failed',
            500,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
