import { Component, OnInit } from '@angular/core';
import { EAPUsers } from 'src/app/shared/models/EAPUsers.model';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { IActivateOrDeactivateBody } from '../interfaces/IActivateOrDeactivateBody';
import { IAdminApprovalBody } from '../interfaces/IAdminApprovalBody';
import { AdministrationManagerService } from '../services/administration-manager.service';
import { EmailConstants } from 'src/app/shared/constants/emailConstants';
import { INotificationEmailBody } from '../interfaces/INotificationEmailBody';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/app/shared/models/profile';

declare var window: any;

@Component({
  selector: 'app-pending-amerenuser',
  templateUrl: './pending-amerenuser.component.html',
  styleUrls: ['./pending-amerenuser.component.scss'],
})
export class PendingAmerenuserComponent implements OnInit {
  adminmodal: any;
  amerenUsers: EAPUsers[];
  isLoading: boolean = true;
  isAPIFailure: boolean = false;
  mymodel: any;
  userProfile: Profile[];
  isApprovalLoading: boolean = true;
  isApprovalAPIFailure: boolean = false;
  isAmerenSupport: boolean | undefined;
  searchAmerenUser: any;

  constructor(
    private administrationService: AdministrationManagerService,
    private profileManager: ProfileManagerService
  ) {
    this.userProfile = this.profileManager.profile;

    this.profileManager.profile.forEach((r: any) => {
      this.isAmerenSupport = r.agencyList?.every(
        (a: any) => a.role.toLowerCase() === 'eapamerensupport'
      );
    });
  }

  ngOnInit(): void {
    this.mymodel = new window.bootstrap.Modal(
      document.getElementById('pendingamerenModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.adminmodal = new window.bootstrap.Modal(
      document.getElementById('adminModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.getEAPUsersList();
  }

  getEAPUsersList() {
    this.isLoading = true;
    this.isAPIFailure = false;
    this.administrationService.getEAPUsersList().subscribe({
      next: (data) => {
        this.isLoading = false;
        this.amerenUsers = [];
        this.handleAmerenUsers(data);
      },
      error: (err) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        console.log('error', err);
      },
    });
  }

  handleAmerenUsers(data: EAPUsers[]) {
    this.amerenUsers = data.filter(
      (user: any) =>
        (user.role === 'eapamerenadmin' || user.role === 'eapamerensupport') &&
        user.stateProvince.toLowerCase() ===
          this.userProfile[0].stateProvince?.toLowerCase() &&
        user.status === 'eappending'
    );
  }

  ui = {
    confirm: async () => this.createConfirm(),
  };

  createConfirm = () => {
    return new Promise((complete) => {
      $('#adminModalConfirmation #confirmYes').off('click');
      $('#adminModalConfirmation #confirmNo').off('click');

      $('#adminModalConfirmation #confirmYes').on('click', () => {
        $('.confirm').hide();
        complete(true);
      });
      $('#adminModalConfirmation #confirmNo').on('click', () => {
        $('.confirm').hide();
        complete(false);
      });

      this.adminmodal.show();
    });
  };

  async submitAdminRequest(data: any, user: EAPUsers) {
    const confirm = await this.ui.confirm();
    const approvalBody: IAdminApprovalBody = {
      id: user.mail,
      roles: {
        REPLACE: [
          {
            AgencyID: user.agencyID,
            role:
              data.target.value == 'eapRevokeStatus'
                ? user.role
                : data.target.value == 'eapPendingStatus'
                ? user.role
                : data.target.value == 'eapAmerenSupport'
                ? 'eapAmerenSupport'
                : data.target.value == 'eapAmerenAdmin'
                ? 'eapAmerenAdmin'
                : user.role,
            status:
              data.target.value === 'eapPendingStatus' ? 'eapPending' : '',
          },
        ],
      },
    };

    let isDenied = data.target.value == 'eapRevokeStatus';
    const activateOrDeactivateBody: IActivateOrDeactivateBody = {
      id: user.mail,
      active: true,
    };
    if (confirm) {
      this.adminmodal.hide();
      this.isApprovalLoading = true;
      this.isApprovalAPIFailure = false;
      await this.mymodel.show();
      await this.administrationService
        .submitAdminApproval(activateOrDeactivateBody, approvalBody)
        .subscribe({
          next: () => {
            if (isDenied) {
              activateOrDeactivateBody.active = false;
              this.administrationService
                .submitAdminApproval(activateOrDeactivateBody, approvalBody)
                .subscribe({
                  next: () => {
                    this.isApprovalLoading = false;
                    this.isApprovalAPIFailure = false;
                    this.getEAPUsersList();
                  },
                  error: () => {
                    this.isApprovalLoading = false;
                    this.isApprovalAPIFailure = true;
                    this.getEAPUsersList();
                  },
                });
            } else {
              this.isApprovalLoading = false;
              this.isApprovalAPIFailure = false;
              this.updateAmerenUser();
              this.getEAPUsersList();
            }
          },
          error: () => {
            this.isApprovalLoading = false;
            this.isApprovalAPIFailure = true;
            this.getEAPUsersList();
          },
        });
    }
  }

  updateAmerenUser() {
    this.administrationService.eventRefresh.next(true);
  }

  sendNotificationEmail(
    approvalBody: IAdminApprovalBody,
    activateOrDeactivateBody: IActivateOrDeactivateBody,
    user: EAPUsers
  ) {
    let body: any;
    //Admin Approved
    if (
      activateOrDeactivateBody.active &&
      approvalBody.roles.REPLACE[0].role == 'eapAmerenAdmin'
    ) {
      body = this.amerenAdminApprovalEmailTemplate(user);
    } else if (
      !activateOrDeactivateBody.active &&
      user.role == 'eapamerenadmin'
    ) {
      //Admin Request denial
      body = this.amerenAdminDenialEmailTemplate(user);
    }

    //Send notification only for Ameren user Admin access approvals or Ameren user Admin access denials/revoke
    if (body) {
      this.administrationService.sendEmailNotification(body).subscribe({
        next: (data) => {
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          console.log('error', err);
        },
      });
    }
  }

  amerenAdminApprovalEmailTemplate(user: EAPUsers) {
    let state = user.stateProvince == 'MO' ? 'Missouri' : 'Illinois';
    let statePhone =
      user.stateProvince == 'MO'
        ? EmailConstants.energyAssistance_MO_Contact
        : EmailConstants.energyAssistance_IL_Contact;
    let fromEmail =
      user.stateProvince == 'MO'
        ? EmailConstants.from_Email_MO
        : EmailConstants.from_Email_IL;
    let eapUrl =
      user.stateProvince == 'MO'
        ? EmailConstants.eap_MO_URL
        : EmailConstants.eap_IL_URL;
    let stateImage =
      user.stateProvince == 'MO'
        ? EmailConstants.mo_image_base64
        : EmailConstants.il_image_base64;

    let emailBody =
      '<div>' +
      "<div><div><img src='data:image/png;base64," +
      stateImage +
      "'/></div>" +
      '<div><p><strong>' +
      EmailConstants.amerenAdminApproval_Body_Title +
      '</strong></p>' +
      '</div></div>' +
      '<div><p>' +
      EmailConstants.amerenAdminApproval_Body
        .replace('{{state}}', state)
        .replace('{{eapURL}}', eapUrl) +
      '</p>' +
      '<p>UserID: ' +
      user.mail +
      '</p>' +
      '<p>Role: Ameren Administrator' +
      '<p>' +
      EmailConstants.email_contact
        .replace('{{state}}', state)
        .replace('{{statePhone}}', statePhone)
        .replace('{{stateEmail}}', fromEmail) +
      '</p>' +
      '</div>' +
      '<div>' +
      EmailConstants.email_Signature.replace('{{state}}', state) +
      '<p>' +
      EmailConstants.email_Footer +
      '</p>' +
      '</div>';

    let notificationEmailBody: INotificationEmailBody = {
      Body: btoa(emailBody),
      From: fromEmail,
      Subject: EmailConstants.amerenAdminApproval_Subject.replace(
        '{{state}}',
        state
      ),
      To: environment.testEmail ? environment.testEmail : user.mail,
      CC: '',
      AttachmentList: [],
    };

    return notificationEmailBody;
  }

  amerenAdminDenialEmailTemplate(user: EAPUsers) {
    let state = user.stateProvince == 'MO' ? 'Missouri' : 'Illinois';
    let statePhone =
      user.stateProvince == 'MO'
        ? EmailConstants.energyAssistance_MO_Contact
        : EmailConstants.energyAssistance_IL_Contact;
    let fromEmail =
      user.stateProvince == 'MO'
        ? EmailConstants.from_Email_MO
        : EmailConstants.from_Email_IL;
    let eapUrl =
      user.stateProvince == 'MO'
        ? EmailConstants.eap_MO_URL
        : EmailConstants.eap_IL_URL;
    let stateImage =
      user.stateProvince == 'MO'
        ? EmailConstants.mo_image_base64
        : EmailConstants.il_image_base64;

    let emailBody =
      '<div>' +
      "<div><div><img src='data:image/png;base64," +
      stateImage +
      "'/></div>" +
      '<div><p><strong>' +
      EmailConstants.amerenAdminDenial_Body_Title +
      '</strong></p>' +
      '</div></div>' +
      '<div><p>' +
      EmailConstants.amerenAdminDenial_Body
        .replace('{{state}}', state)
        .replace('{{eapURL}}', eapUrl) +
      '</p>' +
      '<p>' +
      EmailConstants.email_contact
        .replace('{{state}}', state)
        .replace('{{statePhone}}', statePhone)
        .replace('{{stateEmail}}', fromEmail) +
      '</p>' +
      '</div>' +
      '<div>' +
      EmailConstants.email_Signature.replace('{{state}}', state) +
      '<p>' +
      EmailConstants.email_Footer +
      '</p>' +
      '</div>';

    let notificationEmailBody: INotificationEmailBody = {
      Body: btoa(emailBody),
      From: fromEmail,
      Subject: EmailConstants.amerenAdminDenial_Subject.replace(
        '{{state}}',
        state
      ),
      To: environment.testEmail ? environment.testEmail : user.mail,
      CC: '',
      AttachmentList: [],
    };

    return notificationEmailBody;
  }

  toggleOpenDetails(user: EAPUsers) {
    this.amerenUsers.forEach((a, i) => {
      if (user.agencyID == a.agencyID && !user.toggleStatus) {
        user.toggleStatus = true;
      }
    });
  }

  toggleCloseDetails(user: EAPUsers) {
    this.amerenUsers.forEach((a, i) => {
      if (user.agencyID == a.agencyID && user.toggleStatus) {
        user.toggleStatus = false;
      }
    });
  }
}
