import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';

export enum AgencyPledgeStatusCode {
  Open = 'Open',
  Cancelled = 'Cancelled',
  Satisfied = 'Satisfied',
  Rejected = 'Rejected',
  Expired = 'Expired',
  Transfer = 'Transfer',
  'EFT Payment Pending' = 'EFT Payment Pending',
  All = 'All',
}

export class AgencyPledges {
  agencyPledgeRecordId: number;
  accountNumber: any;
  agencyID: any;
  agencyCSSCode: string;
  agencyName: string;
  requesterName: string;
  pledgeID: number;
  pledgeDate: string;
  pledgeTypeCode: string;
  pledgeTypeDecode: string;
  pledgeAmount: any;
  pledgeStatusCode: string;
  pledgeStatusDecode: string;
  pledgeEndDate: string;
  pledgeStartDate: string;
  payViaEFT: string;
  isEFTAllowedForPledgeType: boolean;
  nearExpiration: string;
  clientFirstName: string;
  clientLastName: string;
  clientMiddleName: string;
  clientCompleteName: string;
  clientAddressLine1: string;
  clientAddressLine2: string;
  clientAddressLine3: string;
  clientAddressLine4: string;
  clientCity: string;
  clientState: string;
  clientPostalCode: string;
  campaignID: any;
  sequenceID: number;
  campaignName: any;
  clientPremiseAddress: string;
  pledgeExpirationDate: string;
  isNearExpiration: boolean;
  clockImage: string;

  constructor(agencyPledge: any, recordId: number) {
    this.agencyPledgeRecordId = recordId;
    this.accountNumber = !_.isNil(agencyPledge.accountNumber)
      ? agencyPledge.accountNumber
      : 0;
    this.agencyID = !_.isNil(agencyPledge.agencyID)
      ? agencyPledge.agencyID
      : '';
    this.agencyCSSCode = !_.isNil(agencyPledge.agencyCSSCode)
      ? agencyPledge.agencyCSSCode
      : '';
    this.agencyName = !_.isNil(agencyPledge.agencyName)
      ? agencyPledge.agencyName
      : '';
    this.requesterName = !_.isNil(agencyPledge.requesterName)
      ? agencyPledge.requesterName
      : '';
    this.pledgeID = !_.isNil(agencyPledge.pledgeID) ? agencyPledge.pledgeID : 0;
    this.pledgeDate = !_.isNil(agencyPledge.pledgeDate)
      ? moment(agencyPledge.pledgeDate).format('MM/DD/YYYY')
      : '';
    this.pledgeTypeCode = !_.isNil(agencyPledge.pledgeTypeCode)
      ? agencyPledge.pledgeTypeCode
      : '';
    this.pledgeTypeDecode = !_.isNil(agencyPledge.pledgeTypeDecode)
      ? agencyPledge.pledgeTypeDecode
      : '';
    this.pledgeAmount = !_.isNil(agencyPledge.pledgeAmount)
      ? agencyPledge.pledgeAmount
      : 0;
    this.pledgeStatusCode = !_.isNil(agencyPledge.pledgeStatusCode)
      ? agencyPledge.pledgeStatusCode
      : '';
    let pledgeStatusDescription = !_.isNil(agencyPledge.pledgeStatusDecode)
      ? agencyPledge.pledgeStatusDecode
      : '';
    this.pledgeStatusDecode =
      this.pledgeStatusCode === 'E'
        ? 'EFT Payment Pending'
        : pledgeStatusDescription;
    this.pledgeEndDate = !_.isNil(agencyPledge.pledgeStatusDate)
      ? agencyPledge.pledgeStatusDate
      : '';
    this.pledgeStartDate = !_.isNil(agencyPledge.pledgeStartDate)
      ? agencyPledge.pledgeStartDate
      : '';
    this.payViaEFT = !_.isNil(agencyPledge.payViaEFT)
      ? agencyPledge.payViaEFT
      : '';
    this.isEFTAllowedForPledgeType = !_.isNil(agencyPledge.pledgeTypeCode) && ['61','30','WS','WH','59','62','54','31','46','47'].includes(agencyPledge.pledgeTypeCode)
      ? false
      : true;
    this.nearExpiration = !_.isNil(agencyPledge.nearExpiration)
      ? agencyPledge.nearExpiration
      : '';
    this.clientFirstName = !_.isNil(agencyPledge.clientFirstName)
      ? agencyPledge.clientFirstName
      : '';
    this.clientLastName = !_.isNil(agencyPledge.clientLastName)
      ? agencyPledge.clientLastName
      : '';
    this.clientMiddleName = !_.isNil(agencyPledge.clientMiddleName)
      ? agencyPledge.clientMiddleName
      : '';
    this.clientCompleteName = !_.isNil(agencyPledge.clientCompleteName)
      ? agencyPledge.clientCompleteName
      : '';
    this.clientAddressLine1 = !_.isNil(agencyPledge.clientAddress.addressLine1)
      ? agencyPledge.clientAddress.addressLine1
      : '';
    this.clientAddressLine2 = !_.isNil(agencyPledge.clientAddress.addressLine2)
      ? agencyPledge.clientAddress.addressLine2
      : '';
    this.clientAddressLine3 = !_.isNil(agencyPledge.clientAddress.addressLine3)
      ? agencyPledge.clientAddress.addressLine3
      : '';
    this.clientAddressLine4 = !_.isNil(agencyPledge.clientAddress.addressLine4)
      ? agencyPledge.clientAddress.addressLine4
      : '';
    this.clientCity = !_.isNil(agencyPledge.clientAddress.city)
      ? agencyPledge.clientAddress.city
      : '';
    this.clientState = !_.isNil(agencyPledge.clientAddress.state)
      ? agencyPledge.clientAddress.state
      : '';
    this.clientPostalCode = !_.isNil(agencyPledge.clientAddress.postalCode)
      ? agencyPledge.clientAddress.postalCode
      : '';
    this.campaignID = '';
    this.sequenceID = !_.isNil(agencyPledge.sequenceID)
      ? agencyPledge.sequenceID
      : 0;
    this.campaignName = '';
    this.clientPremiseAddress = !_.isNil(agencyPledge.clientPremiseAddress)
      ? agencyPledge.clientPremiseAddress
      : '';

    this.pledgeExpirationDate = !_.isNil(agencyPledge.pledgeEndDate)
      ? agencyPledge.pledgeEndDate
      : '';
    let currentDate = moment().tz('America/Chicago').format('YYYY-MM-DD');
    let pledgeExpirationDateValue = moment(this.pledgeExpirationDate);
    let expirationDays = pledgeExpirationDateValue.diff(currentDate, 'days');
    this.isNearExpiration =
      this.pledgeStatusDecode === 'Open' && expirationDays <= 30 ? true : false;
    this.clockImage = this.isNearExpiration == true ? "1" : "";
  }
}

export class pledgeObject {
  index: any;
  accountNumber: any;
  client: any;
  clientName: string;
  pledgeDate: any;
  requesterName: any;
  pledgeTypeDecode: any;
  pledgeAmount: any;
  pledgeStatusDecode: any;
  pledgeStartDate: any;
  pledgeEndDate: any;
  campaignID: any;
  clockImage: any;
}
