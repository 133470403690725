import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client.service';
import { ClientManagerService } from './../client/services/client-manager.service';
import { BillingPaymentPeriod } from './../client/model/billing-payment.model';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import * as FileSaver from 'file-saver';

declare var window: any;

@Component({
  selector: 'app-bill-payment-history',
  templateUrl: './bill-payment-history.component.html',
  styleUrls: ['./bill-payment-history.component.scss'],
})
export class BillPaymentHistoryComponent implements OnInit {
  billingpaymenthistory: BillingPaymentPeriod[];
  billingpaymenthistoryOrig: BillingPaymentPeriod[];
  selectedClient: Client;
  isAPIFailure: boolean = false;
  isLoading: boolean = true;
  @ViewChild('billpaymenthistory')
  billpaymenthistory: ElementRef;
  searchString: string;
  viewBillAPIFailure: boolean = false;
  viewBillLoading: boolean = false;
  viewbillModal: any;

  constructor(
    private clientManagerService: ClientManagerService,
    private clientService: ClientService,
    private _utility: UtilitiesService,
    private datePipe: DatePipe
  ) {
    this.selectedClient = this.clientService.getSelectedClient();
  }

  ngOnInit(): void {
    this.getBillingPaymentHistory();

    this.viewbillModal = new window.bootstrap.Modal(
      document.getElementById('viewBillModel'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
  }

  getBillingPaymentHistory() {
    this.isLoading = true;
    this.billingpaymenthistory = [];
    let date = new Date();
    let toDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    let fromDate = this.datePipe.transform(
      date.setFullYear(date.getFullYear() - 1),
      'yyyy-MM-dd'
    );

    return this.clientManagerService
      .getBillingAndPaymentHistory(
        this.selectedClient.billAccountNumber,
        fromDate,
        toDate
      )
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.billingpaymenthistory = res;
          this.billingpaymenthistoryOrig = this.billingpaymenthistory;
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error && error.error.errorDetails.errorCode === 100) {
            this.billingpaymenthistory = [];
          } else {
            this.isAPIFailure = true;
          }
        },
      });
  }

  searchFilter() {
    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.billingpaymenthistory = this.billingpaymenthistoryOrig.filter(
        (data) =>
          data.billingPeriod.toLocaleLowerCase().match(adjustedSearch) ||
          data.priorBalance.toLocaleLowerCase().match(adjustedSearch) ||
          data.currentBillAmount.toLocaleLowerCase().match(adjustedSearch) ||
          data.totalBilledAmount.toLocaleLowerCase().match(adjustedSearch) ||
          data.dueDate.toLocaleLowerCase().match(adjustedSearch) ||
          data.usage.toLocaleLowerCase().match(adjustedSearch) ||
          data.serviceCharge.toLocaleLowerCase().match(adjustedSearch) ||
          data.paymentInformation.toLocaleLowerCase().match(adjustedSearch)
      );
    } else {
      this.billingpaymenthistory = this.billingpaymenthistoryOrig;
    }
  }

  exportToExcel() {
    let table_elt = document.getElementById('billpaymenthistory');
    let workbook = XLSX.utils.table_to_book(table_elt);
    this.formatSheet(workbook);
  }

  private formatSheet(workbook: XLSX.WorkBook) {
    let worksheet: XLSX.WorkSheet;
    worksheet = workbook.Sheets['Sheet1'];
    let data = XLSX.utils.sheet_to_json(worksheet);
    let ws = XLSX.utils.aoa_to_sheet([[this.selectedClient.fullName]]);
    XLSX.utils.sheet_add_aoa(
      ws,
      [[this.selectedClient.address.streetAddress]],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          this.selectedClient.address.city +
            ' ' +
            this.selectedClient.address.state +
            this.selectedClient.address.zip,
        ],
      ],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          this._utility.transformAccountNumber(
            this.selectedClient.billAccountNumber.toString()
          ),
        ],
      ],
      { origin: -1 }
    );

    XLSX.utils.sheet_add_json(ws, data, { origin: 'A6' });

    this.formatColumn(ws, 1, '$0.00');
    this.formatColumn(ws, 2, '$0.00');
    this.formatColumn(ws, 3, '$0.00');
    this.formatColumn(ws, 4, 'MM/dd/YYYY');
    this.formatColumn(ws, 6, '$0.00');

    var wscols = [
      { wch: 10 },
      { wch: 17 },
      { wch: 18 },
      { wch: 18 },
      { wch: 12 },
      { wch: 21 },
    ];

    ws['!cols'] = wscols;

    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, 'BillingAndPaymentHistory.xlsx');
  }

  formatColumn(worksheet: XLSX.WorkSheet, col: number, fmt: string) {
    const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col });
      if (worksheet[ref] && worksheet[ref].t === 'n') {
        worksheet[ref].z = fmt;
        if (col == 0) worksheet[ref].v = worksheet[ref].v + 1;
      }
    }
  }

  exportToPdf() {
    const billpaymenthistory = this.billpaymenthistory.nativeElement;

    let body = [
      {
        type: 'string',
        element: 'Billing And Payment History',
        align: 'center',
        x: 0,
        y: 15,
        fontsize: 16,
      },
      {
        type: 'string',
        element: 'Account Number: ' + this._utility.transformAccountNumber(this.selectedClient?.billAccountNumber),
        align: '',
        x: 15,
        y: 10,
        fontsize: 12,
      },
      {
        type: 'string',
        element: 'Client: ' + this.selectedClient?.fullName,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'string',
        element: this.selectedClient?.address?.streetAddress,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'string',
        element:
          this.selectedClient?.address?.city +
          ' ' +
          this.selectedClient?.address?.state +
          ' ' +
          this.selectedClient?.address?.zip,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'table',
        element: billpaymenthistory,
        align: 'none',
        x: 0,
        y: 5,
        fontsize: 12,
      },
    ];

    this._utility.exportTabletoPDF('billingpaymenthistory.pdf', body);
  }

  viewBillImage(billImageId: any, billingDate: any) {
    const billingMonth =
      new Date(billingDate).toLocaleString('en-us', {
        month: 'short',
      }) +
      '-' +
      new Date(billingDate).toLocaleString('en-us', {
        year: 'numeric',
      });
    this.viewbillModal.show();
    this.viewBillAPIFailure = false;
    this.viewBillLoading = true;
    this.clientManagerService.getViewBillPDF(billImageId).subscribe({
      next: (blobData: Blob) => {
        this.viewBillAPIFailure = false;
        this.viewBillLoading = false;
        this.viewbillModal.hide();
        let file = new Blob([blobData], { type: 'application/pdf' });
        FileSaver.saveAs(file, `ViewBill-${billingMonth}.pdf`);
      },
      error: (error: any) => {
        this.viewBillAPIFailure = true;
        this.viewBillLoading = false;
        this.viewbillModal.show();
        console.log('error', error);
      },
    });
  }
}
