import {
  SplunkLoggerService,
  SplunkBodyInformation,
  SplunkEventInformation,
} from './splunk-api.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { Profile } from '../models/profile';
import { ProfileManagerService } from './profilemanager.service';
import { AgencyService } from './agency.service';

@Injectable({
  providedIn: 'root',
})
export class SplunkManager {
  profile: Profile;

  constructor(
    private splunkAPIService: SplunkLoggerService,
    private profileMgr: ProfileManagerService,
    private agencyService: AgencyService
  ) {
    this.profileMgr.profile.forEach((a: Profile) => {
      this.profile = a;
    });
  }

  logSplunk(
    level: string,
    sourcetype: string,
    message: { flowName: string; flowDetails: string },
    elapsed?: number,
    method?: string,
    apiName?: string
  ) {
    this.profileMgr.profile.forEach((a: Profile) => {
      this.profile = a;
    });
    const splunkBody = this.buildSplunkBody(
      this.profile,
      level,
      sourcetype,
      message,
      elapsed,
      method,
      apiName
    );

    return this.splunkAPIService.logSplunk(splunkBody).pipe(
      catchError((err: any) => {
        console.log('error', err);

        return throwError(() => err);
      })
    );

    /*istanbul ignore next*/
    return of({});
  }

  /*istanbul ignore next*/
  getUserName(profile: Profile) {
    const anonymous = 'Anonymous';

    if (!_.isNil(profile) && !_.isNil(profile.email)) {
      let userID;
      if (Array.isArray(this.profile.email)) {
        userID = this.profile.email[0];
      } else {
        userID = this.profile.email || '';
      }

      return userID;
    } else {
      return anonymous;
    }
  }

  /*istanbul ignore next*/
  getTimeStamp() {
    const d = new Date();
    const timeStamp =
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + (d.getDate() + 1)).slice(-2) +
      '/' +
      d.getFullYear() +
      ' ' +
      ('0' + d.getHours()).slice(-2) +
      ':' +
      ('0' + d.getMinutes()).slice(-2) +
      ':' +
      ('0' + d.getSeconds()).slice(-2);

    return timeStamp;
  }

  buildSplunkBody(
    profile: Profile,
    level: string,
    sourcetype: string,
    message: any,
    elapsed?: number,
    method?: string,
    apiName?: string
  ) {
    if (!_.isNil(profile) && profile.telephoneNumber) {
      profile.telephoneNumber = 'xxx.xxx.xxxx';
    }

    let selectedAgencyDetails: any = this.getSelectedAgency();
    if (!_.isNil(profile) && !_.isNil(selectedAgencyDetails)) {
      profile.selectedAgency = selectedAgencyDetails;
    }

    const emailAddress = this.getUserName(profile);
    const timeStamp = this.getTimeStamp();
    const splunkBodyInfo = new SplunkBodyInformation();
    splunkBodyInfo.event = new SplunkEventInformation();
    splunkBodyInfo.sourcetype = sourcetype;
    splunkBodyInfo.event.timestamp = timeStamp;
    splunkBodyInfo.event.userName = emailAddress;
    splunkBodyInfo.event.level = level;
    splunkBodyInfo.event.message = message;
    splunkBodyInfo.event.elapsed = elapsed;
    splunkBodyInfo.event.method = method;
    splunkBodyInfo.event.apiName = apiName;
    splunkBodyInfo.event.profile = profile || '';

    return splunkBodyInfo;
  }

  getSelectedAgency() {
    let agency = this.agencyService.getSelectedAgency();
    if (agency && (agency.phoneNumber || agency.faxNumber)) {
      agency.phoneNumber = 'xxx.xxx.xxxx';
      agency.faxNumber = 'xxx.xxx.xxxx';
    }

    return agency || '';
  }
}
