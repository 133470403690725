import { IGlobalAPIResponse } from './../../../shared/interfaces/iglobal-api-response';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreferencesApiService {

  constructor(private http: HttpClient) {}

  getPreferenceList(billAccount: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('billaccountnumber',billAccount.toString());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/getpreferences`,
      { headers }
    );
  }

  updatePreference(preference: any, billAccount: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv.toString().toLocaleLowerCase().includes('ptst')?'PTST':environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('billaccountnumber',billAccount.toString());

    return this.http.put<any>(
      `${environment.apig}/updatepreferences`,
      preference,{ headers }
    );
  }

  addEmail(billAccount: string, data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('x-correlation-id', 'EAP-123')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('billaccountnumber',billAccount.toString());
     

      const body = data;


    return this.http.post<IGlobalAPIResponse>(`${environment.apig}/addemail`, body, { headers });

  }

  addPhone(billAccount: string, data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('billaccountnumber',billAccount.toString());
     

      const body = data;


    return this.http.post<IGlobalAPIResponse>(`${environment.apig}/addphone`, body, { headers });

  }
}
