import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Client } from '../models/client';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  setSelectedClient(client?: Client): void {
    if (!client) sessionStorage.removeItem('selectedClient');
    else sessionStorage.setItem('selectedClient', JSON.stringify(client));
    this.clientUpdatedSubject.next(true);
  }

  getSelectedClient(): Client {
    let client = sessionStorage.getItem('selectedClient') || '';
    return client != '' ? JSON.parse(client) : null;
  }

  showClientSelector = new Subject<boolean>();
  clientUpdatedSubject = new Subject();

  setShowClientSelector(newVal: boolean) {
    this.showClientSelector.next(newVal);
  }
}
