<div class="client_selector_container">
    <div id="clientSelectorModal" class="client_selector_modal"
        [ngClass]="{'show':showClientSelector,'hidden': !showClientSelector}">
        <div class="client_selector_modal_content">
            <div class="client_selector_close_btn_container">
                <span class="client_selector_close_btn" (click)="closeSelector()">&times;</span>
            </div>
            <h4 class="client_selector_modal_header">Search New Client                
            </h4>
            <p class="client_selector_modal_info_text">
                Provide any two inputs to search
            </p>
            <form [formGroup]="searchForm">
                <div class="form-group">
                    <label for="text-input">Account Number
                    </label>
                    <input type="text" inputmode="numeric" formControlName="billAccount" class="form-control" />
                    <div class="text-field-error ameren-font-sm">
                        <span class="client_selector_field_info">(Without Spaces & Hyphens)</span>
                        <span *ngIf="searchForm?.get('billAccount')?.errors?.['pattern']">
                            Please Enter Valid Account Number
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Account Name</label>
                    <input type="text" formControlName="customerName" class="form-control" />
                    <div class="text-field-error ameren-font-sm"
                        *ngIf="searchForm?.get('customerName')?.dirty && searchForm?.get('customerName')?.invalid">
                        <p *ngIf="searchForm?.get('customerName')?.errors?.['required']">
                            Please Enter Valid Account Name
                        </p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Client SSN</label>
                    <input type="text" formControlName="ssn" class="form-control" />
                    <div class="text-field-error ameren-font-sm"
                        *ngIf="searchForm?.get('ssn')?.dirty && searchForm?.get('ssn')?.invalid">
                        <p *ngIf="searchForm?.get('ssn')?.errors?.['pattern']">
                            Please Enter Valid SSN
                        </p>
                    </div>
                </div>

                <div class="form-group" *ngIf="showApiError">
                    <div class="client_selector_error ameren-font-sm form-control">
                        <em class="far fa-times-circle"></em>
                        Failed to retrieve the requested information. Please try again.
                    </div>
                </div>


                <div class="client_selector_submit_btn_container">
                    <button (click)="searchClient()" [disabled]="!searchForm.valid || loading"
                        class="btn btn-success client_selector_submit_btn" type="submit">
                        Search
                    </button>

                    <div>
                        <ameren-loading *ngIf="loading"></ameren-loading>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>