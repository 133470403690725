<div class="edit_password_form_container">
    <form [formGroup]="editPasswordForm" class="edit_password_form" >
        <div id="edit-password" class="edit_password_form_sub_section">
            <div class="row m-0">
                <h4 class="section-header">Edit Password</h4>
                <div class="form-group edit_password_form_password_container">
                    <label class="edit_password_form_label">Current Password</label>
                    <input formControlName="currentPassword" class="form-control" [type]="currentPasswordAttr" />
                    <em class="far fa-eye-slash  edit_password_form_icon" *ngIf="currentPasswordAttr=='text'"  (click)="toggleCurrentPasswordAttr()"></em>
                    <em class="far fa-eye edit_password_form_icon" *ngIf="currentPasswordAttr=='password'" (click)="toggleCurrentPasswordAttr()"></em>

                    <div class="text-field-error ameren-font-sm"
                        *ngIf="editPasswordForm?.get('currentPassword')?.dirty && editPasswordForm?.get('currentPassword')?.invalid">
                        <p *ngIf="editPasswordForm?.get('currentPassword')?.errors?.['required']">
                            Current Password is required.
                        </p>
                    </div>
                </div>
                <div class="form-group edit_password_form_password_container">
                    <label class="edit_password_form_label">New Password</label>
                    <input formControlName="newPassword" class="form-control" [type]="passwordAttr" />
                    <em class="far fa-eye-slash  edit_password_form_icon" *ngIf="passwordAttr=='text'"  (click)="togglePasswordAttr()"></em>
                    <em class="far fa-eye edit_password_form_icon" *ngIf="passwordAttr=='password'" (click)="togglePasswordAttr()"></em>

                    <div class="text-field-error ameren-font-sm"
                        *ngIf="editPasswordForm?.get('newPassword')?.dirty && editPasswordForm?.get('newPassword')?.invalid">
                        <p *ngIf="editPasswordForm?.get('newPassword')?.errors?.['required']">
                            New Password is required.
                        </p>
                    </div>
                </div>
                <div class="form-group edit_password_form_password_container">
                    <label class="edit_password_form_label">Confirm New Password</label>
                    <input formControlName="confirmNewPassword" class="form-control" [type]="confirmPasswordAttr" />
                    <em class="far fa-eye-slash eye-hide edit_password_form_icon" *ngIf="confirmPasswordAttr=='text'" (click)="toggleConfirmPasswordAttr()"></em>
                    <em class="far fa-eye edit_password_form_icon" *ngIf="confirmPasswordAttr=='password'" (click)="toggleConfirmPasswordAttr()"></em>

                    <div class="text-field-error ameren-font-sm"
                        *ngIf="editPasswordForm?.get('confirmNewPassword')?.dirty && editPasswordForm?.get('confirmNewPassword')?.invalid">
                        <p *ngIf="editPasswordForm?.get('confirmNewPassword')?.errors?.['required']">
                            Confirm New Password is required.
                        </p>
                        <p *ngIf="editPasswordForm?.get('confirmNewPassword')?.errors?.['mustMatch']">Passwords must
                            match
                        </p>
                    </div>
                </div>


                <div class="edit_password_form_rules_container">
                    <div class="edit_password_form_rules">
                        <ul class="edit_password_form_rules_group">
                            <li
                                [ngClass]="{'password_rule_valid':!editPasswordForm?.get('newPassword')?.errors?.['charRange'], 'password_rule_invalid':editPasswordForm?.get('newPassword')?.errors?.['charRange'] || editPasswordForm?.get('newPassword')?.errors?.['required']}">
                                Must be between 8-250 characters
                            </li>
                            <li
                                [ngClass]="{'password_rule_valid':!editPasswordForm?.get('newPassword')?.errors?.['alphaNumeric'], 'password_rule_invalid':editPasswordForm?.get('newPassword')?.errors?.['alphaNumeric'] || editPasswordForm?.get('newPassword')?.errors?.['required']}">
                                Must include number and a letter
                            </li>
                            <li
                                [ngClass]="{'password_rule_valid':!editPasswordForm?.get('newPassword')?.errors?.['specialChars'], 'password_rule_invalid':editPasswordForm?.get('newPassword')?.errors?.['specialChars'] || editPasswordForm?.get('newPassword')?.errors?.['required']}">
                                Must also contain at least one special character
                            </li>
                            <li
                                [ngClass]="editPasswordForm?.get('newPassword')?.errors?.['repeatChars'] ? 'password_rule_invalid' : 'password_rule_valid'">
                                Cannot have more than 2 repeating characters (e.g. aaa)
                            </li>
                            <li
                                [ngClass]="editPasswordForm?.get('newPassword')?.errors?.['noContinuous2Spaces'] ? 'password_rule_invalid' : 'password_rule_valid'">
                                Cannot contain 2 continuous spaces
                            </li>
                            <li
                                [ngClass]="editPasswordForm?.get('newPassword')?.errors?.['invalidChars'] ? 'password_rule_invalid' : 'password_rule_valid'">
                                Cannot contain the characters <> & -
                            </li>
                        </ul>
                    </div>
                </div>
                <ameren-loading *ngIf="isLoading" class="d-flex justify-content-center"></ameren-loading>
                <div *ngIf="isAPIFailure" class="mt-3 alert alert-danger" role="alert">
                    {{integrationErrorMsg}}
                </div>
                <div class="form-group text-center">
                    <button type="button" class="btn btn-success edit_password_form_submit_btn" (click)="openModal()"
                        [disabled]="!editPasswordForm.valid">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true">
    <div class="modal-dialog" id="confirmationModalDialog">
        <div class="modal-content" id="confirmationModalContent">
            <div class="pop-header row" id="confirmationModalHeader">
                <p class="modal-title modal-edit text-right col-md-8 mb-0" id="confirmationModalLabel">
                    Edit Confirmation
                </p>
                <p class="col-md-3 mb-0"></p>
                <button type="button" class="btn-close col-md-1 text-right" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center" id ="changePassword">
                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#218737" fill-opacity="0.1" />
                    <circle cx="50" cy="50" r="35" fill="#218737" fill-opacity="0.1" />
                    <circle cx="49.5" cy="49.5" r="17.5" fill="#218737" />
                    <path
                        d="M56.8007 45.339C56.6644 45.0782 56.7657 44.756 57.0265 44.6196C57.2879 44.4833 57.6095 44.5846 57.7459 44.8454C58.1226 45.564 58.4163 46.3309 58.6135 47.1333C58.8057 47.9169 58.9085 48.7328 58.9085 49.568C58.9085 52.3802 57.7684 54.9261 55.9256 56.7697C54.0823 58.6131 51.5362 59.7526 48.7239 59.7526C45.9116 59.7526 43.3658 58.6125 41.5222 56.7697C39.6788 54.9264 38.5393 52.3804 38.5393 49.568C38.5393 46.7557 39.6794 44.2099 41.5222 42.3663C43.3655 40.523 45.9116 39.3834 48.7239 39.3834C49.6005 39.3834 50.4568 39.4961 51.2782 39.7084C52.1206 39.9258 52.9209 40.2489 53.6641 40.6619C53.922 40.8053 54.0143 41.1303 53.8711 41.3875C53.7277 41.6446 53.4027 41.7376 53.1455 41.5945C52.4807 41.2247 51.7642 40.936 51.0106 40.7409C50.2851 40.5529 49.5188 40.4536 48.7248 40.4536C46.2082 40.4536 43.9294 41.4735 42.2803 43.1232C40.6313 44.7722 39.6107 47.0511 39.6107 49.5677C39.6107 52.0843 40.6307 54.3632 42.2803 56.0122C43.9294 57.6612 46.2082 58.6818 48.7248 58.6818C51.2414 58.6818 53.5203 57.6618 55.1693 56.0122C56.8184 54.3631 57.8389 52.0843 57.8389 49.5677C57.8389 48.8127 57.748 48.0822 57.5776 47.3874C57.4 46.6646 57.1372 45.9775 56.8022 45.3379L56.8007 45.339ZM43.3833 49.3313C44.4976 50.4309 45.9439 51.9876 47.2854 53.8632C47.5945 54.2959 48.0648 54.5211 48.5954 54.491C49.1261 54.461 49.5678 54.184 49.8265 53.7199C52.0732 49.683 57.0041 41.3372 60.4615 39.2485C60.4615 39.2485 54.3545 40.1545 48.4498 50.5242L45.5732 47.2616C44.2102 45.716 41.9165 47.8845 43.3831 49.3322L43.3833 49.3313Z"
                        fill="white" />
                </svg>
                <p class="mt-2 modal-edit" id="confirmationModalEdit">Are you sure, you want to change Password?</p>
                <button type="button" class="btn btn-yes-ok mt-2" (click)="changePassword()">
                    Yes
                </button>
                <button type="button" class="btn btn-no-cancel mt-2" data-bs-dismiss="modal">
                    NO
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="updateSuccessModal" tabindex="-1" aria-labelledby="updateSuccessModalLabel" aria-hidden="true">
    <div class="modal-dialog" id="updateSuccessModalDialog">
        <div class="modal-content" id="updateSuccessModalContent">
            <div class="pop-header row" id="updateSuccessModalHeader">
                <p class="modal-title modal-success text-center textColor col-md-11" id="updateSuccessModalLabel">
                    Password Update Successful!
                </p>
            </div>
            <div class="modal-body text-center" id="updateSuccessModalText">
                <p>Your password has been successfully updated.</p>
            </div>
        </div>
    </div>
</div>