<div class="agencypledges__container">
  <div class="agencypledges__title">Agency Pledges</div>
  <div class="d-flex flex-row agencypledges__search agencypledges-filter-desktop">
    <div class="pay-eft-button-div">
      <button class="btn btn-default pay-eft-button" [disabled]="!isAgencyAdmin || !selectedAgency || !selectedAgency.isEFTAllowed" (click)="payViaEFT()">
        Pay Via EFT
      </button>
    </div>
    <div class="ms-auto input-group w-25 agencypledges__search-button">
      <input type="text"
             class="form-control"
             placeholder="Search"
             aria-label="Search"
             [(ngModel)]="searchString"
             autocomplete="off"
             autocorrect="off"
             autocapitalize="off"
             #input />
      <div class="input-group-btn">
        <button id="searchString-submit"
                class="btn btn-default search_magnify"
                type="submit"
                (click)="searchFilter()">
          <span class="sr-only">Search</span>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Search</title>
            <circle cx="10" cy="10" r="7.5"></circle>
            <line x1="23.04" y1="22.99" x2="15.52" y2="15.47"></line>
          </svg>
        </button>
      </div>
    </div>
    <div class="ms-auto export__options">
      <div class="agencypledges-filters">
        <button class="btn btn-default"
                dropdown-toggle
                id="btnCalendarFilter"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img src="assets/icon/calendar.svg" alt="Calendar Image" /> Select
          Date<em class="fa-solid fa-sort-down down-margin"></em>
        </button>
        <div class="dropdown-menu calendar-popup-padding dropdown-desktop"
             aria-labelledby="btnCalendarFilter">
          <div class="container">
            <div class="row">
              <div class="col date-picker-header"><span>From</span></div>
              <div class="col date-picker-header"><span>To</span></div>
            </div>
          </div>
          <div class="date-picker-group">
            <div class="date-picker-div date-picker-float input-group date"
                 data-provide="datepicker">
              <input id="pledgeFromDate"
                     (change)="dateRangeFilter($event, true, false)"
                     type="date"
                     placeholder="MM/DD/YYYY"
                     data-required-error="Please select a date" />
            </div>
            <div class="date-picker-float date-picker-divider">-</div>
            <div class="date-picker-div date-picker-float input-group date"
                 data-provide="datepicker">
              <input id="pledgeToDate"
                     (change)="dateRangeFilter($event, false, false)"
                     type="date"
                     placeholder="MM/DD/YYYY"
                     data-required-error="Please select a date" />
            </div>
          </div>
          <div class="calendar-pastdays-button">
            <button id="btnPastThirtyDays"
                    class="btn btn-default calendar-day-button"
                    [ngClass]="{'calendar-day-button-active' : filterByThirtyPastDays }"
                    (click)="filterByPastDays(30)">
              Past 30 days
            </button>
          </div>
          <div class="calendar-pastdays-button">
            <button id="btnPastSixtyDays"
                    class="btn btn-default calendar-day-button"
                    [ngClass]="{'calendar-day-button-active' : filterBySixtyPastDays }"
                    (click)="filterByPastDays(60)">
              Past 60 days
            </button>
          </div>
          <div class="calendar-pastdays-button">
            <button id="btnPastNinetyDays"
                    class="btn btn-default calendar-day-button"
                    [ngClass]="{'calendar-day-button-active' : filterByNinetyPastDays }"
                    (click)="filterByPastDays(90)">
              Past 90 days
            </button>
          </div>
          <div class="calendar-pastdays-button">
            <button id="btnPastYear"
                    class="btn btn-default calendar-day-button"
                    [ngClass]="{'calendar-day-button-active' : filterByYear }"
                    (click)="filterByPastDays(365)">
              Past 12 months
            </button>
          </div>
        </div>
      </div>
      |
      <div class="dropdown agencypledges-filters">
        <button class="btn btn-default dropdown-toggle"
                type="button"
                id="ddPledgeType"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img src="assets/icon/pledge_type.svg" alt="Pledge Type Image" />
          Pledge Type
        </button>
        <ul class="dropdown-menu" aria-labelledby="ddPledgeType">
          <li *ngFor="let agencyPledgeType of agencyPledgeTypes; let i = index"
              value="{{ agencyPledgeType.pledgeTypeCode }}"
              class="dropdown-item"
              [ngClass]="{'active': pledgeTypeSelectedItemIndex == i}"
              (click)="filterByPledgeType(agencyPledgeType.pledgeTypeCode, i)">
            {{ agencyPledgeType.pledgeTypeDecode }}
          </li>
        </ul>
      </div>
      |
      <div class="dropdown agencypledges-filters">
        <button class="btn btn-default dropdown-toggle"
                type="button"
                id="ddPledgeStatus"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img src="assets/icon/pledge_status.svg" alt="Pledge Status Image" />
          Pledge Status
        </button>
        <ul class="dropdown-menu" aria-labelledby="ddPledgeStatus">
          <li *ngFor="let agencyPledgeStatusCode of agencyPledgeStatusCodeKeys; let i = index"
              value="{{ agencyPledgeStatusCode }}"
              class="dropdown-item"
              [ngClass]="{'active': pledgeStatusSelectedItemIndex == i}"
              (click)="filterByPledgeStatus(agencyPledgeStatusCode, i)">
            {{ agencyPledgeStatusCode }}
          </li>
        </ul>
      </div>
    </div>
    <!--Filter Apply/Clear funtionality-->
    <div>
      &nbsp;
      <button class="btn btn-primary agency-pledges-filter_apply" (click)="applyFilters()"
              id="btnApplyFilter">
        Apply
      </button>
    </div>
    <div>
      &nbsp;
      <button class="btn btn-default agency-pledges-filter_clear" (click)="clearFilters()"
              id="btnClearFilter">
        Clear
      </button>
    </div>
    <div class="ms-auto export__options">
      <button class="btn btn-default" (click)="exportToExcelClientPledges()">
        <img src="assets/icon/excelicon.svg"
             alt="CalendarImage"
             width="22"
             height="19" />Excel
      </button>
      |
      <button class="btn btn-default" (click)="exportToPdfClientPledges()">
        <img src="assets/icon/pdficon.svg"
             alt="CalendarImage"
             width="22"
             height="19" />
        PDF
      </button>
    </div>
  </div>
  <div class="d-flex flex-row agencypledges__search agencypledges-filter-mobile">
    <div class="pay-eft-button-div">
      <button class="btn btn-default pay-eft-button" [disabled]="!isAgencyAdmin || !selectedAgency || !selectedAgency.isEFTAllowed" (click)="payViaEFT()">
        Pay Via EFT
      </button>
    </div>
    <div class="ms-auto input-group agencypledges__search-button agencypledges-search-mobile">
      <input type="text"
             class="form-control"
             placeholder="Search"
             aria-label="Search"
             [(ngModel)]="searchString"
             autocomplete="off"
             autocorrect="off"
             autocapitalize="off"
             #input />
      <div class="input-group-btn">
        <button id="searchString-submit"
                class="btn btn-default search_magnify"
                type="submit"
                (click)="searchFilter()">
          <span class="sr-only">Search</span>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Search</title>
            <circle cx="10" cy="10" r="7.5"></circle>
            <line x1="23.04" y1="22.99" x2="15.52" y2="15.47"></line>
          </svg>
        </button>
      </div>
    </div>
    <div class="ms-auto export__options">
      <button class="btn btn-default" (click)="exportToExcelClientPledges()">
        <img src="assets/icon/excelicon.svg"
             alt="CalendarImage"
             width="22"
             height="19" />
        Excel
      </button>
      |
      <button class="btn btn-default" (click)="exportToPdfClientPledges()">
        <img src="assets/icon/pdficon.svg"
             alt="CalendarImage"
             width="22"
             height="19" />
        PDF
      </button>
    </div>
  </div>
  <div class="d-flex flex-row agencypledges__search agencypledges-filter-mobile">
    <div class="export__options">
      <div class="agencypledges-filters">
        <button class="btn btn-default"
                dropdown-toggle
                id="btnCalendarFilterMobile"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img src="assets/icon/calendar.svg" alt="Calendar Image" />Select
          Date<em class="fa-solid fa-sort-down down-margin"></em>
        </button>
        <div class="dropdown-menu calendar-popup-padding dropdown-mobile"
             aria-labelledby="btnCalendarFilterMobile">
          <div class="container">
            <div class="row">
              <div class="col date-picker-header"><span>From</span></div>
              <div class="col date-picker-header"><span>To</span></div>
            </div>
          </div>
          <div class="date-picker-group">
            <div class="date-picker-div date-picker-float input-group date"
                 data-provide="datepicker">
              <input id="pledgeFromDateMobile"
                     (change)="dateRangeFilter($event, true, true)"
                     type="date"
                     placeholder="MM/DD/YYYY"
                     data-required-error="Please select a date" />
            </div>
            <div class="date-picker-float date-picker-divider">-</div>
            <div class="date-picker-div date-picker-float">
              <input id="pledgeToDateMobile"
                     (change)="dateRangeFilter($event, false, true)"
                     type="date"
                     placeholder="MM/DD/YYYY"
                     data-required-error="Please select a date" />
            </div>
          </div>
          <div class="calendar-pastdays-button">
            <button class="btn btn-default calendar-day-button"
                    [ngClass]="{'active': filterByThirtyPastDays}"
                    (click)="filterByPastDays(30)">
              Past 30 days
            </button>
          </div>
          <div class="calendar-pastdays-button">
            <button class="btn btn-default calendar-day-button"
                    [ngClass]="{'active': filterBySixtyPastDays}"
                    (click)="filterByPastDays(60)">
              Past 60 days
            </button>
          </div>
          <div class="calendar-pastdays-button">
            <button class="btn btn-default calendar-day-button"
                    [ngClass]="{'active': filterByNinetyPastDays}"
                    (click)="filterByPastDays(90)">
              Past 90 days
            </button>
          </div>
          <div class="calendar-pastdays-button">
            <button class="btn btn-default calendar-day-button"
                    [ngClass]="{'active': filterByYear}"
                    (click)="filterByPastDays(365)">
              Past 12 months
            </button>
          </div>
        </div>
      </div>
      |
      <div class="dropdown agencypledges-filters">
        <button class="btn btn-default dropdown-toggle"
                type="button"
                id="ddPledgeType"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img src="assets/icon/pledge_type.svg"
               alt="Pledge Type Image" />Pledge Type
        </button>
        <ul class="dropdown-menu" aria-labelledby="ddPledgeType">
          <li *ngFor="let agencyPledgeType of agencyPledgeTypes; let i = index"
              value="{{ agencyPledgeType.pledgeTypeCode }}"
              class="dropdown-item"
              [ngClass]="{'active': pledgeTypeSelectedItemIndex == i}"
              (click)="filterByPledgeType(agencyPledgeType.pledgeTypeCode, i)">
            {{ agencyPledgeType.pledgeTypeDecode }}
          </li>
        </ul>
      </div>
      |
      <div class="dropdown agencypledges-filters">
        <button class="btn btn-default dropdown-toggle"
                type="button"
                id="ddPledgeStatus"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img src="assets/icon/pledge_status.svg"
               alt="Pledge Status Image" />Pledge Status
        </button>
        <ul class="dropdown-menu" aria-labelledby="ddPledgeStatus">
          <li *ngFor="let agencyPledgeStatusCode of agencyPledgeStatusCodeKeys; let i = index"
              value="{{ agencyPledgeStatusCode }}"
              class="dropdown-item"
              [ngClass]="{'active': pledgeStatusSelectedItemIndex == i}"
              (click)="filterByPledgeStatus(agencyPledgeStatusCode, i)">
            {{ agencyPledgeStatusCode }}
          </li>
        </ul>
      </div>

      <!--Filter Apply/Clear funtionality-->
      <div>
        &nbsp;
        <button class="btn btn-default agency-pledges-filter_apply" (click)="applyFilters()"
                id="btnApplyFilter">
          Apply
        </button>
        &nbsp;
        <button class="btn btn-default agency-pledges-filter_clear" (click)="clearFilters()"
                id="btnClearFilter">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="agency-pledge-search-criteria">
    <table>
      <tbody>
        <tr>
          <td width="150px">
            <label id="lblSelectedCriteria" style="display:none">Selected Criteria</label>
          </td>
          <td width="100px">
            <label id="lblDate" class="badge rounded-pill bg-secondary" style="display:none"></label>
            <label id="lblPastDays" class="badge rounded-pill bg-secondary" style="display:none"></label>
          </td>
          <td width="150px">
            <label id="lblPeldgeType" class="badge rounded-pill bg-secondary" style="display: none"></label>
          </td>
          <td width="100px">
            <label id="lblPeldgeStatus" class="badge rounded-pill bg-secondary" style="display: none"></label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="agencypledges__list">
    <table aria-describedby="Ameren items List"
           class="agencypledges__table align-middle bg-white">
      <thead class="agencypledges__table-header">
        <tr>
          <th scope="col">
            <input #checkboxes
                   class="select__pledge-checkbox"
                   type="checkbox"
                   (click)="checkAll()" />
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="accountNumber">
              <span class="column-name">Account Number</span>
              <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="clientCompleteName">
              <span class="column-name">Client</span>
              <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="pledgeDate">
              <span class="column-name">Pledge Date</span>
              <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="requestedBy">
              <span class="column-name">Requested By</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="pledgeTypeDecode">
              <span class="column-name">Pledge Type</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="pledgeAmount">
              <span class="column-name">Pledge Amount</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="pledgeStatusDecode">
              <span class="column-name">Status</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>

          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="pledgeDateStaisfied">
              <span class="column-name">Date Statisfied</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="campaignID">
              <span class="column-name">Campaign ID</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="agencyPledges"
                  data-order="asc"
                  data-name="campaignName">
              <span class="column-name">Campaign Name</span><em class="fa-solid fa-sort"></em>
            </span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="11">
            <ameren-loading id="loadingIndicator"
                            class="d-flex p-10 justify-content-center"></ameren-loading>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="isAPIFailure && !isLoading">
        <tr>
          <td colspan="11">
            <div class="mt-3 alert alert-danger">
              Sorry, failed to get the agency pledges. Please try again later.
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isAPIFailure && !isLoading">
        <tr *ngIf="agencyPledges && agencyPledges.length === 0">
          <td colspan="11">
            <div id="noRecordsFound" class="no__ameren__users__found">
              There are no Agency pledges found!
            </div>
          </td>
        </tr>
        <tr *ngFor="let agencyPledge of agencyPledges; let i = index">
          <td>
            <input *ngIf="agencyPledge.pledgeStatusCode === '1' && agencyPledge.isEFTAllowedForPledgeType"
                   #checkboxes
                   class="select__pledge-checkbox"
                   type="checkbox"
                   [attr.data-agencypledgerecordid]="
                agencyPledge.agencyPledgeRecordId
              "
                   (click)="selectAccountNumber($event)" />
          </td>
          <td>
            <div>
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <a href="javascript:;"
                     (click)="
                      navigateToClientQuickViewPage(
                        agencyPledge.agencyPledgeRecordId
                      )
                    ">{{ agencyPledge.accountNumber | accountNumber }}</a>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="fw-normal mb-1 agency-pledges-address-container">
              <ul class="agency-pledges-address">
                <li>{{ agencyPledge.clientCompleteName }}</li>
                <li>{{ agencyPledge.clientPremiseAddress }}</li>
              </ul>
            </div>
          </td>
          <td>
            <p class="fw-normal mb-1">
              {{ agencyPledge.pledgeDate }}

              <img *ngIf="agencyPledge.isNearExpiration"
                   src="assets/icon/clock-icon.svg"
                   alt="This pledge is within 30 days of expiration" />
            </p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ agencyPledge.requesterName }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ agencyPledge.pledgeTypeDecode }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">
              {{ agencyPledge.pledgeAmount | currency }}
            </p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ agencyPledge.pledgeStatusDecode }}</p>
          </td>

          <td>
            <p class="fw-normal mb-1">
              {{
                agencyPledge.pledgeStatusDecode === 'Satisfied'
                  ? agencyPledge.pledgeEndDate
                  : ''
              }}
            </p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ agencyPledge.campaignID }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ agencyPledge.campaignName }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="agencypledges__list-mobile">
    <table aria-describedby="Ameren items List"
           class="agencypledges__table align-middle bg-white">
      <thead *ngIf="false">
        <tr>
          <th scope="col">Agency Pledges</th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="2">
            <ameren-loading id="loadingIndicator"
                            class="d-flex p-10 justify-content-center"></ameren-loading>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="isAPIFailure && !isLoading">
        <tr>
          <td colspan="2">
            <div class="mt-3 alert alert-danger">
              Sorry, failed to get the agency pledges. Please try again later.
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isAPIFailure && !isLoading">
        <tr *ngIf="agencyPledges && agencyPledges.length === 0">
          <td colspan="2">
            <div id="noRecordsFound" class="no__ameren__users__found">
              There are no Agency pledges found!
            </div>
          </td>
        </tr>
        <ng-container *ngFor="let agencyPledge of agencyPledges; let i = index">
          <tr>
            <td>
              <input *ngIf="agencyPledge.pledgeStatusCode === '1' && agencyPledge.isEFTAllowedForPledgeType"
                    #checkboxes
                    class="select__pledge-checkbox"
                    type="checkbox"
                    [attr.data-agencypledgerecordid]="
                  agencyPledge.agencyPledgeRecordId
                "
                     (click)="selectAccountNumber($event)" />
            </td>
            <td>
              <div>
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="p-2 bd-highlight">
                    <p class="fw-normal mb-1">Account Number</p>
                    <a href="javascript:;"
                       (click)="
                        navigateToClientQuickViewPage(
                          agencyPledge.agencyPledgeRecordId
                        )
                      ">{{ agencyPledge.accountNumber | accountNumber }}</a>
                  </div>
                  <div class="p-2 bd-highlight">
                    <a class="accordion-toggle"
                       data-bs-toggle="collapse"
                       href="#agencyPledgeRecord{{ i }}"
                       aria-expanded="false"
                       aria-controls="agencyRecord">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           fill="currentColor"
                           class="bi bi-chevron-down"
                           viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           fill="currentColor"
                           class="bi bi-chevron-up"
                           viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="agency-mobile-column-border">
              <div id="agencyPledgeRecord{{ i }}"
                   class="collapse agencypledge-record">
                <div class="row">
                  <div class="col column-header">Client</div>
                </div>
                <div class="row">
                  <div class="col column-value">
                    {{ agencyPledge.clientCompleteName }}
                    {{ agencyPledge.clientAddressLine1 }}
                  </div>
                </div>
                <div class="row agencypledge-record-margin">
                  <div class="col column-header">Pledge Date</div>
                  <div class="col column-header">Requested By</div>
                  <div class="col column-header">Pledge Type</div>
                </div>
                <div class="row">
                  <div class="col column-value">
                    {{ agencyPledge.pledgeDate }}
                    <img *ngIf="agencyPledge.isNearExpiration"
                         src="assets/icon/clock-icon.svg"
                         alt="This pledge is within 30 days of expiration" />
                  </div>
                  <div class="col column-value">
                    {{ agencyPledge.requesterName }}
                  </div>
                  <div class="col column-value">
                    {{ agencyPledge.pledgeTypeDecode }}
                  </div>
                </div>
                <div class="row agencypledge-record-margin">
                  <div class="col column-header">Pledge Amount</div>
                  <div class="col column-header">Status</div>
                </div>
                <div class="row">
                  <div class="col column-value">
                    {{ agencyPledge.pledgeAmount | currency }}
                  </div>
                  <div class="col column-value">
                    {{ agencyPledge.pledgeStatusDecode }}
                  </div>
                </div>
                <div class="row agencypledge-record-margin">
                  <div class="col column-header">Date Satisfied</div>
                  <div class="col column-header">Campaign ID</div>
                  <div class="col column-header">Campaign Name</div>
                  <div class="col column-header"></div>
                </div>
                <div class="row agencypledge-record-margin-bottom">
                  <div class="col column-value">
                    {{
                      agencyPledge.pledgeStatusDecode != 'Open'
                        ? agencyPledge.pledgeEndDate
                        : ''
                    }}
                  </div>
                  <div class="col column-value">
                    {{ agencyPledge.campaignID }}
                  </div>
                  <div class="col column-value">
                    {{ agencyPledge.campaignName }}
                  </div>
                  <div class="col column-value"></div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div id="payeftModal"
     class="modal fade"
     aria-hidden="true"
     aria-labelledby="amerenModal"
     tabindex="-1">
  <div class="modal-dialog" data-target="#modal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="ameren__modal__eft-title" *ngIf="!submitEFtPaymentClicked">
          Pay Via EFT
        </div>
        <div class="ameren__modal__eft-title"
             *ngIf="
            submitEFtPaymentClicked &&
            !isEFTPaymentLoading &&
            !isEFTPaymentAPIFailure
          ">
          Payment Successful
        </div>
        <div class="ameren__modal__eft-title ameren__modal__eft-failure-title"
             *ngIf="
            submitEFtPaymentClicked &&
            !isEFTPaymentLoading &&
            isEFTPaymentAPIFailure
          ">
          Payment Error
        </div>
        <div *ngIf="isEFTPaymentLoading">Loading...</div>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                (click)="closeEFTmodal()"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isEFTPaymentLoading" class="text-center">
          <ameren-loading id="loadingIndicator"
                          class="d-flex p-10 justify-content-center"></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image">
          <img *ngIf="
              submitEFtPaymentClicked &&
              !isEFTPaymentAPIFailure &&
              !isEFTPaymentLoading
            "
               src="assets/images/success.svg"
               alt="Success Image" />
          <img *ngIf="
              submitEFtPaymentClicked &&
              isEFTPaymentAPIFailure &&
              !isEFTPaymentLoading
            "
               src="assets/images/failure.svg"
               alt="Failure Image" />
        </div>
        <div class="eftmodal-message" *ngIf="!submitEFtPaymentClicked">
          You are paying {{ eftPledgeAmount | currency }}. Are you sure you want
          to proceed with this payment?
        </div>
        <div class="eftmodal-message"
             *ngIf="
            submitEFtPaymentClicked &&
            !isEFTPaymentAPIFailure &&
            !isEFTPaymentLoading
          ">
          Payment of {{ eftPledgeAmount | currency }} has been successfully
          paid.
        </div>
        <div class="eftmodal-message"
             *ngIf="
            submitEFtPaymentClicked &&
            isEFTPaymentAPIFailure &&
            !isEFTPaymentLoading
          ">
          Error while making a Payment of {{ eftPledgeAmount | currency }}.
        </div>
        <div class="eftmodal-yesno" *ngIf="!submitEFtPaymentClicked">
          <button class="btn btn-default eftmodal_btn eftmodal_no-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  (click)="closeEFTmodal()">
            No
          </button>
          <button class="btn btn-primary eftmodal_btn eftmodal_yes-btn"
                  (click)="submitEFTPayment()">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="clientSelectorModel"
     class="modal fade"
     aria-hidden="true"
     aria-labelledby="deleteCampaignModel"
     tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!isClientSelectorLoading">
        <div class="ameren__modal__failure-title">Error message!</div>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isClientSelectorLoading" class="text-center">
          <ameren-loading></ameren-loading>
          <div class="text-center">Please wait.</div>
        </div>

        <div class="row ameren__modal__failure-message text-danger"
             *ngIf="clientSelectorError">
          <span> Something went wrong. Please try again.</span>
        </div>
      </div>
    </div>
  </div>
</div>
<p class="m-4">
  <img id="clock"
       src="assets/icon/clock-icon.svg"
       alt="This pledge is within 30 days of expiration" />
  indicates pledge within 30 days of expiration.
</p>