import * as _ from 'lodash';

export class ClientQuickViewModel {
  ssnLast4Digits: string;
  accountBalance: number;
  totalPaymentLast90Days: number;
  totalEnergyAssistancePaymentsLast90Days: number;
  pendingPayments: number;
  pendingPledges: number;
  serviceType: string;
  isBudgetAccount: boolean;
  budgetBillingAmount: number;
  budgetBillingAheadBehindAmount: number;
  unbilledPaymentAgreementBalance: number;
  unbilledDepositBalance: number;
  isDisconnected: boolean;
  disconnectionDate: string;
  reconnectionAmount: number;
  showMPAYAmount: boolean;
  isPendingDisconnect: boolean;
  minDisconnectionPreventionAmount: number;
  numDisconnects: number;
  numPAGDefaults: number;
  billMethod: string;
  hasServiceDiversion: boolean;
  serviceDiversionChargesAmount: number;
  hasOnBillFinancing: boolean;
  onBillFinancingAmount: number;
  baStatus: string;
  isAmerenPrimaryHeatingSource: boolean;
  isOutstandingBalanceTransfer: boolean;
  collectionStatus: string;
  pastDueBalance: number;
  reconnectionFee: number;
  currentDayBalance: number;
  cashOnly: string;
  criticalAccount: any;
  disconnectionDateLabelText: string;
  hasDisconnectionData: boolean;
  showBudgetBilling: boolean;
  lowIncomeCreditTier: any;

  constructor(clientSummary?: any) {
    this.ssnLast4Digits = !_.isNil(clientSummary.ssnLast4Digits)
      ? clientSummary.ssnLast4Digits
      : '';
    this.accountBalance = !_.isNil(clientSummary.accountBalance)
      ? clientSummary.accountBalance
      : 0;
    this.totalPaymentLast90Days = !_.isNil(clientSummary.totalPaymentLast90Days)
      ? clientSummary.totalPaymentLast90Days
      : 0;
    this.totalEnergyAssistancePaymentsLast90Days = !_.isNil(
      clientSummary.totalEnergyAssistancePaymentsLast90Days
    )
      ? clientSummary.totalEnergyAssistancePaymentsLast90Days
      : 0;
    this.pendingPayments = !_.isNil(clientSummary.pendingPayments)
      ? clientSummary.pendingPayments
      : 0;
    this.pendingPledges = !_.isNil(clientSummary.pendingPledges)
      ? clientSummary.pendingPledges
      : 0;
    this.serviceType = !_.isNil(clientSummary.serviceType)
      ? clientSummary.serviceType
      : '';
    this.isBudgetAccount =
      clientSummary && clientSummary.isBudgetAccount
        ? clientSummary.isBudgetAccount
        : false;
    this.budgetBillingAmount = !_.isNil(clientSummary.budgetBillingAmount)
      ? clientSummary.budgetBillingAmount
      : 0;
    this.budgetBillingAheadBehindAmount = !_.isNil(
      clientSummary.budgetBillingAheadBehindAmount
    )
      ? clientSummary.budgetBillingAheadBehindAmount
      : 0;
    this.unbilledPaymentAgreementBalance = !_.isNil(
      clientSummary.unbilledPaymentAgreementBalance
    )
      ? clientSummary.unbilledPaymentAgreementBalance
      : 0;
    this.unbilledDepositBalance = !_.isNil(clientSummary.unbilledDepositBalance)
      ? clientSummary.unbilledDepositBalance
      : 0;
    this.isDisconnected =
      clientSummary && clientSummary.isDisconnected
        ? clientSummary.isDisconnected
        : false;
    this.disconnectionDate = !_.isNil(clientSummary.disconnectionDate)
      ? clientSummary.disconnectionDate
      : '';
    this.reconnectionAmount = !_.isNil(clientSummary.reconnectionAmount)
      ? clientSummary.reconnectionAmount
      : 0;
    this.showMPAYAmount =
      clientSummary && clientSummary.showMPAYAmount
        ? clientSummary.showMPAYAmount
        : false;
    this.isPendingDisconnect =
      clientSummary && clientSummary.isPendingDisconnect
        ? clientSummary.isPendingDisconnect
        : false;
    this.minDisconnectionPreventionAmount = !_.isNil(
      clientSummary.minDisconnectionPreventionAmount
    )
      ? clientSummary.minDisconnectionPreventionAmount
      : 0;
    this.numDisconnects = !_.isNil(clientSummary.numDisconnects)
      ? clientSummary.numDisconnects
      : 0;
    this.numPAGDefaults = !_.isNil(clientSummary.numPAGDefaults)
      ? clientSummary.numPAGDefaults
      : 0;
    this.billMethod = !_.isNil(clientSummary.billMethod)
      ? clientSummary.billMethod
      : '';
    this.hasServiceDiversion =
      clientSummary && clientSummary.hasServiceDiversion
        ? clientSummary.hasServiceDiversion
        : false;
    this.serviceDiversionChargesAmount = !_.isNil(
      clientSummary.serviceDiversionChargesAmount
    )
      ? clientSummary.serviceDiversionChargesAmount
      : 0;
    this.hasOnBillFinancing =
      clientSummary && clientSummary.hasOnBillFinancing
        ? clientSummary.hasOnBillFinancing
        : false;
    this.onBillFinancingAmount = !_.isNil(clientSummary.onBillFinancingAmount)
      ? clientSummary.onBillFinancingAmount
      : 0;
    this.baStatus = !_.isNil(clientSummary.baStatus)
      ? clientSummary.baStatus
      : '';
    this.isAmerenPrimaryHeatingSource =
      clientSummary && clientSummary.isAmerenPrimaryHeatingSource
        ? clientSummary.isAmerenPrimaryHeatingSource
        : false;
    this.isOutstandingBalanceTransfer =
      clientSummary && clientSummary.isOutstandingBalanceTransfer
        ? clientSummary.isOutstandingBalanceTransfer
        : false;
    this.collectionStatus = !_.isNil(clientSummary.collectionStatus)
      ? clientSummary.collectionStatus
      : '';
    this.pastDueBalance = !_.isNil(clientSummary.pastDueBalance)
      ? clientSummary.pastDueBalance
      : 0;
    this.reconnectionFee = !_.isNil(clientSummary.reconnectionFee)
      ? clientSummary.reconnectionFee
      : 0;
    this.currentDayBalance = !_.isNil(clientSummary.currentDayBalance)
      ? clientSummary.currentDayBalance
      : 0;
    this.cashOnly = !_.isNil(clientSummary.cashOnly)
      ? clientSummary.cashOnly.trim()
      : '';
    this.criticalAccount = !_.isNil(clientSummary.criticalAccount)
      ? clientSummary.criticalAccount
      : [];
    this.disconnectionDateLabelText =
      clientSummary && clientSummary.isDisconnected
        ? 'Disconnected'
        : 'Pending Disconnection Date';
    this.hasDisconnectionData =
      clientSummary.isPendingDisconnect || clientSummary.isDisconnected;

    this.showBudgetBilling =
      this.isBudgetAccount || this.budgetBillingAmount != 0 ? true : false;
    
    this.lowIncomeCreditTier = !_.isNil(clientSummary.lowIncomeCreditTier) && !_.isEmpty(clientSummary.lowIncomeCreditTier) ? LowIncomeCreditTierValues[clientSummary.lowIncomeCreditTier as keyof typeof LowIncomeCreditTierValues] : "N/A";    
  }    
}

export enum LowIncomeCreditTierValues{
  T1 = "Tier 1",
  T2 = "Tier 2",
  T3 = "Tier 3",
  T4 = "Tier 4",
  T5 = "Tier 5"
}
