import { IEAPAgencyInformation } from 'src/app/secure/administration/interfaces/IEAPAgencyInformation';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { EAPUserRole } from '../../../../src/app/shared/enums/eap-userrole';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AgencyService } from 'src/app/shared/services/agency.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  showviewclient: boolean = true;
  currentUser: any;
  isAmerenAdmin: boolean;
  selectedState: string;
  canPledge: boolean;
  selectedAgency: IEAPAgencyInformation;
  selectedAgencyRole: string | undefined;

  constructor(private _authservice: AuthService, private profileService: ProfileManagerService, private agencyService: AgencyService) {
    _authservice.getUser().then((user) => {
      this.currentUser = user?.profile;
      this.selectedState = this.currentUser.stateProvince;
    });
    this.selectedAgency = this.agencyService.getSelectedAgency();
    this.selectedAgencyRole = this.profileService.profile[0].agencyList?.filter(a=>a.AgencyID == this.selectedAgency.agencyID)[0]?.role;
    this.canPledge = this.selectedAgencyRole == 'eapUpdate' || this.selectedAgencyRole == 'eapAgencyAdmin';
  } 


  ngOnInit(): void {
    this.showviewclient = true;
    let _amerenAdminRoleValue =
      Object.keys(EAPUserRole)[
        Object.values(EAPUserRole).indexOf(EAPUserRole.eapAmerenAdmin)
      ];
    this.isAmerenAdmin =
      this.currentUser?.agencyRoleMap[0].role === _amerenAdminRoleValue;
  }
}
