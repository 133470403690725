import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  @Input() selectedState = '';

  getFaqs = (state: string): any[] => {
    return state == 'illinois'
      ? this.faqs_IL
      : state == 'missouri'
      ? this.faqs_MO
      : this.faqs_Ameren;
  };

  faqs_IL: any[] = [
    {
      header: 'How do I submit Pledge Payments?',
      detail:
        'Please mail your Pledge Payments to:<br/><br /><table><tr><td><b>Ameren Illinois</b></td></tr><tr><td>Attn: Energy Assistance A-10 </td></tr><tr><td>P.O. Box 2543</td></tr><tr><td>Decatur, IL 62525-2543</td></tr></table>',
    },
    {
      header:
        'Who can I contact if I have a question or a problem using the EA Portal?',
      detail:
        '<table><tr><td><b>Ameren Illinois</b></td></tr><tr><td>Email: <a href="mailto:ILEnergyAssistanceTeam@ameren.com" rel="" class="" target="_blank">ILEnergyAssistanceTeam@ameren.com</a></td></tr><tr><td>Phone: 888.667.7861</td></tr><tr><td>Fax: 217.424.6496</td></tr></table>',
    },
    {
      header: 'How can I help a customer understand their bill?',
      detail:
        '<a href="https://www.ameren.com/illinois/account/customer-service/bill/understanding-your-bill" rel="" class="" target="_blank">Understanding Your Bill - Ameren Illinois</a>',
    },
    {
      header:
        'Where can I access more information on Energy Assistance programs for customers?',
      detail:
        '<a href="https://www.ameren.com/illinois/residential/energy-assistance" rel="" class="" target="_blank">Energy Assistance - Ameren Illinois </a>',
    },
    {
      header: 'Where can I find additional Energy Assistance Portal Resources?',
      detail:
        '<table><tr><td><b>Ameren Illinois</b></td></tr><tr><td><a href="https://www.ameren.com/-/media/illinois-site/files/energy-assistance/agency-portal-guide-IL.ashx" rel="" class="" target="_blank">	Energy Assistance Portal Guide (PDF)</a></tr></td><tr><td><a href="mailto:ILEnergyAssistanceTeam@ameren.com?subject=Ameren Illinois Energy Assistance Portal Request" rel="" class="" target="_blank">Email Us</a> to sign up for Electronic Funds Transfer</td></tr></table>',
    },
  ];
  faqs_MO: any[] = [
    {
      header: 'How do I submit Pledge Payments?',
      detail:
        'Please mail your Pledge Payments to:<br/><br /><table><tr><td><b>Ameren Missouri</b></td></tr><tr><td>Attn: Energy Assistance </td></tr><tr><td>P.O. Box 66881</td></tr><tr><td>St. Louis, MO 63166</td></tr></table>',
    },
    {
      header:
        'Who can I contact if I have a question or a problem using the EA Portal?',
      detail:
        '<table><tr><td><b>Ameren Missouri</b></td></tr><tr><td>Email: EAssistance@ameren.com</td></tr><tr><td>Phone: 800.894.5211</td></tr><tr><td>Fax: 314.612.2844</td></tr></table>',
    },
    {
      header: 'How can I help a customer understand their bill?',
      detail:
        '<a href="https://www.ameren.com/missouri/account/customer-service/bill/understanding-your-bill" rel="" class="" target="_blank">Understanding Your Bill - Ameren Missouri</a>',
    },
    {
      header:
        'Where can I access more information on Energy Assistance programs for customers?',
      detail:
        '<a href="https://www.ameren.com/missouri/residential/energy-assistance" rel="" class="" target="_blank">Energy Assistance - Ameren Missouri</a>',
    },
    {
      header: 'Where can I find additional Energy Assistance Portal Resources?',
      detail:
        '<table><tr><td><b>Ameren Missouri</b></td></tr><tr><td><a href="https://www.ameren.com/-/media/missouri-site/files/energy-assistance/agency-portal-guide-MO.ashx" rel="" class="" target="_blank">	Energy Assistance Portal Guide (PDF)</a></tr></td><tr><td><a href="https://www.ameren.com/-/media/missouri-site/files/energy-assistance/pledge-payment-form.ashx" rel="" class="" target="_blank">New Pledge Payment Form (PDF)</a></tr></td><tr><td><a href="https://www.ameren.com/missouri/residential/energy-assistance/agency-resources" rel="" class="" target="_blank">Agency Resources - Ameren Missouri</a></tr></td><tr><td><a href="mailto:EAssistance@ameren.com?subject=Ameren Missouri Energy Assistance Portal Request" rel="" class="" target="_blank">Email Us</a> to request mailing labels or to sign up for Electronic Funds Transfer</td></tr></table>',
    },
  ];
  faqs_Ameren: any[] = [
    {
      header: 'How do I submit Pledge Payments?',
      detail:
        'Please mail your Pledge Payments to:<br/><br /><table><tr><td><b>Ameren Illinois</b></td></tr><tr><td>Attn: Energy Assistance A-10 </td></tr><tr><td>P.O. Box 2543</td></tr><tr><td>Decatur, IL 62525-2543</td></tr><tr><td></td></tr><tr><td></td></tr><tr><td><b>Ameren Missouri</b></td></tr><tr><td>Attn: Energy Assistance </td></tr><tr><td>P.O. Box 66881</td></tr><tr><td>St. Louis, MO 63166</td></tr></table>',
    },
    {
      header:
        'Who can I contact if I have a question or a problem using the EA Portal?',
      detail:
        '<table><tr><td><b>Ameren Illinois</b></td></tr><tr><td>Email: ILEnergyAssistanceTeam@ameren.com</td></tr><tr><td>Phone: 888.667.7861</td></tr><tr><td>Fax: 217.424.6496</td></tr><tr><td></td></tr><tr><td></td></tr><tr><td><b>Ameren Missouri</b></td></tr><tr><td>Email: EAssistance@ameren.com</td></tr><tr><td>Phone: 800.894.5211</td></tr><tr><td>Fax: 314.612.2844</td></tr></table>',
    },
    {
      header: 'How can I help a customer understand their bill?',
      detail:
        '<a href="https://www.ameren.com/illinois/account/customer-service/bill/understanding-your-bill" rel="" class="" target="_blank">Understanding Your Bill - Ameren Illinois</a><br/><br/><a href="https://www.ameren.com/missouri/account/customer-service/bill/understanding-your-bill" rel="" class="" target="_blank">Understanding Your Bill - Ameren Missouri</a>',
    },
    {
      header:
        'Where can I access more information on Energy Assistance programs for customers?',
      detail:
        '<a href="https://www.ameren.com/illinois/residential/energy-assistance" rel="" class="" target="_blank">Energy Assistance - Ameren Illinois </a><br/><br/><a href="https://www.ameren.com/missouri/residential/energy-assistance" rel="" class="" target="_blank">Energy Assistance - Ameren Missouri</a>',
    },
    {
      header: 'Where can I find additional Energy Assistance Portal Resources?',
      detail:
        '<table><tr><td><b>Ameren Illinois</b></td></tr><tr><td><a href="https://www.ameren.com/-/media/illinois-site/files/energy-assistance/agency-portal-guide-IL.ashx" rel="" class="" target="_blank">	Energy Assistance Portal Guide (PDF)</a></tr></td><tr><td><a href="mailto:ILEnergyAssistanceTeam@ameren.com?subject=Ameren Illinois Energy Assistance Portal Request" rel="" class="" target="_blank">Email Us</a> to sign up for Electronic Funds Transfer</td></tr></table>',
    },
  ];
}
