<div id="add_campaign__container" class="container">
  <div class="add_campaign_form">
    <form [formGroup]="addcampaignForm">
      <div class="row">
        <div class="add_campaign__title my-4 col">Campaign Details</div>
      </div>
      <div id="form-loading">
        <ameren-loading
          *ngIf="isLoading && !agencies"
          class="d-flex justify-content-center"
        ></ameren-loading>
      </div>
      <div class="row add_campaign_details" *ngIf="pledgeType && agencies">
        <div class="col-md-6 add_campaign_details_form">
          <div class="add_campaign_details_group">
            <div class="form-group">
              <label class="required">Campaign Name</label>
              <input
                formControlName="companyName"
                class="form-control"
                type="text"
              />
              <div
                class="text-field-error ameren-font-sm"
                *ngIf="
                  addcampaignForm?.get('companyName')?.dirty &&
                  addcampaignForm?.get('companyName')?.invalid
                "
              >
                <p
                  *ngIf="addcampaignForm?.get('companyName')?.errors?.['required']"
                >
                  Campaign Name is required.
                </p>
                <p
                  *ngIf="addcampaignForm?.get('companyName')?.errors?.['pattern']"
                >
                  Valid Characters (A-Z, spaces and '-').
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="required">Pledge Type</label>
              <select class="form-select" formControlName="pledgeType">
                <option [ngValue]="null" disabled>Select pledge type</option>
                <option
                  *ngFor="let pledge of pledgeType; let i = index"
                  [ngValue]="pledge.pledgeTypeCode"
                >
                  {{ pledge.pledgeTypeDescription }}
                </option>
              </select>

              <div
                class="text-field-error ameren-font-sm"
                *ngIf="
                  addcampaignForm?.get('pledgeType')?.dirty &&
                  addcampaignForm?.get('pledgeType')?.invalid
                "
              >
                <p
                  *ngIf="addcampaignForm?.get('pledgeType')?.errors?.['required']"
                >
                  PledgeType is required.
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="form-text">Admin Fee(%)</label>
              <input
                formControlName="adminFee"
                class="form-control"
                type="number"
                max="0"
                max="15"
              />
              <div
                class="text-field-error ameren-font-sm"
                *ngIf="
                  addcampaignForm?.get('adminFee')?.dirty &&
                  addcampaignForm?.get('adminFee')?.invalid
                "
              >
                <p
                  *ngIf="addcampaignForm?.get('adminFee')?.errors?.['pattern']"
                >
                  Only number Characters.
                </p>
                <p
                  *ngIf="addcampaignForm?.get('adminFee')?.errors?.['max'] || addcampaignForm?.get('adminFee')?.errors?.['min']"
                >
                  Admin fee must be between 0 and 15.
                </p>
              </div>
            </div>
            <div class="form-group d-flex flex-row">
              <label>Individual Pledge</label>
              <div class="ms-auto">
                <label class="mx-2">
                  <input
                    type="radio"
                    value="Range"
                    formControlName="individualPledge"
                    checked
                  />
                  <span>Range</span>
                </label>
                <label class="mx-2">
                  <input
                    type="radio"
                    value="Fixed"
                    formControlName="individualPledge"
                  />
                  <span>Fixed</span>
                </label>
              </div>
            </div>
            <div
              class="d-flex flex-row"
              *ngIf="addcampaignForm.get('individualPledge')?.value == 'Range'"
            >
              <div class="form-group flex-fill">
                <label>Min</label>
                <input formControlName="min" class="form-control" type="text" />
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    addcampaignForm?.get('min')?.dirty &&
                    addcampaignForm?.get('min')?.invalid
                  "
                >
                  <p *ngIf="addcampaignForm?.get('min')?.errors?.['required']">
                    Mininum is required.
                  </p>
                  <p *ngIf="addcampaignForm?.get('min')?.errors?.['pattern']">
                    Only number Characters.
                  </p>
                </div>
              </div>
              <div class="form-group flex-fill">
                <label>Max</label>
                <input formControlName="max" class="form-control" type="text" />
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    addcampaignForm?.get('max')?.dirty &&
                    addcampaignForm?.get('max')?.invalid
                  "
                >
                  <p *ngIf="addcampaignForm?.get('max')?.errors?.['required']">
                    Maxinum is required.
                  </p>
                  <p *ngIf="addcampaignForm?.get('max')?.errors?.['pattern']">
                    Only number Characters.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="addcampaignForm.get('individualPledge')?.value == 'Fixed'"
            >
              <input
                formControlName="pledge"
                class="form-control"
                type="text"
              />
              <div
                class="text-field-error ameren-font-sm"
                *ngIf="
                  addcampaignForm?.get('pledge')?.dirty &&
                  addcampaignForm?.get('pledge')?.invalid
                "
              >
                <p *ngIf="addcampaignForm?.get('pledge')?.errors?.['required']">
                  This field is required.
                </p>
                <p *ngIf="addcampaignForm?.get('pledge')?.errors?.['pattern']">
                  Only number Characters.
                </p>
              </div>
            </div>
            <div class="form-group">
              <label>Campaign Dates</label>
            </div>
            <div class="d-flex flex-row">
              <div class="form-group flex-fill">
                <input
                  formControlName="campaignStartDate"
                  class="form-control"
                  type="date"
                />
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    addcampaignForm?.get('campaignStartDate')?.dirty &&
                    addcampaignForm?.get('campaignStartDate')?.invalid
                  "
                >
                  <p
                    *ngIf="addcampaignForm?.get('campaignStartDate')?.errors?.['required']"
                  >
                    Campaign Start Date is required.
                  </p>
                </div>
              </div>
              <div class="form-group flex-fill">
                <input
                  formControlName="campaignEndDate"
                  class="form-control"
                  type="date"
                />
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    addcampaignForm?.get('campaignEndDate')?.dirty &&
                    addcampaignForm?.get('campaignEndDate')?.invalid
                  "
                >
                  <p
                    *ngIf="addcampaignForm?.get('campaignEndDate')?.errors?.['required']"
                  >
                    Campaign End Date is required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="add_campaign__title my-4 col">Participating Agencies</div>
      </div>

      <div class="row" *ngIf="agencies">
        <div class="add_participating_agencies">
          <div class="add_participating_agencies_details">
            <div
              class="m-0 d-flex flex-row"
              formArrayName="participatingAgencies"
            >
              <div class="d-flex flex-column">
                <div
                  class="p-0"
                  *ngFor="
                    let item of participatingAgencies.controls;
                    index as idx
                  "
                >
                  <div class="m-0 d-flex flex-row" [formGroupName]="idx">
                    <div
                      class="agency_trash_can"
                      (click)="deleteParticipatingAgency(idx)"
                    >
                      <em
                        class="fa-regular fa-trash-can"
                        style="font-size: larger"
                      ></em>
                    </div>
                    <div class="form-group flex-fill new_agency_name">
                      <label>Agency Name</label>
                      <select class="form-select" formControlName="agencyName">
                        <option
                          *ngFor="let agcy of agencies"
                          [value]="agcy.agencyCSSCode"
                        >
                          {{ agcy.agencyName }}
                        </option>
                      </select>
                      <div
                        class="text-field-error ameren-font-sm"
                        *ngIf="
                          participatingAgencies.controls[idx]?.get('agencyName')
                            ?.dirty &&
                          participatingAgencies.controls[idx]?.get('agencyName')
                            ?.invalid
                        "
                      >
                        Invalid Agency Name.
                      </div>
                    </div>
                    <div class="form-group flex-fill new_agency_state">
                      <label>Allocated</label>
                      <input
                        formControlName="allocated"
                        type="number"
                        class="form-control"
                        (keyup)="onAllocationChange(idx)"
                      />
                      <div
                        class="text-field-error ameren-font-sm"
                        *ngIf="
                          participatingAgencies.controls[idx]?.get('allocated')
                            ?.dirty &&
                          participatingAgencies.controls[idx]?.get('allocated')
                            ?.invalid
                        "
                      >
                        <p
                          *ngIf="participatingAgencies.controls[idx]?.get('allocated')?.errors?.['required']"
                        >
                          Allocated is required.
                        </p>
                        <p
                          *ngIf="participatingAgencies.controls[idx]?.get('allocated')?.errors?.['pattern']"
                        >
                          Only number Characters.
                        </p>
                      </div>
                    </div>
                    <div class="form-group flex-fill new_agency_state">
                      <label>Fee($)</label>
                      <input
                        formControlName="fee"
                        readonly
                        type="number"
                        class="form-control"
                      />
                      <div
                        class="text-field-error ameren-font-sm"
                        *ngIf="
                          participatingAgencies.controls[idx]?.get('fee')
                            ?.dirty &&
                          participatingAgencies.controls[idx]?.get('fee')
                            ?.invalid
                        "
                      >
                        <p
                          *ngIf="participatingAgencies.controls[idx]?.get('fee')?.errors?.['required']"
                        >
                          Fee is required.
                        </p>
                        <p
                          *ngIf="participatingAgencies.controls[idx]?.get('fee')?.errors?.['pattern']"
                        >
                          Only number Characters.
                        </p>
                      </div>
                    </div>
                    <div class="form-group flex-fill new_agency_state">
                      <label>Total($)</label>
                      <input
                        formControlName="totalAdmin"
                        type="number"
                        class="form-control"
                      />
                      <div
                        class="text-field-error ameren-font-sm"
                        *ngIf="
                          participatingAgencies.controls[idx]?.get('totalAdmin')
                            ?.dirty &&
                          participatingAgencies.controls[idx]?.get('totalAdmin')
                            ?.invalid
                        "
                      >
                        <p
                          *ngIf="participatingAgencies.controls[idx]?.get('totalAdmin')?.errors?.['required']"
                        >
                          Total is required.
                        </p>
                        <p
                          *ngIf="participatingAgencies.controls[idx]?.get('totalAdmin')?.errors?.['pattern']"
                        >
                          Only number Characters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add_additional_agency">
                <a
                  (click)="addParticipatingAgency()"
                  class="add_additional_agency_button mt-5"
                  >+ Additional Agency</a
                >
              </div>
            </div>
          </div>
          <hr />
          <div
            class="campaign_summary d-flex flex-row my-3"
            *ngIf="participatingAgencies.controls.length > 0"
          >
            <div class="campaign_total">
              Campaign total:
              <br />
              {{ campaignTotal | currency }}
            </div>
            <div class="fee_total">
              Fee:
              <br />
              {{ feeTotal | currency }}
            </div>
            <div class="admin_total">
              Total:
              <br />
              {{ adminTotal | currency }}
            </div>
          </div>
        </div>
      </div>
      <ameren-loading
        *ngIf="isLoading"
        class="d-flex justify-content-center"
      ></ameren-loading>
      <div class="mt-5 text-center text-success" *ngIf="apiSuccess">
        Campaign created successfully.
      </div>
      <div class="mt-5 text-center text-field-error" *ngIf="apiFailure">
        Sorry, Some thing went wrong. Please try again.
      </div>
      <div class="mt-5 text-center">
        <button
          [disabled]="!addcampaignForm.valid"
          (click)="addcampaign()"
          class="btn btn-success create_campaign_button"
        >
          Create Campaign
        </button>
      </div>
    </form>
  </div>
</div>
