import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AgencyRoleDetails, Profile } from 'src/app/shared/models/profile';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { CampaignsManagerService } from '../services/campaigns-manager.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';

import { pledgeType } from '../../pledge-entry/models/pledgetype.model';
import { PledgeEntryManagerService } from '../../pledge-entry/services/pledge-entry-manager.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { Client } from 'src/app/shared/models/client';
import {
  AgencyAllocationModel,
  CampaignDetailsModel,
  ParticipatingAgenciesModel,
} from '../model/campaignbycampaignid.model';
import { formatDate, PercentPipe } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EnumRole } from 'src/app/shared/enums/role';

declare var window: any;

@Component({
  selector: 'campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
})
export class CampaignDetailsComponent implements OnInit, AfterViewInit {
  deleteCampaignAgencyApiFailure = false;
  deleteCampaignAgencyApiSuccess = false;
  saveCampaignSuccess = false;
  saveCampaignFailure = false;
  saveCampaignLoading = false;
  deleteAgencyCampaignLoading = false;
  showSnack: boolean = false;
  selectedCampaignAgencyIdForDelete: string;
  campaignId: any;
  mymodel: any;
  integrationErrorMsg: string;
  isAPIFailure: boolean = false;
  isLoading: any = false;
  campaignDetails: CampaignDetailsModel;
  isLoadingforAgencyById: boolean;
  isAPIFailureAgencyById: boolean;
  userProfile: Profile;
  campaignDetailsForm!: FormGroup;
  pledgeTypes: pledgeType[];
  selectedClient: Client;
  selectedAgency: any;
  campaignAgencies: any;
  updateCampaignBody: any;
  getCampaignDetailsLoading: boolean = false;
  getCampaignDetailsAPIFailure: boolean = false;
  getCampaignDetailsErrorMessage: string;
  deleteConfrmModal: any;
  saveCampaignLoadingModal: any;
  deleteCampaignAgencyModal: any;
  participatingAgenciesModel: ParticipatingAgenciesModel = {
    campaignAgencyID: 0,
    campaignAgencyName: '',
    campaignID: 0,
    agencyCode: '',
    campaignAmount: 0,
    agencyAllocation: {
      amount: 0,
      fee: 0,
      toCustomerAmount: 0,
    },
    pledge: {
      amount: 0,
      fee: 0,
      toCustomerAmount: 0,
    },
    remaining: {
      amount: 0,
      fee: 0,
      toCustomerAmount: 0,
    },
    adminFee: 0,
    isEdited: false,
  };
  updatecampaignAgencies: any[] = [];

  allocationTotal = 0;
  allocationTotalFee = 0;
  allocationTotalAdmin = 0;

  usedTotal = 0;
  usedTotalFee = 0;
  usedTotalAdmin = 0;

  remainingTotal = 0;
  remainingTotalFee = 0;
  remainingTotalAdmin = 0;
  campaignAmount = 0;
  pledgeTypeCodeValue = '';
  pledgeTypeDecodeValue = '';
  totalAmountValue: any[] = [];
  agencyFee: string[] = [];
  isAmerenAdmin: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private campaignManagerService: CampaignsManagerService,
    private profileManager: ProfileManagerService,
    private _fb: FormBuilder,
    private pledgeEntryManager: PledgeEntryManagerService,
    private clientService: ClientService,
    private agencyService: AgencyService,
    private percentPipe: PercentPipe,
    private authService: AuthService
  ) {
    this.profileManager.profile.forEach((a: Profile) => {
      this.userProfile = a;
    });

    this.selectedClient = this.clientService.getSelectedClient();
    this.selectedAgency = this.agencyService.getSelectedAgency();

    this.authService.getUser().then((user: any) => {
      let userRole: AgencyRoleDetails[] = [];
      userRole = user?.profile?.agencyRoleMap;
      if (userRole) {
        userRole.forEach((a) => {
          a.role === EnumRole.EAPAMERENADMIN
            ? (this.isAmerenAdmin = true)
            : (this.isAmerenAdmin = false);
        });
      }
    });
  }

  ngOnInit() {
    this.initCampaignDetailsForm();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.campaignId = params.get('campaignid');
    });

    this.pledgeTypeList();

    this.mymodel = new window.bootstrap.Modal(
      document.getElementById('deleteCampaignModel'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.deleteConfrmModal = new window.bootstrap.Modal(
      document.getElementById('deleteModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.saveCampaignLoadingModal = new window.bootstrap.Modal(
      document.getElementById('saveCampaignLoadingModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    //saveCampaignLoadingModal

    this.deleteConfrmModal = new window.bootstrap.Modal(
      document.getElementById('deleteModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.deleteCampaignAgencyModal = new window.bootstrap.Modal(
      document.getElementById('deleteCampaignAgencyModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.getCampaignAgenciesListByState();
  }

  onDeleteConfirmModal() {
    this.deleteConfrmModal.show();
  }

  onDeleteCampaignCancel() {
    this.deleteConfrmModal.hide();
  }

  onDeleteCampaign() {
    this.deleteConfrmModal.hide();
    this.isLoading = true;
    this.isAPIFailure = false;
    this.campaignManagerService.deleteCampaign(this.campaignId).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.mymodel.show();
        setTimeout(() => {
          this.mymodel.hide();
          this.router.navigate(['campaign-list']);
        }, 1000);
      },
      error: (err) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        if (err.status === 400) {
          this.deleteConfrmModal.hide();
          this.integrationErrorMsg =
            'Sorry, something is not right. Please try again.';
        } else {
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        }
      },
    });
  }

  inputTotalAmount(index: any) {
    let campaignForm = (
      this.campaignDetailsForm.get('addAgencies') as FormArray
    ).controls[index];
    if (campaignForm.get('allocated')?.value) {
      let allocated = parseInt(campaignForm.get('allocated')?.value);
      let fee = allocated * this.campaignDetails.adminFeePercent;
      let total = allocated + fee;
      this.totalAmountValue[index] = '$' + total.toString();
      this.agencyFee[index] = '$' + fee.toString();
    }
  }

  edit(index: any) {
    this.campaignDetails.campaignAgencies[index].isEdited =
      !this.campaignDetails.campaignAgencies[index].isEdited;
  }

  save(index: any, obj: any) {
    this.campaignDetails.campaignAgencies[index].isEdited =
      !this.campaignDetails.campaignAgencies[index].isEdited;

    this.campaignDetails.campaignAgencies[index].agencyAllocation.amount =
      obj.agencyAllocation.amount;
    this.updateCampaign();
  }

  get addAgencies(): FormArray {
    return this.campaignDetailsForm.get('addAgencies') as FormArray;
  }

  initCampaignDetailsForm() {
    this.campaignDetailsForm = this._fb.group({
      campaignId: new FormControl(null, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      campaignName: new FormControl(null, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      state: new FormControl(null, [Validators.pattern('^[-_ a-zA-Z0-9]+$')]),
      pledgeCode: new FormControl(this.pledgeTypeDecodeValue, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      pledgeDecode: new FormControl(null, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      campaignAmount: new FormControl(null, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      adminFeePercent: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      maximumPledgeAmount: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      minimumPledgeAmount: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      fixedPledgeAmount: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      isFixedIndividualPledge: new FormControl(null, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      startDate: new FormControl(null, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      endDate: new FormControl(null, [Validators.pattern('^[-_ a-zA-Z0-9]+$')]),
      timelinePercentComplete: new FormControl(null, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      financialPercentComplete: new FormControl(null, [
        Validators.required,
        Validators.pattern('^\\d*\\.?\\d*$'),
      ]),
      addAgencies: this._fb.array([
        this._fb.group({
          agencyName: new FormControl(null, [
            Validators.pattern('^[-_ a-zA-Z0-9]+$'),
          ]),
          allocated: new FormControl('0.00', [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
          ]),
          percentage: new FormControl({ value: '100%', disabled: true }, [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
          ]),
          fee: new FormControl({ value: '$0.0', disabled: true }, [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
          ]),
          totalAdmin: new FormControl({ value: null, disabled: true }, [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
          ]),
        }),
      ]),
    });

    if (!this.isAmerenAdmin) {
      this.campaignDetailsForm.disable();
      this.addAgencies.disable();
    }
  }

  addAdditionalAgency() {
    (this.campaignDetailsForm.get('addAgencies') as FormArray).push(
      this._fb.group({
        agencyName: new FormControl(null, [
          Validators.pattern('^[-_ a-zA-Z0-9]+$'),
        ]),
        allocated: new FormControl(null, [
          Validators.required,
          Validators.pattern('^\\d*\\.?\\d*$'),
        ]),
        percentage: new FormControl({ value: '100%', disabled: true }, [
          Validators.required,
          Validators.pattern('^\\d*\\.?\\d*$'),
        ]),
        fee: new FormControl({ value: '$0.00', disabled: true }, [
          Validators.required,
          Validators.pattern('^\\d*\\.?\\d*$'),
        ]),
        totalAdmin: new FormControl({ value: null, disabled: true }, [
          Validators.required,
          Validators.pattern('^\\d*\\.?\\d*$'),
        ]),
      })
    );
  }

  ngAfterViewInit(): void {
    this.campaignDetailsForm.valueChanges.subscribe((formData) => {
      this.updateCampaignBody = formData;
    });
  }

  transformPercentage(ele: any) {
    this.campaignDetails.adminFeePercent = this.percentPipe.transform(
      this.campaignDetails.adminFeePercent,
      '%'
    );
    ele.target.value = this.campaignDetails.adminFeePercent;
  }

  removeAgency(id: number) {
    (this.campaignDetailsForm.get('addAgencies') as FormArray).removeAt(id);
  }

  partipatingAgency(agencies: any) {
    if (this.campaignDetails.campaignAgencies.length > 0) {
      this.existingAgencies();
    }

    agencies.forEach((elm: any) => {
      let agency = this.campaignAgencies.find(
        (e: any) => e.agencyName == elm.agencyName
      );
      let agencyAllocation = {
        amount: elm.allocated,
      };
      let campaignAgencies = {
        campaignAgencyID: '0',
        agencyCode: agency.agencyCSSCode,
        agencyAllocation: agencyAllocation,
      };
      this.updatecampaignAgencies.push(campaignAgencies);
    });
  }

  existingAgencies() {
    this.campaignDetails.campaignAgencies.forEach((elm) => {
      let agencyAllocation = {
        amount: elm.agencyAllocation.amount.toString(),
      };
      let campaignAgencies = {
        campaignAgencyID: elm.campaignAgencyID.toString(),
        agencyCode: elm.agencyCode,
        agencyAllocation: agencyAllocation,
      };
      this.updatecampaignAgencies.push(campaignAgencies);
    });
  }

  updateCampaign() {
    this.updatecampaignAgencies = [];
    let agencies = this.campaignDetailsForm.controls['addAgencies'].value;
    if (agencies.length == 1) {
      if (agencies[0].agencyName) {
        this.partipatingAgency(agencies);
      } else {
        this.existingAgencies();
      }
    } else {
      this.partipatingAgency(agencies);
    }

    if (this.updatecampaignAgencies.length > 0) {
      this.campaignAmount = 0;
      this.updatecampaignAgencies.forEach((item) => {
        this.campaignAmount += parseInt(item.agencyAllocation.amount);
      });
    }

    const updateCampaignBody = {
      campaign: {
        campaignID: this.campaignDetails.campaignId.toString(),
        campaignName: this.campaignDetailsForm.controls['campaignName'].value,
        campaignAmount: this.campaignAmount.toString(),
        adminFee: (
          this.campaignDetailsForm.controls['adminFeePercent'].value / 100
        ).toString(),
        isFixedIndividualPledge:
          this.campaignDetails.isFixedIndividualPledge.toString(),
        minPledge:
          this.campaignDetailsForm.controls['minimumPledgeAmount'].value == null
            ? '0.00'
            : this.campaignDetailsForm.controls[
                'minimumPledgeAmount'
              ].value.toString(),
        maxPledge:
          this.campaignDetailsForm.controls['maximumPledgeAmount'].value == null
            ? '0.00'
            : this.campaignDetailsForm.controls[
                'maximumPledgeAmount'
              ].value.toString(),
        fixedPledgeAmount:
          <number>(
            this.campaignDetailsForm.controls['fixedPledgeAmount'].value
          ) == null
            ? 0
            : this.campaignDetailsForm.controls['fixedPledgeAmount'].value,
        campaignFromDate: this.campaignDetailsForm.controls['startDate'].value,
        campaignToDate: this.campaignDetailsForm.controls['endDate'].value,
        campaignAgencies: this.updatecampaignAgencies,
      },
    };

    this.saveCampaignLoading = true;
    this.saveCampaignLoadingModal.show();
    this.campaignManagerService.updateCampaign(updateCampaignBody).subscribe({
      next: (res: any) => {
        this.saveCampaignSuccess = true;
        this.saveCampaignFailure = false;
        this.saveCampaignLoading = false;
        this.campaignDetailsForm.reset();
      },
      error: (error: any) => {
        this.saveCampaignSuccess = false;
        this.saveCampaignFailure = true;
        this.saveCampaignLoading = false;
        console.log('error', error);
        this.campaignDetailsForm.reset();
      },
      complete: () => {
        this.saveCampaignLoading = false;
        this.saveCampaignLoadingModal.hide();
        this.showSnack = true;
        setTimeout(() => {
          this.closeSnack();
        }, 2000);
      },
    });
  }

  getTotal(participateAgencies: ParticipatingAgenciesModel[]) {
    this.allocationTotal = 0;
    this.allocationTotalFee = 0;
    this.allocationTotalAdmin = 0;
    this.usedTotal = 0;
    this.usedTotalFee = 0;
    this.usedTotalAdmin = 0;
    this.remainingTotal = 0;
    this.remainingTotalFee = 0;
    this.remainingTotalAdmin = 0;
    participateAgencies.forEach((element) => {
      this.allocationTotal += element.agencyAllocation.amount;
      this.allocationTotalFee += element.agencyAllocation.fee;
      this.usedTotal += element.pledge.amount;
      this.usedTotalFee += element.pledge.fee;
      this.remainingTotal += element.remaining.amount;
      this.remainingTotalFee += element.remaining.fee;
    });

    this.allocationTotalAdmin = this.allocationTotalFee + this.allocationTotal;
    this.remainingTotalAdmin = this.remainingTotal + this.remainingTotalFee;
    this.usedTotalAdmin = this.usedTotalFee + this.usedTotal;
  }

  getCampaignByCampaignId() {
    this.getCampaignDetailsLoading = true;
    this.getCampaignDetailsAPIFailure = false;
    this.campaignManagerService
      .getCampaignByCampaignId(this.campaignId)
      .subscribe({
        next: (res: any) => {
          this.getCampaignDetailsLoading = false;
          this.getCampaignDetailsAPIFailure = false;
          this.campaignDetails = res;
          this.pledgeTypeDecodeValue = this.campaignDetails.pledgeDecode;
          this.pledgeTypeCodeValue = this.campaignDetails.pledgeCode;
          const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
          const days =
            Math.round(
              Date.parse(this.campaignDetails.endDate) - Date.parse(currentDate)
            ) /
            (1000 * 60 * 60 * 24);

          this.campaignDetails.timeRemainingDays = days || 0;

          this.getTotal(this.campaignDetails.campaignAgencies);
          this.campaignDetails.campaignAgencies.forEach((i) => {
            i.isEdited = false;
            i.campaignAgencyName = this.campaignAgencies.filter(
              (a: any) => a.agencyCSSCode == i.agencyCode
            )[0].agencyName;
          });
          this.campaignDetailsForm
            .get('campaignName')
            ?.setValue(this.campaignDetails.campaignName);

          this.campaignDetailsForm
            .get('campaignId')
            ?.setValue(this.campaignDetails.campaignId);

          this.campaignDetailsForm
            .get('adminFeePercent')
            ?.setValue(this.campaignDetails.adminFeePercent * 100);

          this.campaignDetailsForm
            .get('minimumPledgeAmount')
            ?.setValue(this.campaignDetails.minimumPledgeAmount);

          this.campaignDetailsForm
            .get('maximumPledgeAmount')
            ?.setValue(this.campaignDetails.maximumPledgeAmount);

          this.campaignDetailsForm
            .get('maximumPledgeAmount')
            ?.setValue(this.campaignDetails.maximumPledgeAmount);

          this.campaignDetailsForm
            .get('fixedPledgeAmount')
            ?.setValue(this.campaignDetails.fixedPledgeAmount);

          this.campaignDetailsForm
            .get('startDate')
            ?.setValue(this.campaignDetails.startDate);
          this.campaignDetailsForm
            .get('endDate')
            ?.setValue(this.campaignDetails.endDate);

          this.campaignDetailsForm
            .get('campaignAmount')
            ?.setValue(this.campaignDetails.campaignAmount);

          this.campaignDetailsForm
            .get('financialPercentComplete')
            ?.setValue(this.campaignDetails.financialPercentComplete);

          this.campaignDetailsForm
            .get('timelinePercentComplete')
            ?.setValue(this.campaignDetails.timelinePercentComplete);
        },
        error: (error: AmerenError) => {
          console.log('error', error);
          this.getCampaignDetailsLoading = false;
          this.getCampaignDetailsAPIFailure = true;
          if (error.status === 400) {
            this.getCampaignDetailsErrorMessage =
              'Sorry, something is not right. Please try again.';
          } else {
            this.getCampaignDetailsErrorMessage =
              'Sorry, something went wrong. Please try again later.';
          }
        },
      });
  }

  pledgeTypeList() {
    if (this.selectedAgency) {
      this.pledgeEntryManager
        .getPledgeTypeList(this.selectedAgency.agencyCSSCode)
        .subscribe({
          next: (res: any) => {
            this.pledgeTypes = res;
          },
          error: (error: any) => {
            console.log('error', error);
          },
        });
    } else {
      this.pledgeEntryManager.getPledgeTypeListByState().subscribe({
        next: (res: any) => {
          this.pledgeTypes = res;
        },
        error: (error: any) => {
          console.log('error', error);
        },
      });
    }
  }

  getCampaignAgenciesListByState() {
    this.getCampaignDetailsLoading = true;
    this.campaignManagerService.getAgenciesByState().subscribe({
      next: (res: any) => {
        this.isAPIFailure = false;
        this.campaignAgencies = this.sortData(res);
        this.getCampaignByCampaignId();
      },
      error: (error: AmerenError) => {
        console.log('error', error);
        this.isAPIFailure = true;
        if (error.status === 400) {
          this.integrationErrorMsg =
            'Sorry, something is not right. Please try again.';
        } else {
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        }
      },
    });
  }

  onDeleteCampaignAgency(campaignAgencyId: string) {
    this.selectedCampaignAgencyIdForDelete = campaignAgencyId;
    this.deleteCampaignAgencyModal.show();
  }

  onDeleteCampaignAgencyConfirm() {
    this.deleteAgencyCampaignLoading = true;
    this.campaignManagerService
      .deleteCampaignByAgency(this.selectedCampaignAgencyIdForDelete)
      .subscribe({
        next: (data) => {
          this.deleteCampaignAgencyApiSuccess = true;
          this.deleteCampaignAgencyApiFailure = false;
          this.deleteAgencyCampaignLoading = false;
          this.updateCampaign();
        },
        error: () => {
          this.deleteCampaignAgencyApiSuccess = false;
          this.deleteAgencyCampaignLoading = false;
        },
        complete: () => {
          this.deleteCampaignAgencyModal.hide();
          this.showSnack = true;
          setTimeout(() => {
            this.closeSnack();
          }, 5000);
        },
      });
  }

  onDeleteCampaignAgencyCancel() {
    this.deleteCampaignAgencyModal.hide();
  }

  closeSnack() {
    this.showSnack = false;
    this.deleteCampaignAgencyApiSuccess = false;
    this.deleteCampaignAgencyApiFailure = false;
    this.saveCampaignFailure = false;
    this.saveCampaignSuccess = false;
    this.getCampaignByCampaignId();
  }

  sortData(res: any[]) {
    let sortingData: any[] = res;
    return sortingData.sort((a: any, b: any) => {
      return a.agencyName
        .toLowerCase()
        .localeCompare(b.agencyName.toLowerCase());
    });
  }
}
