import * as _ from 'lodash';

export class AgencyRegistrationList {
  registrationID: number;
  agencyPin: string;
  agencyName: string;
  companyCode: number;
  dateCreated: string;
  updatedBy: string;
  agencyStreetAddress: string;
  agencyCity: string;
  agencyState: string;
  agencyZip: string;
  registrationStatus: string;
  agencyEmailAddress?: string;

  // Agency Details
  phoneNumber: string;
  faxNumber: any;
  emailAddress: string;
  requestorName: string;
  requestorTitle: string;
  dateUpdated: string;
  agencyStreetAddressAddressLine1: string;
  agencyStreetAddressCity: string;
  agencyStreetAddressPostalCode: string;
  agencyStreetAddressState: string;
  agencyMailingAddressAddressLine1: string;
  agencyMailingAddressCity: string;
  agencyMailingAddressPostalCode: string;
  agencyMailingAddressState: string;
  isCustomerReffered: any;
  pdfImage: string;
  serviceZipCodes: any;
  agencyRepresentatives: any;

  constructor(agencyList?: any) {
    this.registrationID = !_.isNil(agencyList.registrationID)
      ? agencyList.registrationID
      : 0;
    this.agencyPin = !_.isNil(agencyList.agencyPin) ? agencyList.agencyPin : '';
    this.agencyName = !_.isNil(agencyList.agencyName)
      ? agencyList.agencyName
      : '';
    this.companyCode = !_.isNil(agencyList.companyCode)
      ? agencyList.companyCode
      : '';
    this.dateCreated = !_.isNil(agencyList.dateCreated)
      ? agencyList.dateCreated
      : '';
    this.updatedBy = !_.isNil(agencyList.updatedBy) ? agencyList.updatedBy : '';
    this.agencyStreetAddress = !_.isNil(agencyList.agencyAddress.streetAddress)
      ? agencyList.agencyAddress.streetAddress
      : '';
    this.agencyCity = !_.isNil(agencyList.agencyAddress.city)
      ? agencyList.agencyAddress.city
      : '';
    this.agencyState = !_.isNil(agencyList.agencyAddress.state)
      ? agencyList.agencyAddress.state
      : '';
    this.agencyZip = !_.isNil(agencyList.agencyAddress.zip)
      ? agencyList.agencyAddress.zip
      : '';
    this.registrationStatus = !_.isNil(agencyList.registrationStatus)
      ? agencyList.registrationStatus
      : '';
    this.agencyEmailAddress = !_.isNil(agencyList.agencyEmailAddress)
      ? agencyList.agencyEmailAddress
      : '';

    // Agency Details

    this.phoneNumber = !_.isNil(agencyList.phoneNumber)
      ? agencyList.phoneNumber
      : '';
    this.faxNumber = !_.isNil(agencyList.faxNumber) ? agencyList.faxNumber : 0;
    this.emailAddress = !_.isNil(agencyList.emailAddress)
      ? agencyList.emailAddress
      : '';
    this.requestorName = !_.isNil(agencyList.requestorName)
      ? agencyList.requestorName
      : '';
    this.requestorTitle = !_.isNil(agencyList.requestorTitle)
      ? agencyList.requestorTitle
      : '';
    this.dateUpdated = !_.isNil(agencyList.dateUpdated)
      ? agencyList.dateUpdated
      : '';
    this.agencyStreetAddressAddressLine1 = !_.isNil(
      agencyList?.agencyStreetAddress?.addressLine1
    )
      ? agencyList?.agencyStreetAddress?.addressLine1
      : '';
    this.agencyStreetAddressCity = !_.isNil(
      agencyList?.agencyStreetAddress?.city
    )
      ? agencyList?.agencyStreetAddress?.city
      : '';
    this.agencyStreetAddressPostalCode = !_.isNil(
      agencyList?.agencyStreetAddress?.postalCode
    )
      ? agencyList?.agencyStreetAddress?.postalCode
      : '';
    this.agencyStreetAddressState = !_.isNil(
      agencyList?.agencyStreetAddress?.state
    )
      ? agencyList?.agencyStreetAddress?.state
      : '';
    this.agencyMailingAddressAddressLine1 = !_.isNil(
      agencyList?.agencyMailingAddress?.addressLine1
    )
      ? agencyList?.agencyMailingAddress?.addressLine1
      : '';
    this.agencyMailingAddressCity = !_.isNil(
      agencyList?.agencyMailingAddress?.city
    )
      ? agencyList?.agencyMailingAddress?.city
      : '';
    this.agencyMailingAddressPostalCode = !_.isNil(
      agencyList?.agencyMailingAddress?.postalCode
    )
      ? agencyList?.agencyMailingAddress?.postalCode
      : '';
    this.agencyMailingAddressState = !_.isNil(
      agencyList?.agencyMailingAddress?.state
    )
      ? agencyList?.agencyMailingAddress?.state
      : '';
    this.isCustomerReffered =
      agencyList.isCustomerReffered === true ? 'Yes' : 'No';
    this.pdfImage = !_.isNil(agencyList.pdfImage) ? agencyList.pdfImage : '';
    this.serviceZipCodes = !_.isNil(agencyList.serviceZipCodes)
      ? agencyList.serviceZipCodes
      : '';
    this.agencyRepresentatives = !_.isNil(agencyList.agencyRepresentatives)
      ? agencyList.agencyRepresentatives
      : '';
  }
}
