<div class="pendingagency__users-container view-none-mobile">
    <p class="pendingagency__users-title">Pending Agency Users</p>
    <br />
    <div class="input-group searchuser mb-3 ms-auto w-25">
      <input
        type="search"
        class="form-control mt-0"
        placeholder="Search"
        aria-label="searchAgencyUser"
        [(ngModel)]="searchAgencyUser"
        aria-describedby="searchAgencyUser"
      />
      <span class="input-group-text" id="searchAgencyUser"
        ><em class="fa fa-search"></em
      ></span>
    </div>
  
    <div class="pendingagency__users-list">
      <table
        id="pendingagencyUser"
        aria-describedby="Pending Agency Users List"
        class="align-middle bg-white"
      >
        <thead class="pendingagency__users__table-header">
          <tr>
            <th scope="col" id="pendingagency__users__table-header_name">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="givenName"
              >
                <span class="column-name">Name</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
            <th scope="col" id="pendingagency__users__table-header_email">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="userName"
              >
                <span class="column-name">Email</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
            <th scope="col" id="pendingagency__users__table-header_status">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="status"
              >
                <span class="column-name">Revoke</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
            <th scope="col" id="pendingagency__users__table-header_pending">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="status"
              >
                <span class="column-name">Pending</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
            <th scope="col" id="pendingagency__users__table-header_view_only">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="role"
              >
                <span class="column-name">View-Only</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
            <th scope="col" id="pendingagency__users__table-header_can_pledge">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="role"
              >
                <span class="column-name">Can Pledge</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
            <th scope="col" id="pendingagency__users__table-header_role">
              <span
                class="table-head"
                [appSort]="pendingAgencyUsersList"
                data-order="asc"
                data-name="role"
              >
                <span class="column-name">Admin</span
                ><em class="fa-solid fa-sort"></em
              ></span>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="8" id="pendingagency__users__table-header_loading">
              <ameren-loading
                id="loadingIndicator"
                class="d-flex mt-30 justify-content-center"
              ></ameren-loading>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isAPIFailure && !isLoading">
          <tr>
            <td colspan="8" id="pendingagency__users__table_error">
              <div
                id="noRecordsFound"
                class="no__pendingagency__users__found mt-3 alert alert-danger"
              >
                Sorry, Unable to retrieve the pending agency users information. Please try
                again later.
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isAPIFailure && !isLoading">
          <tr
            *ngIf="
              (pendingAgencyUsersList && pendingAgencyUsersList.length === 0) ||
              (pendingAgencyUsersList | searchFilter : searchAgencyUser).length === 0
            "
          >
            <td colspan="8" id="pendingagency__users__table-_no_data">
              <div id="noRecordsFound" class="no__pendingagency__users__found">
                There are no users in Revoke/Pending/View-Only/Can Pledge/Admin in Agency
                Administrator.
              </div>
            </td>
          </tr>
          <tr
            *ngFor="
              let user of pendingAgencyUsersList | searchFilter : searchAgencyUser;
              let i = index
            "
          >
            <td id="pendingagency__users__table_name{{i}}">
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <p class="fw-normal mb-1 d-flex flex-wrap text-break">
                    {{ user.sn }} {{ user.givenName }}
                  </p>
                </div>
              </div>
            </td>
            <td id="pendingagency__users__table_user_name{{i}}">
              <p class="fw-normal mb-1 d-flex flex-wrap text-break">
                {{ user.userName }}
              </p>
            </td>
            <td id="pendingagency__users__table_deny{{i}}">
              <span class="d-inline d-flex flex-wrap w-50">
                <input
                  type="radio"
                  id="eapDenyStatus"
                  (click)="submitAdminRequest($event, user)"
                  name="agencyadminstatus{{ i }}"
                  aria-label="Select Revoke Option to Approve"
                  value="eapDenyStatus"
                  [attr.checked]="
                    user.status != 'eappending' &&
                    user.accountStatus === 'inactive'
                      ? 'checked'
                      : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
            <td class="pendingagency__users__table_name_eap_pending">
              <span
                class="d-inline d-flex flex-wrap w-50"
                class="amerenuser_role__and__status"
              >
                <input
                  type="radio"
                  id="eapPendingStatus"
                  (click)="submitAdminRequest($event, user)"
                  name="agencyadminstatus{{ i }}"
                  aria-label="Select Pending Option to Submit"
                  value="eapPendingStatus"
                  [attr.checked]="
                    user.status === 'eappending' &&
                    user.role === 'eapread' &&
                    (user.accountStatus === 'inactive' ||
                      user.accountStatus === 'active')
                      ? 'checked'
                      : null
                  "
                  [disabled]="true"
                />
              </span>
            </td>
            <td class="pendingagency__users__table_name_eap_approve">
              <span class="d-inline d-flex flex-wrap w-50">
                <input
                  type="radio"
                  id="eapRead"
                  (click)="submitAdminRequest($event, user)"
                  name="agencyadminstatus{{ i }}"
                  aria-label="Select Agency Admin Option to Approve"
                  value="eapRead"
                  [attr.checked]="
                    user.role === 'eapread' &&
                    user.accountStatus === 'active' &&
                    user.status != 'eappending'
                      ? 'checked'
                      : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
            <td class="pendingagency__users__table_name_eap_update">
              <span class="d-inline d-flex flex-wrap w-50">
                <input
                  type="radio"
                  id="eapUpdate"
                  (click)="submitAdminRequest($event, user)"
                  name="agencyadminstatus{{ i }}"
                  aria-label="Select Agency Admin Option to Approve"
                  value="eapUpdate"
                  [attr.checked]="
                    user.role === 'eapupdate' && user.accountStatus === 'active'
                      ? 'checked'
                      : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
            <td class="pendingagency__users__table_name_eap_agcy_admin">
              <span class="d-inline d-flex flex-wrap w-50">
                <input
                  type="radio"
                  id="eapAgencyAdmin"
                  (click)="submitAdminRequest($event, user)"
                  name="agencyadminstatus{{ i }}"
                  aria-label="Select Agency Admin Option to Approve"
                  value="eapAgencyAdmin"
                  [attr.checked]="
                    user.role === 'eapagencyadmin' &&
                    user.accountStatus === 'active'
                      ? 'checked'
                      : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <!-- confirmation modal -->
  <div
    id="adminModalConfirmation"
    class="modal fade"
    aria-hidden="true"
    aria-labelledby="agencyReqModal"
    tabindex="-1"
  >
    <div class="modal-dialog text-center">
      <div class="modal-content">
        <div class="modal-header text-center">
          <div class="ameren__modal__success-title">Confirm Your Changes</div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <span>You have made changes to Agency Logins.</span>
            <span>Do you want to save these changes?</span>
          </div>
          <div class="row mt-4">
            <div class="col-md-4"></div>
  
            <div class="col-md-2">
              <button
                class="btn btn-secondary create_campaign_button"
                id="confirmNo"
                value="No"
              >
                No
              </button>
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-success create_campaign_button"
                id="confirmYes"
                value="Yes"
              >
                Yes
              </button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--------modal-------->
  <div
    id="pendingagencyUserModal"
    class="modal fade"
    aria-hidden="true"
    aria-labelledby="amerenModal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" *ngIf="!isApprovalLoading">
          <div
            class="ameren__modal__success-title"
            *ngIf="!isApprovalLoading && !isApprovalAPIFailure"
          >
            Successfully Submitted!
          </div>
          <div
            class="ameren__modal__failure-title"
            *ngIf="!isApprovalLoading && isApprovalAPIFailure"
          >
            Submission Error!
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div *ngIf="isApprovalLoading" class="text-center">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
          <div class="ameren__modal-image">
            <img
              *ngIf="!isApprovalAPIFailure && !isApprovalLoading"
              src="assets/images/success.svg"
              alt="Success Image"
            />
            <img
              *ngIf="isApprovalAPIFailure && !isApprovalLoading"
              src="assets/images/failure.svg"
              alt="Failure Image"
            />
          </div>
          <div class="ameren__modal-footer" *ngIf="!isApprovalLoading">
            <div
              class="ameren__modal-message"
              *ngIf="!isApprovalLoading && !isApprovalAPIFailure"
            >
              Ameren approval request has been submitted successfully.
            </div>
  
            <div
              class="ameren__modal-message"
              *ngIf="!isApprovalLoading && isApprovalAPIFailure"
            >
              Ameren approval request has not been submitted successfully. Please
              try again to submit your request.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!------mobile view-------->
  <div class="pendingagencyuser_container view-mobile">
    <p class="pendingagency__users-title pendingagencyuser-title">Pending Agency Users</p>
    <div style="margin-top: 24px" class="view__pendingagencyuser__container">
      <div>
        <div class="pendingameranuser_list_container" *ngIf="isLoading">
          <div>
            <ameren-loading
              id="loadingIndicator"
              class="d-flex mt-30 justify-content-center"
            ></ameren-loading>
          </div>
        </div>
        <div
          class="pendingagencyuser_list_container"
          *ngIf="pendingAgencyUsersList && pendingAgencyUsersList.length === 0"
        >
          <div *ngIf="!isAPIFailure && !isLoading">
            <div id="noRecordsFound" class="no__ameren__users__found">
              There are no users in Revoke/Pending/View-Only/Can Pledge/Admin in Agency
              Administrator.
            </div>
          </div>
        </div>
        <div class="pendingagencyuser_list_container" *ngIf="isAPIFailure && !isLoading">
          <div>
            <div
              id="noRecordsFound"
              class="no__pendingagency__users__found mt-3 alert alert-danger"
            >
              Sorry, Unable to retrieve the agency users information. Please try
              again later.
            </div>
          </div>
        </div>
        <div class="pendingagency_user_container">
          <div
            class="pendingagencyuser_list_container"
            *ngFor="let user of pendingAgencyUsersList; let i = index"
          >
            <div class="user_details">
              <div class="info">
                <span class="label">Name</span>
                <span class="info_data">{{ user.sn }} {{ user.givenName }}</span>
              </div>
              <div class="info">
                <span class="label">User ID/Email</span>
                <span class="info_data">{{ user.userName }}</span>
              </div>
              <div class="info">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    (click)="toggleCloseDetails(user)"
                    *ngIf="user.toggleStatus == true"
                    class="icon-svg"
                    viewBox="0 0 100 100"
                  >
                    <path
                      fill="#1b6cb5"
                      d="M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z"
                    /></svg
                ></span>
  
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    (click)="toggleOpenDetails(user)"
                    *ngIf="!user.toggleStatus"
                    class="icon-svg"
                    viewBox="0 0 100 100"
                  >
                    <path
                      fill="#1b6cb5"
                      d="m 24 30.75 l -12 -12 l 2.15 -2.15 L 24 26.5 l 9.85 -9.85 L 36 18.8 Z"
                    /></svg
                ></span>
              </div>
            </div>
            <div
              class="admin_actions admin_actions_active"
              *ngIf="user.toggleStatus == true"
            >
              <div class="action">
                <span class="title">Revoke</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapDenyStatus"
                    (click)="submitAdminRequest($event, user)"
                    name="agencyadminstatus{{ i }}"
                    aria-label="Select Revoke Option to Approve"
                    value="eapDenyStatus"
                    [attr.checked]="
                      user.status != 'eappending' &&
                      user.accountStatus === 'inactive'
                        ? 'checked'
                        : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">Pending</span>
                <span
                  class="d-inline d-flex flex-wrap text-break"
                  class="amerenuser_role__and__status"
                >
                  <input
                    type="radio"
                    id="eapPendingStatus"
                    (click)="submitAdminRequest($event, user)"
                    name="agencyadminstatus{{ i }}"
                    aria-label="Select Pending Option to Submit"
                    value="eapPendingStatus"
                    [attr.checked]="
                      user.status === 'eappending' &&
                      user.accountStatus === 'inactive'
                        ? 'checked'
                        : null
                    "
                    [disabled]="true"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">View-Only</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapRead"
                    (click)="submitAdminRequest($event, user)"
                    name="agencyadminstatus{{ i }}"
                    aria-label="Select Agency Admin Option to Approve"
                    value="eapRead"
                    [attr.checked]="
                      user.role === 'eapread' && user.accountStatus === 'active'
                        ? 'checked'
                        : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">Can Pledge</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapUpdate"
                    (click)="submitAdminRequest($event, user)"
                    name="agencyadminstatus{{ i }}"
                    aria-label="Select Agency Admin Option to Approve"
                    value="eapUpdate"
                    [attr.checked]="
                      user.role === 'eapupdate' && user.accountStatus === 'active'
                        ? 'checked'
                        : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">Admin</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapAgencyAdmin"
                    (click)="submitAdminRequest($event, user)"
                    name="agencyadminstatus{{ i }}"
                    aria-label="Select Agency Admin Option to Approve"
                    value="eapAgencyAdmin"
                    [attr.checked]="
                      user.role === 'eapagencyadmin' &&
                      user.accountStatus === 'active'
                        ? 'checked'
                        : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  