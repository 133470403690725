import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { IBudgetBillingHistory } from '../interfaces/Ibudgetbillinghistory';
import { BudgetBilling } from '../model/budget-billing.model';
import { BudgetbillingApiService } from './budgetbilling-api.service';

@Injectable({
  providedIn: 'root'
})
export class BudgetbillingManagerService {
  budgetBillingHistory: BudgetBilling[];

  constructor(private budgetBillingApiService: BudgetbillingApiService) { 
    this.budgetBillingHistory = [];
  }

  getBudgetBilling(billAccount: any) {
    return this.budgetBillingApiService.getBudgetBilling(billAccount).pipe(map((res: IGlobalAPIResponse) => {
      if(!res.success) {
        throw new AmerenError(
          'Budget billing cannot be retrieved',
          400,
          null,
          null,
          null
        );
      }

      const budgetBillingData =  res.data?.budgetBillingHistory;
      this.budgetBillingHistory = [];

    if(budgetBillingData instanceof Array) {
      budgetBillingData.forEach((budgetBilling: IBudgetBillingHistory) => {
        this.budgetBillingHistory.push(new BudgetBilling(
          budgetBilling.actualUsedAmount, 
          budgetBilling.aheadBehindCode, 
          budgetBilling.billAccountNumber, 
          budgetBilling.billDate, 
          budgetBilling.billRelativeMonth,
          budgetBilling.budgetBillAmount,
          budgetBilling.budgetBillMonth,
          budgetBilling.deferredAmount,
          budgetBilling.planDescription,
          budgetBilling.updateTypeDescription))
      })
      return this.budgetBillingHistory;
    }
    }),
    catchError((error: any) => {
      return throwError(() => error);
    })
    )
  }
}
