import {
  EnumSplunkLevels,
  EnumSplunkSourceType,
  SplunkApiSuccessResponse,
  SplunkApiFailureResponse,
} from './../services/splunk-api.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, catchError, tap } from 'rxjs';
import { SplunkManager } from '../services/splunk-manager.service';
import { AmerenError } from '../models/amerenError';

@Injectable({
  providedIn: 'root',
})
export class SplunkInterceptor implements HttpInterceptor {
  constructor(private splunkManager: SplunkManager) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTime = performance.now();
    if (this.requestCheck(req)) {
      return next.handle(req).pipe(
        tap((evt) => {
          if (evt instanceof HttpResponse) {
            const logSuccessEvents = new SplunkApiSuccessResponse();
            logSuccessEvents.apiResponse = evt.body;
            logSuccessEvents.url = evt.url || '';
            logSuccessEvents.status = evt.status;
            logSuccessEvents.statusText = evt.statusText;
            const elapsed = Math.round(performance.now() - startTime);
            const method = req.method;
            const apiName = req.url.split('/')[3].split('?')[0];

            this.submitSplunk(
              EnumSplunkLevels.INFO,
              EnumSplunkSourceType.CLOUDHUBAPISUCCESSLOGS,
              logSuccessEvents,
              elapsed,
              method,
              apiName
            );
          }
        }),
        catchError((err: AmerenError) => {
          const logFailureEvents = new SplunkApiFailureResponse();
          if (err instanceof HttpErrorResponse) {
            logFailureEvents.apiResponse = err.message;
            logFailureEvents.url = err.url || '';
            logFailureEvents.status = err.status;
            logFailureEvents.statusText = err.statusText;
            logFailureEvents.error = err.error;
          } else {
            logFailureEvents.apiResponse = err;
          }
          const elapsed = Math.round(performance.now() - startTime);
          const method = req.method;
          const apiName = req.url.split('/')[3].split('?')[0];

          if (err.errorCode === 401) {
            this.submitSplunk(
              EnumSplunkLevels.INFO,
              EnumSplunkSourceType.CLOUDHUBAPIFAILURELOGS,
              logFailureEvents,
              elapsed,
              method,
              apiName
            );
          } else {
            this.submitSplunk(
              EnumSplunkLevels.WARN,
              EnumSplunkSourceType.CLOUDHUBAPIFAILURELOGS,
              logFailureEvents,
              elapsed,
              method,
              apiName
            );
          }

          return throwError(() => err);
        })
      );
    }

    return next.handle(req);
  }

  /*istanbul ignore next*/
  private requestCheck(req: HttpRequest<any>): boolean {
    return (
      !/splunkcloudhec/.test(req.url) && window.location.hostname != 'localhost'
    );
  }

  submitSplunk(
    level: EnumSplunkLevels,
    sourceType: EnumSplunkSourceType,
    message: any,
    elapsed: number,
    method: string,
    apiName: string
  ) {
    this.splunkManager
      .logSplunk(level, sourceType, message, elapsed, method, apiName)
      .subscribe({
        next: (data: any) => {},
        error: (error) => {
          console.log('error', error);
        },
      });
  }
}
