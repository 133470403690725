import * as _ from 'lodash';
import { Serializable } from './Serializable';

export class AgencyInformationByPIN {
  agencyID: number;
  agencyName: string;
  state: string;
  pinNumber: string;
  isValidPinNumber: boolean;
  pinNumberAllowsUpdates: boolean;
  operatingState: string;
  agencyType: string;
  agencyCSSCode: string;
  isEFTAllowed: boolean;
  agencyStreetName?: string;
  agencyCity?: string;
  agencyState?: string;
  agencyZip?: string;
  address?:string;

  constructor(
    agencyID: number,
    agencyName: string,
    state: string,
    pinNumber: string,
    isValidPinNumber: boolean,
    pinNumberAllowsUpdates: boolean,
    operatingState: string,
    agencyType: string,
    agencyCSSCode: string,
    isEFTAllowed: boolean,
    agencyStreetName?: string,
    agencyCity?: string,
    agencyState?: string,
    agencyZip?: string,
    address?:string
  ) {
    this.agencyID = !_.isNil(agencyID) ? agencyID : 0;
    this.agencyName = !_.isNil(agencyName) ? agencyName : '';
    this.agencyStreetName = !_.isNil(agencyStreetName) ? agencyStreetName : '';
    this.agencyCity = !_.isNil(agencyCity) ? agencyCity : '';
    this.agencyState = !_.isNil(agencyState) ? agencyState : '';
    this.agencyZip = !_.isNil(agencyZip) ? agencyZip : '';
    this.state = !_.isNil(state) ? state : '';
    this.pinNumber = !_.isNil(pinNumber) ? pinNumber : '';
    this.isValidPinNumber = !_.isNil(isValidPinNumber)
      ? isValidPinNumber
      : false;
    this.pinNumberAllowsUpdates = !_.isNil(pinNumberAllowsUpdates)
      ? pinNumberAllowsUpdates
      : false;
    this.operatingState = !_.isNil(operatingState) ? operatingState : '';
    this.agencyType = !_.isNil(agencyType) ? agencyType : '';
    this.agencyCSSCode = !_.isNil(agencyCSSCode) ? agencyCSSCode : '';
    this.isEFTAllowed = !_.isNil(isEFTAllowed) ? isEFTAllowed : false;
    this.address = !_.isNil(address) ? address : '';
  }
}

export class AgencyInfo extends Serializable {
  agencyID: number;
  agencyName: string;
  state: string;
  pinNumber: string;
  isValidPinNumber: boolean;
  pinNumberAllowsUpdates: boolean;
  operatingState: string;
  agencyType: string;
  agencyCSSCode: string;
  isEFTAllowed: boolean;
  agencyStreetName?: string;
  agencyCity?: string;
  agencyState?: string;
  agencyZip?: string;
  address?:string;

  constructor(data: Object = {}) {
    super(data);
  }
}
