import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss']
})
export class StateSelectorComponent implements OnInit {

  @Input() showStateSelector:boolean;
  @Output() ondismiss = new EventEmitter();
  selectedState:string;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/illinois')) this.selectedState = 'illinois';
        else if (event.url.includes('/missouri')) this.selectedState = 'missouri';
      }
    });
   }


  ngOnInit(): void {
  }

  closeStateSelector(selectedState?: string){
    this.selectedState= selectedState?selectedState:'';
    this.ondismiss.emit(selectedState);
  }
}
