import { AbstractControl } from '@angular/forms';
export function AtLeastSomeRequired(controlNames: Array<string>, minimumNumberOfRequiredControls: number) {
  return (formGroup: AbstractControl) => {
    let numberOfvalidInputs = 1;
    for (const controlName of controlNames) {
      if (formGroup.get(controlName)?.value) {
        numberOfvalidInputs++;
      }
    }
    if (numberOfvalidInputs <= minimumNumberOfRequiredControls) return { atLeastSomeRequired: true };
  };
}
