<div class="landing__pagedisplay_header">
  <div class="nav-container">
    <ameren-menu [login_menu]="login_menu"> </ameren-menu>
  </div>
</div>

<div class="wrapper landing__pagedisplay">
  <div class="app__container app__inner__container">
    <!--- System Maintenance --->
    <div class="bg-warning bg-gradient">
      <div
        class="toast"
        [ngClass]="{ 'd-none': !showMaintenanceSnack }"
        class="maintenance_toast"
      >
        <div
          class="toast-body d-flex align-items-center justify-content-center"
        >
          <img src="assets/icon/maintenance-icon.svg" alt="Maintenance" />
          <span class="ms-2">
            The system is temporarily unavailable. Please try again later, or
            call 800.894.5211 for Missouri, and call 888.667.7861 for
            Illinois</span
          >
          <button
            class="btn-close ms-2"
            *ngIf="showMaintenanceSnack"
            (click)="closeMaintenanceSnack()"
          ></button>
        </div>
      </div>
    </div>
    <!--- System Maintenance --->
    <app-hero *ngIf="!hide_banner"></app-hero>
    <app-program-banner
      [selectedState]="currentState"
      *ngIf="
        !hide_banner &&
        (currentState == 'illinois' || currentState == 'missouri')
      "
    ></app-program-banner>
    <app-billing-and-payment-options
      [selectedState]="currentState"
      *ngIf="
        !hide_banner &&
        (currentState == 'illinois' || currentState == 'missouri')
      "
    ></app-billing-and-payment-options>
    <app-faq [selectedState]="currentState" *ngIf="!hide_banner"></app-faq>
    <app-saving-tips
      *ngIf="!hide_banner"
      [selectedState]="currentState"
    ></app-saving-tips>

    <router-outlet></router-outlet>
    <app-client-selector></app-client-selector>
    <ameren-footer [topLinks]="[]"></ameren-footer>
  </div>
</div>
