import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'accountNumber'
})

export class AccountNumberPipe implements PipeTransform {
    transform(value: string): string {
        try {
            let leadingzero = value.toString().padStart(10, '0');
            let matched = leadingzero.toString().match(/(\d*)(\d{5})$/);
            return matched ? matched[1] + "-" + matched[2] : value;

        } catch (error) {
            return value;
        }
    }
}