import { ProfileManagerService } from './../../shared/services/profilemanager.service';
import { HostListener, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import { AgencyRoleDetails } from 'src/app/shared/models/profile';
import { EnumRole } from 'src/app/shared/enums/role';
import { isUndefined } from 'lodash';
import { AdministrationManagerService } from 'src/app/secure/administration/services/administration-manager.service';
import { IEAPAgencyInformation } from 'src/app/secure/administration/interfaces/IEAPAgencyInformation';
import { AgencyService } from 'src/app/shared/services/agency.service';
import {
  EnumSplunkLevels,
  EnumSplunkSourceType,
} from 'src/app/shared/services/splunk-api.service';
import { SplunkManager } from 'src/app/shared/services/splunk-manager.service';

@Component({
  selector: 'ameren-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isMobile = window.innerWidth <= 1023;
  isAuthenticated: boolean;
  currentUser: any;
  isAdmin: boolean;
  isAmerenAdmin: boolean = false;
  isAmerenSupport: boolean = false;
  isAmerenInternal: boolean;
  isUserWithMultipleAgencies: boolean;
  isAgencyAdmin: boolean;
  isCampaignPage: boolean;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    let navbar = document.getElementById('navbar');
    if (
      this.isMobile &&
      navbar?.classList.contains('show-menu') &&
      !navbar?.contains(event.target)
    ) {
      navbar?.classList.remove('show-menu');
      navbar?.classList.add('hide-menu');
    }
  }

  @Input() login_menu: boolean;
  agencyTitle: any;
  agencyAddress: any;
  showStateSelector: boolean = false;
  selectedState: string;
  hasSelectedAgency: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private agencyManager: AdministrationManagerService,
    private profileMgerService: ProfileManagerService,
    private agencyService: AgencyService,
    private splunkManager: SplunkManager
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/illinois')) this.selectedState = 'illinois';
        else if (event.url.includes('/missouri'))
          this.selectedState = 'missouri';

        if (event.url.toLocaleLowerCase().includes('campaign'))
          this.isCampaignPage = true;
        else this.isCampaignPage = false;
      }
    });

    this.authService.loginChanged.subscribe((t) => {
      this.isAuthenticated = t;

      this.authService.getUser().then((user: any) => {
        if (user?.profile?.stateProvince == 'MO') {
          this.selectedState = 'missouri';
        } else if (user?.profile?.stateProvince == 'IL') {
          this.selectedState = 'illinois';
        }
      });
    });

    this.agencyService.agencyUpdatedSubject.subscribe(() => {
      let agency = this.agencyService.getSelectedAgency();
      this.hasSelectedAgency = agency ? true : false;
      if (agency) {
        if (!this.isAmerenInternal) {
          let selectedAgencyRole =
            this.profileMgerService.profile[0].agencyList?.filter(
              (x) => x.AgencyID == agency.agencyID
            )[0].role;
          this.isAdmin = selectedAgencyRole == 'eapAgencyAdmin';
        }
        this.agencyTitle = agency.selectedAgencyName || agency.agencyName;
        const address = agency.address
          ? agency.address?.addressLines[0] +
            ',' +
            agency.address.city +
            ',' +
            agency.address.state +
            ',' +
            agency.address.zip
          : '';

        this.agencyAddress = agency.selectedAgencyAddress || address;
      }
    });
  }

  ngOnInit() {
    let agency = this.agencyService.getSelectedAgency();
    this.hasSelectedAgency = agency ? true : false;
    if (this.hasSelectedAgency) {
      this.agencyTitle = agency.selectedAgencyName || agency.agencyName;
      const address = agency.address
        ? agency.address?.addressLines[0] +
          ',' +
          agency.address.city +
          ',' +
          agency.address.state +
          ',' +
          agency.address.zip
        : '';

      this.agencyAddress = agency.selectedAgencyAddress || address;
    }

    this.authService.userLoaded.subscribe((user: any) => {
      this.currentUser = user;
      this.getUserData();
    });
    if (isUndefined(this.isAdmin)) {
      this.authService.getUser().then((user: any) => {
        this.currentUser = user;
        this.getUserData();
      });
    }
  }

  unselectAgency() {
    this.agencyService.setSelectedAgency();
    this.router.navigate(['administration']);
  }

  getUserData() {
    this.isAdmin, (this.isAmerenInternal = false);
    let userRole: AgencyRoleDetails[] = [];
    userRole = this.currentUser?.profile?.agencyRoleMap;
    if (userRole) {
      //filter multiple entries in rolemap with same agencyID
      userRole = userRole.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.AgencyID === item.AgencyID) === i
      );
      this.isUserWithMultipleAgencies = userRole.length > 1;
      userRole.forEach((a) => {
        if (
          a.role === EnumRole.EAPAMERENADMIN ||
          a.role === EnumRole.EAPAGENCYADMIN ||
          a.role === EnumRole.EAPAMERENSUPPORT
        )
          this.isAdmin = true;
        if (
          a.role === EnumRole.EAPAMERENADMIN ||
          a.role === EnumRole.EAPAMERENSUPPORT
        )
          this.isAmerenInternal = true;

        if (a.role === EnumRole.EAPAMERENADMIN) this.isAmerenAdmin = true;

        if (a.role === EnumRole.EAPAMERENSUPPORT) this.isAmerenSupport = true;

        if (a.role === EnumRole.EAPAGENCYADMIN) this.isAgencyAdmin = true;
      });
      if (userRole.length == 1) {
        this.agencyManager
          .getAgencyInformationById(userRole[0].AgencyID)
          .subscribe({
            next: (res: IEAPAgencyInformation) => {
              this.agencyTitle = res.agencyName;
              this.agencyAddress =
                res.mailingStreetAddress +
                ', ' +
                res.mailingCity +
                ' ' +
                res.mailingState +
                ' ' +
                res.mailingZipCode;
            },
            error: (error) => {},
          });
      }
    }
  }

  login() {
    this.authService.Login();
  }

  logout() {
    let userProfile = this.currentUser?.profile;
    let loginMessage =
      'User ' + userProfile.email + ' has logged out the portal successfully';

    this.submitSplunk(
      EnumSplunkLevels.INFO,
      EnumSplunkSourceType.EAPUILOGS,
      loginMessage
    );

    this.agencyService.setSelectedAgency('');
    this.authService.Logout();
  }

  submitSplunk(
    level: EnumSplunkLevels,
    sourceType: EnumSplunkSourceType,
    message: any,
    elapsed?: number,
    method?: string,
    apiName?: string
  ) {
    this.splunkManager
      .logSplunk(level, sourceType, message, elapsed, method, apiName)
      .subscribe({
        next: (data: any) => {},
        error: (error) => {
          console.log('error', error);
        },
      });
  }

  toggleStateSelector() {
    this.showStateSelector = !this.showStateSelector;
  }

  onStateSelected(selectedState?: string) {
    this.showStateSelector = !this.showStateSelector;
    this.selectedState = selectedState ? selectedState : '';
  }

  showMenu(event: any) {
    let navbar = document.getElementById('navbar');
    navbar?.classList.remove('desktop-only');
    navbar?.classList.remove('hide-menu');
    navbar?.classList.add('show-menu');
    event.stopPropagation();
  }
  hideMenu(event: any) {
    let navbar = document.getElementById('navbar');
    navbar?.classList.remove('desktop-only');
    navbar?.classList.remove('show-menu');
    navbar?.classList.add('hide-menu');
    event.stopPropagation();
  }

  editUserProfile() {
    this.router.navigate(['edit-profile'], {
      queryParams: { ref: 'menu' },
      queryParamsHandling: 'merge',
    });
  }
}
