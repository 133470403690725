import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  selectedState: string = '';

  constructor(private router: Router, private authService: AuthService) { 
    this.InitializeState();
  }

  InitializeState(){    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {        
        if (event.url.includes('/illinois') || event.url.includes('/missouri')) {
          let stateFromURL: string = event.url.replace('/', '');
          if (stateFromURL.includes('?_gl=')){
            stateFromURL = stateFromURL.substring(0,stateFromURL.indexOf('?'));
          }
          this.selectedState = stateFromURL;
        }
        else{
          this.selectedState='';
        }        
      }
    });
    this.authService.loginChanged.subscribe((t) => {
      this.authService.getUser().then((user: any) => {
        if (user?.profile?.stateProvince == 'MO') {
          this.selectedState = 'missouri';
        } else if (user?.profile?.stateProvince == 'IL') {
          this.selectedState = 'illinois';
        }
      });
    });
  }
}
