import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Route, Router } from '@angular/router';
import { Client } from 'src/app/shared/models/client';
import { Profile } from 'src/app/shared/models/profile';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { IClientQuickView } from '../client/interfaces/Iclientquickview';
import { ClientManagerService } from '../client/services/client-manager.service';
import { pledgeType } from './models/pledgetype.model';
import { PledgeEntryManagerService } from './services/pledge-entry-manager.service';

declare var window: any;

@Component({
  selector: 'app-pledge-entry',
  templateUrl: './pledge-entry.component.html',
  styleUrls: ['./pledge-entry.component.scss'],
})
export class PledgeEntryComponent implements OnInit, AfterViewInit {
  isAPIFailure: boolean;
  isLoading: boolean;
  clientSummary: IClientQuickView;
  disconnectionDateLabelText: string;
  hasDisconnectionData: boolean = false;
  showMinimumDisconnectionPreventionAmount: boolean = false;
  IsDisconnected: boolean = false;
  HasPendingDisconnection: boolean = false;
  isSubmitLoading: boolean = false;
  showResponse: boolean = false;
  selectedClient: Client;
  selectedAgency: any;
  userProfile: Profile;
  pledgeTypes: pledgeType[];
  pledgeEntryForm: FormGroup;
  pledgeEntryApproveModal: any;
  pledgeEntrySummaryModal: any;
  pledgeEntryBody: any;
  pledgeEntryList: IPledgeEntryBody[] = [];
  pledgeEntryListReview: any[] = [];
  totalAmount = 0;
  minimumRemainingAmountLeft = 0;
  reconnectionAmountLeft = 0;
  selectedAddress: any;
  apiFailureDuetoPledgedAmount: boolean = false;
  minimumPledgeAmountError: boolean = false;
  pledgeTypeError: boolean = false;
  isExceedsRemainingAmount: boolean = false;
  canPledge: boolean;
  selectedAgencyRole: string | undefined;

  constructor(
    private pledgeEntryManager: PledgeEntryManagerService,
    private clientService: ClientService,
    private agencyService: AgencyService,
    private profileService: ProfileManagerService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private clientManagerService: ClientManagerService,
    private _router: Router
  ) {
    this.selectedClient = this.clientService.getSelectedClient();
    this.selectedAgency = this.agencyService.getSelectedAgency();

    this.selectedAgencyRole = this.profileService.profile[0].agencyList?.filter(
      (a) => a.AgencyID == this.selectedAgency.agencyID
    )[0]?.role;
    this.canPledge =
      this.selectedAgencyRole == 'eapUpdate' ||
      this.selectedAgencyRole == 'eapAgencyAdmin';

    if (!this.canPledge) {
      this._router.navigate(['ineligible-user']);
    }
    this.selectedAddress = this.selectedAgency.address
      ? this.selectedAgency?.address?.addressLines[0] +
        ',' +
        this.selectedAgency.address.city +
        ',' +
        this.selectedAgency.address.state +
        ',' +
        this.selectedAgency.address.zip
      : '';

    this.profileService.profile.forEach((a: Profile) => {
      this.userProfile = a;
    });
  }

  ngOnInit(): void {
    this.initPledgeEntryForm();
    this.pledgeEntryApproveModal = new window.bootstrap.Modal(
      document.getElementById('pledgeEntryApproveModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.pledgeEntrySummaryModal = new window.bootstrap.Modal(
      document.getElementById('pledgeEntrySummary'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.pledgeTypeList();

    this.getAccountFinancials();
  }

  onBackClick() {
    location.reload();
  }
  initPledgeEntryForm() {
    this.pledgeEntryForm = this._fb.group({
      pledgeEntries: this._fb.array([
        this._fb.group({
          accountNumber: this.selectedClient.billAccountNumber.toString(),
          minPledge: 0,
          maxPledge: 0,
          agencyID: this.selectedAgency.agencyID,
          requesterName:
            this.userProfile.firstName + ' ' + this.userProfile.lastName,
          pledgeDate: new Date().toISOString().slice(0, 10),
          pledgeExpirationDate: new Date().toISOString().slice(0, 10),
          pledgeStatusDate: new Date().toISOString().slice(0, 10),
          pledgeTypeCode: new FormControl(null, [
            Validators.required,
            Validators.min(1),
          ]),
          pledgeAmount: new FormControl(null, {
            validators: [
              Validators.required,
              Validators.pattern('^\\d*\\.?\\d*$'),
              Validators.min(1),
            ],
          }),
        }),
      ]),
    });
  }
  get pledgeEntries(): FormArray {
    return this.pledgeEntryForm.get('pledgeEntries') as FormArray;
  }

  onPledgeTypeChange(rowIndex: number) {
    let selectedPledgeTypeCode = (
      this.pledgeEntryForm.get('pledgeEntries') as FormArray
    ).controls[rowIndex].get('pledgeTypeCode')?.value;

    if (selectedPledgeTypeCode) {
      let pledge_type: pledgeType = this.pledgeTypes.filter(
        (x) => x.pledgeTypeCode == selectedPledgeTypeCode
      )[0];

      if (!pledge_type.isFixedPledge) {
        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('pledgeAmount')
          ?.setValidators([
            Validators.min(pledge_type.minPledge),
            Validators.max(pledge_type.maxPLedge),
          ]);

        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('minPledge')
          ?.setValue(pledge_type.minPledge);
        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('maxPledge')
          ?.setValue(pledge_type.maxPLedge);
      } else {
        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('pledgeAmount')
          ?.setValue(pledge_type.fixPledgeAmount);

        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('pledgeAmount')
          ?.setValidators([
            Validators.min(pledge_type.fixPledgeAmount),
            Validators.max(pledge_type.fixPledgeAmount),
          ]);

        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('minPledge')
          ?.setValue(pledge_type.fixPledgeAmount);
        (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
          rowIndex
        ]
          .get('maxPledge')
          ?.setValue(pledge_type.fixPledgeAmount);
      }

      (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
        rowIndex
      ]
        .get('pledgeAmount')
        ?.markAsDirty();

      (this.pledgeEntryForm.get('pledgeEntries') as FormArray).controls[
        rowIndex
      ]
        .get('pledgeAmount')
        ?.updateValueAndValidity();
    }
  }
  addPledgeEntryRow() {
    (this.pledgeEntryForm.get('pledgeEntries') as FormArray).push(
      this._fb.group({
        accountNumber: this.selectedClient.billAccountNumber.toString(),
        agencyID: this.selectedAgency.agencyID,
        minPledge: 0,
        maxPledge: 0,
        requesterName:
          this.userProfile.firstName + ' ' + this.userProfile.lastName,
        pledgeDate: new Date().toISOString().slice(0, 10),
        pledgeExpirationDate: new Date().toISOString().slice(0, 10),
        pledgeStatusDate: new Date().toISOString().slice(0, 10),
        pledgeTypeCode: new FormControl('Select Pledge Type', [
          Validators.required,
          Validators.min(1),
        ]),
        pledgeAmount: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
            Validators.min(1),
          ],
        }),
      })
    );
  }

  deletePledgeEntryRow(index: number) {
    (this.pledgeEntryForm.get('pledgeEntries') as FormArray).removeAt(index);
  }

  ngAfterViewInit(): void {
    (
      this.pledgeEntryForm.get('pledgeEntries') as FormArray
    ).valueChanges.subscribe((formData) => {
      this.pledgeEntryList = formData;
      this.pledgeEntryListReview = this.pledgeEntryList;
      this.pledgeEntryBody = {
        pledges: formData,
      };

      this.totalAmount = 0;
      this.pledgeEntryBody.pledges.forEach((data: IPledgeEntryBody) => {
        this.totalAmount += data.pledgeAmount;
      });

      if (this.clientSummary) {
        this.minimumRemainingAmountLeft =
          this.clientSummary.minDisconnectionPreventionAmount -
          this.totalAmount;
        this.reconnectionAmountLeft =
          this.clientSummary.reconnectionAmount - this.totalAmount;
      }
    });
  }

  approve() {
    this.pledgeEntryListReview.forEach((res1) => {
      this.pledgeTypes.forEach((res2) => {
        if (res2.pledgeTypeCode === res1.pledgeTypeCode) {
          res1.pledgeTypeDescription = res2.pledgeTypeDescription;
        }
      });
    });

    this.minimumPledgeAmountError = false;
    this.minimumPledgeAmountError = this.pledgeEntryList.every(
      (e: IPledgeEntryBody) => e.pledgeAmount <= 0
    );

    if (this.minimumPledgeAmountError) {
      return;
    }

    this.showResponse = false;
    this.pledgeEntryApproveModal.show();
  }

  close() {
    this.pledgeEntryApproveModal.hide();
  }

  submitPledgeEntry() {
    this.isSubmitLoading = true;
    this.isAPIFailure = false;
    this.isExceedsRemainingAmount = false;
    this.pledgeEntryManager.submitPledge(this.pledgeEntryBody).subscribe({
      next: (res: any) => {
        this.showResponse = true;
        this.isAPIFailure = false;
        this.isSubmitLoading = false;
        this.isExceedsRemainingAmount = false;
      },
      error: (error: any) => {
        if (
          error &&
          error?.error?.errorDetails?.message ===
            'We cannot accept that pledge amount because it does not meet the full amount of account balance requirement'
        ) {
          this.apiFailureDuetoPledgedAmount = true;
        }

        if (error && error?.error?.errorDetails?.statusCode === '10049') {
          //We cannot accept that pledge amount because it exceeds the remaining amount for the campaign
          this.isExceedsRemainingAmount = true;
        }
        this.showResponse = true;
        this.isAPIFailure = true;
        this.isSubmitLoading = false;
        console.log('error', error);
        return;
      },
    });

    this.close();
  }

  pledgeTypeList() {
    const stateCode = this.profileService.profile[0].stateProvince;
    const isMissouri = stateCode === 'MO' ? true : false;
    this.isLoading = true;
    this.isAPIFailure = false;
    let agencyIdValue =
      this.selectedAgency.agencyId || this.selectedAgency.agencyID;
    this.pledgeEntrySummaryModal.show();
    if (stateCode === 'MO') {
      this.pledgeEntryManager
        .getAgencyPledgeTypeList(
          this.selectedAgency.agencyCSSCode,
          agencyIdValue.toString()
        )
        .subscribe({
          next: (res: any) => {
            this.pledgeTypes = res;
            this.isLoading = false;
            this.isAPIFailure = false;
            this.pledgeEntrySummaryModal.hide();
          },
          error: (error: any) => {
            console.log('error', error);
            this.isLoading = false;
            this.isAPIFailure = true;
            this.pledgeEntrySummaryModal.hide();
          },
        });
    } else {
      this.pledgeEntryManager
        .getPledgeTypeList(this.selectedAgency.agencyCSSCode)
        .subscribe({
          next: (res: any) => {
            this.pledgeTypes = res.filter(
              (data: any) =>
                data.pledgeTypeCode != '70' && data.pledgeTypeCode != '71'
            );
            this.isLoading = false;
            this.isAPIFailure = false;
            this.pledgeEntrySummaryModal.hide();
          },
          error: (error: any) => {
            console.log('error', error);
            this.isLoading = false;
            this.isAPIFailure = true;
            this.pledgeEntrySummaryModal.hide();
          },
        });
    }
  }

  getAccountFinancials() {
    this.isLoading = true;
    this.pledgeEntrySummaryModal.show();
    this.selectedClient = this.clientService.getSelectedClient();
    this.selectedAgency = this.agencyService.getSelectedAgency();
    this.clientManagerService
      .getAccountFinancials(
        this.selectedClient.billAccountNumber,
        this.selectedAgency.pinNumber
      )
      .subscribe({
        next: (resp) => {
          this.pledgeEntrySummaryModal.hide();
          this.isLoading = false;
          this.isAPIFailure = false;
          this.clientSummary = resp;
          this.disconnectionDateLabelText =
            this.clientSummary.disconnectionDateLabelText;
          this.IsDisconnected = this.clientSummary.isDisconnected;
          this.showMinimumDisconnectionPreventionAmount =
            this.clientSummary.showMPAYAmount;
          this.hasDisconnectionData = this.clientSummary.hasDisconnectionData;
          this.HasPendingDisconnection = this.clientSummary.isPendingDisconnect;
        },
        error: (err) => {
          this.isLoading = false;
          this.isAPIFailure = true;
          this.pledgeEntrySummaryModal.hide();
        },
      });
  }
}

export interface IPledgeEntryBody {
  accountNumber: string;
  agencyID: number;
  requesterName: string;
  pledgeDate: string;
  pledgeTypeCode: any;
  pledgeAmount: number;
  pledgeExpirationDate: string;
  pledgeStatusDate: string;
}
