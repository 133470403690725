import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-silent-renew',
  templateUrl: './silent-renew.component.html',
  styleUrls: ['./silent-renew.component.scss'],
})
export class SilentRenewComponent implements OnInit {
  constructor(private authService: AuthService) {}
  data: any;
  ngOnInit(): void {
    this.authService.getUser().then((user) => (this.data = user));
  }
  silentRenew() {
    this.data = 'loading';
    this.authService.silentRenew().then((user) => {
      this.data = user;
      console.log('silent renew', this.data);
    });
  }
}
