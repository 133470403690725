import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { AgencyRoleDetails, Profile } from 'src/app/shared/models/profile';
import { Agency } from 'src/app/shared/models/agency';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { IAdminApprovalBody } from '../interfaces/IAdminApprovalBody';
import { AdministrationManagerService } from '../services/administration-manager.service';
import { RegistrationManagerService } from 'src/app/public/registration/services/registration-manager.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AuthService } from 'src/app/shared/services/auth.service';

declare var window: any;

@Component({
  selector: 'app-add-additional-agency',
  templateUrl: './add-additional-agency.component.html',
  styleUrls: ['./add-additional-agency.component.scss'],
})
export class AddAdditionalAgencyComponent implements OnInit {
  isFirstUserofUserEnteredAgency: boolean;
  currentUser: any;
  profile: Profile;
  isLoading: boolean;
  isAPIFailure: boolean;
  isInvalidData: boolean;
  myModal: any;
  adminmodal: any;
  selectedAgency: any;
  availableAgencies: any[];
  agencypin: string;
  disableSubmitButton: boolean = true;
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private administrationMgr: AdministrationManagerService,
    private profileMgr: ProfileManagerService,
    private registrationMgr: RegistrationManagerService,
    private agencyService: AgencyService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.profileMgr.profile.forEach((a: Profile) => {
      this.profile = a;
    });
    this.myModal = new window.bootstrap.Modal(
      document.getElementById('addAgencyModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
    this.adminmodal = new window.bootstrap.Modal(
      document.getElementById('addAgencyModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
  }

  addAdditionalAgencyForm = this.formBuilder.group({
    agencypin: [''],
  });

  ui = {
    confirm: async () => this.createConfirm(),
  };

  createConfirm = () => {
    return new Promise((complete) => {
      $('#confirmYes').off('click');
      $('#confirmNo').off('click');

      $('#confirmYes').on('click', () => {
        $('.confirm').hide();
        complete(true);
      });
      $('#confirmNo').on('click', () => {
        $('.confirm').hide();
        complete(false);
      });

      this.adminmodal.show();
    });
  };

  addAdditionalAgency() {
    this.submitAdditionalAgency();
  }

  async submitAdditionalAgency() {
    const confirm = await this.ui.confirm();

    if (confirm) {
      this.adminmodal.hide();
      this.isLoading = true;
      try {
        let agencyalreadyExists = this.checkUserProfileHasNewAgency();
        if (!agencyalreadyExists) {
          this.isInvalidData = false;
          await this.submitUserRequest(
            this.selectedAgency.isFirstUserForAgency
          );
          this.isLoading = false;
          this.isAPIFailure = false;
        } else {
          this.isLoading = false;
          this.isInvalidData = true;
        }
      } catch (e) {
        this.isLoading = false;
        this.isAPIFailure = true;
      } finally {
        this.agencypin = '';
        this.myModal.show();
        setTimeout(() => {
          this.selectedAgency = null;
        }, 5000);
      }
    } else {
      this.adminmodal.hide();
      this.isLoading = false;
    }
  }

  getAgencyIDFromAgencyPin() {
    return new Promise((resolve, reject) => {
      this.registrationMgr.validateAgencyPin(this.agencypin).subscribe({
        next: (data) => {
          if (data && data.data && data.data.length > 0) {
            data = data.data.filter(
              (x: { agencyAddress: { state: string } }) =>
                x.agencyAddress.state.toLowerCase() ==
                this.profile.stateProvince?.toLowerCase()
            );
            if (
              data &&
              data.length > 0 &&
              data.filter(
                (x: { isPinNumberValid: boolean }) =>
                  x.isPinNumberValid == false
              ).length == 0
            ) {
              this.errorMessage = '';
              if (data.length == 1) {
                this.disableSubmitButton = false;
                this.selectedAgency = new Agency(data[0]);
              } else {
                this.selectedAgency = null;
                data.forEach((aa: any) => {
                  let agency = new Agency(aa);
                  if (
                    this.availableAgencies &&
                    !(
                      this.availableAgencies.filter(
                        (x) => x.agencyID == agency.agencyID
                      ).length > 0
                    )
                  ) {
                    this.availableAgencies.push(agency);
                  }
                });
              }
              this.isLoading = false;
              resolve(data[0].agencyID);
            } else {
              this.isLoading = false;
              this.selectedAgency = null;
              this.disableSubmitButton = true;
              this.errorMessage = 'Invalid Agency PIN';
              reject(new Error());
            }
          } else {
            this.isLoading = false;
            this.selectedAgency = null;
            this.disableSubmitButton = true;
            this.errorMessage = 'Invalid Agency PIN';
            reject(new Error());
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.selectedAgency = null;
          this.disableSubmitButton = true;
          this.errorMessage = 'Invalid Agency PIN';
          reject(err);
        },
      });
    });
  }

  checkUserProfileHasNewAgency(): boolean {
    let agencyalreadyExists: boolean = false;
    if (this.profile && this.profile.agencyList?.length != 0) {
      this.profile.agencyList?.forEach((agency) => {
        if (agency.AgencyID == this.selectedAgency.agencyID)
          agencyalreadyExists = true;
      });
    }
    return agencyalreadyExists;
  }

  async submitUserRequest(isFirstUser: boolean) {
    let updatedRoles: AgencyRoleDetails[] = [];
    //get existing roles
    if (this.profile && this.profile.agencyList?.length != 0) {
      this.profile.agencyList?.forEach((agency) => {
        updatedRoles.push(agency);
      });
    }
    //append the new role
    let newAgency: any = {
      AgencyID: this.selectedAgency.agencyID.toString(),
      role: isFirstUser ? 'eapAgencyAdmin' : 'eapRead',
      status: 'eapPending',
    };
    updatedRoles.push(newAgency);
    let userID = '';
   if (Array.isArray(this.profile.email)) {
      userID = this.profile.email[0];
    }else{
      userID = this.profile.email || '';
    }
    const approvalBody: IAdminApprovalBody = {
      id: userID,
      roles: {
        REPLACE: updatedRoles,
      },
    };
    return new Promise((resolve, reject) => {
      this.administrationMgr.submitEAPUserStatus(approvalBody).subscribe({
        next: () => {
          resolve(true);
          //send email based on first user or not
          //if first user send email to user and ameren admin, if not send email to user and agency admin
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  async getAgencyDetails() {
    this.availableAgencies = [];
    if (this.agencypin) {
      this.isLoading = true;
      if (this.agencypin.toString().length == 5) {
        this.errorMessage = '';
        await this.getAgencyIDFromAgencyPin();
      } else {
        this.selectedAgency = null;
        this.errorMessage = 'Agency PIN must be 5 digits';
        this.disableSubmitButton = true;
        this.isLoading = false;
      }
    }
  }

  selectAgency(event: any) {
    let agencyID = event.currentTarget.getAttribute('data-agencyid');
    let agency = this.availableAgencies.filter((x) => x.agencyID == agencyID);
    if (agency && agency.length > 0) {
      this.selectedAgency = agency[0];
      this.availableAgencies = [];
      this.disableSubmitButton = false;
    }
  }

  logout() {
    this.agencyService.setSelectedAgency('');
    this.authService.Logout();
  }
}
