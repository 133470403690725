import { AmerenError } from 'src/app/shared/models/amerenError';
import { catchError, map } from 'rxjs/operators';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { ClientApiService } from './client-api.service';
import { BillingPaymentPeriod } from '../model/billing-payment.model';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ClientQuickViewModel } from '../model/clientquickview.model';

@Injectable({
  providedIn: 'root',
})
export class ClientManagerService {
  constructor(private _clientApiSvc: ClientApiService) {}

  getAccountStatusBySSN(ssn: string, customerName: string) {
    return this._clientApiSvc.getAccountStatusBySSN(ssn, customerName).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Account Status cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getAccountStatusByBillAccount(
    billAccount: string,
    accountName: string,
    ssn: string,
    state: string,
    agencyId: Number,
    requestor: string
  ) {
    return this._clientApiSvc
      .getAccountStatusByBillaccount(
        billAccount,
        accountName,
        ssn,
        state,
        agencyId,
        requestor
      )
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Account Status cannot be retrieved',
              400,
              null,
              null,
              null
            );
          }
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  //getRelatedAccountsByBillAccount

  getRelatedAccountsByBillAccount(billAccount: string) {
    return this._clientApiSvc.getRelatedAccountsByBillAccount(billAccount).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Account Status cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getAccountFinancials(billAccount: string, eapPin: string) {
    return this._clientApiSvc.getAccountFinancials(billAccount, eapPin).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Account Financials end point has failed to retrive the data',
            400,
            null,
            null,
            null
          );
        }
        return new ClientQuickViewModel(res.data);
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getPaymentagreementsByBillAccountNumber(billAccountNumber: string) {
    return this._clientApiSvc
      .getPaymentagreementsByBillAccountNumber(billAccountNumber)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Payment Agreements cannot be retrieved',
              400,
              null,
              null,
              null
            );
          }
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getBillingAndPaymentHistory(
    billAccountNumber: string,
    fromDate: any,
    toDate: any
  ) {
    return this._clientApiSvc
      .getBillingAndPaymentHistory(billAccountNumber, fromDate, toDate)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          let billingPaymentHistory: BillingPaymentPeriod[] = [];
          if (!res.success) {
            throw new AmerenError(
              'Billing And Payment history data cannot be retrieved',
              400,
              null,
              null,
              null
            );
          } else {
            if (
              res.data &&
              res.data.billPeriod &&
              res.data.billPeriod.length > 0
            ) {
              res.data.billPeriod.forEach((bp: any) => {
                let billPeriod = new BillingPaymentPeriod(bp);
                billingPaymentHistory.push(billPeriod);
              });

              const sortedList = billingPaymentHistory.sort(
                (objA, objB) =>
                  Number(objB.billPeriodStartDate) -
                  Number(objA.billPeriodStartDate)
              );
              return sortedList;
            }
          }
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getViewBillPDF(billImageId: string) {
    return this._clientApiSvc.getViewBillPDF(billImageId).pipe(
      map((data: Blob) => {
        return data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
