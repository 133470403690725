import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgencyReviewApiService {
  constructor(private http: HttpClient) {}

  getAgencyRegistrationList() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/agencyregistrationlist`,
      { headers }
    );
  }

  updateAgencyRegistration(
    status: string,
    userid: string,
    registrationid: any
  ) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('userid', userid);

    const body = {
      status: status,
    };

    return this.http.put<IGlobalAPIResponse>(
      `${environment.apig}/agencyregistration/registrationid/${registrationid}`,
      body,
      { headers }
    );
  }

  getRegistrationById(registrationid: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/registrationbyid?registrationId=${registrationid}`,
      { headers }
    );
  }
}
