<div class="row justify-content-center">
  <div class="col-md-12 text-center">
    <button class="btn btn-success" (click)="silentRenew()">
      Silent Renew
    </button>
  </div>
  <div class="col-md-12">
    <pre *ngIf="data != 'loading'">
            {{ data | json }}
    </pre>
  </div>
  <div *ngIf="data == 'loading'">
    <i class="fa fa-spinner fa-spin fa-2x"></i>
  </div>
</div>
