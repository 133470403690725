<h3 class="billing_payments_heading">
    Billing and Payment Assistance Options
</h3>
<div class="billing_payments_desktop">
    <ameren-carousel [slidesPerPage]="3" [disableAutoSliding]="true" [autoSlidingDuration]="5" [slides]="getslides(selectedState)">
    </ameren-carousel>
</div>

<div class="billing_payments_mobile">
    <div *ngFor="let s of getslides(selectedState)" class="text-center slide">
        <img [src]="s.img_src" [alt]="s.headline" />
        <h4 class="carousel_heading">{{s.headline}}</h4>
        <p class="text-center">
            {{s.description}}
        </p>

        <a class="carousel_inner_link" [href]="s.linkUrl">{{s.linkText}}

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg>
        </a> 
    </div>
</div>