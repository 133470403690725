<div class="training_list_container">
  <div class="row" id="training_list_header">
    <div class="col"><h3 class="training_header">Training Materials</h3></div>
    <div class="col training_pagination m-1">
      <nav aria-label="Page navigation example" *ngIf="totalCount > 0">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" [ngClass]="page < 2 ? 'disable' : ''" (click)="previousPage()">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only" >Previous</span>
            </a>
          </li>
          <li class="page-item"><a class="page-link" (click)="loadPerPage(1, 10)">1</a></li>
          <li class="page-item"><a class="page-link" *ngIf="totalCount > 10" (click)="loadPerPage(2, 10)">2</a></li>
          <li class="page-item"><a class="page-link" *ngIf="totalCount > 20" (click)="loadPerPage(3, 10)">3</a></li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" [ngClass]="(page == Math.ceil(totalCount / rowsPerPage)) ? 'disable':''" (click)="nextPage()">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only" >Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="row" id="training_list_table" *ngIf="!isTrainingListLoading">
    <div
      class="col-md-6 col-sm-12 training_tile"
      *ngFor="let training of trainingList"
    >
      <div class="training-tile_inner">
        <div class="row" id="training_tile_inner_doc">
          <div class="col-md-1 col-sm-1 col-xs-1">
            <img
              *ngIf="training.docmentType == 'application/pdf'"
              [src]="iconImage.img_pdf_src"
              class="training_tile_image"
              [alt]="iconImage.headline"
              width="50"
              height="50"
            />
            <img
              *ngIf="training.docmentType == 'video'"
              [src]="iconImage.img_video_src"
              class="training_tile_image"
              [alt]="training.documentName"
              width="50"
              height="50"
            />
            <img
              *ngIf="
                training.docmentType == 'txt' ||
                training.docmentType == 'tx' ||
                training.docmentType == 'text/plain' ||
                training.docmentType == 'application/xml'
              "
              [src]="iconImage.img_txt_src"
              class="training_tile_image"
              [alt]="training.documentName"
              width="50"
              height="50"
            />
            <img
              *ngIf="
                training.docmentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              "
              [src]="iconImage.img_word_src"
              class="training_tile_image"
              [alt]="training.documentName"
              width="50"
              height="50"
            />
            <img
              *ngIf="training.docmentType == 'excel'"
              [src]="iconImage.img_excel_src"
              class="training_tile_image"
              [alt]="training.documentName"
              width="50"
              height="50"
            />
          </div>
          <div class="col-md-8 col-sm-8 col-xs-8 training_tile_text">
            <div class="list_content">
              <span
                *ngIf="training.docmentType != 'video'"
                class="training_tile_title"
                (click)="getDocById(training.documentID)"
                >{{ training.documentName }}</span
              >
              <a
                *ngIf="training.docmentType === 'video'"
                class="training_tile_title"
                href="{{ training.documentResourceString }}"
                target="_blank"
                rel="noopener"
                >{{ training.documentName }}</a
              >
              <span class="training_tile_description">{{
                training.documentDescription
              }}</span>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="training_action" *ngIf="isAdmin">
          <button
            class="btn btn-sm training_action_delete"
            (click)="onDeleteConfirmModal(training.documentID)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash3"
              viewBox="0 0 16 16"
            >
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
              />
            </svg>
            <span class="training_action_delete_text"> Delete </span>
          </button>
          <hr class="training_action_divider" />
          <button
            class="btn btn-sm training_action_edit"
            (click)="getDocDetails(training.documentID)"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="edit">
                <path
                  id="Combined Shape"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.7071 1.29289L22.7071 5.29289C23.0976 5.68342 23.0976 6.31658 22.7071 6.70711L12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17H8C7.44772 17 7 16.5523 7 16V12C7 11.7348 7.10536 11.4804 7.29289 11.2929L17.2929 1.29289C17.6834 0.902369 18.3166 0.902369 18.7071 1.29289ZM19 14.66V20C19 20.5523 18.5523 21 18 21H4C3.44772 21 3 20.5523 3 20V6C3 5.44772 3.44772 5 4 5H9.34C9.89229 5 10.34 4.55228 10.34 4C10.34 3.44772 9.89229 3 9.34 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H18C19.6569 23 21 21.6569 21 20V14.66C21 14.1077 20.5523 13.66 20 13.66C19.4477 13.66 19 14.1077 19 14.66ZM9 15V12.4142L18 3.41421L20.5858 6L11.5858 15H9Z"
                  fill="black"
                />
                <mask
                  id="mask0_0_10597"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="1"
                  y="1"
                  width="22"
                  height="22"
                >
                  <path
                    id="Combined Shape_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.7071 1.29289L22.7071 5.29289C23.0976 5.68342 23.0976 6.31658 22.7071 6.70711L12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17H8C7.44772 17 7 16.5523 7 16V12C7 11.7348 7.10536 11.4804 7.29289 11.2929L17.2929 1.29289C17.6834 0.902369 18.3166 0.902369 18.7071 1.29289ZM19 14.66V20C19 20.5523 18.5523 21 18 21H4C3.44772 21 3 20.5523 3 20V6C3 5.44772 3.44772 5 4 5H9.34C9.89229 5 10.34 4.55228 10.34 4C10.34 3.44772 9.89229 3 9.34 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H18C19.6569 23 21 21.6569 21 20V14.66C21 14.1077 20.5523 13.66 20 13.66C19.4477 13.66 19 14.1077 19 14.66ZM9 15V12.4142L18 3.41421L20.5858 6L11.5858 15H9Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_0_10597)">
                  <g id="COLOR/ black">
                    <rect id="Rectangle" width="24" height="24" fill="black" />
                  </g>
                </g>
              </g>
            </svg>

            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isTrainingListLoading" class="text-center">
    <ameren-loading
      id="listLoadingIndicator"
      class="d-flex p-10 justify-content-center"
    ></ameren-loading>
    Please wait...
  </div>
  <div class="text-center text-danger" *ngIf="trainingListApiFailure">
    Failed to load Training Materials.
  </div>

  <div class="row text-center mt-4" *ngIf="isAdmin">
    <div>
      <button class="btn btn-success add_training_button" (click)="add()">
        Add New
      </button>
    </div>
  </div>
</div>

<!------modal option------->
<div
  id="addtraingmaterialoption"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-body">
        <!------------form---------------->
        <div class="title">Add New Training Material</div>
        <div class="material">Material Type</div>
        <div class="items">
          <button
            type="button"
            class="btn btn-outline-success item"
            (click)="selectOption(true)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-earmark-arrow-up"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"
              />
              <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
              /></svg
            >Document
          </button>
          <button
            type="button"
            class="btn btn-outline-success item"
            (click)="selectOption(false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-play"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z"
              />
              <path
                d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
              /></svg
            >Video
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!------------add training material modal----------->
<div
  id="addtraingmaterial"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div
          class="ameren__modal__success-title title"
          *ngIf="!isApiMessageResponse"
        >
          Add New Training Material
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="!isApprovalAPIFailure && isApiMessageResponse"
        >
          Succefully Uploaded
        </div>
        <div
          class="ameren__modal__failure-title"
          *ngIf="isApprovalAPIFailure && isApiMessageResponse"
        >
          Upload Failed
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>

      <ng-container *ngIf="!isApiMessageResponse">
        <div class="material">Material Type</div>
        <div class="items">
          <span
            class="file"
            [ngClass]="{ active: isDocument }"
            (click)="isDocument = true"
            style="cursor: pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-earmark-arrow-up"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"
              />
              <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
              /></svg
            >Document</span
          >
          <span
            class="file"
            [ngClass]="{ active: !isDocument }"
            (click)="isDocument = false"
            style="cursor: pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-play"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z"
              />
              <path
                d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
              /></svg
            >Video</span
          >
        </div>
      </ng-container>
      <div class="modal-body">
        <!------------form---------------->
        <form [formGroup]="trainingMaterialForm" *ngIf="!isApiMessageResponse">
          <div class="form-group">
            <label for="text-input" class="caption"
              >Title
              <span class="required-asterisk">*</span>
            </label>
            <input
              type="text"
              id="title"
              name="title"
              formControlName="title"
              class="form-control"
              required
              (input)="clearInvalid('title')"
              maxlength="25"
            />
            <div *ngIf="isTitleInvalid" class="invalid">
              This field is required.
            </div>
            <div *ngIf="isTitleLimitInvalid" class="invalid">
              Maximum character limit (25) exceeded.
            </div>
          </div>

          <div class="form-group">
            <label for="text-input" class="caption"
              >Description
              <span class="required-asterisk">*</span>
            </label>
            <input
              type="text"
              formControlName="description"
              class="form-control"
              id="description"
              name="description"
              required
              (input)="clearInvalid('description')"
              maxlength="50"
            />
            <div *ngIf="isDescInvalid" class="invalid">
              This field is required.
            </div>
            <div *ngIf="isDescLimitInvalid" class="invalid">
              Maximum character limit (50) exceeded.
            </div>
          </div>
          <div class="form-group" *ngIf="!isDocument">
            <label class="form-check-label caption" for="exampleCheck1"
              >YouTube Video Link
            </label>
            <input
              type="text"
              formControlName="link"
              class="form-control"
              id="link"
              name="link"
            />
          </div>
          <div
            class="form-group drag justify-content-center"
            *ngIf="isDocument"
          >
            <div class="center file_upload_container">
              <ng-container *ngIf="isUploaded">
                <div class="caption"><a (click)="remove()">Remove</a></div>
                <img
                  *ngIf="fileType == 'application/pdf'"
                  [src]="iconImage.img_pdf_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <img
                  *ngIf="
                    fileType == 'txt' ||
                    fileType == 'tx' ||
                    fileType == 'text/plain' ||
                    fileType == 'application/xml'
                  "
                  [src]="iconImage.img_txt_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <img
                  *ngIf="
                    fileType ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                  [src]="iconImage.img_word_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <img
                  *ngIf="
                    fileType ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  "
                  [src]="iconImage.img_excel_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <div>
                  {{ fileName }}
                </div>
              </ng-container>
              <ng-container *ngIf="!isUploaded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
                  />
                </svg>

                <div>Drag and drop here</div>
                <div>or</div>
                <div><a class="active">Browse files</a></div>
                <input
                  type="file"
                  class="upload-section"
                  (change)="uploadFile($event)"
                />
              </ng-container>
            </div>
          </div>
          <small
            id="emailHelp"
            class="form-text caption caption-text"
            *ngIf="isDocument"
            >Accepted File Types: .doc .docx .pdf only</small
          >

          <div class="center">
            <div>
              <small class="text-danger" *ngIf="error != ''">
                {{ error }}
              </small>
            </div>
            <button
              type="button"
              class="btn btn-outline-primary item"
              data-bs-dismiss="modal"
              aria-label="Close"
              (click)="close()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-outline-success item"
              (click)="submit()"
            >
              Submit
            </button>
          </div>
          <div *ngIf="isApprovalLoading" class="text-center">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
          </div>
        </form>
        <div
          class="ameren__modal-image text-center"
          *ngIf="isApiMessageResponse"
        >
          <img
            *ngIf="!isApprovalAPIFailure"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="isApprovalAPIFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!--------loading indicator--------->
<div
  id="dpcLoadingModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center" *ngIf="getDocByIdApiFailure">
        <div class="ameren__modal__failure-title">
          Failed to download the training material
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-header text-center" *ngIf="getDocByIdApiFailure">
        <div class="ameren__modal__failure-title">
          Failed to get training material
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="ameren__modal-image text-center">
          <div *ngIf="isTrainingDownloading" class="text-center">
            <ameren-loading
              id="trainingLoadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            Please wait...
          </div>
          <img
            *ngIf="getDocByIdApiFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-----------delete confirmation-------->
<div
  id="deleteModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="deleteModalConfirmation"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div
          class="ameren__modal__failure-title"
          *ngIf="deleteDocApiFailure && isDeleteApiMessageResponse"
        >
          Failed to delete the training material
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="deleteDocApiSuccess && isDeleteApiMessageResponse"
        >
          Training material has been deleted successfully.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="!isDeleteApiMessageResponse"
        >
          Confirm Your Changes
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeDeleteModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" id="delete_training_confirm_message" *ngIf="!isDeleteApiMessageResponse">
          <span
            >Are you sure that you want to delete this Training Material?</span
          >
        </div>
        <div class="row mt-4" id="delete_training_button_section" *ngIf="!isDeleteApiMessageResponse">
          <div class="col-md-4"></div>
          <div class="col-md-2">
            <button
              (click)="onDeleteTraining()"
              class="btn btn-success create_campaign_button"
            >
              Yes
            </button>
          </div>
          <div class="col-md-2">
            <button
              (click)="onDeleteTrainingCancel()"
              class="btn btn-secondary create_campaign_button"
            >
              No
            </button>
          </div>
          <div class="col-md-4"></div>
          <div class="text-center" *ngIf="isDeleteLoading">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
        </div>
        <div
          class="ameren__modal-image text-center"
          *ngIf="isDeleteApiMessageResponse"
        >
          <img
            *ngIf="!isApprovalAPIFailure"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="isApprovalAPIFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!---------edit training material modal----->
<div
  id="docDetailsLoadingModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center" *ngIf="getDocDetailsByIdApiFailure">
        <div class="ameren__modal__failure-title">
          Failed to get training material
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-header text-center" *ngIf="getDocDetailsByIdApiFailure">
        <div class="ameren__modal__failure-title">
          Failed to get training material
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="ameren__modal-image text-center">
          <div *ngIf="isDocDetailLoading" class="text-center">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            Please wait...
          </div>
          <img
            *ngIf="getDocDetailsByIdApiFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="edittraingmaterialoption"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="ameren__modal__success-title title">
          Edit Training Material
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <ng-container>
        <div class="material">Material Type</div>
        <div class="items">
          <span class="file" *ngIf="isDocument" style="cursor: pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-earmark-arrow-up"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"
              />
              <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
              /></svg
            >Document</span
          >
          <span class="file" *ngIf="!isDocument" style="cursor: pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-file-play"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z"
              />
              <path
                d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
              /></svg
            >Video</span
          >
        </div>
      </ng-container>
      <div class="modal-body">
        <!------------form---------------->
        <form [formGroup]="trainingMaterialForm">
          <div class="form-group">
            <label for="text-input" class="caption"
              >Title
              <span class="required-asterisk">*</span>
            </label>
            <input
              type="text"
              id="title"
              name="title"
              formControlName="title"
              class="form-control"
              required
              (input)="clearInvalid('title')"
              maxlength="25"
            />
            <div *ngIf="isTitleInvalid" class="invalid">
              This field is required.
            </div>
            <div *ngIf="isTitleLimitInvalid" class="invalid">
              Maximum character limit (25) exceeded.
            </div>
          </div>

          <div class="form-group">
            <label for="text-input" class="caption"
              >Description
              <span class="required-asterisk">*</span>
            </label>
            <input
              type="text"
              formControlName="description"
              class="form-control"
              id="description"
              name="description"
              required
              (input)="clearInvalid('description')"
              maxlength="50"
            />
            <div *ngIf="isDescInvalid" class="invalid">
              This field is required.
            </div>
            <div *ngIf="isDescLimitInvalid" class="invalid">
              Maximum character limit (50) exceeded.
            </div>
          </div>
          <div class="form-group" *ngIf="!isDocument">
            <label class="form-check-label caption" for="exampleCheck1"
              >YouTube Video Link
            </label>
            <input
              type="text"
              formControlName="link"
              class="form-control"
              id="link"
              name="link"
            />
          </div>
          <div
            class="form-group drag justify-content-center"
            *ngIf="isDocument"
          >
            <div class="center file_upload_container">
              <ng-container *ngIf="isUploaded">
                <div class="caption"><a (click)="remove()">Remove</a></div>
                <img
                  *ngIf="fileType == 'application/pdf'"
                  [src]="iconImage.img_pdf_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <img
                  *ngIf="
                    fileType == 'txt' ||
                    fileType == 'tx' ||
                    fileType == 'text/plain' ||
                    fileType == 'application/xml'
                  "
                  [src]="iconImage.img_txt_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <img
                  *ngIf="
                    fileType ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                  [src]="iconImage.img_word_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <img
                  *ngIf="
                    fileType ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  "
                  [src]="iconImage.img_excel_src"
                  class="training_tile_image"
                  [alt]="fileName"
                  width="50"
                  height="50"
                />
                <div>
                  {{ fileName }}
                </div>
              </ng-container>
              <ng-container *ngIf="!isUploaded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
                  />
                </svg>

                <div>Drag and drop here</div>
                <div>or</div>
                <div><a class="active">Browse files</a></div>
                <input
                  type="file"
                  class="upload-section"
                  (change)="uploadFile($event)"
                />
              </ng-container>
            </div>
          </div>
          <small
            id="emailHelp"
            class="form-text caption caption-text"
            *ngIf="isDocument"
            >Accepted File Types: .doc .docx .pdf only</small
          >

          <div class="center">
            <div>
              <small class="text-danger" *ngIf="error != ''">
                {{ error }}
              </small>
            </div>
            <button
              type="button"
              class="btn btn-outline-primary item"
              data-bs-dismiss="modal"
              aria-label="Close"
              (click)="close()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-outline-success item"
              (click)="update()"
            >
              Update
            </button>
          </div>
          <div *ngIf="isApprovalLoading" class="text-center">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
          </div>
        </form>
        <div
          class="ameren__modal-image text-center"
          *ngIf="isApiMessageResponse"
        >
          <div class="training_success-title" *ngIf="!isApprovalAPIFailure">
            Successfully Uploaded
          </div>
          <div class="training_failure-title" *ngIf="isApprovalAPIFailure">
            Upload Failed
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
