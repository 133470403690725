import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AgencyCampaign } from '../model/campaignbyagency.model';
import { CampaignsManagerService } from '../services/campaigns-manager.service';

@Component({
  selector: 'app-campaign-activity',
  templateUrl: './campaign-activity.component.html',
  styleUrls: ['./campaign-activity.component.scss'],
})
export class CampaignActivityComponent implements OnInit {
  isAPIFailure: boolean;
  isLoading: boolean;
  agencyCampaignList: AgencyCampaign[];
  activeCampaignList: AgencyCampaign[];
  selectedAgency: any;

  constructor(
    private campaignManager: CampaignsManagerService,
    private datePipe: DatePipe,
    private agencyService: AgencyService
  ) {
    this.selectedAgency = this.agencyService.getSelectedAgency();
  }

  ngOnInit(): void {
    this.getCampaignByAgency();
  }

  getCampaignByAgency() {
    this.isAPIFailure = false;
    this.isLoading = true;
    this.activeCampaignList = [];
    this.campaignManager
      .getCampaignByAgency(this.selectedAgency.agencyCSSCode)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.agencyCampaignList = this.sortData(res);
          this.activeCampaignList = this.agencyCampaignList.filter(
            (data) => data.campaignAgencyStatus?.toLowerCase() != 'inactive'
          );

          this.activeCampaignList.forEach((e) => {
            const currentDate = new Date();
            const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

            const days =
              Math.round(Date.parse(e['campaignToDate']) - Date.parse(cValue)) /
              (1000 * 60 * 60 * 24);

            e['timeRemaining'] = days || 0;
            e['timeRemainingDayHour'] =
              days < 0 ? 'Campaign is expired' : days.toString() + ' Days';
          });
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error && error.error.errorDetails.code === 400) {
            this.agencyCampaignList = [];
          } else {
            this.isAPIFailure = true;
          }
          console.log('error', error);
        },
      });
  }

  sortData(res: any[]) {
    let sortingData: any[] = res;
    return sortingData.sort((a: any, b: any) => {
      return a.campaignName
        .toLowerCase()
        .localeCompare(b.campaignName.toLowerCase());
    });
  }
}
