import { FormControl } from '@angular/forms';
import { AdministrationManagerService } from 'src/app/secure/administration/services/administration-manager.service';
import { ProfileManagerService } from './../../../../shared/services/profilemanager.service';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
declare var window: any;

@Component({
  selector: 'app-edit-message',
  templateUrl: './edit-message.component.html',
  styleUrls: ['./edit-message.component.scss'],
})
export class EditMessageComponent implements OnInit, OnChanges {
  constructor(
    private _fb: FormBuilder,
    private _profileSvc: ProfileManagerService,
    private _adminSvc: AdministrationManagerService
  ) {}
  @Input() message: any;
  @Output() Cancel = new EventEmitter();
  editForm: FormGroup;
  showPlaceHolder: boolean = false;
  EditConfrmModal: any;

  isEditApiMessageResponse: boolean;
  isEditAPIFailure: boolean;
  isEditLoading: boolean;
  editMsgApiSuccess: boolean;

  isDeleteAPIFailure: boolean;
  isDeleteApiMessageResponse: boolean;
  isDeleteLoading: boolean;
  deleteMessageApiFailure: boolean;
  deleteMsgApiSuccess: boolean;
  DeleteConfrmModal: any;
  selectedMsgIdToDelete: string;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],

    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'backgroundColor',
        'insertImage',
        'insertVideo',
        'link',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'fontSize',
        'heading',
      ],
    ],
  };
  ngOnInit(): void {
    this.EditConfrmModal = new window.bootstrap.Modal(
      document.getElementById('EditModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.editForm.get('htmlContent')?.valueChanges.subscribe((val) => {
      if (val && val.length) {
        this.showPlaceHolder = false;
      } else {
        this.showPlaceHolder = true;
      }
    });
    this.DeleteConfrmModal = new window.bootstrap.Modal(
      document.getElementById('DeleteMessageModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.editForm = this._fb.group({
      messageID: new FormControl(changes['message'].currentValue?.messageID),
      messageStatus: new FormControl(
        changes['message'].currentValue?.messageStatus
      ),
      htmlContent: new FormControl(
        changes['message'].currentValue?.messageBody
      ),
      subject: new FormControl(changes['message'].currentValue?.messageHeader),
      expireDate: new FormControl(changes['message'].currentValue?.expireDate),
    });

    this.editForm.get('htmlContent')?.valueChanges.subscribe((val) => {
      if (val && val.length) {
        this.showPlaceHolder = false;
      } else {
        this.showPlaceHolder = true;
      }
    });
  }

  onCancelClick() {
    this.Cancel.emit();
  }

  closeEditModal() {
    location.reload();
  }

  onSubmitClick() {
    this.EditConfrmModal.show();
    this.isEditLoading = true;
    let msg = {
      id: this.editForm?.value?.messageID,
      editedBy:
        this._profileSvc.profile[0].stateProvince == 'IL'
          ? 'ILAdmin'
          : 'MOAdmin',
      messageStatus: this.editForm.value?.messageStatus,
      messageHeader: this.editForm.value?.subject,
      messageBody: this.editForm.value?.htmlContent,
      expireDate: this.editForm.value?.expireDate || '',
    };

    this._adminSvc.updateMessage(msg).subscribe({
      next: (resp) => {
        this.isEditLoading = false;
        this.editMsgApiSuccess = true;
        this.isEditApiMessageResponse = true;
        this.isEditAPIFailure = false;
      },
      error: (err) => {
        this.isEditLoading = false;
        this.editMsgApiSuccess = false;
        this.isEditApiMessageResponse = true;
        this.isEditAPIFailure = true;
      },
    });
  }

  closeDeleteModal() {
    location.reload();
  }
  onDeleteMsgCancel() {
    this.DeleteConfrmModal.hide();
  }
  deleteMessage() {
    this.isDeleteLoading = true;
    this._adminSvc
      .deleteMessage(this.editForm?.value?.messageID || '')
      .subscribe({
        next: (resp) => {
          this.deleteMsgApiSuccess = true;
          this.deleteMessageApiFailure = false;
          this.isDeleteApiMessageResponse = true;
          this.isDeleteLoading = false;
        },
        error: (error) => {
          this.deleteMsgApiSuccess = false;
          this.deleteMessageApiFailure = true;
          this.isDeleteApiMessageResponse = true;
          this.isDeleteLoading = false;
        },
      });
  }
  onDeleteClick() {
    this.DeleteConfrmModal.show();
  }
}
