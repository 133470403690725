<div class="edit-message-container">
    <form [formGroup]="editForm" class="edit-message-form">
        <div class="message-subject-container">
            <label>Subject: </label>
            <input type="text" formControlName = "subject" placeholder="Subject:" class="message-subject">
            <span *ngIf="editForm?.value?.subject" class="subject-char-counter">
                {{editForm.value.subject.length}}/250
            </span>
    
            <span *ngIf="!editForm?.value?.subject" class="subject-char-counter">
                0/250
            </span>
        </div>
        <div class="message-placeholder" *ngIf="showPlaceHolder">
    Compose message
        </div>
       <div class="message-expire-date">
       
        <input type="date" formControlName = "expireDate" placeholder=" Expiration (Optional)" >
       </div>
        <angular-editor [placeholder]="'Compose Message'" formControlName="htmlContent" [config]="editorConfig"></angular-editor>
    
    </form>

    <div class="row mt-3">
<div class="col-md-8">
    <button type="button" class="btn btn-danager delete-btn" (click)="onDeleteClick()">Delete</button>
</div>
<div class="col-md-4 text-right"> 
<button type="button" class="btn cancel-btn" (click)="onCancelClick()">Cancel</button>
<button type="button" class="btn btn-success" (click)="onSubmitClick()">Update</button>

</div>
    </div>
    
    </div>

    <div *ngIf="!editForm?.value?.htmlContent && editForm.get('htmlContent')?.dirty" class="edit-message-error">
        This field cannot be empty.
            </div>

    <div
  id="EditModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="EditModalConfirmation"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div
          class="ameren__modal__failure-title"
          *ngIf="isEditAPIFailure && isEditApiMessageResponse"
        >
          Failed to update the Message.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="editMsgApiSuccess && isEditApiMessageResponse"
        >Message has been updated successfully.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="!isEditApiMessageResponse"
        >
          Update message
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeEditModal()"
        ></button>
      </div>
      <div class="modal-body">

        <div
          class="ameren__modal-image text-center"
          *ngIf="isEditApiMessageResponse"
        >
          <img
            *ngIf="!isEditAPIFailure"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="isEditAPIFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>

        <div class="text-center" *ngIf="isEditLoading">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
      </div>
    </div>
  </div>
</div>

<div
  id="DeleteMessageModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="DeleteMessageModalConfirmation"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div
          class="ameren__modal__failure-title"
          *ngIf="deleteMessageApiFailure && isDeleteApiMessageResponse"
        >
          Failed to Delete Message.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="deleteMsgApiSuccess && isDeleteApiMessageResponse"
        >
          New Message has been Deleted successfully.
        </div>
        <div
          class="ameren__modal__success-title"
          *ngIf="!isDeleteApiMessageResponse"
        >
          Delete Message
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeDeleteModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="!isDeleteApiMessageResponse">
          <span
            >Are you sure that you want to delete this post</span
          >
        </div>
        <div class="row mt-4" *ngIf="!isDeleteApiMessageResponse">
          <div class="col-md-4"></div>
          <div class="col-md-2">
            <button
              (click)="onDeleteMsgCancel()"
              class="btn btn-secondary create_campaign_button"
            >
              Cancel
            </button>
          </div>
          <div class="col-md-2">
            <button
              (click)="deleteMessage()"
              class="btn btn-success create_campaign_button"
            >
              Submit
            </button>
          </div>

          <div class="col-md-4"></div>
          <div class="text-center" *ngIf="isDeleteLoading">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
        </div>
        <div
          class="ameren__modal-image text-center"
          *ngIf="isDeleteApiMessageResponse"
        >
          <img
            *ngIf="deleteMsgApiSuccess"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="deleteMessageApiFailure"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
      </div>
    </div>
  </div>
</div>
