import { AbstractControl } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: AbstractControl) => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (matchingControl?.errors && !matchingControl?.errors['mustMatch']) {
      // return if another validator has already found an error on the matchingControl
      return { not_the_same: true };
    }

    // set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      return matchingControl?.setErrors({ mustMatch: true });
    } else {
      return matchingControl?.setErrors(null);
    }
  };
}
