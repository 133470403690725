import { Injectable } from '@angular/core';
import { jsPDF, AcroFormCheckBox } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { EmailConstants } from 'src/app/shared/constants/emailConstants';
import { isArray } from 'lodash';
import * as XLSX from 'xlsx';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AccountNumberPipe } from '../pipes/accountNumber.pipe';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  pdfObject: string;
  constructor(private datepipe: DatePipe,
    private currencypipe: CurrencyPipe,
    private accountpipe: AccountNumberPipe) { }

  exportObjectToPDF(filename: string, DATA: any) {
    const doc = new jsPDF();
    let startx = 20;
    let starty = 10;
    if (DATA) {
      doc.setFontSize(22);
      doc.text(DATA.description, startx, starty);

      starty = starty + 12;
      DATA.sections.forEach((section: any) => {
        doc.setFontSize(20);
        doc.text(section.sectionTitle, startx, starty);
        starty = starty + 10;
        doc.setFontSize(12);
        section.fields.forEach((item: any) => {
          doc.text(item.value || "", startx, starty);
          starty = starty + 8;
        });
      });
      doc.save(filename);
    }
  }

  SetPdfObject(pdf: string): void {
    this.pdfObject = pdf;
  }

  public GetPdfObject(): string {
    return this.pdfObject;
  }

  exportTabletoPDF(filename: string, body: any, hasImage?: boolean, isMO?: boolean) {

    let finalY = 0;
    let offsetY = 5;
    var doc = new jsPDF();

    if (hasImage) {
      let img = 'data:image/png;base64,' + (isMO ? EmailConstants.mo_image_base64
        : EmailConstants.il_image_base64);
      doc.addImage(img, 'PNG', 10, 9, 25, 15);
    }

    const date = new Date()
    doc.setFontSize(11);
    var currentDate = this.datepipe.transform(date, 'MM/dd/yyyy h:mm a')
    doc.text(currentDate!.toString(), 10, 5);

    if (body) {
      body.forEach((item: any) => {
        if (item && item.element) {
          let startX = 0;

          switch (item.type) {
            case "string":
              startX = (item.align === "center") ? this.getCenterOffset(item.element, doc) : 0;

              if (!item.sameRow)
                finalY = finalY + item.y;

              doc.setFontSize(item.fontsize);
              doc.setFont('helvetica', (item.fontWeight) ? item.fontWeight : 'normal')
              doc.setTextColor(item.fontColor ? item.fontColor : 'black');

              if (item.splitText && item.splitText > 0) {
                let splitTextString = doc.splitTextToSize(item.element, item.splitText);
                doc.text(splitTextString, startX + item.x, finalY);
              } else {
                doc.text(item.element, startX + item.x, finalY);
              }

              finalY = finalY + (((item.offsetY != null || item.offsetY !== undefined) && item.offsetY >= 0) ? item.offsetY : offsetY);
              break;
            case "line":
              if (!item.sameRow)
                finalY = finalY + item.y;
              doc.line(item.x, finalY, item.x1, finalY);
              finalY = finalY + (((item.offsetY != null || item.offsetY !== undefined) && item.offsetY >= 0) ? item.offsetY : offsetY);
              break;
            case "page":
              doc.addPage();
              doc.text(item.element, item.x, item.y);
              break;
            case "checkbox":
              if (!item.sameRow)
                finalY = finalY + item.y;
              var checkBox = new AcroFormCheckBox();
              checkBox.fieldName = item.element;
              checkBox.appearanceState = 'On';
              checkBox.maxFontSize = 8;
              checkBox.readOnly = true;
              checkBox.height = 2;
              checkBox.width = 2;
              checkBox.x = item.x;
              checkBox.y = finalY;
              doc.rect(checkBox.x, finalY, checkBox.width, checkBox.height);
              doc.addField(checkBox);
              finalY = finalY + (((item.offsetY != null || item.offsetY !== undefined) && item.offsetY >= 0) ? item.offsetY : offsetY);
              break;
            case "table":
              autoTable(doc, {
                html: item.element,
                startY: finalY + item.y,
                styles: { halign: 'center' }
              });
              break;
          }
        }
      });
    }
    doc.save(filename);
  }

  getCenterOffset(item: string, doc: any) {
    var textWidth = doc.getStringUnitWidth(item) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    return textOffset;
  }

  private fixWidth(worksheet: XLSX.WorkSheet) {
    const data = XLSX.utils.sheet_to_json<any>(worksheet)
    const colLengths = Object.keys(data[0]).map((k) => k.toString().length)

    for (const d of data) {
      Object.values(d).forEach((element: any, index) => {
        if (element) {
          const length = element.length
          if (colLengths[index] < length) {
            colLengths[index] = length
          }
        }
      })
    }
    worksheet["!cols"] = colLengths.map((l) => {
      return {
        wch: l,
      }
    })
  }

  public getBase64Image(img: any) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx: any = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL('image/png');
    return { img: dataURL, width: canvas.width, height: canvas.height };
  }

  public exportJsonToPdfFile(json: any[], pdfFileName: string, keyHeaders: string[], displayHeaders?: string[], pdfTitle: string = '', hasImage?: boolean, isMO?: boolean, clockImg?: any, isClientPledge?: boolean) {
    var doc = new jsPDF('l', 'pt', 'a4');
    let displayhdrs: string[] = displayHeaders ? displayHeaders : [];
    let jsonFormat = this.formatTableData(json, keyHeaders);
    let jsonArray: string[][] = jsonFormat.map((x: string) => Object.values(x));

    if (hasImage) {
      let img = 'data:image/png;base64,' + (isMO ? EmailConstants.mo_image_base64
        : EmailConstants.il_image_base64);
      doc.addImage(img, 'PNG', 20, 22, 50, 30);
    }

    const date = new Date()
    doc.setFontSize(11);
    var currentDate = this.datepipe.transform(date, 'MM/dd/yyyy h:mm a')
    doc.text(currentDate!.toString(), 20, 13);

    autoTable(doc, {
      head: [new Array(pdfTitle)],
      margin: { top: 60 },
      styles: { halign: 'center' },

    });

    autoTable(doc, {
      head: [displayhdrs],
      body: jsonArray,
      margin: { top: 20 },

      //removing placeholder text from client & agency pledge report
      didParseCell: function (data) {
        if (clockImg) {
          if (isClientPledge) {
            if (data.column.index === 1 && data.cell.section === 'body') {
              var td = data.cell.raw;
              if (td === "1") {
                data.cell.text[0] = ''
              }
              else {
              }
            }

          }
          else {
            if (data.column.index === 3 && data.cell.section === 'body') {
              var td = data.cell.raw;
              if (td === "1") {
                data.cell.text[0] = ''
              }
              else {
              }
            }
          }
        }
      },

      didDrawCell: function (data) {
        //adding clock image
        if (clockImg) {
          if (isClientPledge) {
            if (data.column.index === 1 && data.cell.section === 'body') {
              var td = data.cell.raw;
              if (td == true) {
                var textPos = data.cell.getTextPos();
                doc.addImage(clockImg.img, 'JPG', data.cell.x, textPos.y, 10, 10);
              }
              else {
              }
            }

          }
          else {
            if (data.column.index === 3 && data.cell.section === 'body') {
              var td = data.cell.raw;
              if (td == true) {
                var textPos = data.cell.getTextPos();
                doc.addImage(clockImg.img, 'JPG', data.cell.x, textPos.y, 10, 10);
              }
              else {
              }
            }
          }
        }
      },


    });

    doc.save(`${pdfFileName}.pdf`);
  }

  public exportJsonToExcelFile(json: any[], excelFileName: string, skipFirst: boolean, keyHeaders?: string[], displayHeaders?: string[]) {
    let worksheet: XLSX.WorkSheet;
    let displayhdrs: string[] = displayHeaders ? displayHeaders : [];
    if (skipFirst) {
      worksheet = XLSX.utils.json_to_sheet(this.formatTableData(json, keyHeaders), { skipHeader: true });
    }
    else {
      worksheet = XLSX.utils.json_to_sheet(this.formatTableData(json, keyHeaders), { skipHeader: false });
    }
    XLSX.utils.sheet_add_aoa(worksheet, [displayhdrs]);
    this.fixWidth(worksheet);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
  }

  public exportJsonToExcelBook(json: any[], excelFileName: string, skipFirst: boolean, keyHeaders?: string[], displayHeaders?: string[]) {
    let worksheet: XLSX.WorkSheet;
    let displayhdrs: string[] = displayHeaders ? displayHeaders : [];
    if (skipFirst) {
      worksheet = XLSX.utils.json_to_sheet(this.formatTableData(json, keyHeaders), { skipHeader: true });
    }
    else {
      worksheet = XLSX.utils.json_to_sheet(this.formatTableData(json, keyHeaders), { skipHeader: false });
    }
    XLSX.utils.sheet_add_aoa(worksheet, [displayhdrs]);
    this.fixWidth(worksheet);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    return workbook;
  }

  formatColumn(worksheet: XLSX.WorkSheet, col: number, fmt: string, style: string = 'n') {
    const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col });
      if (worksheet[ref] && worksheet[ref].t === style) {
        worksheet[ref].z = fmt;
        if (col == 0) worksheet[ref].v = worksheet[ref].v + 1;
      }
    }
  }

  public exportHTMLTableToExcel(table: HTMLElement, excelFileName: string) {
    let wb = XLSX.utils.table_to_book(table, <XLSX.Table2SheetOpts>{ sheet: "ExportResult" });
    XLSX.writeFile(wb, `${excelFileName}.xlsx`);
  }

  private formatTableData(data: object[], columns?: string[]): any[] {
    let table: any[] = [];
    let tempRow: any;
    if (columns) {
      if (isArray(data)) {
        data.forEach((row: any) => {
          tempRow = {};

          columns.forEach(x => {
            if (x) {
              let isBoolean: boolean = row[x] == 'true' || row[x] == 'false';
              tempRow[x] = isBoolean ? (row[x] == 'true' ? 'Yes' : 'No') : row[x];
            }
          });

          table.push(tempRow);
        });
      }
      else {
        tempRow = {};
        table.push(tempRow);
        table.push(tempRow);
        columns.forEach(x => {
          if (x) {
            let isBoolean: boolean = data[x] == 'true' || data[x] == 'false';
            tempRow[x] = isBoolean ? (data[x] == 'true' ? 'Yes' : 'No') : data[x];
          }
        });
        table.push(tempRow);
      }
      return table;
    }
    return data;
  }

  transformAccountNumber(account: string): string {
    return this.accountpipe.transform(account);
  }

  transformDate(date: string) {
    try {
      let pledgeDate = new Date(date);
      let formatted = this.datepipe.transform(pledgeDate, 'MM/dd/yyyy');
      return formatted;
    } catch (error) {
      return date;
    }
  }
  transformCurrency(amount: number) {
    try {
      let formatted = this.currencypipe.transform(amount, 'USD');
      return formatted;

    } catch (error) {
      return amount;
    }
  }
}