import { Injectable } from '@angular/core';
import { Profile } from 'src/app/shared/models/profile';
import { catchError, map, throwError } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AgencyInformationByPIN } from 'src/app/shared/models/AgencyInformationByPIN.model';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AgencyPledges } from '../model/agencypledges.model';
import { AgencypledgesApiService } from './agencypledges-api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { isArray } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AgencypledgesManagerService {
  agencyPledgeList: any;
  agencySummary: any;
  userProfile: Profile[];
  currentUser: any;
  constructor(
    private agencyPledgeApiService: AgencypledgesApiService,
    private profileManager: ProfileManagerService,
    private _authService: AuthService
  ) {
    this.agencyPledgeList = [];
    this.userProfile = this.profileManager.profile;

    this._authService.getUser().then((user) => {
      this.currentUser = user;
    });
  }

  getAgencyPledgeList(agencyPledgeRequest: any) {
    return this.agencyPledgeApiService
      .getAgencyPledges(agencyPledgeRequest)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Agency Pledges cannot be retrieved',
              400,
              null,
              null,
              null
            );
          }

          const agencyPledges = res.data?.pledgesByAgency;
          this.agencyPledgeList = [];

          if (agencyPledges instanceof Array) {
            agencyPledges.forEach((data: any, index: number) => {
              this.agencyPledgeList.push(new AgencyPledges(data, index));
            });
          }

          return this.agencyPledgeList;
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  getUserSummary() {
    let emailAddress =
      this.userProfile[0] && isArray(this.userProfile[0].email)
        ? this.userProfile[0].email[0]
        : this.userProfile[0].email;
    return this.agencyPledgeApiService.getUserSummary(emailAddress).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'User Summary cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }

        const userSummary = res.data;
        this.agencySummary = [];

        if (userSummary instanceof Array) {
          userSummary.forEach((data: any, index: number) => {
            this.agencySummary.push(
              new AgencyInformationByPIN(
                data.agencyID,
                data.agencyName,
                data.state,
                data.pinNumber,
                data.isValidPinNumber,
                data.pinNumberAllowsUpdates,
                data.operatingState,
                data.agencyType,
                data.agencyCSSCode,
                data.isEFTAllowed,
                data.address.addressLines[0],
                data.address.city,
                data.address.state,
                data.address.zip
              )
            );
          });
        }

        return this.agencySummary;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  submitEFTPayment(eftPaymentPledgeDetails: any) {
    return this.agencyPledgeApiService
      .submitEFTPayment(eftPaymentPledgeDetails)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        }),
        map((res: any) => {
          if (!res.success) {
            throw new AmerenError(
              'EFT Payment has failed',
              500,
              null,
              null,
              null
            );
          }

          return res;
        })
      );
  }
}
