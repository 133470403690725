import * as _ from 'lodash';

export class Agency {
    agencyID?: string
    agencyName?: string;
    agencyAddressLines?: any[];
    agencyCity?: string;
    agencyState?: string;
    agencyZip?: string;
    agencyAddress?: string;
    isFirstUserForAgency: boolean;
    
    constructor(
        agency?: any
    ) {
        this.isFirstUserForAgency = !_.isNil(agency.isFirstUserForAgency) ? agency.isFirstUserForAgency: false;
        this.agencyID = !_.isNil(agency.AgencyID) ? agency.AgencyID: '';
        this.agencyName = !_.isNil(agency.agencyName) ? agency.agencyName : '';
        this.agencyCity = !_.isNil(agency.agencyAddress.city) ? agency.agencyAddress.city : '';
        this.agencyState = !_.isNil(agency.agencyAddress.state) ? agency.agencyAddress.state : '';
        this.agencyZip = !_.isNil(agency.agencyAddress.zip) ? agency.agencyAddress.zip: '';
        this.agencyAddress = (agency.agencyAddress.addressLines && agency.agencyAddress.addressLines.length > 0) ? agency.agencyAddress.addressLines.join() : '';
        this.agencyAddress = this.agencyAddress + ", " + this.agencyCity + ", " + this.agencyState + " " + this.agencyZip;
    }
}