export class DisconnectCorrespondence{
    correspondenceDate: Date;        
    letterTypeDescription: string;
    pdfLink: string;

    constructor(correspondenceDate: Date, letterTypeDescription: string, pdfLink: string){
        this.correspondenceDate=correspondenceDate;        
        this.letterTypeDescription=letterTypeDescription;
        this.pdfLink=pdfLink;
    }
}