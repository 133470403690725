<div
  class="addadditionalagency_container"
  [formGroup]="addAdditionalAgencyForm"
>
  <div class="addadditionalagency__title">Add Additional Agency</div>
  <div class="addadditionalagency__body">
    <input
      id="agencypin"
      name="agencypin"
      [(ngModel)]="agencypin"
      formControlName="agencypin"
      class="text-agencypin"
      type="text"
      placeholder="Enter Agency Pin"
      minlength="5"
      maxlength="5"
      pattern="^[0-9]*$"
      (keypress)="keyPressNumbers($event)"
      (keyup)="getAgencyDetails()"
    />

    <button
      type="button"
      class="btn btn-submit addadditionalagency__body_submit"
      [disabled]="disableSubmitButton"
      (click)="addAdditionalAgency()"
    >
      Submit
    </button>
    <div class="addadditionalagency__details" *ngIf="selectedAgency">
      <p>
        <span class="addadditionalagency__name">{{
          selectedAgency.agencyName
        }}</span>
        {{ selectedAgency.agencyAddress }}
      </p>
    </div>
    <div *ngIf="isLoading" class="addadditionalagency__loading">
      <ameren-loading id="loadingIndicator"></ameren-loading>
      Please wait...
    </div>
    <div id="divAlert" class="agencypin-alert" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <div
      *ngIf="availableAgencies && availableAgencies.length > 1"
      class="addadditionalagency__agencylist"
    >
      <div *ngFor="let aa of availableAgencies">
        <div class="addadditionalagency__agencylistdetails">
          <ul class="addadditionalagency__agencylistdetails_inner">
            <li>
              <a
                [routerLink]=""
                (click)="selectAgency($event)"
                [attr.data-agencyid]="aa.agencyID"
                ><span class="addadditionalagency__agencylistname">{{
                  aa.agencyName
                }}</span></a
              >
            </li>
            <li>{{ aa.agencyAddress }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="addAgencyModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!isLoading">
        <div
          class="ameren__modal__success-title"
          *ngIf="!isLoading && !isAPIFailure && !isInvalidData"
        >
          Successfully Submitted!
        </div>
        <div
          class="ameren__modal__failure-title"
          *ngIf="!isLoading && (isAPIFailure || isInvalidData)"
        >
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isLoading" class="text-center">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image">
          <img
            *ngIf="!isInvalidData && !isAPIFailure && !isLoading"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="(isAPIFailure || isInvalidData) && !isLoading"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!isLoading">
          <div
            class="ameren__modal-message"
            *ngIf="!isLoading && isInvalidData"
          >
            The user is already part of the requested agency. Please validate
            the entered agency.
          </div>
          <div
            class="ameren__modal-message"
            *ngIf="!isLoading && !isAPIFailure && !isInvalidData"
          >
            Your additional agency request has been successfully submitted.
            Please logout and then, login back to see the added agency.

            <br />
          </div>
          <div
            id="logoutBtn"
            class="ameren__modal-message"
            *ngIf="!isLoading && !isAPIFailure && !isInvalidData"
          >
            <button
              class="btn btn-success d-grid mt-2 gap-2 col-3 mx-auto rounded"
              (click)="logout()"
            >
              Logout
            </button>
          </div>
          <div
            class="ameren__modal-message"
            *ngIf="!isLoading && !isInvalidData && isAPIFailure"
          >
            Add additional agency request has not been submitted successfully.
            Please try again to submit your request.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- confirmation modal -->
<div
  id="addAgencyModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="agencyReqModal"
  tabindex="-1"
>
  <div class="modal-dialog text-center">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="ameren__modal__success-title">Confirm Your Changes</div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <span>You have added a new Agency.</span>
          <span>Do you want to save these changes?</span>
        </div>
        <div class="row mt-4">
          <div class="col-md-4"></div>

          <div class="col-md-2">
            <button
              class="btn btn-secondary create_campaign_button"
              id="confirmNo"
              value="No"
            >
              No
            </button>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-success create_campaign_button"
              id="confirmYes"
              value="Yes"
            >
              Yes
            </button>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</div>
