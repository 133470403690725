import * as _ from 'lodash';

export class BudgetBilling {
    actualUsedAmount: number;
    aheadBehindCode: string;
    billAccountNumber: number;
    billDate: string;
    billRelativeMonth: number;
    budgetBillAmount: number;
    budgetBillMonth: string;
    deferredAmount: number;
    planDescription: string;
    updateTypeDescription: string;

    constructor(actualUsedAmount: number,
        aheadBehindCode: string,
        billAccountNumber: number,
        billDate: string,
        billRelativeMonth: number,
        budgetBillAmount: number,
        budgetBillMonth: string,
        deferredAmount: number,
        planDescription: string,
        updateTypeDescription: string) {
            this.actualUsedAmount = !_.isNil(actualUsedAmount) ? actualUsedAmount : 0;
            this.aheadBehindCode = !_.isNil(aheadBehindCode) ? aheadBehindCode : '';
            this.billAccountNumber = !_.isNil(billAccountNumber) ? billAccountNumber : 0;
            this.billDate = !_.isNil(billDate) ? billDate : '';
            this.billRelativeMonth = !_.isNil(billRelativeMonth) ? billRelativeMonth : 0;
            this.budgetBillAmount = !_.isNil(budgetBillAmount) ? budgetBillAmount : 0;
            this.budgetBillMonth = !_.isNil(budgetBillMonth) ? budgetBillMonth : '';
            this.deferredAmount = !_.isNil(deferredAmount) ? deferredAmount : 0;
            this.planDescription = !_.isNil(planDescription) ? planDescription : '';
            this.updateTypeDescription = !_.isNil(updateTypeDescription) ? updateTypeDescription : '';
    }
}