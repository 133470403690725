import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageprofileApiService {  
  
  constructor(private http:HttpClient) {}
  
  retrieveAgencybyID(agencyID: string){
    const headers = new HttpHeaders()     
      .set('amrnenv',`${environment.cssEnv}`)
      .set('vpcenv',`${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('agencyid',agencyID);     
      
      return this.http.get<IGlobalAPIResponse>(`${environment.apig}/agencybyid`, {headers});   
  }
}
