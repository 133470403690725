import { map, catchError, throwError } from 'rxjs';
import { AmerenError } from './../../../shared/models/amerenError';
import { PreferencesApiService } from './preferences-api.service';
import { IGlobalAPIResponse } from './../../../shared/interfaces/iglobal-api-response';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PreferencesManagerService {

  constructor(private preferenceSvc: PreferencesApiService) { }

  getPreferenceList(billAccount: string) {
    return this.preferenceSvc.getPreferenceList(billAccount).pipe(
      map((res: IGlobalAPIResponse) => {
        if (_.isNil(res) || !res.success) {
          throw new AmerenError(
            'Getting Preferences list has failed',
            500,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  updatePreferenceList(preference: any,billAccount: string) {
    return this.preferenceSvc.updatePreference(preference,billAccount).pipe(
      map((res: any) => {
        if (_.isNil(res) || !res.success) {
          throw new AmerenError(
            'Getting Preferences list has failed',
            500,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  addEmail(billAccount: string, email: string) {
    
    var data = {
      "contact": email,
      "isVerified": false,
      "isNotPromotional": false,
      "isPrimary": false,
      "addAlerts": true
    }
    return this.preferenceSvc.addEmail(billAccount, data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (_.isNil(res) || !res.success) {
          throw new AmerenError(
            'Add Email has failed',
            500,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  addPhone(billAccount: string, phone: string) {
    
    var data = {
      "phoneTypeCode": 1,
      "isPrimary": true,
      "isUnlisted": false,
      "isTextable": true,
      "areaCode": phone.substring(0,3),
      "mainNumber": phone.substring(3,10),
      "extension": "",
      "TransferAlerts": false,
      "optIn": false
    }
    return this.preferenceSvc.addPhone(billAccount, data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (_.isNil(res) || !res.success) {
          throw new AmerenError(
            'Add phone has failed',
            500,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
