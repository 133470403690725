import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';

@Injectable({
  providedIn: 'root'
})
export class AdministrationApiService {
  constructor(private http: HttpClient) { }

  getEAPUsersList() {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)

    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/users`, { headers });
  }

  submitEAPUserStatus(userStatus: any) {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)
    
    const body = userStatus;

    return this.http.patch<IGlobalAPIResponse>(`${environment.apig}/role`, body, { headers });
  }

  unlockUser(userDetails: any) {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)
    
    const body = userDetails;

    return this.http.patch<IGlobalAPIResponse>(`${environment.apig}/unlockuser`, body, { headers });
  }

  getAgencyInformationById(agencyId: any) {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)
    .set('agencyId', agencyId);

    return this.http.get<any>(`${environment.apig}/agencybyid`, { headers });
  }

  getAgencyInformationByPin(agencyPin: any) {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)
    .set('pin', agencyPin);

    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/agencybypin`, { headers });
  }

  sendEmailNotification(emailBody: any) {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)
    
    const body = emailBody;

    return this.http.post<IGlobalAPIResponse>(`${environment.apig}/sendemail`, body, { headers });
  }

  getUserMessages(userId: any) {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`)
    .set('userId', userId);

    return this.http.get<any>(`${environment.apig}/usermessages?userId=${userId}`, { headers });
  }

  createUserMessage(messageBody: any){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`);

    return this.http.post<any>(`${environment.apig}/usermessage`,messageBody, { headers });
  }

  updateUserMessage(messageBody: any){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`);

    return this.http.put<any>(`${environment.apig}/usermessage?messageId=${messageBody.id}`,messageBody, { headers });
  }

  deleteUserMessage(id: string){
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-modified-by-user-id', 'EAP')
    .set('amrnenv', `${environment.cssEnv}`)
    .set('domain', 'private')
    .set('vpcenv', `${environment.environmentName}`);

    return this.http.delete<any>(`${environment.apig}/usermessage?messageId=${id}`, { headers });

  }

}