import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumRole } from 'src/app/shared/enums/role';
import { Profile } from 'src/app/shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import {
  EnumSplunkLevels,
  EnumSplunkSourceType,
} from 'src/app/shared/services/splunk-api.service';
import { SplunkManager } from 'src/app/shared/services/splunk-manager.service';

@Component({
  selector: 'app-signin-redirect-callback',
  templateUrl: './signin-redirect-callback.component.html',
  styleUrls: ['./signin-redirect-callback.component.scss'],
})
export class SigninRedirectCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private profileManager: ProfileManagerService,
    private splunkManager: SplunkManager
  ) {}

  ngOnInit() {
    this.authService.LoginComplete().then(
      (user) => {
        this.profileManager.profile.forEach((a: Profile) => {
          let loginMessage =
            'User ' + a.email + ' has logged in the portal successfully';

          this.submitSplunk(
            EnumSplunkLevels.INFO,
            EnumSplunkSourceType.EAPUILOGS,
            loginMessage
          );

          if (a && a.agencyList?.length != 0 && !a.isUserWithMultiAgency) {
            a.agencyList?.forEach((a) => {
              if (
                a.role === EnumRole.EAPAMERENADMIN ||
                a.role === EnumRole.EAPAMERENSUPPORT
              ) {
                this.router.navigateByUrl('/administration');
              }
              //a.role === EnumRole.EAPAGENCYADMIN
              else if (a.role === EnumRole.EAPAGENCYADMIN) {
                this.router.navigateByUrl('/agency-administration');
              } else if (
                a.role === EnumRole.EAPREAD ||
                a.role === EnumRole.EAPUPDATE
              ) {
                this.router.navigateByUrl('/select-agency');
              } else {
                this.router.navigateByUrl('/ineligible-user');
              }
            });
          } else if (a.isUserWithMultiAgency) {
            this.router.navigateByUrl('/select-agency');
          } else {
            this.router.navigateByUrl('/ineligible-user');
          }
        });
      },
      (error: any) => {
        this.authService.Login();
      }
    );
  }

  submitSplunk(
    level: EnumSplunkLevels,
    sourceType: EnumSplunkSourceType,
    message: any,
    elapsed?: number,
    method?: string,
    apiName?: string
  ) {
    this.splunkManager
      .logSplunk(level, sourceType, message, elapsed, method, apiName)
      .subscribe({
        next: (data: any) => {},
        error: (error) => {
          console.log('error', error);
        },
      });
  }
}
