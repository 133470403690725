import { Injectable } from '@angular/core';
import { map, catchError, throwError } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { CampaignsApiService } from './campaigns-api.service';
import * as _ from 'lodash';
import { AgencyCampaign } from '../model/campaignbyagency.model';
import {
  AgencyAllocationModel,
  CampaignDetailsModel,
  ParticipatingAgenciesModel,
  PledgeAmountModel,
  RemainingAmountModel,
} from '../model/campaignbycampaignid.model';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { Profile } from 'src/app/shared/models/profile';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AgenciesByState } from '../model/agenciesbystate.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignsManagerService {
  campaignByAgency: any[] = [];
  campaignByState: any[] = [];
  userProfile: Profile;
  currentUser: any;
  stateCode: string;
  agencyListByState: any[] = [];

  constructor(
    private campaignApiService: CampaignsApiService,
    private profileManager: ProfileManagerService,
    private _authService: AuthService
  ) {
    this.profileManager.profile.forEach((a: Profile) => {
      this.userProfile = a;
    });

    this._authService.getUser().then((user) => {
      this.currentUser = user?.profile;
      this.stateCode = this.currentUser && this.currentUser.stateProvince;
    });
  }

  getCampaignByAgency(agencycsscode: any) {
    return this.campaignApiService.getCampaignByAgency(agencycsscode).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Getting campaign list by agencyhas failed',
            400,
            null,
            null,
            null
          );
        }

        const campaignAgencyList = res.data;
        this.campaignByAgency = [];

        if (campaignAgencyList instanceof Array) {
          campaignAgencyList.forEach((data: any) => {
            this.campaignByAgency.push(new AgencyCampaign(data));
          });
        }

        return this.campaignByAgency;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getCampaignByCampaignId(campaignid: any) {
    return this.campaignApiService.getCampaignByCampaignId(campaignid).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Getting campaign list by agencyhas failed',
            400,
            null,
            null,
            null
          );
        }

        return this.handleCampaignDetails(res.data[0]);
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getCampaignByState() {
    const statecode: any = this.currentUser.stateProvince;
    return this.campaignApiService.getCampaignByState(statecode).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Getting campaign list by agencyhas failed',
            400,
            null,
            null,
            null
          );
        }

        const campaignAgencyList = res.data;
        this.campaignByState = [];

        if (campaignAgencyList instanceof Array) {
          campaignAgencyList.forEach((data: any) => {
            this.campaignByState.push(new AgencyCampaign(data));
          });
        }

        return this.campaignByState;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  updateCampaign(campaignDetails: any) {
    return this.campaignApiService.updateCampaign(campaignDetails).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Update campaign has failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      })
    );
  }

  addCampaign(campaignDetails: any) {
    return this.campaignApiService.addCampaign(campaignDetails).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Add Campaign has failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      })
    );
  }

  deleteCampaign(campaignId: any) {
    return this.campaignApiService.deleteCampaign(campaignId).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Delete Campaign has failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      })
    );
  }

  deleteCampaignByAgency(campaignagencyid: any) {
    return this.campaignApiService
      .deleteCampaignByAgency(campaignagencyid)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        }),
        map((res: any) => {
          if (!res.success) {
            throw new AmerenError(
              'Delete Campaign by Agency has failed',
              500,
              null,
              null,
              null
            );
          }

          return res;
        })
      );
  }

  handleCampaignDetails(res: CampaignDetailsModel) {
    const campaignDetails = new CampaignDetailsModel();
    campaignDetails.campaignAgencies = new Array<ParticipatingAgenciesModel>();

    campaignDetails.campaignId = res.campaignId;
    campaignDetails.campaignName = res.campaignName;
    campaignDetails.state = res.state;
    campaignDetails.pledgeCode = res.pledgeCode;
    campaignDetails.pledgeDecode = res.pledgeDecode;
    campaignDetails.campaignAmount = res.campaignAmount;
    campaignDetails.adminFeePercent = res.adminFeePercent || 0;
    campaignDetails.maximumPledgeAmount = res.maximumPledgeAmount;
    campaignDetails.minimumPledgeAmount = res.minimumPledgeAmount;
    campaignDetails.fixedPledgeAmount = res.fixedPledgeAmount;
    campaignDetails.isFixedIndividualPledge = res.isFixedIndividualPledge;
    campaignDetails.startDate = res.startDate;
    campaignDetails.endDate = res.endDate;
    campaignDetails.pledgeAmount = res.pledgeAmount;
    campaignDetails.timelinePercentComplete = res.timelinePercentComplete;
    campaignDetails.financialPercentComplete = res.financialPercentComplete;
    campaignDetails.financialPercentComplete = res.financialPercentComplete;
    campaignDetails.campaignAgencies = res.campaignAgencies;

    return campaignDetails;
  }

  getAgenciesByState() {
    return this.campaignApiService.getAgenciesByState(this.stateCode).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'DGet Agencies By State has failed',
            500,
            null,
            null,
            null
          );
        }

        const agencyList = res.data;
        this.agencyListByState = [];

        if (agencyList instanceof Array) {
          agencyList.forEach((data: any) => {
            this.agencyListByState.push(new AgenciesByState(data));
          });
        }

        return this.agencyListByState;
      })
    );
  }
}
