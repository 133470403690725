import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client.service';
import { environment } from 'src/environments/environment';
import { DisconnectCorrespondence } from '../model/disconnect-correspondence.model';

@Injectable({
  providedIn: 'root',
})
export class DisconnectHistoryApiService {
  selectedClient: Client;
  constructor(private http: HttpClient, private clientService: ClientService) {
    this.selectedClient = this.clientService.getSelectedClient();
  }

  getCorrespondenceByBillAccount(billAccount: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private');

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/correspondencebillaccount?billAccountNumber=${billAccount}`,
      { headers }
    );
  }

  getCorrespondencePdf(billaccount: string, pdfData: DisconnectCorrespondence) {
    const guid = pdfData.pdfLink.replace('/documents/', '');
    const billAccount = billaccount.toString();
    const filename = `${billAccount}_PinkDisconnectNotice_${pdfData.correspondenceDate}`;

    const headers = new HttpHeaders()
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('billaccountnumber', billAccount)
      .set('fileName', filename);

    return this.http.get(`${environment.apig}/report/${guid}`, {
      headers: headers,
      responseType: 'blob',
    });
  }

  getDisconnectPDF(billaccount: string, pdfData: DisconnectCorrespondence) {
    const guid = pdfData.pdfLink.replace('/documents/', '');
    const billAccount = billaccount.toString();
    const filename = `${billAccount}_PinkDisconnectNotice_${pdfData.correspondenceDate}`;

    const headers = new HttpHeaders()
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('billaccountnumber', billAccount)
      .set('fileName', filename)
      .set('realm', 'partner');

    return this.http.get(
      `${environment.openIGurl}/eap-support-e/v2/documents/${guid}`,
      { headers, responseType: 'blob' }
    );
  }
}
