<div class="back-align back-nav" (click)="onBackClick()">
  <svg
    width="22px"
    height="19px"
    viewBox="0 0 22 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>🎨 Icon Сolor</title>
    <g
      id="New-Login-/-Registration"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Register-Account---validation"
        transform="translate(-62.000000, -156.000000)"
        fill="#1B6CB5"
        fill-rule="nonzero"
      >
        <path
          d="M82.6428483,164.142787 L66.5402438,164.142787 L71.4707758,158.225605 C71.9512075,157.650173 71.8724927,156.79381 71.2970604,156.314736 C70.7202709,155.834304 69.8652654,155.913019 69.3861908,156.488451 L62.6004325,164.631361 C62.5475036,164.695147 62.5203605,164.769791 62.4810031,164.840363 C62.4484315,164.897363 62.4090741,164.94622 62.3846454,165.008649 C62.3235735,165.164722 62.2869304,165.328937 62.2869304,165.49451 C62.2869304,165.495867 62.2855733,165.498581 62.2855733,165.499938 C62.2855733,165.501295 62.2869304,165.50401 62.2869304,165.505367 C62.2869304,165.670939 62.3235735,165.835155 62.3846454,165.991227 C62.4090741,166.053656 62.4484315,166.102514 62.4810031,166.159514 C62.5203605,166.230086 62.5475036,166.304729 62.6004325,166.368515 L69.3861908,174.511425 C69.6549069,174.83307 70.0403379,175 70.4284833,175 C70.7351996,175 71.043273,174.896856 71.2970604,174.685141 C71.8724927,174.206066 71.9512075,173.349704 71.4707758,172.774271 L66.5402438,166.85709 L82.6428483,166.85709 C83.3919961,166.85709 84,166.249086 84,165.499938 C84,164.750791 83.3919961,164.142787 82.6428483,164.142787"
          id="🎨-Icon-Сolor"
        ></path>
      </g>
    </g>
  </svg>
  <span class="back-nav">Back</span>
</div>
<form [formGroup]="registrationForm">
  <div class="eap_registration__container">
    <div class="row">
      <div class="eap_registration_title">
        <div class="back-m-nav" (click)="onBackClick()">
          <svg
            width="22px"
            height="19px"
            viewBox="0 0 22 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>🎨 Icon Сolor</title>
            <g
              id="New-Login-/-Registration"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Register-Account---validation"
                transform="translate(-62.000000, -156.000000)"
                fill="#1B6CB5"
                fill-rule="nonzero"
              >
                <path
                  d="M82.6428483,164.142787 L66.5402438,164.142787 L71.4707758,158.225605 C71.9512075,157.650173 71.8724927,156.79381 71.2970604,156.314736 C70.7202709,155.834304 69.8652654,155.913019 69.3861908,156.488451 L62.6004325,164.631361 C62.5475036,164.695147 62.5203605,164.769791 62.4810031,164.840363 C62.4484315,164.897363 62.4090741,164.94622 62.3846454,165.008649 C62.3235735,165.164722 62.2869304,165.328937 62.2869304,165.49451 C62.2869304,165.495867 62.2855733,165.498581 62.2855733,165.499938 C62.2855733,165.501295 62.2869304,165.50401 62.2869304,165.505367 C62.2869304,165.670939 62.3235735,165.835155 62.3846454,165.991227 C62.4090741,166.053656 62.4484315,166.102514 62.4810031,166.159514 C62.5203605,166.230086 62.5475036,166.304729 62.6004325,166.368515 L69.3861908,174.511425 C69.6549069,174.83307 70.0403379,175 70.4284833,175 C70.7351996,175 71.043273,174.896856 71.2970604,174.685141 C71.8724927,174.206066 71.9512075,173.349704 71.4707758,172.774271 L66.5402438,166.85709 L82.6428483,166.85709 C83.3919961,166.85709 84,166.249086 84,165.499938 C84,164.750791 83.3919961,164.142787 82.6428483,164.142787"
                  id="🎨-Icon-Сolor"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="eap_registration_align">New User Registration</div>
      </div>
      <P class="personal-info">Personal Information</P>

      <div class="form-group">
        <label for="text-input"
          >First Name
          <span class="required-asterisk">*</span>
        </label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          formControlName="firstname"
          required
          minlength="1"
          maxlength="50"
          class="form-control form-control-sm"
          pattern="[a-zA-Z ]*"
          [class.is-invalid]="
            firstname.invalid && (firstname.dirty || firstname.touched)
          "
        />
        <div
          *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="firstname.errors?.['required']">
            This field is required.
          </div>
          <div *ngIf="firstname.errors?.['minlength']">
            This field must have at least 1 character.
          </div>
          <div *ngIf="firstname.errors?.['maxlength']">
            This field must have at most 50 characters.
          </div>
        </div>

        <label for="text-input1"
          >Last Name
          <span class="required-asterisk">*</span>
        </label>

        <input
          type="text"
          id="lastname"
          name="lastname"
          formControlName="lastname"
          required
          minlength="1"
          maxlength="50"
          class="form-control form-control-sm"
          pattern="[a-zA-Z ]*"
          [class.is-invalid]="
            lastname.invalid && (lastname.dirty || lastname.touched)
          "
        />
        <div
          *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="lastname.errors?.['required']">
            This field is required.
          </div>
          <div *ngIf="lastname.errors?.['minlength']">
            This field must have at least 1 character.
          </div>
          <div *ngIf="lastname.errors?.['maxlength']">
            This field must have at most 50 characters.
          </div>
        </div>
      </div>

      <div class="spacing-box">
        <p class="perosnal-info">Contact Information</p>
        <label for="text-input"
          >Email Address
          <span class="required-asterisk">*</span>
        </label>

        <input
          type="email"
          id="email"
          name="email"
          formControlName="email"
          required
          minlength="1"
          maxlength="250"
          class="form-control form-control-sm"
          [class.is-invalid]="email.invalid && (email.dirty || email.touched)"
        />
        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="email.errors?.['required']">This field is required.</div>
          <div *ngIf="email.errors?.['minlength']">
            This field must have at least 1 character.
          </div>
          <div *ngIf="email.errors?.['maxlength']">
            This field must have at most 250 characters.
          </div>
          <div
            *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']"
          >
            Invalid email format.
          </div>
        </div>

        <label for="text-input"
          >Confirm Email Address
          <span class="required-asterisk">*</span>
        </label>
        <input
          type="confirmemail"
          id="confirmemail"
          name="confirmemail"
          formControlName="confirmemail"
          (blur)="emailmatch()"
          required
          minlength="1"
          maxlength="250"
          class="form-control form-control-sm"
          [class.is-invalid]="
            confirmemail.invalid && (confirmemail.dirty || confirmemail.touched)
          "
        />
        <div
          *ngIf="
            confirmemail.invalid && (confirmemail.dirty || confirmemail.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="confirmemail.errors?.['required']">
            This field is required.
          </div>
          <div *ngIf="confirmemail.errors?.['minlength']">
            This field must have at least 1 character.
          </div>
          <div *ngIf="confirmemail.errors?.['maxlength']">
            This field must have at most 250 characters.
          </div>
          <div
            *ngIf="!confirmemail.errors?.['required'] && !confirmemail.errors?.['minlength'] && !confirmemail.errors?.['maxlength'] && confirmemail.errors?.['emailValidator']"
          >
            Invalid email format.
          </div>
        </div>
        <div class="displayerror" *ngIf="mailmatch == true">
          This email address does not match.
        </div>

        <label for="text-input">Phone Number </label>

        <input
          type="text"
          id="phonenumber"
          name="phonenumber"
          formControlName="phonenumber"
          maxlength="14"
          class="form-control form-control-sm"
          (input)="validatePhoneNo($event.target)"
        />
        <div class="displayerror" *ngIf="!isPhoneNumberValidFlg">
          Phone Number must have at least 10 numbers.
        </div>

        <label for="text-input"
          >Agency Pin
          <span class="required-asterisk">*</span>
        </label>

        <input
          numbersOnly
          type="text"
          id="agencypin"
          name="agencypin"
          formControlName="agencypin"
          required
          minlength="5"
          maxlength="5"
          class="form-control form-control-sm"
          pattern="^[0-9]{5}$"
          (keyup)="validatePin()"
          [class.is-invalid]="
            agencypin.invalid && (agencypin.dirty || agencypin.touched)
          "
        />
        <div
          *ngIf="agencypin.invalid && (agencypin.dirty || agencypin.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="agencypin.errors?.['required']">
            This field is required.
          </div>
          <div *ngIf="agencypin.errors?.['pattern']">
            This field must be 5 digits.
          </div>
          <div *ngIf="agencypin.errors?.['invalidPin']">
            {{agencypin.errors?.['invalidPin']}}
          </div>
        </div>
        <div
          class="agency_search_results_container"
          *ngIf="
            agencySearchResults && agencySearchResults.length && !selectedAgency
          "
        >
          <ul class="agency_search_results">
            <li
              class="agency_search_result"
              *ngFor="let agency of agencySearchResults"
              (click)="selectAgency(agency)"
            >
              <div class="agency_shortName_container">
                <div class="agency_search_results_shortName">
                  {{ agency.shortName }}
                </div>
              </div>

              <div class="agency_search_results_demographic_info_container">
                <ul class="agency_search_results_demographic_info">
                  <li class="agency_search_results_agency_name">
                    {{ agency.agencyName }}
                  </li>
                  <li>
                    {{ agency.address }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="selected_agency" *ngIf="selectedAgency">
          <ul class="selected_agency_demographic_info">
            <li class="selected_agency__agency_name">
              {{ selectedAgency.agencyName }}
            </li>
            <li>
              {{ selectedAgency.address }}
            </li>
          </ul>
        </div>
        <ameren-loading
          *ngIf="isPinSearchLoading"
          class="d-flex justify-content-center"
        ></ameren-loading>
        <label for="text-input"
          >State
          <span class="required-asterisk">*</span>
        </label>
        <ameren-dropdown
          id="stateprovince"
          name="stateprovince"
          [options]="states"
          value="abbreviation"
          text="name"
          frmControlName="stateprovince"
          (click)="dpChange()"
        >
        </ameren-dropdown>
      </div>

      <div class="password-info">
        <p class="perosnal-info">Password</p>
        <div>
          <label for="text-input"
            >Create Password
            <span class="required-asterisk">*</span>
          </label>
          <div class="form-group inner-addon right-addon">
            <div class="input-group">
              <input
                name="password"
                [type]="showPassword ? 'text' : 'password'"
                class="form-control pwd-control"
                id="password-field"
                formControlName="password"
                required
                minlength="8"
                maxlength="250"
                (keyup)="passwordValidate()"
                [class.is-invalid]="
                  password.invalid && (password.dirty || password.touched)
                "
              />
              <div>
                <em
                  alt="show"
                  class="far fa-eye eye-show"
                  (click)="showPassword = !showPassword"
                  [class.hide]="showPassword"
                ></em>
                <em
                  alt="hide"
                  class="far fa-eye-slash eye-hide"
                  (click)="showPassword = !showPassword"
                  [class.hide]="!showPassword"
                ></em>
              </div>
              <div class="invalid-feedback" *ngFor="let i of pwdErrors">
                <div *ngIf="i.LetterAndNumberAndSpecialChar">
                  Include a number, a letter and a special character (e.g. @!#)
                </div>
                <div *ngIf="i.NoLeadingTrailingSpaces">
                  Cannot begin or end with a space.
                </div>
                <div *ngIf="i.RepeatingCharacters">
                  Cannot have more than 2 repeating characters (e.g. aaa)
                </div>
                <div *ngIf="i.NoControlCharacters">
                  The password field must not contain whitespace other than a
                  space character.
                </div>
                <div *ngIf="i.NoTwoContinuousSpaces">
                  Cannot contain two continuous spaces.
                </div>

                <div *ngIf="i.InvalidCharacters">
                  Cannot contain the characters <> & -
                </div>
              </div>

              <div
                *ngIf="
                  (password.invalid && (password.dirty || password.touched)) ||
                  pwdErrors.length == 1
                "
                class="invalid-feedback"
              >
                <div *ngIf="password.errors?.['required']">
                  This field is required.
                </div>
                <div *ngIf="password.errors?.['minlength']">
                  This field must have at least 8 character.
                </div>
                <div *ngIf="password.errors?.['maxlength']">
                  This field must have at most 250 characters.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label for="text-input"
            >Confirm Password
            <span class="required-asterisk">*</span>
          </label>
          <div class="form-group inner-addon right-addon">
            <div class="input-group col-6 mx-auto">
              <input
                name="confirmpassword"
                [type]="showConfirmPassword ? 'text' : 'password'"
                class="form-control pwd-control"
                (blur)="passwordmatch()"
                required
                id="confirmPassword-field"
                formControlName="confirmpassword"
                [class.is-invalid]="
                  confirmpassword.invalid &&
                  (confirmpassword.dirty || confirmpassword.touched)
                "
              />
              <div>
                <em
                  alt="show"
                  class="far fa-eye eye-show"
                  (click)="showConfirmPassword = !showConfirmPassword"
                  [class.hide]="showConfirmPassword"
                ></em>
                <em
                  alt="hide"
                  class="far fa-eye-slash eye-hide"
                  (click)="showConfirmPassword = !showConfirmPassword"
                  [class.hide]="!showConfirmPassword"
                ></em>
              </div>
              <div
                *ngIf="
                  confirmpassword.invalid &&
                  (confirmpassword.dirty || confirmpassword.touched)
                "
                class="invalid-feedback"
              >
                <div *ngIf="confirmpassword.errors?.['required']">
                  Confirm Password is required.
                </div>
              </div>

              <div class="displayerror" *ngIf="pwdmatch == true">
                This given password does not match.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="terms-info">
    <div>
      <div class="form-check">
        <input
          class="form-check-input fs-5"
          type="checkbox"
          id="acceptPrivacy"
          name="acceptprivacy"
          formControlName="acceptprivacy"
          [class.is-invalid]="
            acceptprivacy.invalid &&
            (acceptprivacy.dirty || acceptprivacy.touched)
          "
        />
        <label
          class="form-check-label ameren__policy-description"
          for="acceptPrivacy"
        >
          I would like to receive information from Ameren as outlined in the
          Privacy Policy.
        </label>
      </div>
      <div class="help-block with-errors"></div>
      <br />
      <div class="form-check">
        <input
          type="checkbox"
          id="acceptTerms"
          name="acceptterms"
          formControlName="acceptterms"
          class="form-check-input fs-5"
          [class.is-invalid]="
            acceptterms.invalid && (acceptterms.dirty || acceptterms.touched)
          "
        />
        <label
          class="form-check-label ameren__policy-description"
          for="acceptTerms"
        >
          I have read and accept the
          <a
            href="https://www.ameren.com/privacy"
            rel="noopener"
            target="_blank"
            >Terms and Conditions</a
          >
          and I understand that I should keep my UserID and Password
          Confidential.
        </label>
        <div class="help-block with-errors"></div>
      </div>
    </div>
    <div class="recaptcha__foot-note">
      This site is protected by reCAPTCHA and the Google
      <a
        href="https://policies.google.com/privacy"
        rel="noopener"
        target="_blank"
        alt="Google Policy"
        >Privacy Policy</a
      >
      and
      <a
        alt="Google terms"
        rel="noopener"
        target="_blank"
        href="https://policies.google.com/privacy"
        >Terms of Service</a
      >
      apply
    </div>
    <ameren-loading
      *ngIf="isLoading"
      class="d-flex justify-content-center"
    ></ameren-loading>
    <div
      *ngIf="isAPIFailure"
      class="mt-3 alert text-center alert-danger"
      role="alert"
    >
      {{ integrationErrorMsg }}
    </div>

    <div class="text-center space-box-button">
      <button
        type="button"
        [disabled]="
          !registrationForm.valid ||
          mailmatch ||
          pwdmatch ||
          !this.isCheckboxSelected
        "
        (click)="verifyRecaptchaForRegistration()"
        class="registration__submit-btn"
      >
        Submit
      </button>
    </div>
    <br />
  </div>
</form>
