import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(list: any, args?: any): any {
    if (!list) return null;
    if (!args) return list;

    return list.filter((data: any) => {
      return (
        JSON.stringify(data).toLowerCase().includes(args.toLowerCase()) ||
        JSON.stringify(data).includes(args)
      );
    });
  }
}
