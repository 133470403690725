<div aria-live="polite" aria-atomic="true" class="campaign__details_snack">
  <div
    class="toast"
    [ngClass]="{
      show: showSnack,
      campaign_details_snack_error:
        deleteCampaignAgencyApiFailure || saveCampaignFailure,
      campaign_details_snack_success:
        deleteCampaignAgencyApiSuccess || saveCampaignSuccess
    }"
    class="campaign_details_snack_body"
  >
    <div class="toast-body">
      <span *ngIf="deleteCampaignAgencyApiSuccess">
        Campaign agency has been deleted successfully.
      </span>
      <span *ngIf="saveCampaignSuccess">
        Campaign details have been saved successfully.
      </span>
      <span *ngIf="deleteCampaignAgencyApiFailure || saveCampaignFailure">
        Something went wrong. Please try again.
      </span>
      <button
        class="btn-close"
        *ngIf="showSnack"
        (click)="closeSnack()"
      ></button>
    </div>
  </div>
</div>

<div id="campaign__details__main-container">
  <div
    *ngIf="getCampaignDetailsAPIFailure"
    class="mt-6 alert text-center alert-danger campaign__details__error-handling"
    role="alert"
  >
    <em class="fa fa-times-square"></em>
    {{ getCampaignDetailsErrorMessage }}
  </div>

  <div
    class="text-center campaign__details__error-handling"
    *ngIf="getCampaignDetailsLoading"
  >
    <ameren-loading class="d-flex p-10 justify-content-center"></ameren-loading>
    <span>Please wait...</span>
  </div>
  <form
    *ngIf="!getCampaignDetailsLoading && !getCampaignDetailsAPIFailure"
    [formGroup]="campaignDetailsForm"
  >
    <!-- Delete Campaign Start -->
    <div class="campaign__details__delete-container">
      <div class="row">
        <div class="delete__campaign my-4 col">
          <button
            *ngIf="isAmerenAdmin"
            class="btn btn-default delete__campaign-btn"
            (click)="onDeleteConfirmModal()"
          >
            <em class="fa-regular fa-trash-can"></em> Delete Campaign
          </button>
          <ameren-loading
            *ngIf="isLoading"
            class="d-flex justify-content-center"
          ></ameren-loading>
          <div *ngIf="isAPIFailure" class="mt-3 text-danger">
            <em class="fa fa-times-circle"></em>
            {{ integrationErrorMsg }}
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Campaign End-->

    <!-- Campaign Details Start -->
    <div class="campaign__details-container">
      <div class="row">
        <div class="campaign__details-title">Campaign Details</div>
      </div>

      <div class="card campaign__details-card">
        <div class="card-body">
          <div class="row">
            <div class="col p-5">
              <div class="form-group m-2">
                <div class="form-text" id="campaignid">
                  <label class="form-label" for="campaignid">Campaign Id</label>
                  <div class="form-text fs-5" id="campaignid">
                    {{ campaignDetails.campaignId }}
                  </div>
                </div>
              </div>
              <div class="form-group m-2">
                <label class="form-label" for="campaignName"
                  >Campaign Name</label
                >
                <input
                  formControlName="campaignName"
                  type="text"
                  class="form-control"
                  id="campaignName"
                />
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    campaignDetailsForm.get('campaignName')?.dirty &&
                    campaignDetailsForm.get('campaignName')?.invalid
                  "
                >
                  <p
                    *ngIf="campaignDetailsForm.get('campaignName')?.errors?.['required']"
                  >
                    Campaign Name is required.
                  </p>
                  <p
                    *ngIf="campaignDetailsForm.get('campaignName')?.errors?.['pattern']"
                  >
                    Valid Characters (A-Z, spaces and '-').
                  </p>
                </div>
              </div>
              <div class="form-group m-2">
                <label class="form-label" for="pledgeTypeCode"
                  >Pledge Type</label
                >
                <select
                  disabled
                  class="form-select"
                  aria-label="Select Pledge Type"
                  id="pledgeCode"
                >
                  <option selected [ngValue]="pledgeTypeCodeValue">
                    {{ pledgeTypeDecodeValue }}
                  </option>
                  <option
                    *ngFor="let pledgeType of pledgeTypes; let i = index"
                    [ngValue]="pledgeType.pledgeTypeCode"
                  >
                    {{ pledgeType.pledgeTypeDescription }}
                  </option>
                </select>
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    campaignDetailsForm?.get('pledgeCode')?.dirty &&
                    campaignDetailsForm?.get('pledgeCode')?.invalid
                  "
                >
                  <p
                    *ngIf="campaignDetailsForm?.get('pledgeCode')?.errors?.['required']"
                  >
                    PledgeType is required.
                  </p>
                </div>
              </div>
              <div class="form-group m-2">
                <label class="form-label" for="adminFee">Admin Fee(%)</label>
                <input
                  PercentagePipe="adminFeePercent"
                  formControlName="adminFeePercent"
                  type="number"
                  class="form-control"
                  id="adminFee"
                />
                <div
                  class="text-field-error ameren-font-sm"
                  *ngIf="
                    campaignDetailsForm?.get('adminFeePercent')?.dirty &&
                    campaignDetailsForm?.get('adminFeePercent')?.invalid
                  "
                >
                  <p
                    *ngIf="campaignDetailsForm?.get('adminFeePercent')?.errors?.['required']"
                  >
                    Admin Fee is required.
                  </p>
                  <p
                    *ngIf="campaignDetailsForm?.get('adminFeePercent')?.errors?.['pattern']"
                  >
                    Only number Characters.
                  </p>
                </div>
              </div>
            </div>
            <div class="col p-5">
              <div class="m-2">
                <div
                  class="form-group m-2 flex-fill"
                  *ngIf="campaignDetails.isFixedIndividualPledge === 'N'"
                >
                  <div class="row">
                    <div class="col">
                      <label class="form-label" for="minimumPledgeAmount"
                        >Minimum Pledge</label
                      >
                    </div>
                    <div class="col">
                      <label class="form-label" for="maximumPledgeAmount"
                        >Maximum Pledge</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <input
                        formControlName="minimumPledgeAmount"
                        type="number"
                        class="form-control"
                        id="minimumPledgeAmount"
                      />
                    </div>
                    <div class="col">
                      <input
                        formControlName="maximumPledgeAmount"
                        type="number"
                        class="form-control"
                        id="maximumPledgeAmount"
                      />
                    </div>
                  </div>
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      campaignDetailsForm?.get('minimumPledgeAmount')?.dirty &&
                      campaignDetailsForm?.get('minimumPledgeAmount')?.invalid
                    "
                  >
                    <p
                      *ngIf="campaignDetailsForm?.get('minimumPledgeAmount')?.errors?.['required']"
                    >
                      Minimum Pledge is required.
                    </p>
                    <p
                      *ngIf="campaignDetailsForm?.get('minimumPledgeAmount')?.errors?.['pattern']"
                    >
                      Only number Characters.
                    </p>
                  </div>
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      campaignDetailsForm?.get('maximumPledgeAmount')?.dirty &&
                      campaignDetailsForm?.get('maximumPledgeAmount')?.invalid
                    "
                  >
                    <p
                      *ngIf="campaignDetailsForm?.get('maximumPledgeAmount')?.errors?.['required']"
                    >
                      Maximum Pledge is required.
                    </p>
                    <p
                      *ngIf="campaignDetailsForm?.get('maximumPledgeAmount')?.errors?.['pattern']"
                    >
                      Only number Characters.
                    </p>
                  </div>
                </div>
                <div
                  class="form-group m-2"
                  *ngIf="campaignDetails.isFixedIndividualPledge === 'Y'"
                >
                  <label class="form-label" for="adminFee"
                    >Fixed Pledge Amount</label
                  >
                  <input
                    PercentagePipe="fixedPledgeAmount"
                    formControlName="fixedPledgeAmount"
                    type="number"
                    class="form-control"
                    id="fixedPledgeAmount"
                  />
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      campaignDetailsForm?.get('fixedPledgeAmount')?.dirty &&
                      campaignDetailsForm?.get('fixedPledgeAmount')?.invalid
                    "
                  >
                    <p
                      *ngIf="campaignDetailsForm?.get('fixedPledgeAmount')?.errors?.['required']"
                    >
                      Fixed Pledge Amount is required.
                    </p>
                    <p
                      *ngIf="campaignDetailsForm?.get('fixedPledgeAmount')?.errors?.['pattern']"
                    >
                      Only number Characters.
                    </p>
                  </div>
                </div>
                <div class="form-group m-2 flex-fill">
                  <div class="row">
                    <div class="col">
                      <label class="form-label" for="startDate"
                        >Campaign Start Date</label
                      >
                    </div>
                    <div class="col">
                      <label class="form-label" for="endDate"
                        >Campaign End Date</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <input
                        formControlName="startDate"
                        class="form-control"
                        type="date"
                        id="startDate"
                      />
                    </div>
                    <div class="col">
                      <input
                        formControlName="endDate"
                        class="form-control"
                        type="date"
                        id="endDate"
                      />
                    </div>
                  </div>
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      campaignDetailsForm?.get('startDate')?.dirty &&
                      campaignDetailsForm?.get('startDate')?.invalid
                    "
                  >
                    <p
                      *ngIf="campaignDetailsForm?.get('startDate')?.errors?.['required']"
                    >
                      Campaign Start Date is required.
                    </p>
                  </div>
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      campaignDetailsForm?.get('endDate')?.dirty &&
                      campaignDetailsForm?.get('endDate')?.invalid
                    "
                  >
                    <p
                      *ngIf="campaignDetailsForm?.get('endDate')?.errors?.['required']"
                    >
                      Campaign End Date is required.
                    </p>
                  </div>
                </div>
                <div class="form-group m-2">
                  <label class="form-label" for="timeRemaining"
                    >Time Remaining
                  </label>
                  <span
                    class="fs-6 form-text float-end"
                    id="daysCompleted"
                    [ngClass]="
                      campaignDetails.timeRemainingDays < 30
                        ? 'text-danger'
                        : 'text-primary fw-bold'
                    "
                  >
                    {{
                      campaignDetails.timeRemainingDays < 0
                        ? 'Campaign is expired'
                        : campaignDetails.timeRemainingDays + ' days'
                    }}
                  </span>
                  <div class="progress">
                    <div
                      class="progress-bar progress-bar-striped bg-primary"
                      role="progressbar"
                      [style.width]="
                        campaignDetails &&
                        campaignDetails.timelinePercentComplete
                          ? campaignDetails.timelinePercentComplete + '%'
                          : 0 + '%'
                      "
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="form-group m-2">
                  <label class="form-label" for="campaignAmount"
                    >Total Campaign Allocation</label
                  >
                  <div class="form-text fs-5">
                    {{ campaignDetails.campaignAmount | currency }}
                  </div>
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      campaignDetailsForm?.get('campaignAmount')?.dirty &&
                      campaignDetailsForm?.get('campaignAmount')?.invalid
                    "
                  >
                    <p
                      *ngIf="campaignDetailsForm?.get('campaignAmount')?.errors?.['required']"
                    >
                      Total Campaign Allocation is required.
                    </p>
                    <p
                      *ngIf="campaignDetailsForm?.get('pledgeAmount')?.errors?.['pattern']"
                    >
                      Only number Characters.
                    </p>
                  </div>
                </div>
                <div class="form-group m-2">
                  <label class="form-label" for="financialPercentComplete"
                    >Percentage of Funds spent</label
                  >
                  <output
                    class="fs-5 form-text text-success fw-bold float-end"
                    id="fundSpent"
                    >{{
                      campaignDetails.financialPercentComplete + ' % '
                    }}</output
                  >
                  <div class="progress">
                    <div
                      id="financialPercentComplete"
                      class="progress-bar progress-bar-striped bg-success"
                      role="progressbar"
                      [style.width]="
                        campaignDetails &&
                        campaignDetails.financialPercentComplete
                          ? campaignDetails.financialPercentComplete + '%'
                          : 0 + '%'
                      "
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button
              *ngIf="isAmerenAdmin"
              class="btn btn-success d-grid gap-1 col-3 mx-auto rounded"
              (click)="updateCampaign()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <!-- Campaign Details Start -->

      <!-- Particpating Agencies Campaign Start -->
      <div
        class="campaign__details__participating__agencies-container"
        *ngIf="campaignDetails && campaignDetails.campaignAgencies"
      >
        <div class="row">
          <div class="participating__agencies-title">
            Participating Agencies
          </div>
        </div>
        <div
          id="review__campaign__list"
          class="campaign-detalis-list"
          [ngClass]="
            campaignDetails.campaignAgencies.length > 5
              ? 'select_campaign-border'
              : ''
          "
          *ngIf="!isLoading && !isAPIFailure"
        >
          <div
            id="campaign__review__pending__header"
            class="row select__campaign-row select__campaign-header select__campaign-header-main"
          >
            <div class="col-3 select__campaign-header">Agency Name</div>
            <div class="col select__campaign-header">Agency Allocation</div>
            <div class="col select__campaign-header">Used/Pledge Progress</div>
            <div class="col select__campaign-header">Remaining</div>
            <div
              *ngIf="isAmerenAdmin"
              class="col select__campaign-header edit-column"
            >
              Edit
            </div>
          </div>
          <div>
            <div
              id="campaign__review__pending__list"
              class="row select__campaign-row"
              *ngFor="
                let campaignAgency of campaignDetails.campaignAgencies;
                let i = index
              "
            >
              <div class="col-3 select__campaign-column">
                <div class="campaign__name">
                  <a
                    class="accordion-toggle"
                    data-bs-toggle="collapse"
                    href="#pendingCampaignRecord{{ i }}"
                    aria-expanded="false"
                    aria-controls="agencyRecord"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                    >
                      <path
                        d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"
                      />
                    </svg>
                  </a>
                  <span class="p-2">{{
                    campaignAgency.campaignAgencyName
                  }}</span>
                </div>
              </div>

              <div
                class="col select__campaign-column select__campaign-column-desktop"
              >
                <input
                  type="text"
                  class="form-control"
                  [disabled]="campaignAgency.isEdited == false"
                  [ngClass]="
                    campaignAgency.isEdited == false ? 'border-edit' : ''
                  "
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="campaignAgency.agencyAllocation.amount"
                />
              </div>

              <div
                class="col select__campaign-column select__campaign-column-desktop"
              >
                <span>{{ campaignAgency.pledge.amount | currency }} </span>
              </div>

              <div
                class="col select__campaign-column select__campaign-column-desktop"
              >
                {{ campaignAgency.remaining.amount | currency }}
              </div>
              <div
                *ngIf="isAmerenAdmin"
                class="col select__campaign-column select__campaign-column-desktop edit-column"
              >
                <span *ngIf="!campaignAgency.isEdited">
                  <button
                    *ngIf="isAmerenAdmin"
                    class="btn btn-success d-grid gap-2 col-3 mx-auto rounded"
                    (click)="edit(i)"
                  >
                    Edit
                  </button>
                </span>
                <div class="row" *ngIf="campaignAgency.isEdited">
                  <div class="col-10">
                    <span>
                      <button
                        *ngIf="isAmerenAdmin"
                        class="btn btn-success d-grid gap-2 col-4 mx-auto rounded"
                        (click)="save(i, campaignAgency)"
                      >
                        Save
                      </button>
                    </span>
                  </div>

                  <div
                    class="col-2 campaign_delete-btn"
                    *ngIf="campaignDetails.campaignAgencies.length > 1"
                    (click)="
                      onDeleteCampaignAgency(
                        campaignAgency.campaignAgencyID.toString()
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      class="icon"
                    >
                      <path
                        fill="#C10000"
                        d="M13.05 42q-1.25 0-2.125-.875T10.05 39V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div
                class="collapse registration-details"
                id="pendingCampaignRecord{{ i }}"
              >
                <div class="text-center m-4" *ngIf="isLoadingforAgencyById">
                  <ameren-loading
                    class="d-flex p-10 justify-content-center"
                  ></ameren-loading>
                  <span>Please wait...</span>
                </div>

                <div class="text-danger m-4" *ngIf="isAPIFailureAgencyById">
                  <em class="far fa-times-circle"></em>
                  Failed to get the agency registration list. Please try again
                  later.
                </div>
                <div
                  class="campaign__registration-display"
                  *ngIf="!isLoadingforAgencyById && !isAPIFailureAgencyById"
                >
                  <div class="campaign__information campaign__info">
                    <div class="campaign__details">
                      <div class="card campaign__details-card">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col campaign__records">
                                <strong>Fee</strong>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col campaign__records">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{
                                    campaignAgency.agencyAllocation.fee
                                  }}"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col campaign__records">
                                <strong>Total</strong>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col campaign__records">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{
                                    campaignAgency.agencyAllocation
                                      .toCustomerAmount
                                  }}"
                                  style="width: 60%"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="particapating__agency participate_used">
                      <div class="card particapating__agency-card">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">
                            <div>
                              <div class="particapating__agency-header">
                                Fee
                              </div>
                              <div class="text-center campaign__records">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{ campaignAgency.pledge.fee }}"
                                  disabled
                                />
                              </div>
                            </div>
                            <div>
                              <div class="particapating__agency-header">
                                Total
                              </div>
                              <div class="text-center campaign__records">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{
                                    campaignAgency.pledge.toCustomerAmount
                                  }}"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="particapating__agency">
                      <div class="card particapating__agency-card">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">
                            <div>
                              <div class="particapating__agency-header">
                                Fee
                              </div>
                              <div class="text-center campaign__records">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{ campaignAgency.remaining.fee }}"
                                  disabled
                                />
                              </div>
                            </div>
                            <div>
                              <div class="particapating__agency-header">
                                Total
                              </div>
                              <div class="text-center campaign__records">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{
                                    campaignAgency.remaining.toCustomerAmount
                                  }}"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row campaign__footer">
          <div class="col-3 select__campaign-column"></div>
          <div
            class="col select__campaign-column select__campaign-column-desktop"
          >
            <div class="card campaign__details-card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col campaign__records">
                      <strong>Campain Total</strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col campaign__records">
                      <input
                        type="text"
                        class="form-control"
                        value="{{ allocationTotal | currency }}"
                        style="width: 60%"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col campaign__records">
                      <strong>Fee:</strong> {{ allocationTotalFee | currency }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col campaign__records">
                      <strong>Total:</strong>
                      {{ allocationTotalAdmin | currency }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col select__campaign-column select__campaign-column-desktop"
          >
            <div class="card campaign__details-card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col campaign__records">
                      <strong>Total Used</strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col campaign__records">
                      <input
                        type="text"
                        class="form-control"
                        value="{{ usedTotal | currency }}"
                        style="width: 60%"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col campaign__records">
                      <strong>Fee:</strong> {{ usedTotalFee | currency }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col campaign__records">
                      <strong>Total:</strong>
                      {{ usedTotalAdmin | currency }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col select__campaign-column select__campaign-column-desktop"
          >
            <div class="card campaign__details-card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col campaign__records">
                      <strong>Total Remaining</strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col campaign__records">
                      <input
                        type="text"
                        class="form-control"
                        value="{{ remainingTotal | currency }}"
                        style="width: 60%"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col campaign__records">
                      <strong>Fee:</strong> {{ remainingTotalFee | currency }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col campaign__records">
                      <strong>Total:</strong>
                      {{ remainingTotalAdmin | currency }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col select__campaign-column select__campaign-column-desktop"
          ></div>
        </div>
      </div>
      <!-- Particpating Agencies Campaign End -->

      <!-- Add Agencies Start -->
      <div
        class="campaign__details__add__agencies-container"
        *ngIf="isAmerenAdmin"
      >
        <div class="row">
          <div class="add__agencies-title">Add Agencies</div>
        </div>
        <div class="row">
          <div class="add_agencies">
            <div class="add_agencies_details">
              <div class="m-0 d-flex flex-column" formArrayName="addAgencies">
                <div class="d-flex flex-column">
                  <div
                    class="p-0"
                    *ngFor="let item of addAgencies.controls; index as idx"
                  >
                    <div class="m-0 d-flex flex-row" [formGroupName]="idx">
                      <div
                        class="agency_trash_can"
                        *ngIf="addAgencies.length > 1"
                      >
                        <a class="agency_anchor" (click)="removeAgency(idx)">
                          <em class="fa-regular fa-trash-can"></em>
                        </a>
                      </div>
                      <div class="form-group flex-fill new_agency_name">
                        <label>Agency Name</label>
                        <select
                          class="form-select"
                          formControlName="agencyName"
                        >
                          <option selected disabled [ngValue]="null">
                            Select Agency Name
                          </option>
                          <option
                            *ngFor="let item of campaignAgencies; let i = index"
                            [value]="item.agencyName"
                          >
                            {{ item.agencyName | uppercase }}
                          </option>
                        </select>
                        <div
                          class="text-field-error ameren-font-sm"
                          *ngIf="
                            addAgencies.controls[idx]?.get('agencyName')
                              ?.dirty &&
                            addAgencies.controls[idx]?.get('agencyName')
                              ?.invalid
                          "
                        >
                          Invalid Agency Name.
                        </div>
                      </div>
                      <div class="form-group flex-fill new_agency_state">
                        <label>Allocated</label>
                        <input
                          formControlName="allocated"
                          type="text"
                          class="form-control"
                          (input)="inputTotalAmount(idx)"
                        />
                        <div
                          class="text-field-error ameren-font-sm"
                          *ngIf="
                            addAgencies.controls[idx]?.get('allocated')
                              ?.dirty &&
                            addAgencies.controls[idx]?.get('allocated')?.invalid
                          "
                        >
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('allocated')?.errors?.['required']"
                          >
                            Allocated is required.
                          </p>
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('allocated')?.errors?.['pattern']"
                          >
                            Only number Characters.
                          </p>
                        </div>
                      </div>
                      <div class="form-group flex-fill new_agency_state">
                        <label>Percentage(%)</label>
                        <input
                          formControlName="percentage"
                          type="text"
                          class="form-control"
                        />
                        <div
                          class="text-field-error ameren-font-sm"
                          *ngIf="
                            addAgencies.controls[idx]?.get('percentage')
                              ?.dirty &&
                            addAgencies.controls[idx]?.get('percentage')
                              ?.invalid
                          "
                        >
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('percentage')?.errors?.['required']"
                          >
                            Percentage is required.
                          </p>
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('percentage')?.errors?.['pattern']"
                          >
                            Only number Characters.
                          </p>
                        </div>
                      </div>
                      <div class="form-group flex-fill new_agency_state">
                        <label>Fee</label>
                        <input
                          formControlName="fee"
                          type="text"
                          class="form-control"
                          [(ngModel)]="agencyFee[idx]"
                        />
                        <div
                          class="text-field-error ameren-font-sm"
                          *ngIf="
                            addAgencies.controls[idx]?.get('fee')?.dirty &&
                            addAgencies.controls[idx]?.get('fee')?.invalid
                          "
                        >
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('fee')?.errors?.['required']"
                          >
                            Fee is required.
                          </p>
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('fee')?.errors?.['pattern']"
                          >
                            Only number Characters.
                          </p>
                        </div>
                      </div>
                      <div class="form-group flex-fill new_agency_state">
                        <label>Total</label>
                        <input
                          formControlName="totalAdmin"
                          type="text"
                          class="form-control"
                          [(ngModel)]="totalAmountValue[idx]"
                        />
                        <div
                          class="text-field-error ameren-font-sm"
                          *ngIf="
                            addAgencies.controls[idx]?.get('totalAdmin')
                              ?.dirty &&
                            addAgencies.controls[idx]?.get('totalAdmin')
                              ?.invalid
                          "
                        >
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('totalAdmin')?.errors?.['required']"
                          >
                            Total is required.
                          </p>
                          <p
                            *ngIf="addAgencies.controls[idx]?.get('totalAdmin')?.errors?.['pattern']"
                          >
                            Only number Characters.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="ms-auto add_additional_agency me-3"
                  *ngIf="isAmerenAdmin"
                >
                  <a
                    (click)="addAdditionalAgency()"
                    class="add_additional_agency_button mt-3"
                    >+ Additional Agency</a
                  >
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <button
                *ngIf="isAmerenAdmin"
                class="btn btn-success d-grid gap-2 col-3 mx-auto rounded"
                (click)="updateCampaign()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Agencies End -->
    </div>

    <!-- Delete Campaign Modal -->
  </form>
</div>

<div
  id="deleteCampaignModel"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="deleteCampaignModel"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="ameren__modal-image">
          <img src="assets/images/success.svg" alt="Success Image" />
        </div>
        <div class="row ameren__modal-message">
          <span> Campaign Successfully Deleted.</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="deleteModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="deleteModalConfirmation"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="ameren__modal__success-title">Delete Campaign</div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <span>Are you sure that you want to delete this campaign?</span>
        </div>
        <div class="row mt-4">
          <div class="col-md-4"></div>
          <div class="col-md-2">
            <button
              (click)="onDeleteCampaign()"
              class="btn btn-success create_campaign_button"
            >
              Yes
            </button>
          </div>
          <div class="col-md-2">
            <button
              (click)="onDeleteCampaignCancel()"
              class="btn btn-secondary create_campaign_button"
            >
              No
            </button>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="deleteCampaignAgencyModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="deleteCampaignAgencyModalConfirmation"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="ameren__modal__success-title">Delete Campaign Agency</div>
        <button
          type="button"
          class="btn-close"
          *ngIf="!deleteAgencyCampaignLoading"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <span
            >Are you sure that you want to delete this Campaign Agency?</span
          >
        </div>
        <div class="row mt-4">
          <div class="col-md-4"></div>
          <div class="col-md-2" *ngIf="!deleteAgencyCampaignLoading">
            <button
              (click)="onDeleteCampaignAgencyConfirm()"
              class="btn btn-success create_campaign_button"
            >
              Yes
            </button>
          </div>
          <div class="col-md-2" *ngIf="!deleteAgencyCampaignLoading">
            <button
              (click)="onDeleteCampaignAgencyCancel()"
              class="btn btn-secondary create_campaign_button"
            >
              No
            </button>
          </div>
          <div class="col-md-4"></div>
          <div
            class="text-center campaign__details__delete_error-handling"
            *ngIf="deleteAgencyCampaignLoading"
          >
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="saveCampaignLoadingModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="saveCampaignLoadingModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <ameren-loading
          class="d-flex p-10 justify-content-center"
        ></ameren-loading>
        <span>Please wait...</span>
      </div>
    </div>
  </div>
</div>
