import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { EAPUsers } from 'src/app/shared/models/EAPUsers.model';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AgencypledgesManagerService } from '../../agencypledges/services/agencypledges-manager.service';
import { IActivateOrDeactivateBody } from '../interfaces/IActivateOrDeactivateBody';
import { IAdminApprovalBody } from '../interfaces/IAdminApprovalBody';
import { IEAPAgencyInformation } from '../interfaces/IEAPAgencyInformation';
import { AdministrationManagerService } from '../services/administration-manager.service';

declare var window: any;

@Component({
  selector: 'app-pending-agency-user',
  templateUrl: './pending-agency-user.component.html',
  styleUrls: ['./pending-agency-user.component.scss']
})
export class PendingAgencyUserComponent implements OnInit {

  pendingAgencyUsersList: EAPUsers[];
  isLoading: boolean = true;
  isAPIFailure: boolean = false;
  mymodel: any;
  adminmodal: any;
  isApprovalLoading: boolean = true;
  isApprovalAPIFailure: boolean = false;
  isAmerenSupport: boolean | undefined;
  loggedInUserAgencyId: any;
  isAmerenAdmin: boolean | undefined;
  searchAgencyUser: any;
  pendingAgencyUsersListOriginal: any[] = [];

  constructor(
    private administrationService: AdministrationManagerService,
    private profileManager: ProfileManagerService,
    private agencyService: AgencyService,
    private agencyPledgesManager: AgencypledgesManagerService
  ) {
    this.profileManager.profile.forEach((r) => {
      this.isAmerenSupport = r.agencyList?.every(
        (a) => a.role.toLowerCase() === 'eapamerensupport'
      );

      this.isAmerenAdmin = r.agencyList?.every(
        (a) => a.role.toLowerCase() === 'eapamerenadmin'
      );
    });

    if(this.profileManager.profile[0].isUserWithMultiAgency || this.isAmerenAdmin || this.isAmerenSupport){
      this.loggedInUserAgencyId =
        this.agencyService.getSelectedAgency().agencyID;
    }
        else{
          this.loggedInUserAgencyId = this.profileManager.profile[0].selectedAgency
        }
  }

  ngOnInit(): void {
    if (!this.isAmerenAdmin && !this.isAmerenSupport) this.getAgencySummary();
    this.mymodel = new window.bootstrap.Modal(
      document.getElementById('pendingagencyUserModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
    this.adminmodal = new window.bootstrap.Modal(
      document.getElementById('adminModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.getPendingEAPUsersList();
  }

  getPendingEAPUsersList() {
    this.isLoading = true;
    this.isAPIFailure = false;
    this.pendingAgencyUsersList = [];
    this.administrationService.getEAPUsersList().subscribe({
      next: (data) => {
        this.isLoading = false;
        this.pendingAgencyUsersListOriginal = data;
        this.handleAmerenUsers(data);
      },
      error: (err) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        console.log('error', err);
      },
    });
  }

  handleAmerenUsers(data: EAPUsers[]) {
    this.pendingAgencyUsersList = data.filter(
      (user) => user.agencyID == this.loggedInUserAgencyId && user.status === 'eappending'
    );
  }

  getAgencyInfobyId(agencyId: any, idx: any) {
    this.isLoading = true;
    return this.administrationService
      .getAgencyInformationById(agencyId)
      .subscribe({
        next: (res: IEAPAgencyInformation) => {
          this.isLoading = false;
          this.pendingAgencyUsersList[idx].agenyName = res.agencyName;
          this.pendingAgencyUsersList[idx].agencyStreetName = res.address.addressLines;
          this.pendingAgencyUsersList[idx].agencyCity = res.address.city;
          this.pendingAgencyUsersList[idx].agencyState = res.address.state;
          this.pendingAgencyUsersList[idx].agencyZip = res.address.zip;
          return res;
        },
        error: (error: any) => {
          throwError(() => error);
        },
      });
  }

  ui = {
    confirm: async () => this.createConfirm(),
  };

  createConfirm = () => {
    return new Promise((complete) => {
      $('#adminModalConfirmation #confirmYes').off('click');
      $('#adminModalConfirmation #confirmNo').off('click');

      $('#adminModalConfirmation #confirmYes').on('click', () => {
        $('.confirm').hide();
        complete(true);
      });
      $('#adminModalConfirmation #confirmNo').on('click', () => {
        $('.confirm').hide();
        complete(false);
      });

      this.adminmodal.show();
    });
  };

  async submitAdminRequest(data: any, user: EAPUsers) {
    const confirm = await this.ui.confirm();
    let otherExistingAgencies: any[] = [];
    otherExistingAgencies = this.pendingAgencyUsersListOriginal.filter(
      (data) =>
        data.mail == user.mail && data.agencyID != this.loggedInUserAgencyId
    );

    let otherExistingAgenciesBody: any[] = [];

    otherExistingAgencies.forEach((data: any) => {
      otherExistingAgenciesBody.push({
        AgencyID: data.agencyID,
        role:
          data.role === 'eapagencyadmin'
            ? 'eapAgencyAdmin'
            : data.role === 'eapupdate'
            ? 'eapUpdate'
            : data.role === 'eapread'
            ? 'eapRead'
            : '',
        status: data.status === 'eappending' ? 'eapPending' : '',
      });
    });

    if (confirm) {
      this.adminmodal.hide();
      const approvalBody: IAdminApprovalBody = {
        id: user.mail,
        roles: {
          REPLACE: [
            {
              AgencyID: user.agencyID,
              role:
                data.target.value == 'eapDenyStatus'
                  ? user.role
                  : data.target.value == 'eapPendingStatus'
                  ? user.role
                  : data.target.value == 'eapRead'
                  ? 'eapRead'
                  : data.target.value == 'eapUpdate'
                  ? 'eapUpdate'
                  : data.target.value == 'eapAgencyAdmin'
                  ? 'eapAgencyAdmin'
                  : user.role,
              status:
                data.target.value === 'eapPendingStatus' ? 'eapPending' : '',
            },
          ],
        },
      };

      if (otherExistingAgenciesBody) {
        otherExistingAgenciesBody.forEach((data: any) => {
          approvalBody.roles.REPLACE.push(data);
        });
      }

      const activateOrDeactivateBody: IActivateOrDeactivateBody = {
        id: user.mail,
        active: data.target.value == 'eapDenyStatus' ? false : true,
      };
      this.isApprovalLoading = true;
      this.isApprovalAPIFailure = false;
      await this.mymodel.show();
      await this.administrationService
        .submitAdminApproval(activateOrDeactivateBody, approvalBody)
        .subscribe({
          next: () => {
            this.isApprovalLoading = false;
            this.isApprovalAPIFailure = false;
            this.updateAgencyUser();
            this.getPendingEAPUsersList();
          },
          error: () => {
            this.isApprovalLoading = false;
            this.isApprovalAPIFailure = true;
            this.getPendingEAPUsersList();
          },
        });
    } else {
      this.adminmodal.hide();
      this.getPendingEAPUsersList();
    }
  }

  updateAgencyUser(){
    this.administrationService.eventRefresh.next(true)
  }

  toggleOpenDetails(user: EAPUsers) {
    this.pendingAgencyUsersList.forEach((a, i) => {
      if (user.agencyID == a.agencyID && !user.toggleStatus) {
        user.toggleStatus = true;
      }
    });
  }

  toggleCloseDetails(user: EAPUsers) {
    this.pendingAgencyUsersList.forEach((a, i) => {
      if (user.agencyID == a.agencyID && user.toggleStatus) {
        user.toggleStatus = false;
      }
    });
  }

  getAgencySummary() {
    this.agencyPledgesManager.getUserSummary().subscribe({
      next: (res: any) => {
        if (res && res.length === 1) {
          const agencyDetails = {
            selectedAgencyName: res[0].agencyName,
            pinNumber: res[0].pinNumber,
            agencyID: res[0].agencyID,
            agencyCSSCode: res[0].agencyCSSCode,
            selectedAgencyAddress:
              res[0].agencyStreetName +
              ' ' +
              res[0].agencyCity +
              ' ' +
              res[0].agencyState +
              ' ' +
              res[0].agencyZip,
              isEFTAllowed: res[0].isEFTAllowed
          };
          this.agencyService.setSelectedAgency(agencyDetails);
        }
      },
      error: (error: any) => {
        console.log('error', error);
      },
    });
  }
}
