import { AbstractControl } from '@angular/forms';
export class PasswordValidation {

    static PasswordRules(AC: AbstractControl) {
        if (!(AC.value == null || AC.value=='')) {
            let password = AC.value; // to get value in input tag
            //let confirmPassword = AC.get('ConfirmPassword').value; // to get value in input tag
            //let emailAddress = AC.get('EmailAddress').value;
            let isNoLeadingTrailingSpacesValid = !/^[ \s]+|[ \s]+$/.test(password);
            let isLetterAndNumberAndSpecialCharValid =/^(?=.*[a-zA-Z])(?=.*\d)(?=.*([~`!@#$%\^\(\)_*+=\-\[\]\\';,/{}|\\"":\?])).+$/.test(password);
            let isRepeatingCharactersValid = !/(\S)\1\1+/.test(password);
            let isNoControlCharactersValid = /^[\S ]+$/.test(password);
            let isNoTwoContinuousSpacesValid = !/\s\s/.test(password);
            let invalidCharacters = !/[<]|[>]|[&]|[-]/.test(password);
         //   let isNotPasswordMatching = !(confirmPassword === password);
            let isMinMaxLimitExceeded = ! /^(?=.{8,250}$).*/.test(password);
          //  let isStrongPassword =!(password.toUpperCase() === emailAddress.toUpperCase());
            let errors = { NoLeadingTrailingSpaces: false, InvalidCharacters: false, LetterAndNumberAndSpecialChar: false, RepeatingCharacters: false, NoControlCharacters: false, NoTwoContinuousSpaces: false , WeakPassword:false};
            let confirmPassworderrors = {
                IsNotPasswordMatching: false,
            };
            // if (isNotPasswordMatching) {
            //     confirmPassworderrors.IsNotPasswordMatching = true;
            // }
            if (!isNoLeadingTrailingSpacesValid) {
                errors.NoLeadingTrailingSpaces= true;
            }
            if (!invalidCharacters) {
                errors.InvalidCharacters = true;
            }
            if (!isLetterAndNumberAndSpecialCharValid) {
                errors.LetterAndNumberAndSpecialChar = true;
            }
            if (!isRepeatingCharactersValid) {
                errors.RepeatingCharacters = true;
            }
            if (!isNoControlCharactersValid) {
                errors.NoControlCharacters = true;
            }
            if (!isNoTwoContinuousSpacesValid) {
                errors.NoTwoContinuousSpaces = true;
            }
            // if (!isStrongPassword) {
            //     errors.WeakPassword = true;
            // }

            if (!invalidCharacters || !isNoControlCharactersValid || !isNoTwoContinuousSpacesValid || !isNoLeadingTrailingSpacesValid || !isLetterAndNumberAndSpecialCharValid || !isRepeatingCharactersValid ||  isMinMaxLimitExceeded)
                AC.setErrors(errors);
            else
                AC.setErrors(null);

            // if (isNotPasswordMatching)
            //     AC.get('ConfirmPassword').setErrors(errors);
            // else
            //     AC.get('ConfirmPassword').setErrors(null);
        }
        return null;
    }
}

export class ConfirmPasswordValidation {

    static ConfirmPasswordRules(AC: AbstractControl) {
        // if (!(AC.get('ConfirmPassword').value == null || AC.get('ConfirmPassword').value == '')) {
        //     let confirmPassword = AC.get('ConfirmPassword').value; // to get value in input tag
        //     let password = AC.get('Password').value; // to get value in input tag
        //     let isNotPasswordMatching = !(confirmPassword === password);
        //     let errors = {
        //         IsNotPasswordMatching: false,
        //     };
        //     if (isNotPasswordMatching) {
        //         errors.IsNotPasswordMatching = true;
        //     }

        //     if (isNotPasswordMatching) 
        //         AC.get('ConfirmPassword').setErrors(errors);
        //     else
        //         AC.get('ConfirmPassword').setErrors(null);
        // }
        return null;
    }
}