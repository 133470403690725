<div class="container admin__dashboard">
    <div class="row">
        <div class="col-4 manage__profile">
            <app-manageprofile [IsAmerenAdmin]="isAmerenAdmin" [CurrentUser]="currentUser"></app-manageprofile>
        </div>
        <div class="col-8 admin__users-list">
            <div class="pending__agency-admin">
                <app-pendingagencyadmin *ngIf="isAmerenAdmin"></app-pendingagencyadmin>
            </div>
            <div class="all__ameren__users-list">
                <p class="ameren__users-title">Ameren Logins</p>
                  <button class="ameren__users__submit">Submit</button>
            </div>
            <app-amerenuser class="ameren__users-component"></app-amerenuser>
        </div>
    </div>
</div>
