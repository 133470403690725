<div class="disconnecthistory__container">
  <div class="disconnecthistory__title">Disconnect History</div>
  <div
    class="disconnecthistory__list"
    [ngClass]="disconnectHistory.length != 0 ? 'screenheight' : ''"
  >
    <table
      id="disconnecthistory"
      #disconnecthistory
      aria-describedby="Ameren items List"
      class="disconnecthistory align-middle bg-white"
    >
      <thead class="disconnecthistory__header">
        <tr>
          <th scope="col">
            <div>
              <span class="column-sort">Date</span
              ><img
                [appSort]="disconnectHistory"
                data-order="asc"
                data-name="correspondenceDate"
                src="../../../assets/icon/sort.svg"
                alt="Sort by Date"
              />
            </div>
          </th>
          <th scope="col">
            <div>
              <span class="column-sort">Description</span
              ><img
                [appSort]="disconnectHistory"
                data-order="asc"
                data-name="letterTypeDescription"
                src="../../../assets/icon/sort.svg"
                alt="Sort by Description"
              />
            </div>
          </th>
          <th scope="col">
            <span>View<em class="fa-solid"></em></span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="6">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="isAPIFailure && !isLoading">
        <tr>
          <td colspan="6">
            <div class="mt-3 text-danger text-center">
              <em class="far fa-times-circle"></em>
              Sorry, Unable to retrieve the Disconnect History information.
              Please try again later.
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isAPIFailure && !isLoading">
        <tr *ngIf="disconnectHistory && disconnectHistory.length === 0">
          <td colspan="6">
            <div
              id="noRecordsFound"
              class="no__ameren__users__found text-center"
            >
              There are no Disconnect History records found!
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of disconnectHistory; let i = index">
          <td>
            <p class="fw-normal mb-1">{{ item.correspondenceDate }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.letterTypeDescription }}</p>
          </td>
          <td>
            <button
              *ngIf="item.pdfLink.length > 0"
              class="btn btn-default"
              (click)="getDisconnectPDF(item)"
            >
              <img src="assets/icon/pdficon.svg" alt="PDF Image" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="isPDFLoading">
  <ameren-loading
    id="loadingIndicator"
    class="d-flex p-10 m-3 justify-content-center"
  ></ameren-loading>
</div>

<div *ngIf="isPDFAPIFailure">
  <div class="mt-5 text-danger text-center">
    <em class="far fa-times-circle"></em>
    Sorry, Unable to download the PDF. Please try again later.
  </div>
</div>

<div
  id="viewDisconnectPDF"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="viewDisconnectPDF"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="disconnectPDFAPIFailure">
        <div class="ameren__modal__failure-title">Error message!</div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="disconnectPDFLoading" class="text-center">
          <ameren-loading></ameren-loading>
          <div class="text-center">Please wait.</div>
        </div>

        <div
          class="row ameren__modal__failure-message text-danger"
          *ngIf="disconnectPDFAPIFailure"
        >
          <span> Something went wrong. Please try again.</span>
        </div>
      </div>
    </div>
  </div>
</div>
