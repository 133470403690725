import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IGlobalAPIResponse } from '../interfaces/iglobal-api-response';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceApiService {
  constructor(private http: HttpClient) {}

  getMaintenanceStatus() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/systemmessage`,
      {
        headers,
      }
    );
  }
}
