import * as _ from "lodash";

export class EAPUsers {
    accountStatus: string;
    agencyID: any;
    role: any;
    status: any;
    givenName: string;
    isMigrated: boolean;
    isOtpEnabled: boolean;
    isVerified: boolean;
    mail: string;
    organizationName: string;
    passwordChanged: boolean;
    portalNameList: string;
    pwdReset: boolean;
    sn: string;
    stateProvince: string;
    telephoneNumber: string;
    userName: string;
    agenyName?: any;
    agencyStreetName?: any;
    agencyCity?: any;
    agencyState?: any;
    agencyZip?: any;
    toggleStatus?: boolean;

    constructor(accountStatus: string,
        agencyID: any,
        role: any,
        status: any,
        givenName: string,
        isMigrated: boolean,
        isOtpEnabled: boolean,
        isVerified: boolean,
        mail: string,
        organizationName: string,
        passwordChanged: boolean,
        portalNameList: string,
        pwdReset: boolean,
        sn: string,
        stateProvince: string,
        telephoneNumber: string,
        userName: string,
        agenyName?: any,
        agencyStreetName?: any,
        agencyCity?: any,
        agencyState?: any,
        agencyZip?: any

        ) {
        this.accountStatus = !_.isNil(accountStatus) ? accountStatus.toLowerCase() : '';
        this.agencyID = !_.isNil(agencyID) ? agencyID : '';
        this.role = !_.isNil(role) ? role.toLowerCase() : '';
        this.status = !_.isNil(status) ? status.toLowerCase() : '';
        this.givenName = !_.isNil(givenName) ? givenName : '';
        this.isMigrated = !_.isNil(isMigrated) ? isMigrated : false;
        this.isOtpEnabled = !_.isNil(isOtpEnabled) ? isOtpEnabled : false;
        this.isVerified = !_.isNil(isVerified) ? isVerified : false;
        this.mail = !_.isNil(mail) ? mail : '';
        this.organizationName = !_.isNil(organizationName) ? organizationName : '';
        this.passwordChanged = !_.isNil(passwordChanged) ? passwordChanged : false;
        this.portalNameList =  !_.isNil(portalNameList) ? portalNameList : 'EAP';
        this.pwdReset = !_.isNil(pwdReset) ? pwdReset : false;
        this.sn = !_.isNil(sn) ? sn : '';
        this.stateProvince = !_.isNil(stateProvince) ? stateProvince : '';
        this.telephoneNumber = !_.isNil(telephoneNumber) ? telephoneNumber : '';
        this.userName = !_.isNil(userName) ? userName : '';
        this.agenyName = !_.isNil(agenyName) ? agenyName : '';
        this.agencyStreetName = !_.isNil(agencyStreetName) ? agencyStreetName : '';
        this.agencyCity = !_.isNil(agencyCity) ? agencyCity : '';
        this.agencyState = !_.isNil(agencyState) ? agencyState : '';
        this.agencyZip = !_.isNil(agencyZip) ? agencyZip : '';
    }
}