import { Client } from 'src/app/shared/models/client';
import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/shared/services/client.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent implements OnInit {
  selectedClient: Client;
  userInitials: string;
  constructor(private _clientSvc: ClientService) {
    this._clientSvc.clientUpdatedSubject.subscribe(() => {
      let client = this._clientSvc.getSelectedClient();
      if (client) {
        this.selectedClient = client;
        this.userInitials = this.selectedClient.firstName.toString().substr(0,1) + this.selectedClient.lastName.toString().substr(0,1);
      }
    });
  }

  ngOnInit(): void {
    let client = this._clientSvc.getSelectedClient();
    if (client){
      this.selectedClient = client;
      this.userInitials = this.selectedClient.firstName.toString().substr(0,1) + this.selectedClient.lastName.toString().substr(0,1);
    } 
  }

  searchNewClient(){
    this._clientSvc.setShowClientSelector(true);
  }
}
