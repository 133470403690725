import * as _ from 'lodash';

export class pledgeType {
  pledgeTypeCode: any;
  pledgeTypeDescription: string;
  isValidEFT: string;
  isAddable: string;
  minPledge: number;
  maxPLedge: number;
  isFixedPledge: boolean;
  fixPledgeAmount: number;
  remainingBalanceAmount: number;
  isCampaignPledgeType: boolean;
  endDate: string;
  startDate: string;
  isValidMissouri: boolean;
  isValidIllinois: boolean;

  constructor(
    pledgeTypeCode?: any,
    pledgeTypeDescription?: string,
    isValidEFT?: string,
    isAddable?: string,
    minPledge?: number,
    maxPledge?: number,
    isFixedPledge?: string,
    fixPledgeAmount?: number,
    remainingBalanceAmount?: number,
    isCampaignPledgeType?: boolean,
    endDate?: string,
    startDate?: string,
    isValidMissouri?: boolean,
    isValidIllinois?: boolean
  ) {
    this.pledgeTypeCode = !_.isNil(pledgeTypeCode) ? pledgeTypeCode : '';
    this.pledgeTypeDescription = !_.isNil(pledgeTypeDescription)
      ? pledgeTypeDescription
      : '';
    this.isValidEFT = !_.isNil(isValidEFT) ? isValidEFT : '';
    this.isAddable = !_.isNil(isAddable) ? isAddable : '';
    this.minPledge = !_.isNil(minPledge) ? minPledge : 0;
    this.maxPLedge = !_.isNil(maxPledge) ? maxPledge : 0;
    this.isFixedPledge = isFixedPledge == 'Y';
    this.fixPledgeAmount = !_.isNil(fixPledgeAmount) ? fixPledgeAmount : 0;
    this.remainingBalanceAmount = !_.isNil(remainingBalanceAmount)
      ? remainingBalanceAmount
      : 0;
    this.isCampaignPledgeType = !_.isNil(isCampaignPledgeType)
      ? isCampaignPledgeType
      : false;

    this.endDate = !_.isNil(endDate) ? endDate : '';
    this.startDate = !_.isNil(startDate) ? startDate : '';
    this.isValidMissouri = !_.isNil(isValidMissouri) ? isValidMissouri : false;
    this.isValidIllinois = !_.isNil(isValidIllinois) ? isValidIllinois : false;
  }
}
