import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';

export class ClientPledge {
  accountNumber: number;
  agencyID: number;
  agencyCSSCode: string;
  agencyName: string;
  requesterName: string;
  pledgeID: number;
  pledgeDate: string;
  pledgeTypeCode: string;
  pledgeTypeDecode: string;
  pledgeAmount: number;
  pledgeStatusCode: string;
  pledgeStatusDecode: string;
  pledgeEndDate: string;
  pledgeStartDate: string;
  payViaEFT: string;
  nearExpiration: string;
  campaignId: string;
  campaignStartDate: string;
  campaignEndDate: string;
  campaignName: string;
  pledgeExpirationDate: string;
  isNearExpiration: boolean;
  clockImage: string;
  
  constructor(clientPledge?: any) {
    this.accountNumber = !_.isNil(clientPledge.accountNumber)
      ? clientPledge.accountNumber
      : 0;
    this.agencyID = !_.isNil(clientPledge.agencyID) ? clientPledge.agencyID : 0;
    this.agencyCSSCode = !_.isNil(clientPledge.agencyCSSCode)
      ? clientPledge.agencyCSSCode
      : '';
    this.agencyName = !_.isNil(clientPledge.agencyName)
      ? clientPledge.agencyName
      : '';
    this.requesterName = !_.isNil(clientPledge.requesterName)
      ? clientPledge.requesterName
      : '';
    this.pledgeID = !_.isNil(clientPledge.pledgeID) ? clientPledge.pledgeID : 0;
    this.pledgeDate = !_.isNil(clientPledge.pledgeDate)
      ? clientPledge.pledgeDate
      : '';
    this.pledgeTypeCode = !_.isNil(clientPledge.pledgeTypeCode)
      ? clientPledge.pledgeTypeCode
      : '';
    this.pledgeTypeDecode = !_.isNil(clientPledge.pledgeTypeDecode)
      ? clientPledge.pledgeTypeDecode
      : '';
    this.pledgeAmount = !_.isNil(clientPledge.pledgeAmount)
      ? clientPledge.pledgeAmount
      : 0;
    this.pledgeStatusCode = !_.isNil(clientPledge.pledgeStatusCode)
      ? clientPledge.pledgeStatusCode
      : '';
    let pledgeStatusDescription = !_.isNil(clientPledge.pledgeStatusDecode)
      ? clientPledge.pledgeStatusDecode
      : '';
    this.pledgeStatusDecode =
      this.pledgeStatusCode === 'E'
        ? 'EFT Payment Pending'
        : pledgeStatusDescription;
    this.pledgeEndDate =
      this.pledgeStatusDecode === 'Satisfied'
        ? clientPledge.pledgeStatusDate
        : '';
    this.pledgeStartDate = !_.isNil(clientPledge.pledgeStartDate)
      ? clientPledge.pledgeStartDate
      : '';
    this.payViaEFT = !_.isNil(clientPledge.payViaEFT)
      ? clientPledge.payViaEFT
      : '';
    this.nearExpiration = !_.isNil(clientPledge.nearExpiration)
      ? clientPledge.nearExpiration
      : '';
    this.campaignId = !_.isNil(clientPledge.campaignId)
      ? clientPledge.campaignId
      : '';
    this.campaignStartDate = !_.isNil(clientPledge.campaignStartDate)
      ? clientPledge.campaignStartDate
      : '';
    this.campaignEndDate = !_.isNil(clientPledge.campaignEndDate)
      ? clientPledge.campaignEndDate
      : '';
    this.campaignName =
      this.pledgeTypeCode.length > 2 ? this.pledgeTypeDecode : '';

    this.pledgeExpirationDate = !_.isNil(clientPledge.pledgeEndDate)
      ? clientPledge.pledgeEndDate
      : '';
    let currentDate = moment().tz('America/Chicago').format('YYYY-MM-DD');
    let pledgeExpirationDateValue = moment(this.pledgeExpirationDate);
    let expirationDays = pledgeExpirationDateValue.diff(currentDate, 'days');
    this.isNearExpiration =
      this.pledgeStatusDecode === 'Open' && expirationDays <= 30 ? true : false;
      this.clockImage = this.isNearExpiration == true ? "1" : "";
  }
}

export class pledgeObject {
  index: any;
  campaignName: any;
  pledgeDate: any;
  pledgeTypeDecode: any;
  agencyName: any;
  pledgeAmount: any;
  pledgeStatusDecode: any;
  pledgeEndDate: any;
  clockImage: any;
}
