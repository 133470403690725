<div id="select__agency-container" class="container" *ngIf="!isAdmin">
  <div class="row mb-4">
    <h3 *ngIf="!isAdmin">Add Agency</h3>
    <app-add-additional-agency *ngIf="!isAdmin"></app-add-additional-agency>
    <div class="select__agency-title col">Select An Agency Below</div>
    <div class="select__agency-search col">
      <div class="input-group">
        <input class="form-control border agency__search-input" type="search" placeholder="Search" id="search-input"
          [(ngModel)]="searchVal" (keyup.enter)="searchFilter()" />
        <span class="input-group-append">
          <button (click)="searchFilter()" class="btn-outline-secondary border agency__search-btn" type="button">
            <em class="fa fa-search fs-4"></em>
          </button>
        </span>
      </div>
    </div>
  </div>

  <div class="select_agency-list" *ngIf="isLoading">
    <div class="row select__agency-row select__agency-header">
      <div class="col select__agency-header">Agency Name</div>
      <div class="col select__agency-header" *ngIf="!isAdmin">Address</div>
      <div class="col select__agency-header">Agency PIN</div>
      <div class="col select__agency-header" *ngIf="!isAdmin">Status</div>
    </div>
    <div class="row select__agency-row">
      <div class="text-center col select__agency-column">
        <ameren-loading id="loadingIndicator" class="d-flex p-10 justify-content-center"></ameren-loading>
        <span>Please wait...</span>
      </div>
    </div>
  </div>

  <div class="select_agency-list" *ngIf="isAPIFailure">
    <div class="row select__agency-row select__agency-header">
      <div class="col select__agency-header">Agency Name</div>
      <div class="col select__agency-header">Agency PIN</div>
      <div class="col select__agency-header" *ngIf="!isAdmin">Address</div>
      <div class="col select__agency-header" *ngIf="!isAdmin">Status</div>
    </div>
    <div class="row select__agency-row">
      <div class="col select__agency-column text-danger">
        <em class="far fa-times-circle"></em>
        Failed to get the agency registration list. Please try again later.
      </div>
    </div>
  </div>

  <div class="select_agency-list" *ngIf="!isLoading && !isAPIFailure"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''">
    <div class="row select__agency-row select__agency-header select__agency-header-main">
      <div class="col select__agency-header">Agency Name</div>
      <div class="col select__agency-header" *ngIf="!isAdmin">Address</div>
      <div class="col select__agency-header">Agency PIN</div>
      <div class="col select__agency-header" *ngIf="!isAdmin">Status</div>
    </div>
    <div>
      <div class="row select__agency-row" *ngFor="let agencylist of registrationList; let i = index">
        <div class="col select__agency-column">
          <div class="agency__initial-circle">
            <span class="agency__initials">{{
              agencylist.agencyName.substring(0, 1).toUpperCase()
              }}</span>
          </div>
          <div class="agency__name" [ngClass]="{ disabled: !agencylist.isApproved && !isAdmin }">
            <span class="p-2" (click)="selectAgencyByAgencyID(agencylist)">{{
              agencylist.agencyName
              }}</span>
            <a class="accordion-toggle select__agency-column-mobile" data-bs-toggle="collapse"
              href="#agencyRecord{{ i }}" aria-expanded="false" aria-controls="agencyRecord">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8L12 1.52381L10.5882 2.40727e-06L6 4.95238L1.41177 0L0 1.52381L6 8Z" fill="#1B6CB5" />
              </svg>
            </a>
          </div>
          <div class="collapse" id="agencyRecord{{ i }}">
            <div class="row">
              <div class="col">Agency PIN</div>
              <div class="col">{{ agencylist.agencyPin }}</div>
            </div>
          </div>
        </div>

        <div class="col select__agency-column select__agency-column-desktop" *ngIf="!isAdmin">
          <span>
            {{ agencylist.address?.addressLines.join('') }}
            {{ agencylist.address?.city }} {{ agencylist.address?.state }}
            {{ agencylist.address?.zip }}</span>
        </div>
        <div class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.agencyPin }} </span>
        </div>
        <div class="col select__agency-column select__agency-column-desktop" *ngIf="!isAdmin">
          <span> {{ agencylist.isApproved ? 'Approved' : 'Pending' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="selectAgencyModal" class="modal fade" aria-hidden="true" aria-labelledby="amerenModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="isSelectAgencyAPIFailure">
        <div class="ameren__modal__failure-title" *ngIf="!isSelectAgencyLoading && isSelectAgencyAPIFailure">
          Select Agency Error!
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isSelectAgencyLoading" class="text-center">
          <ameren-loading id="loadingIndicator" class="d-flex p-10 justify-content-center"></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image">
          <img *ngIf="isSelectAgencyAPIFailure && !isSelectAgencyLoading" src="assets/images/failure.svg"
            alt="Failure Image" />
        </div>
        <div class="ameren__modal-footer" *ngIf="!isSelectAgencyLoading">
          <div class="ameren__modal__failure-message" *ngIf="!isSelectAgencyLoading && isSelectAgencyAPIFailure">
            Sorry, Unable to select the agency at this moment. Please try again
            later.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="select__agency_pin_search" *ngIf="isAdmin">
  <label for="text-input">Agency Pin
    <span class="required-asterisk">*</span>
  </label>

  <form [formGroup]="pinSearchForm">
    <input numbersOnly type="text" id="agencypin" name="agencypin" formControlName="agencypin" required minlength="5"
      maxlength="5" class="form-control form-control-sm" pattern="^[0-9]{5}$" (keyup)="validatePin($event)"
      [class.is-invalid]="
      agencypin.invalid && (agencypin.dirty || agencypin.touched)
    " />
    <div *ngIf="agencypin.invalid && (agencypin.dirty || agencypin.touched)" class="invalid-feedback">
      <div *ngIf="agencypin.errors?.['required']">
        This field is required.
      </div>
      <div *ngIf="agencypin.errors?.['pattern']">
        This field must be 5 digits.
      </div>
      <div *ngIf="agencypin.errors?.['invalidPin']">
        {{agencypin.errors?.['invalidPin']}}
      </div>
    </div>
    <div class="agency_search_results_container" *ngIf="
      agencySearchResults && agencySearchResults.length && (!selectedAgency || !selectedAgency.agencyID)
    ">
      <ul class="agency_search_results">
        <li class="agency_search_result" *ngFor="let agency of agencySearchResults"
          (click)="selectAgencyByAgencyID(agency)">
          <div class="agency_shortName_container">
            <div class="agency_search_results_shortName">
              {{ agency.shortName }}
            </div>
          </div>

          <div class="agency_search_results_demographic_info_container">
            <ul class="agency_search_results_demographic_info">
              <li class="agency_search_results_agency_name">
                {{ agency.agencyName }}
              </li>
              <li>
                {{ agency.address }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="selected_agency" *ngIf="selectedAgency">
      <ul class="selected_agency_demographic_info">
        <li class="selected_agency__agency_name">
          {{ selectedAgency.agencyName }}
        </li>
        <li>
          {{ selectedAgency.address }}
        </li>
      </ul>
    </div>

    <div *ngIf="isPinSearchLoading">
      <ameren-loading></ameren-loading>
    </div>

    <div>
      <button [disabled]="!pinSearchForm.valid || isPinSearchLoading || isSubmitLoading || !selectedAgency || !selectedAgency.agencyID" class="btn btn-success pin_search_submit" (click)="submitAgencySelection()">Submit</button>
    </div>
    <div *ngIf="isSubmitLoading">
      <ameren-loading></ameren-loading>
    </div>
  </form>
</div>