import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CorrelationService {
    private correlationId: string;

    getCorrelationId() {
     const value = Math.random().toString(12) + new Date().getTime().toString(12);
     this.correlationId = [value.substring(0,8), value.substring(8,2), 'EAP', value.substring(13,1)].join('-');
     return this.correlationId;
    }

}