import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { IGlobalAPIResponse } from '../../../../../src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from '../../../../../src/app/shared/models/amerenError';
import { RegistrationApiService } from './registration-api.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RegistrationManagerService {
  constructor(private registrationService: RegistrationApiService) {}

  submitRegistration(data: any) {
    return this.registrationService.submitRegistration(data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'User Submission has failed',
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  submitAgencyRegistration(data: any) {
    return this.registrationService.submitAgencyRegistration(data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'User Submission has failed',
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  updateProfile(data: any) {
    return this.registrationService.updateProfile(data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'User Submission has failed',
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  changePassword(email: string, currentPass: string, newPass: string) {
    return this.registrationService
      .changePassword(email, currentPass, newPass)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(
            () => new AmerenError(error.error.message, error.status)
          );
        })
      );
  }

  recaptchaVerification(data: any) {
    return this.registrationService.recaptchaVerification(data).pipe(
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Recaptcha verification is failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  validateAgencyPin(pin: string) {
    return this.registrationService.validateAgencyPin(pin).pipe(
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'Validate Agency Pin is failed',
            500,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
