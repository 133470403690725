import * as _ from 'lodash';

export class Profile {
  firstName?: string;
  telephoneNumber?: string;
  auditTrackingId?: string;
  isverified?: string;
  agencyList?: AgencyRoleDetails[];
  azp?: string;
  email?: string;
  isMigrated?: string;
  stateProvince?: string;
  lastName?: string;
  lastLoginTime?: string;
  isOtpEnabled?: string;
  pwdChangedTime?: string;
  selectedAgency?: any;
  isUserWithMultiAgency?: any;
  selectedAgencyName?: string;
  selectedAgencyPIN?: any;
  selectedAgencyAddress?: any;
  selectedAgencyID?: any;

  constructor(
    firstName?: string,
    telephoneNumber?: string,
    auditTrackingId?: string,
    isverified?: string,
    agencyList?: any,
    azp?: string,
    email?: string,
    isMigrated?: string,
    stateProvince?: string,
    lastName?: string,
    lastLoginTime?: string,
    isOtpEnabled?: string,
    pwdChangedTime?: string,
    selectedAgencyName?: string,
    selectedAgencyPIN?: any,
    selectedAgencyAddress?: any,
    selectedAgencyID?: any
  ) {
    this.firstName = !_.isNil(firstName) ? firstName : '';
    this.telephoneNumber = !_.isNil(telephoneNumber) ? telephoneNumber : '';
    this.auditTrackingId = !_.isNil(auditTrackingId) ? auditTrackingId : '';
    this.isverified = !_.isNil(isverified) ? isverified : '';
    this.agencyList = !_.isNil(this.getAgencyMapDetails(agencyList))
      ? this.getAgencyMapDetails(agencyList)
      : [];
    this.azp = !_.isNil(azp) ? azp : '';
    this.email = !_.isNil(email) ? email : '';
    this.isMigrated = !_.isNil(isMigrated) ? isMigrated : '';
    this.stateProvince = !_.isNil(stateProvince) ? stateProvince : '';
    this.lastName = !_.isNil(lastName) ? lastName : '';
    this.lastLoginTime = !_.isNil(lastLoginTime) ? lastLoginTime : '';
    this.isOtpEnabled = !_.isNil(isOtpEnabled) ? isOtpEnabled : '';
    this.pwdChangedTime = !_.isNil(pwdChangedTime) ? pwdChangedTime : '';
    this.selectedAgency =
      !_.isNil(this.agencyList) && this.agencyList[0]
        ? this.agencyList[0].AgencyID
        : [];
    this.isUserWithMultiAgency =
      !_.isNil(this.agencyList) && this.agencyList.length > 1 ? true : false;
    this.selectedAgencyName = !_.isNil(selectedAgencyName)
      ? selectedAgencyName
      : '';
    this.selectedAgencyPIN = !_.isNil(this.selectedAgencyPIN)
      ? selectedAgencyPIN
      : '';
    this.selectedAgencyAddress = !_.isNil(this.selectedAgencyAddress)
      ? selectedAgencyAddress
      : '';
    this.selectedAgencyID = !_.isNil(this.selectedAgencyID)
      ? selectedAgencyID
      : '';
  }

  getAgencyMapDetails(agencyList: AgencyRoleDetails[]) {
    if (agencyList) {
      return agencyList.filter(
        (value1, i, arr) =>
          arr.findIndex((value2) => value2.AgencyID === value1.AgencyID) === i
      );
    }
  }
}

export class AgencyRoleDetails {
  AgencyID: string;
  role: string;
  status: string;
}
