<div class="state-selector" *ngIf="showStateSelector">
    <div class="state-selector-close-btn">
        <button (click)="closeStateSelector(selectedState)">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

                <g>
                    <line x1="7" y1="7" x2="17" y2="17"></line>
                    <line x1="17" y1="7" x2="7" y2="17"></line>
                </g>
            </svg></button>
    </div>
    <div class="state-selector-inner">
        <a (click)="closeStateSelector()" routerLink="" id="header-home" class="btn btn-default btn-block state-selector-home-btn">Corporate Home</a>

        <div class="state-selector-divider">
            <span>
                or
            </span>
        </div>
        <h4>Select your state to personalize your experience</h4>
        <div class="state-selector-container">
            <div class="col-xs-6">
                <a [routerLink]="['illinois']" (click)="closeStateSelector('illinois')" id="header-state-link-Illinois" data-state="en-US-il"
                    class="state-container-illinois state--active state-link">
                    <svg [class.active]="selectedState=='illinois'" xmlns="http://www.w3.org/2000/svg" viewBox="2937 -636.16 66.594 118.605">
                        <path
                            d="M2.945,48.082c-3.138,2.051-.362,10.861,1.207,13.274.965,1.689,3.5,3.138,5.189,5.31,2.051,2.655,4.586,3.982,5.792,7.361v.241a11.156,11.156,0,0,0,.6,4.224,4.461,4.461,0,0,0,.6,1.327c3.379-1.81,10.016-1.569,8.568,3.741-.6,2.172-2.051,7.6-3.862,8.93-.483.483-.6.845-.121,1.448a7.326,7.326,0,0,1,.845,1.207,28.554,28.554,0,0,0,3.5,4.1c1.207.965,2.293,2.051,3.258,2.775,2.172,1.569,3.379,1.448,4.948,4.1,1.207,1.931,1.448,3.741,2.293,6.034a3.846,3.846,0,0,1,0,3.258c-.724,1.448-.241,2.413.965,3.741,1.086,1.207,2.9,2.534,3.982.362,1.207-2.293,3.258-2.413,5.068-2.172s5.068,4.586,6.516,1.81c.965-1.931-1.327-3.62,0-5.43a16.118,16.118,0,0,1,2.776-1.931c3.741-1.689,2.051,1.207,2.534-3.62.121-3.138,1.086-1.327,1.569-3.017.241-.845-.483-1.689-.241-2.775.241-1.569.965-2.9,1.327-4.344.241-1.207.121-2.655.724-3.741.845-1.81,4.706-5.672,6.516-8.809,1.086-1.81.362-4.948-.362-6.758a17.752,17.752,0,0,1-.965-3.62c0-2.172,1.569-2.172,1.569-5.31,0-8.93.121-17.739.121-26.307,0-6.758.241-21.239.241-23.411a5.987,5.987,0,0,0-.362-1.569,14.588,14.588,0,0,1-.965-3.017,8.8,8.8,0,0,0-1.448-3.62A9.9,9.9,0,0,1,63.4,4.157V2.226c-.362-.241-.845-.121-1.448-.121H17.305c.121.121.121.241.241.241a11.485,11.485,0,0,1,3.017,2.9c.845,1.207.241,1.448,1.81,2.775.845.724,1.81.845,2.413,1.81,2.172,3.62,0,6.4-1.81,8.326-1.689,1.931-2.413,5.31-6.154,6.637-2.413.845-7.482.241-7.6,3.982,0,1.448,1.086,1.689,1.81,3.017,1.086,2.051.6,3.379-.483,5.551-.724,1.81-1.81,4.586-3.5,5.792-1.327.965-2.172.483-2.655,1.81C3.066,45.668,5,46.754,2.945,48.082Z"
                            transform="translate(2935.485 -638.235)"></path>
                    </svg>
                    <span>Illinois</span>
                </a>

            </div>
            <div class="col-xs-6">
                <a [routerLink]="['missouri']" (click)="closeStateSelector('missouri')" id="header-state-link-Missouri" data-state="en-US-mo"
                    class="state-container-missouri state-link">
                    <svg [class.active]="selectedState=='missouri'" xmlns="http://www.w3.org/2000/svg" viewBox="3082 -622.885 102.892 93.643">
                        <path
                            d="M100.54,81.331c1.207-.6,2.534-.6,2.534-1.81s.845-3.62-.241-4.706c-.6-.724-1.569-.362-2.293-.724-1.327-.845-1.569-1.931-2.413-3.138-1.81-2.655.121-4.706-1.327-7.844-1.569-3.5-3.379-3.862-5.792-6.516-2.293-2.655-6.758-5.068-7.361-8.688-.483-2.775,3.017-4.948,2.776-7.844-.362-4.1.241-1.689-1.448-4.586-1.569-2.775-3.379,1.81-4.706.362-1.931-2.051-1.569-5.792-3.258-8.326-2.776-4.224-7.844-6.154-9.9-10.861-1.569-3.741.483-11.1-2.413-13.636a13.766,13.766,0,0,1-1.327-1.327A6.63,6.63,0,0,0,59.39.6H.5C.38,1.083,2.432,5.306,3.638,6.634c2.776,2.9,7.6,5.31,9.292,11.585.362,1.327-1.207,3.258-1.086,4.706.121.845,6.034,8.326,6.034,9.9.121,15.688-.241,31.254.483,46.942,0,.845-.241,3.741.483,4.1.483.241,66.13,0,67.578,0,1.569,0,3.379.6,1.81,3.017-1.086,1.689-5.672,4.827-6.758,7.24,2.413,0,9.654.121,12.912.121C95.713,91.588,99.334,85.554,100.54,81.331Z"
                            transform="translate(3081.504 -623.485)"></path>
                    </svg>
                    <span>Missouri</span>
                </a>

            </div>
        </div>
    </div>


</div>