import { AgencyuserComponent } from './secure/administration/agencyuser/agencyuser.component';
import { ClientComponent } from './secure/client/client.component';
import { EditProfileComponent } from './secure/manageprofile/edit-profile/edit-profile.component';
import { ManageprofileComponent } from './secure/manageprofile/manageprofile.component';
import { RegisterNewAgencyComponent } from './public/register-new-agency/register-new-agency.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { RegistrationComponent } from './public/registration/registration.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { AgencyReviewComponent } from './secure/agency-review/agency-review.component';
import { CampaignListComponent } from './secure/campaigns/campaign-list/campaign-list.component';
import { AddCampaignComponent } from './secure/campaigns/add-campaign/add-campaign.component';
import { ClientListComponent } from './secure/client-list/client-list.component';
import { RegistrationSuccessComponent } from './public/registration/registration-success/registration-success.component';
import { SigninRedirectCallbackComponent } from './public/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './public/signout-redirect-callback/signout-redirect-callback.component';
import { AgencyRegistrationSuccessComponent } from './public/register-new-agency/agency-registration-success/agency-registration-success.component';
import { AdministrationComponent } from './secure/administration/administration.component';
import { ClientGuardService } from './shared/services/client-guard.service';
import { ClientQuickViewComponent } from './secure/client-quick-view/client-quick-view.component';
import { BillPaymentHistoryComponent } from './secure/bill-payment-history/bill-payment-history.component';
import { PledgeEntryComponent } from './secure/pledge-entry/pledge-entry.component';
import { PledgeNotificationComponent } from './secure/pledge-notification/pledge-notification.component';
import { DisconnectHistoryComponent } from './secure/disconnect-history/disconnect-history.component';
import { ClientPledgeComponent } from './secure/client-pledge/client-pledge.component';
import { AgencypledgesComponent } from './secure/agencypledges/agencypledges.component';
import { IneligibleUserComponent } from './secure/ineligible-user/ineligible-user.component';
import { EmulationComponent } from './public/emulation/emulation.component';
import { SelectAgencyComponent } from './secure/select-agency/select-agency.component';
import { CampaignDetailsComponent } from './secure/campaigns/campaign-details/campaign-details.component';
import { CampaignActivityComponent } from './secure/campaigns/campaign-activity/campaign-activity.component';
import { ClientAccountsComponent } from './secure/client/client-accounts/client-accounts.component';
import { TrainingListComponent } from './secure/training/training-list/training-list.component';
import { MessageBoardComponent } from './secure/administration/message-board/message-board.component';
import { SilentRenewComponent } from './public/silent-renew/silent-renew.component';
import { SilentCallbackComponent } from './public/silent-callback/silent-callback.component';

const routes: Routes = [
  {
    path: 'home',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'illinois',
    component: HomeComponent,
  },
  {
    path: 'missouri',
    component: HomeComponent,
  },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'missouri/registration',
    component: RegistrationComponent,
  },
  {
    path: 'illinois/registration',
    component: RegistrationComponent,
  },
  {
    path: 'missouri/agency-registration',
    component: RegisterNewAgencyComponent,
  },
  {
    path: 'illinois/agency-registration',
    component: RegisterNewAgencyComponent,
  },
  {
    path: 'agency-registration',
    component: RegisterNewAgencyComponent,
  },
  {
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'agency-administration',
    component: AdministrationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'agency-review',
    component: AgencyReviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'campaign-list',
    component: CampaignListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-campaign',
    component: AddCampaignComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'campaign-activity',
    component: CampaignActivityComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'search-client',
    component: ClientListComponent,
    canActivate: [AuthGuardService, ClientGuardService],
  },
  {
    path: 'registration-success',
    component: RegistrationSuccessComponent,
  },
  {
    path: 'agency-registration-success',
    component: AgencyRegistrationSuccessComponent,
  },
  {
    path: 'signincallback',
    component: SigninRedirectCallbackComponent,
  },
  {
    path: 'signoutcallback',
    component: SignoutRedirectCallbackComponent,
  },
  {
    path: 'client',
    component: ClientComponent,
    canActivate: [AuthGuardService, ClientGuardService],
    children: [
      { path: 'client-quick-view', component: ClientQuickViewComponent },
      { path: 'bill-payment-history', component: BillPaymentHistoryComponent },
      { path: 'pledge-notification', component: PledgeNotificationComponent },
      { path: 'disconnect-history', component: DisconnectHistoryComponent },
      { path: 'client-pledge', component: ClientPledgeComponent },
      { path: 'pledge-entry', component: PledgeEntryComponent },
      { path: 'client-accounts', component: ClientAccountsComponent },
    ],
  },
  {
    path: 'manage-profile',
    component: ManageprofileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'agency-pledges',
    component: AgencypledgesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'ineligible-user',
    component: IneligibleUserComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'emulation',
    component: EmulationComponent,
  },
  {
    path: 'select-agency',
    component: SelectAgencyComponent,
  },
  {
    path: 'campaign-details/:campaignid',
    component: CampaignDetailsComponent,
  },
  {
    path: 'agency-pledges/:pledge-type',
    component: AgencypledgesComponent,
  },
  {
    path: 'training',
    component: TrainingListComponent,
  },
  {
    path: 'message-board',
    component: MessageBoardComponent,
  },
  {
    path: 'silentrenew',
    component: SilentRenewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'silentcallback',
    component: SilentCallbackComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
