import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client.service';
import { DisconnectCorrespondence } from './model/disconnect-correspondence.model';
import { DisconnectHistoryApiManagerService } from './services/disconnect-history-api-manager.service';
import * as FileSaver from 'file-saver';

declare var window: any;

@Component({
  selector: 'app-disconnect-history',
  templateUrl: './disconnect-history.component.html',
  styleUrls: ['./disconnect-history.component.scss'],
})
export class DisconnectHistoryComponent implements OnInit {
  isLoading: boolean = true;
  isAPIFailure: boolean = false;
  isPDFLoading: boolean = false;
  isPDFAPIFailure: boolean = false;
  disconnectHistory: DisconnectCorrespondence[] = [];
  selectedClient: Client;
  disconnectPDFAPIFailure: boolean = false;
  disconnectPDFLoading: boolean = false;
  disconnectPDFModal: any;

  constructor(
    private disconnectHistoryManager: DisconnectHistoryApiManagerService,
    private clientService: ClientService
  ) {
    this.selectedClient = this.clientService.getSelectedClient();
  }

  ngOnInit(): void {
    this.getDisconnectHistory();
    this.disconnectPDFModal = new window.bootstrap.Modal(
      document.getElementById('viewDisconnectPDF'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
  }

  getDisconnectHistory() {
    this.isLoading = true;
    return this.disconnectHistoryManager
      .getDisconnectHistory(this.selectedClient.billAccountNumber)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.disconnectHistory = res;
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error && error.error.errorDetails.errorCode === 100) {
            this.disconnectHistory = [];
          } else {
            this.isAPIFailure = true;
          }
        },
      });
  }

  getDisconnectPDF(disconnectHistoryItem: DisconnectCorrespondence) {
    const billAccount = this.selectedClient.billAccountNumber;
    this.disconnectPDFModal.show();
    this.disconnectPDFAPIFailure = false;
    this.disconnectPDFLoading = true;
    this.disconnectHistoryManager
      .getDisconnectPDF(billAccount, disconnectHistoryItem)
      .subscribe({
        next: (blobData: Blob) => {
          this.disconnectPDFAPIFailure = false;
          this.disconnectPDFLoading = false;
          this.disconnectPDFModal.hide();
          let file = new Blob([blobData], { type: 'application/pdf' });
          FileSaver.saveAs(file, `disconnect-history.pdf`);
        },
        error: (error: any) => {
          this.disconnectPDFAPIFailure = true;
          this.disconnectPDFLoading = false;
          this.disconnectPDFModal.show();
          console.log('error', error);
        },
      });
  }
}
