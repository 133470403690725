import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ClientManagerService } from '../services/client-manager.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/shared/services/client.service';
import { AtLeastSomeRequired } from 'src/app/shared/validators/atleast-some-required';
import { Client } from 'src/app/shared/models/client';
import { Address } from 'src/app/shared/models/address';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AgencyService } from 'src/app/shared/services/agency.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss'],
})
export class ClientSelectorComponent implements OnInit {
  showClientSelector: boolean = false;
  searchForm: FormGroup;
  selectedClient: Client;
  loading: boolean;
  CurrentUser: any;
  showApiError: boolean;
  selectedAgency: any;
  isSSNSearchClientFlow: boolean = false;

  constructor(
    private _clientSvc: ClientService,
    private _clientManagerService: ClientManagerService,
    private _fb: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private agencyService: AgencyService
  ) {
    this._clientSvc.showClientSelector.subscribe((val) => {
      this.showClientSelector = val;
    });

    this._clientSvc.clientUpdatedSubject.subscribe(() => {
      this.selectedClient = this._clientSvc.getSelectedClient();
    });

    this.agencyService.agencyUpdatedSubject.subscribe(() => {
      this.selectedAgency = this.agencyService.getSelectedAgency();
    });
  }

  ngOnInit(): void {
    this.initSearchForm();
    this.selectedAgency = this.agencyService.getSelectedAgency();
  }

  closeSelector() {
    this.showApiError = false;
    this._clientSvc.setShowClientSelector(false);
  }

  searchClient() {
    if (this.searchForm.valid) {
      let model = this.searchForm.value;
      this.isSSNSearchClientFlow =
        !_.isNil(model.customerName) && !_.isNil(model.ssn);
      this.loading = true;
      this._authService.getUser().then((user) => {
        this.CurrentUser = user?.profile;
        this._clientManagerService
          .getAccountStatusByBillAccount(
            model.billAccount ? model.billAccount : '0',
            model.customerName ? model.customerName : '',
            model.ssn ? model.ssn : '0',
            this.CurrentUser?.stateProvince,
            this.selectedAgency.agencyID,
            this.CurrentUser.email
          )
          .subscribe({
            next: (res) => {
              this.loading = false;
              if (res?.data?.isViewable)
                this.getRelatedAccountsByBillAccount(
                  res.data.billAccountNumber
                );
              else this.showApiError = true;
            },
            error: (error) => {
              this.loading = false;
              this.showApiError = true;
              this.searchForm.reset();
            },
          });
      });
    }
  }

  getRelatedAccountsByBillAccount(billAccount: string) {
    this.loading = true;
    this._clientManagerService
      .getRelatedAccountsByBillAccount(billAccount)
      .subscribe({
        next: (resp) => {
          this.loading = false;
          this.showApiError = false;
          this.handleGetRelatedAccountsApiSuccess(resp);
        },
        error: (error) => {
          this.loading = false;
          this.showApiError = true;
          this.searchForm.reset();
        },
      });
  }

  private handleGetRelatedAccountsApiSuccess(res: IGlobalAPIResponse) {
    let inputAccountNumber = this.searchForm.value.billAccount;
    this.loading = false;
    let clientResp: any;
    if (inputAccountNumber) {
      clientResp = res.data.filter(
        (x: any) => x.billAccountNumber == inputAccountNumber
      )[0];
    } else {
      clientResp = res.data.filter(
        (s: any) =>
          s.clientAccountInfo.address.state == this.CurrentUser.stateProvince
      )[0];
    }
    let client = new Client();
    client.address = new Address();
    client.billAccountNumber = clientResp.billAccountNumber;
    client.firstName = clientResp.clientAccountInfo.firstName;
    client.lastName = clientResp.clientAccountInfo.lastName;
    client.fullName = clientResp.clientAccountInfo.fullName;
    client.address.city = clientResp.clientAccountInfo.address.city;
    client.address.state = clientResp.clientAccountInfo.address.state;
    client.address.zip = clientResp.clientAccountInfo.address.zip;
    client.address.streetAddress =
      clientResp.clientAccountInfo.address.addressLines[0];
    this._clientSvc.setSelectedClient(client);
    this.searchForm.reset();
    this.closeSelector();
    if (this.isSSNSearchClientFlow && res.data.length >= 2) {
      this._router.navigateByUrl('/client/client-accounts');
    } else {
      this._router.navigateByUrl('/client/client-quick-view');
    }
  }

  initSearchForm() {
    this.searchForm = this._fb.group(
      {
        billAccount: new FormControl(null, [
          Validators.pattern('^$|^[0-9]{10}$'),
        ]),
        customerName: new FormControl(null),
        ssn: new FormControl(null, [Validators.pattern('^$|^[0-9]{9}$')]),
      },
      {
        validators: AtLeastSomeRequired(
          ['billAccount', 'ssn', 'customerName'],
          2
        ),
      }
    );
  }
}
