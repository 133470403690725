import { of } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientApiService {
  constructor(private http: HttpClient) {}

  getAccountStatusBySSN(ssn: string, customerName: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('ssn', ssn);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/accountstatusbyssn?customerName=${customerName}`,
      { headers }
    );
  }

  getAccountStatusByBillaccount(
    billAccount: string,
    accountName: string,
    ssn: string,
    state: string,
    agencyId: Number,
    requestor: string
  ) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('ssn', ssn)
      .set('requestor', requestor)
      .set('agencyid', agencyId.toString())
      .set('state', state)
      .set('accountname', accountName);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/accountstatusbybillaccount?billAccountNumber=${billAccount}`,
      { headers }
    );
  }

  getRelatedAccountsByBillAccount(billAccount: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private');

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/relatedaccounts?billAccountNumber=${billAccount}`,
      { headers }
    );
  }

  getAccountFinancials(billAccount: string, eapPin: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('eapPin', eapPin);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/accountfinancials?accountNumber=${billAccount}`,
      { headers }
    );
  }

  getPaymentagreementsByBillAccountNumber(billAccountNumber: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('userid', 'EAP')
      .set('channel', 'EAP');

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/paymentagreement?billAccountNumber=${billAccountNumber}`,
      { headers }
    );
  }

  getBillingAndPaymentHistory(
    billAccountNumber: string,
    fromDate: string,
    toDate: string
  ) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private');

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/activitystatement?dateFrom=${fromDate}&billAccountNumber=${billAccountNumber}&dateTo=${toDate}`,
      { headers }
    );
  }

  getViewBillPDF(billImageId: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('realm', 'partner');

    return this.http.get(
      `${environment.openIGurl}/web-report-e/v2/webguid/${billImageId}`,
      { headers, responseType: 'blob' }
    );
  }
}
