import { Injectable } from '@angular/core';
import { catchError, throwError, map } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { ManageprofileApiService } from './manageprofile-api.service';

@Injectable({
  providedIn: 'root'
})
export class ManageprofileApiManagerService {

  constructor(private manageProfileApiService: ManageprofileApiService) { }

  retrieveAgencyInfo(agencyID: string){
    return this.manageProfileApiService.retrieveAgencybyID(agencyID).pipe(map((res: IGlobalAPIResponse) => {
      if (!res.success){
        throw new AmerenError('Agnecy information cannot be retrieved',400,null,null,null);
      }
      return res;
    }),
    catchError((error:any) => {
      return throwError(() => error);
    })
    );
  }  
}
