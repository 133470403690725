import { Byte } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { catchError, throwError, map } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { DisconnectCorrespondence } from '../model/disconnect-correspondence.model';
import { DisconnectHistoryApiService } from './disconnect-history-api.service';

export interface IDisconnectHistoryAPIResponse {
  billAccountNumber: string;
  processDate: Date;
  letterTypeCode: string;
  letterTypeDescription: string;
  pdfLink: string;
}

@Injectable({
  providedIn: 'root',
})
export class DisconnectHistoryApiManagerService {
  disconnectHistory: DisconnectCorrespondence[];

  constructor(
    private disconnectHistoryApiService: DisconnectHistoryApiService
  ) {
    this.disconnectHistory = [];
  }

  getDisconnectHistory(billAccount: any) {
    return this.disconnectHistoryApiService
      .getCorrespondenceByBillAccount(billAccount)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Disconnect History cannot be retrieved',
              400
            );
          }
          const disconnectHistoryData = res.data;
          this.disconnectHistory = [];

          if (disconnectHistoryData instanceof Array) {
            disconnectHistoryData.forEach(
              (correspondenceItem: IDisconnectHistoryAPIResponse) => {
                if (
                  correspondenceItem.letterTypeCode == '1134' ||
                  correspondenceItem.letterTypeCode == '1135'
                ) {
                  this.disconnectHistory.push(
                    new DisconnectCorrespondence(
                      correspondenceItem.processDate,
                      correspondenceItem.letterTypeDescription,
                      correspondenceItem.pdfLink
                    )
                  );
                }
              }
            );
            return this.disconnectHistory;
          }
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getCorrespondencePdf(billAccount: string, pdfData: DisconnectCorrespondence) {
    return this.disconnectHistoryApiService
      .getCorrespondencePdf(billAccount, pdfData)
      .pipe(
        map((res: Blob) => {
          return res;
        }),
        catchError((error: any) => {
          console.log('error', error);
          return throwError(() => error);
        })
      );
  }

  getDisconnectPDF(billAccount: string, pdfData: DisconnectCorrespondence) {
    return this.disconnectHistoryApiService
      .getDisconnectPDF(billAccount, pdfData)
      .pipe(
        map((data: Blob) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }
}
