import { Component, OnInit } from '@angular/core';
import { AgencyByID } from './models/agencybyid.model';
import { AgencyRegistrationList } from './models/agencyregistrationlist.model';
import { AgencyReviewManagerService } from './services/agency-review-manager.service';
import * as _ from 'lodash';
import { Profile } from '../../shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { EmailConstants } from 'src/app/shared/constants/emailConstants';

declare var window: any;

@Component({
  selector: 'app-agency-review',
  templateUrl: './agency-review.component.html',
  styleUrls: ['./agency-review.component.scss'],
})
export class AgencyReviewComponent implements OnInit {
  registrationList: AgencyRegistrationList[];
  isLoading: boolean;
  isAPIFailure: boolean;
  registrationDetails: AgencyByID;
  isLoadingforAgencyById: boolean;
  isAPIFailureAgencyById: boolean;
  profile: Profile[];
  userProfile: Profile;
  pendingReviewAgencyList: AgencyRegistrationList[];
  deniedReviewAgencyList: AgencyRegistrationList[];
  approvedReviewAgencyList: AgencyRegistrationList[];
  searchPendingReviewString: any;
  searchApprovedReviewString: any;
  searchDeniedReviewString: any;
  isApprovalLoading: boolean;
  isApprovalAPIFailure: boolean;
  agencyReviewErrorHandlingModel: any;

  constructor(
    private agencyReviewManager: AgencyReviewManagerService,
    private profileManager: ProfileManagerService,
    private _utility: UtilitiesService
  ) {
    this.profile = this.profileManager.profile;

    this.profileManager.profile.forEach((a: Profile) => {
      this.userProfile = a;
    });
  }

  ngOnInit(): void {
    this.agencyReviewErrorHandlingModel = new window.bootstrap.Modal(
      document.getElementById('agencyReviewModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.getAgencyRegistrationList();
  }

  getAgencyRegistrationList() {
    this.isLoading = true;
    this.isAPIFailure = false;
    this.agencyReviewManager.getAgencyRegistrationList().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.isAPIFailure = false;
        let agencyList = [];
        this.registrationList = [];
        let statecode =
          this.profile[0].stateProvince?.toLowerCase() === 'mo' ? 17 : 18;
        agencyList = res.filter((data: any) => data.companyCode === statecode);
        this.registrationList = agencyList;

        //Pending Review Agency List
        this.pendingReviewAgencyList = agencyList.filter(
          (data: any) => data.registrationStatus.toLowerCase() === 'pending'
        );

        //Denied Agency List
        this.deniedReviewAgencyList = agencyList.filter(
          (data: any) => data.registrationStatus.toLowerCase() === 'denied'
        );

        //Approved Agency List
        this.approvedReviewAgencyList = agencyList.filter(
          (data: any) => data.registrationStatus.toLowerCase() === 'approved'
        );
      },
      error: (error: any) => {
        console.log('error', error);
        this.isLoading = false;
        this.isAPIFailure = true;
      },
    });
  }

  getRegistrationById(registrationid: any) {
    this.isLoadingforAgencyById = true;
    this.isAPIFailureAgencyById = false;
    return new Promise((resolve, reject) => {
      this.agencyReviewManager.getRegistrationById(registrationid).subscribe({
        next: (res: any) => {
          this.isLoadingforAgencyById = false;
          this.isAPIFailureAgencyById = false;
          const agencyData = this.registrationList.filter(
            (data) => data.registrationID === res.registrationID
          );

          agencyData.forEach((data) => {
            data.phoneNumber = !_.isNil(res.phoneNumber) ? res.phoneNumber : '';
            data.faxNumber = !_.isNil(res.faxNumber) ? res.faxNumber : '';
            data.emailAddress = !_.isNil(res.emailAddress)
              ? res.emailAddress
              : '';
            data.requestorName = !_.isNil(res.requestorName)
              ? res.requestorName
              : '';
            data.requestorTitle = !_.isNil(res.requestorTitle)
              ? res.requestorTitle
              : '';
            data.dateUpdated = !_.isNil(res.dateUpdated) ? res.dateUpdated : '';
            data.agencyStreetAddressAddressLine1 = !_.isNil(
              res.agencyStreetAddress.addressLine1
            )
              ? res.agencyStreetAddress.addressLine1
              : '';
            data.agencyStreetAddressCity = !_.isNil(
              res.agencyStreetAddress.city
            )
              ? res.agencyStreetAddress.city
              : '';
            data.agencyStreetAddressPostalCode = !_.isNil(
              res.agencyStreetAddress.postalCode
            )
              ? res.agencyStreetAddress.postalCode
              : '';
            data.agencyStreetAddressState = !_.isNil(
              res.agencyStreetAddress.state
            )
              ? res.agencyStreetAddress.state
              : '';
            data.agencyMailingAddressAddressLine1 = !_.isNil(
              res.agencyMailingAddress.addressLine1
            )
              ? res.agencyMailingAddress.addressLine1
              : '';
            data.agencyMailingAddressCity = !_.isNil(
              res.agencyMailingAddress.city
            )
              ? res.agencyMailingAddress.city
              : '';
            data.agencyMailingAddressPostalCode = !_.isNil(
              res.agencyMailingAddress.postalCode
            )
              ? res.agencyMailingAddress.postalCode
              : '';
            data.agencyMailingAddressState = !_.isNil(
              res.agencyMailingAddress.state
            )
              ? res.agencyMailingAddress.state
              : '';
            data.isCustomerReffered =
              res.isCustomerReffered === true ? 'Yes' : 'No';
            data.pdfImage = !_.isNil(res.pdfImage) ? res.pdfImage : '';
            data.serviceZipCodes = !_.isNil(res.serviceZipCodes)
              ? res.serviceZipCodes
              : [];
            data.agencyRepresentatives = !_.isNil(res.agencyRepresentatives)
              ? res.agencyRepresentatives
              : [];

            resolve(data);
          });
        },
        error: (error: any) => {
          this.isLoadingforAgencyById = false;
          this.isAPIFailureAgencyById = true;
          console.log('error', error);
          reject(error);
        },
      });
    });
  }

  async updateAgencyRegistration(registrationId: any, approvalStatus: string) {
    this.isApprovalLoading = true;
    this.isApprovalAPIFailure = false;
    await this.agencyReviewErrorHandlingModel.show();
    const userid = this.profile[0].email || 'EAP';
    this.agencyReviewManager
      .updateAgencyRegistration(approvalStatus, userid, registrationId)
      .subscribe({
        next: (res: any) => {
          this.isApprovalLoading = false;
          this.isApprovalAPIFailure = false;
          console.log('Update Regisgtration Status', res);
          this.getAgencyRegistrationList();
        },
        error: (error: any) => {
          this.isApprovalLoading = false;
          this.isApprovalAPIFailure = true;
          console.log('error', error);
        },
      });
  }

  async getAgencyDetails(registrationid: any) {
    await this.getRegistrationById(registrationid);
  }

  submitAgencyApproval(registrationId: any, approvalStatus: string) {
    this.updateAgencyRegistration(registrationId, approvalStatus);
  }

  async generatePDF(registrationId: any) {
    await this.getRegistrationById(registrationId);
    const pdfData = this.registrationList.filter(
      (data) => data.registrationID === registrationId
    );

    if (pdfData && pdfData.length > 0) {
      let agencyRegistrationData: AgencyRegistrationList = pdfData[0];
      let isMO: boolean =
        agencyRegistrationData.agencyState == 'MO' ? true : false;

       let portalTerms: string = `  Portal Terms and Conditions
 
 ACCOUNT ACCESS

  Agency and Representatives of Agency understand and agree that they
  are solely responsible for maintaining the integrity of the Agency's
  portal PIN and that each representative for the Agency must have their
  own unique PIN. Sharing of a representative's portal PIN with anyone
  is prohibited. The Agency PIN will only be used to access a client's
  Ameren Missouri customer account information if Agency has obtained
  written authorization from each of its Energy Assistance ("EA")
  clients to access the client's Ameren Missouri customer account
  information before accessing the client's Ameren Missouri customer
  account information via the portal. Any client account information
  received from Ameren Missouri and through the portal
  is confidential as to the client, the Agency, and
  Ameren Missouri, and Agency will take all reasonable
  safeguards to prevent further disclosure of this information without
  consent of the client. Agency and Representatives understand and agree
  to notify Ameren Missouri immediately if they believe the Agency's PIN
  has been compromised.


TRAINING ON USE OF PORTAL

  Ameren Missouri will offer Agency and Representatives training on how
  to access Ameren Missouri customer account information and make
  pledges via the portal. Agency and Representatives agree to
  participate in such training at least annually.


PLEDGES AND PAYMENTS

  A "pledge" is a promise by the EA Agency to pay the pledged amount
  toward a client's Ameren Missouri customer account, and may cause a
  client's Ameren Missouri customer account to be removed from the
  disconnection queue. Pledges are entered by Agency and Representatives
  through Ameren Missouri's EA Agency portal. Agency is responsible for
  securing any third-party funding for pledges it enters into the
  portal. Partial payments of pledges will not be accepted.


  Once the pledge is made, actual payment should be received by Ameren
  Missouri within 30 calendar days of the pledge being entered in the
  portal. Payment of a pledge via Electronic Funds Transfer ("EFT") is
  encouraged to meet the pledge/payment timeframes, but EFT is not
  required. If actual payment of the full pledged amount is not received
  by Ameren Missouri within 45 days of the pledge being entered in the
  portal, a notification of late payment will appear in the portal for
  the Agency and Representatives. If actual payment of the pledged
  amount is not received by Ameren Missouri within 60 days of the pledge
  being entered in the portal, Ameren Missouri's EA outreach team will
  reach out to the Agency and Representatives to discuss the outstanding
  payment and work out a payment plan. If actual payment of the pledged
  amount is not received by Ameren Missouri within 90 days of the pledge
  being entered in the portal, and Ameren Missouri has not received a
  payment plan from the Agency, the Agency's portal PIN may be
  deactivated. If an agency has more than 25% of the total dollars they
  have pledged in a calendar year open and unpaid beyond 60 days from
  the date pledged, the Agency's portal PIN may be deactivated. Please
  note, exceptions can be made due to government funding sources that
  payment terms to the Agency extend beyond 90 days with approval by
  Ameren Missouri leadership.

  If a payment is submitted for multiple pledges and/or customers, the
  Agency will submit a spreadsheet identifying each pledge/customer and
  corresponding amount of payment to be applied to the customer's
  account.

`
let portalTerms2: string = `NO WARRANTIES AND LIMITATION OF LIABILITY

Ameren Missouri strives to ensure the accuracy of the information
presented in the portal. However, due to various circumstances
including, but not limited to, delays caused by postal handling,
upgrades and/or changes to Ameren's bill and payment processing
system, there may be variances between the information available on
the portal and customer bill, payment, or other customer account
information. For this reason, the accuracy, truthfulness or
reliability of any information provided on or by means of the portal
is not guaranteed.


AMEREN MISSOURI, ON BEHALF OF ITSELF AND ALL OF ITS AFFILIATES,
SUBSIDIARIES, BUSINESS UNITS, CONTENT PROVIDERS, AGENTS AND THE LIKE,
HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND/OR
REPRESENTATIONS, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN,
INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF
MERCHANTABILITY, REASONABLE CARE, COMPATIBILITY, SECURITY,
RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE
(WHETHER OR NOT AMEREN KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR
IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), IN EACH INSTANCE IN
RESPECT OF THE PORTAL. FURTHERMORE, AMEREN, ON BEHALF OF ITSELF AND
ALL OF ITS AFFILIATES, SUBSIDIARIES, BUSINESS UNITS, CONTENT
PROVIDERS, AGENTS AND THE LIKE, HEREBY EXPRESSLY DISCLAIMS ANY AND ALL
WARRANTIES OF TITLE AND/OR NON-INFRINGEMENT IN RESPECT OF THE PORTAL.


MODIFICATION

Ameren Missouri may at any time modify these Terms & Conditions, and
Agency's continued use of the portal will indicate your agreement to
be governed by the terms and conditions in force at the time of your
use.

SEVERABILITY

These Terms and Conditions shall be deemed severable. In the event
that any provision is determined to be unenforceable or invalid, such
provision shall nonetheless be enforced to the fullest extent
permitted by applicable law, and such determination shall not affect
the validity and enforceability of any other remaining provisions.`

  if(!isMO){
   portalTerms = `  Portal Terms and Conditions
  
    ACCOUNT ACCESS
  
    Agency and Representatives of Agency understand and agree that they
    are solely responsible for maintaining the integrity of the Agency's
    portal PIN and that each representative for the Agency must have their
    own unique PIN. Sharing of a representative's portal PIN with anyone
    is prohibited. The Agency PIN will only be used to access a client's
    Ameren Illinois customer account information if Agency has obtained
    written authorization from each of its Energy Assistance ("EA")
    clients to access the client's Ameren Illinois customer account
    information before accessing the client's Ameren Illinois customer
    account information via the portal. Any client account information
    received from Ameren Illinois and through the portal
    is confidential as to the client, the Agency, and
    Ameren Illinois, and Agency will take all reasonable
    safeguards to prevent further disclosure of this information without
    consent of the client. Agency and Representatives understand and agree
    to notify Ameren Illinois immediately if they believe the Agency's PIN
    has been compromised.
    
    TRAINING ON USE OF PORTAL
    
    Ameren Illinois will offer Agency and Representatives training on how
    to access Ameren Illinois customer account information and make
    pledges via the portal. Agency and Representatives agree to
    participate in such training at least annually.
    
    PLEDGES AND PAYMENTS
    
    A "pledge" is a promise by the EA Agency to pay the pledged amount
    toward a client's Ameren Illinois customer account and may cause a
    client's Ameren Illinois customer account to be removed from the
    disconnection queue. Pledges are entered by Agency and Representatives
    through Ameren Illinois's EA Agency portal. Agency is responsible for
    securing any third-party funding for pledges it enters in the portal.
    Partial payments of pledges will not be accepted.
    
    
    Once the pledge is made, actual payment should be received by Ameren
    Illinois within 30 calendar days of the pledge being entered in the
    portal. Payment of a pledge via Electronic Funds Transfer ("EFT") is
    encouraged to meet the pledge/payment timeframes, but EFT is not
    required. If actual payment of the full pledged amount is not received
    by Ameren Illinois within 45 days of the pledge being entered in the
    portal, a notification of late payment will appear in the portal for
    the Agency and Representatives. If actual payment of the pledged
    amount is not received by Ameren Illinois within 60 days of the pledge
    being entered in the portal, Ameren Illinois's EA outreach team will
    reach out to the Agency and Representatives to discuss the outstanding
    payment and work out a payment plan. If actual payment of the pledged
    amount is not received by Ameren Illinois within 90 days of the pledge
    being entered in the portal, and Ameren Illinois has not received a
    payment plan from the Agency, the Agency's portal PIN may be
    deactivated. If an agency has more than 25% of the total dollars, they
    have pledged in a calendar year open and unpaid beyond 60 days from
    the date pledged, the Agency's portal PIN may be deactivated. Please
    note, exceptions can be made due to government funding sources that
    payment terms to the Agency extend beyond 90 days with approval by
    Ameren Illinois leadership.
    
    
    If a payment is submitted for multiple pledges and/or customers, the
    Agency will submit a spreadsheet identifying each pledge/customer and
    corresponding amount of payment to be applied to the customer's
    account.`;
  
    portalTerms2 = `NO WARRANTIES AND LIMITATION OF LIABILITY
  
    Ameren Illinois strives to ensure the accuracy of the information
    presented in the portal. However, due to various circumstances
    including, but not limited to, delays caused by postal handling,
    upgrades and/or changes to Ameren's bill and payment processing
    system, there may be variances between the information available on
    the portal and customer bill, payment, or other customer account
    information. For this reason, the accuracy, truthfulness or
    reliability of any information provided on or by means of the portal
    is not guaranteed.
    
    
    AMEREN ILLINOIS, ON BEHALF OF ITSELF AND ALL OF ITS AFFILIATES,
    SUBSIDIARIES, BUSINESS UNITS, CONTENT PROVIDERS, AGENTS AND THE LIKE,
    HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND/OR
    REPRESENTATIONS, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN,
    INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF
    MERCHANTABILITY, REASONABLE CARE, COMPATIBILITY, SECURITY,
    RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE
    (WHETHER OR NOT AMEREN KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR
    IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), IN EACH INSTANCE IN
    RESPECT OF THE PORTAL. FURTHERMORE, AMEREN, ON BEHALF OF ITSELF AND
    ALL OF ITS AFFILIATES, SUBSIDIARIES, BUSINESS UNITS, CONTENT
    PROVIDERS, AGENTS AND THE LIKE, HEREBY EXPRESSLY DISCLAIMS ANY AND ALL
    WARRANTIES OF TITLE AND/OR NON-INFRINGEMENT IN RESPECT OF THE PORTAL.
    
    MODIFICATION
    
    Ameren Illinois may at any time modify these Terms & Conditions, and
    Agency's continued use of the portal will indicate your agreement to
    be governed by the terms and conditions in force at the time of your
    use.
    
    SEVERABILITY
    
    These Terms and Conditions shall be deemed severable. If any provision
    is determined to be unenforceable or invalid, such provision shall
    nonetheless be enforced to the fullest extent permitted by applicable
    law, and such determination shall not affect the validity and
    enforceability of any other remaining provisions.`
  }

      let body = [
        {
          type: 'string',
          element: 'ENERGY ASSISTANCE AUTHORIZATION FORM',
          align: 'center',
          x: 10,
          y: 20,
          fontsize: 14,
          fontWeight: 'bold',
          sameRow: false,
          fontColor: 'green',
        },
        {
          type: 'string',
          element: 'To:',
          align: '',
          x: 20,
          y: 10,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: isMO ? 'Ameren Missouri' : 'Ameren Illinois',
          align: '',
          x: 25,
          y: 2,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: isMO
            ? EmailConstants.amerenMOAttn
            : EmailConstants.amerenILAttn,
          align: '',
          x: 25,
          y: 2,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: isMO
            ? EmailConstants.amerenMOAddressLine1
            : EmailConstants.amerenILAddressLine1,
          align: '',
          x: 25,
          y: 2,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: isMO
            ? EmailConstants.amerenMOAddressLine2
            : EmailConstants.amerenILAddressLine2,
          align: '',
          x: 25,
          y: 2,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: isMO
            ? EmailConstants.amerenMOFax
            : EmailConstants.amerenILFax,
          align: '',
          x: 25,
          y: 2,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: 'From:',
          align: '',
          x: 20,
          y: 8,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
        },
        {
          type: 'string',
          element: agencyRegistrationData.agencyName,
          align: '',
          x: 35,
          y: 8,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: agencyRegistrationData.phoneNumber,
          align: '',
          x: 130,
          y: 8,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 35,
          y: 0,
          x1: 35 + agencyRegistrationData.agencyName.length * 2,
          fontsize: 10,
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 130,
          y: 0,
          x1: 130 + agencyRegistrationData.phoneNumber.length * 2,
          fontsize: 10,
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'string',
          element: 'Agency',
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: 'Phone Number',
          align: '',
          x: 130,
          y: 0,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: true,
          offsetY: 2,
        },
        {
          type: 'string',
          element: agencyRegistrationData.faxNumber,
          align: '',
          x: 130,
          y: 4,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 1,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 130,
          y: 0,
          x1: 130 + agencyRegistrationData.faxNumber.length * 2,
          fontsize: 10,
          sameRow: false,
          offsetY: 1,
        },
        {
          type: 'string',
          element: 'Fax',
          align: '',
          x: 130,
          y: 4,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: false,
          offsetY: 2,
        },
        {
          type: 'string',
          element: agencyRegistrationData.emailAddress,
          align: '',
          x: 130,
          y: 4,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 1,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 130,
          y: 0,
          x1: 130 + (agencyRegistrationData.emailAddress?.length || 0) * 2,
          fontsize: 10,
          sameRow: false,
          offsetY: 1,
        },
        {
          type: 'string',
          element: 'Email Address',
          align: '',
          x: 130,
          y: 4,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: false,
          offsetY: 2,
        },
        {
          type: 'string',
          element: agencyRegistrationData.requestorName,
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: agencyRegistrationData.requestorTitle,
          align: '',
          x: 130,
          y: 0,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 35,
          y: 1,
          x1: 35 + agencyRegistrationData.requestorName.length * 2,
          fontsize: 10,
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 130,
          y: 0,
          x1: 130 + agencyRegistrationData.requestorTitle.length * 2,
          fontsize: 10,
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'string',
          element: 'Requester Name',
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: 'Requester Title',
          align: '',
          x: 130,
          y: 0,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: true,
          offsetY: 2,
        },
        {
          type: 'string',
          element: agencyRegistrationData.agencyStreetAddressAddressLine1,
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: agencyRegistrationData.agencyMailingAddressAddressLine1,
          align: '',
          x: 130,
          y: 0,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'string',
          element: `${agencyRegistrationData.agencyStreetAddressCity} ${agencyRegistrationData.agencyStreetAddressState} ${agencyRegistrationData.agencyStreetAddressPostalCode}`,
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: `${agencyRegistrationData.agencyMailingAddressCity} ${agencyRegistrationData.agencyMailingAddressState} ${agencyRegistrationData.agencyMailingAddressPostalCode}`,
          align: '',
          x: 130,
          y: 0,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 35,
          y: 1,
          x1:
            35 +
            `${agencyRegistrationData.agencyStreetAddressCity} ${agencyRegistrationData.agencyStreetAddressState} ${agencyRegistrationData.agencyStreetAddressPostalCode}`
              .length *
              2,
          fontsize: 10,
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 130,
          y: 0,
          x1:
            130 +
            `${agencyRegistrationData.agencyMailingAddressCity} ${agencyRegistrationData.agencyMailingAddressState} ${agencyRegistrationData.agencyMailingAddressPostalCode}`
              .length *
              2,
          fontsize: 10,
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'string',
          element: 'Agency Address',
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: 'Mailing Address',
          align: '',
          x: 130,
          y: 0,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: true,
          offsetY: 2,
          splitText: 0,
        },
      ];

      if (
        agencyRegistrationData.agencyRepresentatives &&
        agencyRegistrationData.agencyRepresentatives.length > 0
      ) {
        let repText =
          'Listed below are representatives of the agency named who are authorized by the clients of this agency to access Ameren customer account information and make pledges to customer accounts on behalf of this agency.';
        body.push({
          type: 'string',
          element: repText,
          align: '',
          x: 20,
          y: 8,
          fontsize: 8,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 5,
          splitText: 180,
        });

        for (
          let i = 0;
          i < agencyRegistrationData.agencyRepresentatives.length;
          i++
        ) {
          let agencyRepresentative =
            agencyRegistrationData.agencyRepresentatives[i];
          if (i % 2 === 0) {
            //display entries left side
            body.push({
              type: 'string',
              element: agencyRepresentative,
              align: '',
              x: 35,
              y: i === 0 ? 8 : 4,
              fontsize: 10,
              fontWeight: 'normal',
              sameRow: false,
              offsetY: 0,
            });

            body.push({
              type: 'line',
              element: 'line',
              align: '',
              x: 35,
              y: 1,
              x1: 35 + agencyRepresentative.length * 2,
              fontsize: 10,
              sameRow: false,
              offsetY: 0,
            });
          } else {
            //display entries right side
            body.push({
              type: 'string',
              element: agencyRepresentative,
              align: '',
              x: 130,
              y: 0,
              fontsize: 10,
              fontWeight: 'normal',
              sameRow: true,
              offsetY: 1,
            });

            body.push({
              type: 'line',
              element: 'line',
              align: '',
              x: 130,
              y: 0,
              x1: 130 + agencyRepresentative.length * 2,
              fontsize: 10,
              sameRow: true,
              offsetY: 1,
            });
          }
        }
      }

      body.push({
        type: 'checkbox',
        element: 'chk_eaagreement',
        align: '',
        x: 20,
        y: 8,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
        offsetY: 0,
        splitText: 180,
      });

      let eaAgreement =
        'I certify that these individuals are authorized to recieve and provide information to Ameren for the purpose or providing energy assitance to ';
      body.push({
        type: 'string',
        element: eaAgreement,
        align: '',
        x: 25,
        y: 2,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
        offsetY: 1,
        splitText: 180,
      });

      let eaAgreement1 =
        "clients served by the agency which name and address appear on this form. I also certify that should any of this information change, I will notify Ameren immediately. I understand that a confidential identification number will be issued to this agency by Ameren up on Ameren's receipt of this completed and signed form. I agree to make this identification number available to only the individuals representing this agency whose names appear above.";
      body.push({
        type: 'string',
        element: eaAgreement1,
        align: '',
        x: 20,
        y: 2,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
        offsetY: 5,
        splitText: 180,
      });

      body.push({
        type: 'checkbox',
        element: 'chk_confidentialgreement',
        align: '',
        x: 20,
        y: 15,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
        offsetY: 0,
        splitText: 180,
      });

      let confidentialAgreement =
        'I further understand that any client account information I recieve from Ameren is confidential as to the client, the agency, and Ameren; and I ';
      body.push({
        type: 'string',
        element: confidentialAgreement,
        align: '',
        x: 25,
        y: 2,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
        offsetY: 1,
        splitText: 180,
      });

      let confidentialAgreement1 =
        'agree to take all reasonable safegaruds, on behalf of the agency, to prevent further desssemination of this information without consent of the client. I represent to Ameren that this agency will not attempt to obtain any client information from Ameren until the agency has obtained the express consent of the client.';
      body.push({
        type: 'string',
        element: confidentialAgreement1,
        align: '',
        x: 20,
        y: 2,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
        offsetY: 5,
        splitText: 180,
      });

      let referralText =
        'Would your agency like customer referrals from Ameren?';
      body.push({
        type: 'string',
        element: 'Would your agency like customer referrals from Ameren?',
        align: '',
        x: 20,
        y: 15,
        fontsize: 10,
        fontWeight: 'bold',
        sameRow: false,
        offsetY: 0,
        splitText: 0,
      });

      body.push({
        type: 'string',
        element: agencyRegistrationData.isCustomerReffered,
        align: '',
        x: 20 + referralText.length + 45,
        y: 15,
        fontsize: 10,
        fontWeight: 'normal',
        sameRow: true,
        offsetY: 1,
        splitText: 0,
      });

      let dateParts = agencyRegistrationData.dateCreated.split('-');
      let date = new Date(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1,
        parseInt(dateParts[2])
      );
      let createdDate =
        date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

      body.push(
        {
          type: 'string',
          element: agencyRegistrationData.requestorName.toUpperCase(),
          align: '',
          x: 35,
          y: 15,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: agencyRegistrationData.requestorTitle,
          align: '',
          x: 90,
          y: 0,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 0,
        },
        {
          type: 'string',
          element: createdDate,
          align: '',
          x: 160,
          y: 0,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 35,
          y: 1,
          x1: 35 + agencyRegistrationData.requestorName.length * 2,
          fontsize: 10,
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 90,
          y: 0,
          x1: 90 + agencyRegistrationData.requestorTitle.length * 2,
          fontsize: 10,
          sameRow: true,
          offsetY: 0,
        },
        {
          type: 'line',
          element: 'line',
          align: '',
          x: 160,
          y: 0,
          x1: 160 + createdDate.length * 2,
          fontsize: 10,
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'string',
          element: 'Signature',
          align: '',
          x: 35,
          y: 4,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: false,
          offsetY: 0,
        },
        {
          type: 'string',
          element: 'Title',
          align: '',
          x: 90,
          y: 0,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: true,
          offsetY: 0,
        },
        {
          type: 'string',
          element: 'Date',
          align: '',
          x: 160,
          y: 0,
          fontsize: 10,
          fontWeight: 'bold',
          sameRow: true,
          offsetY: 1,
        },
        {
          type: 'page',
          element: portalTerms,
          align: '',
          x: 12,
          y: 20,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1     
        } ,
        {
          type: 'page',
          element: portalTerms2,
          align: '',
          x: 12,
          y: 20,
          fontsize: 10,
          fontWeight: 'normal',
          sameRow: true,
          offsetY: 1     
        }
      );

      this._utility.exportTabletoPDF(
        'AgencyAuthorizationForm.pdf',
        body,
        true,
        isMO
      );
    }
  }

  
  searchPendingReviewAgencies() {
    if (
      this.searchPendingReviewString &&
      this.searchPendingReviewString != ''
    ) {
      this.pendingReviewAgencyList = this.registrationList.filter(
        (data) =>
          data.registrationStatus.toLowerCase() === 'pending' &&
          data.agencyName
            .toLocaleLowerCase()
            .match(this.searchPendingReviewString.trim().toLocaleLowerCase())
      );
    } else {
      this.pendingReviewAgencyList = this.registrationList.filter(
        (data: any) => data.registrationStatus.toLowerCase() === 'pending'
      );
    }
  }

  searchApprovedReviewAgencies() {
    if (
      this.searchApprovedReviewString &&
      this.searchApprovedReviewString != ''
    ) {
      this.approvedReviewAgencyList = this.registrationList.filter(
        (data) =>
          data.registrationStatus.toLowerCase() === 'approved' &&
          data.agencyName
            .toLocaleLowerCase()
            .match(this.searchApprovedReviewString.trim().toLocaleLowerCase())
      );
    } else {
      this.approvedReviewAgencyList = this.registrationList.filter(
        (data: any) => data.registrationStatus.toLowerCase() === 'approved'
      );
    }
  }

  searchDeniedReviewAgencies() {
    if (this.searchDeniedReviewString && this.searchDeniedReviewString != '') {
      this.deniedReviewAgencyList = this.registrationList.filter(
        (data) =>
          data.registrationStatus.toLowerCase() === 'denied' &&
          data.agencyName
            .toLocaleLowerCase()
            .match(this.searchDeniedReviewString.trim().toLocaleLowerCase())
      );
    } else {
      this.deniedReviewAgencyList = this.registrationList.filter(
        (data: any) => data.registrationStatus.toLowerCase() === 'denied'
      );
    }
  }
}
