<div id="savingtips" class="carousel slide savingtips mt-5" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button *ngFor="let s of getslides(selectedState); let i = index" type="button" data-bs-target="#savingtips" [attr.data-bs-slide-to]="i" 
      [ngClass]="s.state === '' ? 'active' : ''" [attr.aria-current]="s.state === '' ? 'true' : ''" [attr.aria-label]="s.headline"></button>
  </div>
  <div class="carousel-inner">
    <div *ngFor="let sl of getslides(selectedState)" [ngClass]="sl.state === '' ? 'carousel-item active' : 'carousel-item'">
      <img [src]="sl.img_src" [alt]="sl.headline" class="d-block w-100" />
      <div class="carousel-caption">
        <div class="card-item">
          <h2 class="card-title">{{sl.headline}}</h2>
          <div class="card-text"><p>{{sl.description}}</p></div>
          <a [href]="sl.linkUrl" target="_blank" rel="noopener" class="btn btn-success m-2">{{sl.linkText}}</a>
        </div>
      </div>
    </div>
  </div>
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#savingtips"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#savingtips"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
