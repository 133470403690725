import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { EAPUsers } from 'src/app/shared/models/EAPUsers.model';
import { Profile } from 'src/app/shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { IActivateOrDeactivateBody } from '../interfaces/IActivateOrDeactivateBody';
import { IAdminApprovalBody } from '../interfaces/IAdminApprovalBody';
import { IEAPAgencyInformation } from '../interfaces/IEAPAgencyInformation';
import { AdministrationManagerService } from '../services/administration-manager.service';

declare var window: any;

@Component({
  selector: 'app-pendingagencyadmin',
  templateUrl: './pendingagencyadmin.component.html',
  styleUrls: ['./pendingagencyadmin.component.scss'],
})
export class PendingagencyadminComponent implements OnInit {
  adminmodal: any;
  pendingAgencyUsers: EAPUsers[];
  isLoading: boolean = false;
  isAPIFailure: boolean = false;
  mymodel: any;
  isApprovalLoading: boolean = true;
  isApprovalAPIFailure: boolean = false;
  isAmerenSupport: boolean | undefined;
  pendingAgencyUsersList = [];
  deskId = '1';
  mobId = '2';
  userProfile: Profile[];
  searchPendingAgencyUser: any;

  constructor(
    private administrationService: AdministrationManagerService,
    private profileManager: ProfileManagerService
  ) {
    this.userProfile = this.profileManager.profile;

    this.profileManager.profile.forEach((r) => {
      this.isAmerenSupport = r.agencyList?.every(
        (a) => a.role.toLowerCase() === 'eapamerensupport'
      );
    });
  }

  ngOnInit(): void {
    this.mymodel = new window.bootstrap.Modal(
      document.getElementById('pendingAgencyModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.adminmodal = new window.bootstrap.Modal(
      document.getElementById('agencyAdminModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.getEAPUsersList();
  }

  getEAPUsersList() {
    this.isLoading = true;
    this.isAPIFailure = false;
    this.administrationService.getEAPUsersList().subscribe({
      next: (data) => {
        this.isLoading = false;
        this.pendingAgencyUsers = [];
        this.handleAmerenUsers(data);
      },
      error: (err) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        console.log('error', err);
      },
    });
  }

  handleAmerenUsers(data: EAPUsers[]) {
    this.pendingAgencyUsers = data.filter(
      (user) =>
        user.role === 'eapagencyadmin' &&
        user.status === 'eappending' &&
        user.accountStatus != 'active' &&
        user.stateProvince.toLowerCase() ===
          this.userProfile[0].stateProvince?.toLowerCase()
    );
    if (this.pendingAgencyUsers.length > 0) {
      this.pendingAgencyUsers.forEach((a, i) => {
        this.getAgencyInfobyId(a.agencyID, i);
      });
    }
  }

  getAgencyInfobyId(agencyId: any, idx: any) {
    this.isLoading = true;
    return this.administrationService
      .getAgencyInformationById(agencyId)
      .subscribe({
        next: (res: IEAPAgencyInformation) => {
          this.isLoading = false;
          this.pendingAgencyUsers[idx].agenyName = res.agencyName;
          this.pendingAgencyUsers[idx].agencyStreetName =
            res.address.addressLines;
          this.pendingAgencyUsers[idx].agencyCity = res.address.city;
          this.pendingAgencyUsers[idx].agencyState = res.address.state;
          this.pendingAgencyUsers[idx].agencyZip = res.address.zip;
          return res;
        },
        error: (error: any) => {
          this.isLoading = false;
          throwError(() => error);
        },
      });
  }

  ui = {
    confirm: async () => this.createConfirm(),
  };

  createConfirm = () => {
    return new Promise((complete) => {
      $('#agencyAdminModalConfirmation #confirmYes').off('click');
      $('#agencyAdminModalConfirmation #confirmNo').off('click');

      $('#agencyAdminModalConfirmation #confirmYes').on('click', () => {
        $('.confirm').hide();
        complete(true);
      });
      $('#agencyAdminModalConfirmation #confirmNo').on('click', () => {
        $('.confirm').hide();
        complete(false);
      });

      this.adminmodal.show();
    });
  };

  async submitAdminRequest(data: any, user: EAPUsers) {
    const confirm = await this.ui.confirm();
    const approvalBody: IAdminApprovalBody = {
      id: user.mail,
      roles: {
        REPLACE: [
          {
            AgencyID:
              data.target.value != 'eapDenyStatus' ? user.agencyID : '0',
            role:
              data.target.value == 'eapDenyStatus'
                ? ''
                : data.target.value == 'eapPendingStatus'
                ? user.role
                : data.target.value == 'eapAgencyAdmin'
                ? 'eapAgencyAdmin'
                : user.role,
            status:
              data.target.value === 'eapPendingStatus'
                ? 'eapPending'
                : data.target.value === 'eapDenyStatus'
                ? 'eapDeny'
                : '',
          },
        ],
      },
    };

    let isDenied = data.target.value === 'eapDenyStatus';
    const activateOrDeactivateBody: IActivateOrDeactivateBody = {
      id: user.mail,
      active: true,
    };

    if (confirm) {
      this.adminmodal.hide();
      this.isApprovalLoading = true;
      this.isApprovalAPIFailure = false;
      await this.mymodel.show();
      await this.administrationService
        .submitAdminApproval(activateOrDeactivateBody, approvalBody)
        .subscribe({
          next: () => {
            if (isDenied) {
              activateOrDeactivateBody.active = false;
              this.administrationService
                .submitAdminApproval(activateOrDeactivateBody, approvalBody)
                .subscribe({
                  next: () => {
                    this.isApprovalLoading = false;
                    this.isApprovalAPIFailure = false;
                    this.getEAPUsersList();
                  },
                  error: () => {
                    this.isApprovalLoading = false;
                    this.isApprovalAPIFailure = true;
                    this.getEAPUsersList();
                  },
                });
            } else {
              this.isApprovalLoading = false;
              this.isApprovalAPIFailure = false;
              this.getEAPUsersList();
            }
          },
          error: () => {
            this.isApprovalLoading = false;
            this.isApprovalAPIFailure = true;
            this.getEAPUsersList();
          },
        });
    }
  }

  toggleDetailsHandler(user: EAPUsers) {
    this.pendingAgencyUsers.forEach((a, i) => {
      if (user.agencyID == a.agencyID && user.toggleStatus) {
        user.toggleStatus = false;
      } else if (user.agencyID == a.agencyID && !user.toggleStatus) {
        user.toggleStatus = true;
      }
    });
  }
}
