import { Router, ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import { EAPUsers } from 'src/app/shared/models/EAPUsers.model';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { Profile } from '../../shared/models/profile';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  usersList: EAPUsers[];
  currentUser: any;
  isAmerenAdmin:boolean;
  isAgencyView: boolean = false;

  constructor(private _authservice: AuthService, private profileManager: ProfileManagerService, private _router: ActivatedRoute) { 
    this._authservice.getUser().then((user) => {      
      this.currentUser = user?.profile;         
    });    
    this._router.url.subscribe(
      (data)=>{
        this.isAgencyView = data.every(a=>a.path=='agency-administration')
      }
    )
  }

  ngOnInit(): void {    
    this.profileManager.profile.forEach((a: Profile) => {
      if(a && a.agencyList?.length !=0 ) {
        a.agencyList?.forEach((a) => {
          if(a.role.toLowerCase() === 'eapamerensupport' || a.role.toLowerCase() === 'eapamerenadmin') {
            this.isAmerenAdmin = true;
          } else if(a.role.toLowerCase() === 'eapagencyadmin') {
            this.isAmerenAdmin = false
          }
        })
      }
    })

  }

}
