import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { maintenance } from './shared/models/maintenance';
import { MaintenanceManagerService } from './shared/services/maintenance-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hide_banner: boolean = false;
  login_menu: boolean = true;
  currentState: string = '';
  maintenanceStatus: maintenance;
  showMaintenanceSnack: boolean = false;

  constructor(
    private router: Router,
    private maintenanceService: MaintenanceManagerService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.maintenanceService.maintenanceStatus$.subscribe(() =>
      this.checkMaintenance()
    );
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {        
        if (
          event.url == '/' ||
          event.url == '/illinois' ||
          event.url == '/missouri' ||
          event.url.includes('?_gl=')
        ) {
          if (event.url.includes('/illinois') || event.url.includes('/missouri')) {
            let stateFromURL: string = event.url.replace('/', '');
            if (stateFromURL.includes('?_gl=')){
              stateFromURL = stateFromURL.substring(0,stateFromURL.indexOf('?'));
            }
            this.currentState = stateFromURL;
            this.hide_banner = true;
          } else {
            this.hide_banner = false;
            this.currentState = '';
          }
          this.login_menu = true;
        } else if (event.url == '/dashboard') {
          this.login_menu = false;
          this.hide_banner = true;
        } else {
          this.hide_banner = true;
        }
      }
    });
  }

  checkMaintenance() {
    this.maintenanceService.getMaintenanceStatus().subscribe({
      next: (res: any) => {
        this.maintenanceStatus = res;
        this.maintenanceStatus.isMaintenance === true
          ? (this.showMaintenanceSnack = true)
          : (this.showMaintenanceSnack = false);
      },
      error: (err) => {
        this.showMaintenanceSnack = false;
        console.log('maintenance request has failed', err);
      },
    });
  }

  closeMaintenanceSnack() {
    this.showMaintenanceSnack = false;
  }
}
