import { IEAPAgencyInformation } from './../../secure/administration/interfaces/IEAPAgencyInformation';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  setSelectedAgency(agency?: any): void {
    if (!agency) sessionStorage.removeItem('selectedAgency');
    else sessionStorage.setItem('selectedAgency', JSON.stringify(agency));
    this.agencyUpdatedSubject.next(true);
  }

  getSelectedAgency(): IEAPAgencyInformation {
    let agency = sessionStorage.getItem('selectedAgency') || '';
    return agency != '' ? JSON.parse(agency) : null;
  }

  agencyUpdatedSubject = new Subject();
}
