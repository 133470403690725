import { AmerenError } from './../../../shared/models/amerenError';
import { map, catchError, throwError } from 'rxjs';
import { IGlobalAPIResponse } from './../../../shared/interfaces/iglobal-api-response';
import { TrainingApiService } from './training-api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainingManagerService {

  constructor(private _trainingApiService: TrainingApiService) { }

  getTrainingList(stateCode: string) {
    return this._trainingApiService.getTrainingList(stateCode).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Training List cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  createDocument(data: any) {
    return this._trainingApiService.createDocument(data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Upload failed',
            400,
            null,
            null,
            null
          );
        }
        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  editDocument(data: any, documentId: number) {
    return this._trainingApiService.editDocument(data, documentId).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Edit failed',
            400,
            null,
            null,
            null
          );
        }
        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getTrainingById(id: number) {
    return this._trainingApiService.getDocument(id.toString()).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Training document by ID: '+id.toString() +' cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  deleteTrainingById(id: number) {
    return this._trainingApiService.deleteDocument(id.toString()).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Training document by ID: '+id.toString() +' cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  base64toBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
