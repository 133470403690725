<div class="eap_agency_registration_success__container ">
    <div class="d-flex flex-column align-items-center">
      <div class="eap_agency_registration_success_title">
        Application Submitted Successfully!
      </div>
      <div class="m-3">
        <p>
          We will review your application and get back to you within 1-2 business
          days. In the meantime, download a copy of your application for future
          references.
        </p>
      </div>
  
      <div>
        <a [routerLink]="['/']" class="btn btn-success m-2">Return to Home</a>
      </div>
      <div>
        <a (click)="downloadPDF()" class="eap_agency_registration_pdf">Download PDF</a>
      </div>
    </div>
  </div>
