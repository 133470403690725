import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AmerenError } from '../models/amerenError';
import { maintenance } from '../models/maintenance';
import { MaintenanceApiService } from './maintenance-api.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceManagerService {
  public maintenanceSubject = new BehaviorSubject<any>({});
  maintenanceStatus$ = this.maintenanceSubject.asObservable();

  constructor(private maintenanceService: MaintenanceApiService) {}

  getMaintenanceStatus() {
    return this.maintenanceService.getMaintenanceStatus().pipe(
      map((res: any) => {
        if (!res.success) {
          throw new AmerenError(
            'maintenance request has failed',
            400,
            null,
            null,
            null
          );
        }

        return new maintenance(res.data);
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
