import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BudgetbillingApiService {

  constructor(private http: HttpClient) { }

  getBudgetBilling(billAccount: any) {
    const headers = new HttpHeaders()
    .set('amrnenv', `${environment.cssEnv}`)
    .set('vpcenv', `${environment.environmentName}`)
    .set('x-modified-by-user-id', 'EAP')
    .set('domain', 'private')

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/budgetbill?billAccountNumber=${billAccount}`,
      { headers }
    );
    }
}
