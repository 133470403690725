export class AmerenError {
    url?: any;
    method?: any
    message: string;
    errorCode?: number;
    errorDescription?: any;
    useErrorMessage?: boolean;
    status?: any

    constructor(message: string, errorCode?: number, status?: any, url?: any, method?: any, errorDescription?: any) {
        this.message = message;
        this.errorCode = errorCode
        this.status = status;
        this.url = url;
        this.method = method;
        this.errorDescription = errorDescription
    }
}