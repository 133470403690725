import { Component, Input } from '@angular/core';
import { AmerenCarouselSlide } from 'oneuiframework/lib/interface/ameren-carousel-slide.interface';

@Component({
  selector: 'app-saving-tips',
  templateUrl: './saving-tips.component.html',
  styleUrls: ['./saving-tips.component.scss']
})
export class SavingTipsComponent {

  @Input() selectedState='';

  constructor() { }
  getslides=(state:string):AmerenCarouselSlide[]=>{
    return state=='illinois'?this.slides_IL: (state == 'missouri'?this.slides_MO:this.slides_Ameren);
    }
    slides_IL: AmerenCarouselSlide[] = [
      {
        img_src: '../../../assets/images/scam-img.webp',
        headline: 'Beware of Scams',
        description:'Be aware of telephone, mail, email, door-to-door, and other in-person scams that involve criminals posing as Ameren representatives and demanding immediate payment or personal information.',
        linkText: 'Learn More',
        linkUrl: 'https://www.ameren.com/account/customer-service/stop-scammers',
        state: '',
      },
      {
        img_src: '../../../assets/images/energy-saving-tips.png',
        headline: 'Energy Saving Tips',
        description:
          'Explore energy-saving center tips, tools, how-to videos, and more to save energy in the home.',
        linkText: 'Learn More',
        linkUrl: 'https://amerenillinoissavings.com/residential/energy-savings-center-tips-tools/',
        state: 'IL',
      },
      {
        img_src: '../../../assets/images/health-img.webp',
        headline: 'Health, Safety and Financial Assistance Options',
        description:
          'No matter what unique circumstances you or your loved ones might encounter, we have ways to help.',
        linkText: 'Learn More',
        linkUrl: 'https://www.ameren.com/illinois/residential/energy-assistance',
        state: 'IL',
      }      
    ];
  
    slides_MO: AmerenCarouselSlide[] = [
      {
        img_src: '../../../assets/images/scam-img.webp',
        headline: 'Beware of Scams',
        description:'Be aware of telephone, mail, email, door-to-door, and other in-person scams that involve criminals posing as Ameren representatives and demanding immediate payment or personal information.',
        linkText: 'Learn More',
        linkUrl: 'https://www.ameren.com/account/customer-service/stop-scammers',
        state: '',
      },
      {
        img_src: '../../../assets/images/energy-saving-tips.png',
        headline: 'Energy Saving Tips',
        description:
          'Explore energy-saving center tips, tools, how-to videos, and more to save energy in the home.',
        linkText: 'Learn More',
        linkUrl: 'https://www.amerenmissourisavings.com/myplan/energytips',
        state: 'MO',
      },
      {
        img_src: '../../../assets/images/health-img.webp',
        headline: 'Health, Safety and Financial Assistance Options',
        description:
          'No matter what unique circumstances you or your loved ones might encounter, we have ways to help.',
        linkText: 'Learn More',
        linkUrl: 'https://www.ameren.com/missouri/residential/energy-assistance/health-safety-options',
        state: 'MO',
      }
    ];

    slides_Ameren: AmerenCarouselSlide[] = [
      {
        img_src: '../../../assets/images/scam-img.webp',
        headline: 'Beware of Scams',
        description:'Be aware of telephone, mail, email, door-to-door, and other in-person scams that involve criminals posing as Ameren representatives and demanding immediate payment or personal information.',
        linkText: 'Learn More',
        linkUrl: 'https://www.ameren.com/account/customer-service/stop-scammers',
        state: '',
      }
    ];
}
