<div class="messageview_container">
  <div class="title_container">
    <span class="message-title">Message Board</span>
    <div class="message_info__container" *ngIf="!isAPIFailure && !isLoading">
      <em
        ><span class="m-1"> - {{ userMessages.length }}</span> New Messages</em
      >
    </div>
  </div>
  <br />
  <div *ngIf="isLoading">
    <ameren-loading
      id="loadingIndicator"
      class="d-flex mt-30 justify-content-center"
    ></ameren-loading>
  </div>
  <div *ngIf="isAPIFailure && !isLoading">
    <div
      id="noRecordsFound"
      class="no__messages__found mt-3 alert alert-danger"
    >
      Sorry, Unable to retrieve user messages. Please try again later.
    </div>
  </div>

  <div *ngIf="!isAPIFailure && !isLoading" class="message_board__container">
    <div *ngIf="userMessages && userMessages.length === 0">
      <div id="noRecordsFound" class="no__messages__found">
        There are no user messages.
      </div>
    </div>

    <ng-container *ngFor="let message of userMessages; let i = index">
      <div *ngIf="!message.isBeingEdited" class="message_board">
        <div class="title_section">
          <div class="title">{{ message.messageHeader }}</div>
          <div class="ms-auto" [ngClass]="{'disabled':isMessageEditing}" *ngIf="isAmerenAdmin">
            <button type="button" class="btn btn-sm btn-success" (click)="onMessageEdit(i)" >Edit</button>
          </div>
        </div>
        <div class="info">
          <span class="name">{{ message.createdBy }}</span>
          <span class="seperator">.</span>
          <span class="date">{{ message.dateCreated }}</span>
        </div>
        <div class="board_body" [innerHTML]="message.messageBody"></div>
      </div>

      <app-edit-message [message]="message" (Cancel)="onEditCancel()" *ngIf="message.isBeingEdited && isAmerenAdmin">

      </app-edit-message>
    </ng-container>
  </div>

  <app-create-message *ngIf="!isLoading && isAmerenAdmin"></app-create-message>
</div>

