import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class ClientGuardService implements CanActivate {
  isAuthenticated: boolean;

  constructor(
    private authService: AuthService,
    private _clientService: ClientService
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let client = this._clientService.getSelectedClient();

    if (client) return true;
    else {
      this._clientService.setShowClientSelector(true);
      return false;
    }
  }
}
