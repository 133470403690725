import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientpledgeApiService {
  constructor(private http: HttpClient) {}

  getClientPledge(clientPledgeList: any) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'EAP')
      .set('domain', 'private')
      .set('accountnumber', clientPledgeList.accountnumber)
      .set('pledgestatuscode', clientPledgeList.pledgestatuscode)
      .set('pledgetypecode', clientPledgeList.pledgetypecode)
      .set('pledgestartdate', clientPledgeList.pledgestartdate)
      .set('pledgeenddate', clientPledgeList.pledgeenddate);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/account/pledges`,
      { headers }
    );
  }
}
