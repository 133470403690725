import { IEAPAgencyInformation } from './../administration/interfaces/IEAPAgencyInformation';
import { AuthService } from './../../shared/services/auth.service';
import { ClientManagerService } from './../client/services/client-manager.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'src/app/shared/services/client.service';
import { Client } from 'src/app/shared/models/client';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { PaymentAgreement } from 'src/app/shared/models/paymentAgreement';
import {
  PaymentAgreementOptionPhase,
  PaymentAgreementOptionType,
} from 'src/app/shared/enums/paymentAgreementOptionType';
import { IClientQuickView } from '../client/interfaces/Iclientquickview';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';

@Component({
  selector: 'app-client-quick-view',
  templateUrl: './client-quick-view.component.html',
  styleUrls: ['./client-quick-view.component.scss'],
})
export class ClientQuickViewComponent implements OnInit {
  selectedClient: Client;
  selectedAgency: IEAPAgencyInformation;
  clientSummary: IClientQuickView;
  paymentAgreements: PaymentAgreement[];
  company: string;
  loading: boolean;
  isApiFailed: boolean;
  paLoading: boolean;
  isPaymentAgreementApiFailed: boolean;
  disconnectionDateLabelText: string;
  hasDisconnectionData: boolean = false;
  showMinimumDisconnectionPreventionAmount: boolean = false;
  IsDisconnected: boolean = false;
  HasPendingDisconnection: boolean = false;
  tooltip: any;
  @ViewChild('clientQuickViewDetails')
  clientQuickViewDetails: ElementRef<any>;
  @ViewChild('paymentAgreementDetails')
  paymentAgreementDetails: ElementRef<any>;
  agencyTitle: string;
  agencyAddress: string;
  errorMessage: string = '';
  callCenterNumber: any;

  constructor(
    private clientManagerService: ClientManagerService,
    private authsvc: AuthService,
    private clientService: ClientService,
    private agencyService: AgencyService,
    private _utility: UtilitiesService
  ) {
    this.clientService.clientUpdatedSubject.subscribe(() => {
      this.getAccountFinancials();
      this.getPaymentAgreements();
    });
  }

  ngOnInit(): void {
   
    let agency = this.agencyService.getSelectedAgency();
    if (agency) {
      this.agencyTitle = agency.selectedAgencyName || agency.agencyName;
      const address = agency.address
        ? agency.address?.addressLines[0] +
          ',' +
          agency.address.city +
          ',' +
          agency.address.state +
          ',' +
          agency.address.zip
        : '';

      this.agencyAddress = agency.selectedAgencyAddress || address;
    }

    this.authsvc.getUser().then((user) => {
      this.company =
        user?.profile?.['stateProvince'] == 'MO' ? 'Missouri' : 'Illinois';
      this.callCenterNumber =
        user?.profile?.['stateProvince'] == 'MO'
          ? '800.552.7583'
          : '800.755.5000';
    });
    this.getAccountFinancials();
    this.getPaymentAgreements();
  }

  public get PaymentAgreementOptionType() {
    return PaymentAgreementOptionType;
  }

  getAccountFinancials() {
    this.loading = true;
    this.errorMessage = ''
    this.selectedClient = this.clientService.getSelectedClient();
    this.selectedAgency = this.agencyService.getSelectedAgency();
    this.clientManagerService
      .getAccountFinancials(
        this.selectedClient.billAccountNumber,
        this.selectedAgency.pinNumber
      )
      .subscribe({
        next: (resp) => {
          this.clientSummary = resp;

          this.disconnectionDateLabelText =
            this.clientSummary.disconnectionDateLabelText;
          this.IsDisconnected = this.clientSummary.isDisconnected;
          this.showMinimumDisconnectionPreventionAmount =
            this.clientSummary.showMPAYAmount;
          this.hasDisconnectionData = this.clientSummary.hasDisconnectionData;
          this.HasPendingDisconnection = this.clientSummary.isPendingDisconnect;

          this.loading = false;
          this.isApiFailed = false;
        },
        error: (err) => {
          this.loading = false;
          this.isApiFailed = true;
          if(err.error.errorDetails.errorDescription === `Account balance records not found for ${this.selectedClient.billAccountNumber}`)
          this.errorMessage = 'Account balance records not found'

          else
          this.errorMessage = 'Failed to load client summary. Please reload the page to try again'
        },
      });
  }

  getPaymentAgreements() {
    this.paLoading = true;
    this.selectedClient = this.clientService.getSelectedClient();
    this.clientManagerService
      .getPaymentagreementsByBillAccountNumber(
        this.selectedClient.billAccountNumber
      )
      .subscribe({
        next: (resp) => {
          let pmtAgreements =
            resp?.data?.paymentAgreementDetails?.paymentAgreementOptions;
          if (pmtAgreements && pmtAgreements.length > 0) {
            this.paymentAgreements = [];
            pmtAgreements.forEach((pa: any) => {
              let addToList: Boolean = false;
              if (this.company == 'Missouri') {
                addToList =
                  pa.optionType == PaymentAgreementOptionType.MA ||
                  pa.optionType == PaymentAgreementOptionType.EX ||
                  pa.optionType == PaymentAgreementOptionType.PG ||
                  pa.optionType == PaymentAgreementOptionType.LI ||
                  pa.optionType == PaymentAgreementOptionType.CW;
              } else {
                //IL
                addToList =
                  ((pa.optionPhase == PaymentAgreementOptionPhase.Normal || pa.optionPhase == PaymentAgreementOptionPhase.RE) &&
                    (pa.optionType == PaymentAgreementOptionType.MA ||
                      pa.optionType == PaymentAgreementOptionType.EX ||
                      pa.optionType == PaymentAgreementOptionType.PG ||
                      pa.optionType == PaymentAgreementOptionType.LI)) ||
                  (pa.optionPhase == PaymentAgreementOptionPhase.RE &&
                    (pa.optionType == PaymentAgreementOptionType.LI ||
                      pa.optionType == PaymentAgreementOptionType.LA ||
                      pa.optionType == PaymentAgreementOptionType.WR ||
                      pa.optionType == PaymentAgreementOptionType.SC));
              }

              if (addToList) {
                this.paymentAgreements.push(
                  new PaymentAgreement(
                    pa.optionType,
                    pa.optionPhase,
                    pa.optionDescription,
                    pa.requiredPaymentAmount,
                    pa.paymentDueDate,
                    pa.installmentAmount,
                    pa.numberOfInstallments
                  )
                );
              }
            });
          }

          this.paLoading = false;
          this.isPaymentAgreementApiFailed = false;
        },
        error: (err) => {
          this.paLoading = false;
          this.isPaymentAgreementApiFailed = true;
        },
      });
  }

  generateQuickViewPDF() {
    let clientQuickViewDetail = this.clientQuickViewDetails.nativeElement;
    let paymentAgreement = this.paymentAgreementDetails.nativeElement;

    let body = [
      {
        type: 'string',
        element: 'QUICK VIEW SUMMARY',
        align: 'center',
        x: 10,
        y: 20,
        fontsize: 12,
        fontWeight: 'bold',
        sameRow: false,
        fontColor: 'green',
      },
      {
        type: 'string',
        element: 'Agency Name: ' + this.agencyTitle.toUpperCase(),
        align: '',
        x: 15,
        y: 10,
        fontsize: 11,
        sameRow: false,
      },
      {
        type: 'string',
        element: this.agencyAddress,
        align: '',
        x: 15,
        y: 0,
        fontsize: 11,
        sameRow: false,
      },

      {
        type: 'string',
        element: 'Account Number: ' + this._utility.transformAccountNumber(this.selectedClient?.billAccountNumber),
        align: '',
        x: 15,
        y: 5,
        fontsize: 11,
      },
      {
        type: 'string',
        element: 'Client: ' + this.selectedClient?.fullName,
        align: '',
        x: 15,
        y: 0,
        fontsize: 11,
      },
      {
        type: 'string',
        element: this.selectedClient?.address?.streetAddress,
        align: '',
        x: 15,
        y: 0,
        fontsize: 11,
      },
      {
        type: 'string',
        element:
          this.selectedClient?.address?.city +
          ' ' +
          this.selectedClient?.address?.state +
          ' ' +
          this.selectedClient?.address?.zip,
        align: '',
        x: 15,
        y: 0,
        fontsize: 11,
      },
      {
        type: 'table',
        element: clientQuickViewDetail,
        align: '',
        x: 0,
        y: 5,
        fontsize: 9,
      },
      {
        type: 'table',
        element: paymentAgreement,
        align: '',
        x: 0,
        y: 155,
        fontsize: 8,
        fontWeight: 'normal',
        sameRow: false,
      },
    ];

    const isMO = this.company === 'Missouri' ? true : false;

    this._utility.exportTabletoPDF('QuickViewSummary.pdf', body, true, isMO);
  }
}
