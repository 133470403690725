import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EAPUserRole } from 'src/app/shared/enums/eap-userrole';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {  
  currentUser: any;
  isAmerenAdmin:boolean;

  constructor(private _authservice: AuthService) { 
    _authservice.getUser().then((user) => {      
      this.currentUser = user?.profile;         
    });    
  }

  ngOnInit(): void {    
    let _amerenAdminRoleValue = Object.keys(EAPUserRole)[Object.values(EAPUserRole).indexOf(EAPUserRole.eapAmerenAdmin)];    
    this.isAmerenAdmin = this.currentUser?.agencyRoleMap[0].role === _amerenAdminRoleValue;
  }

}
