import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CampaignsApiService {
  constructor(private http: HttpClient) {}

  getCampaignByAgency(agencycsscode: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/agency/campaigns/${agencycsscode}`,
      {
        headers,
      }
    );
  }

  getCampaignByCampaignId(campaignid: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('campaignid', campaignid);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/campaigns/campaign`,
      {
        headers,
      }
    );
  }

  getCampaignByState(statecode: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('statecode', statecode);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/campaignsbystate`,
      {
        headers,
      }
    );
  }

  updateCampaign(campaignDetails: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    const body = campaignDetails;

    return this.http.put<IGlobalAPIResponse>(
      `${environment.apig}/campaigns/campaign`,
      body,
      {
        headers,
      }
    );
  }

  addCampaign(campaignDetails: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    const body = campaignDetails;

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/campaigns/campaign`,
      body,
      { headers }
    );
  }

  deleteCampaign(campaignId: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    return this.http.delete<IGlobalAPIResponse>(
      `${environment.apig}/agency/campaigns/campaignid/${campaignId}`,
      { headers }
    );
  }

  deleteCampaignByAgency(campaignagencyid: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('campaignagencyid', campaignagencyid);

    return this.http.delete<IGlobalAPIResponse>(
      `${environment.apig}/agency/campaigns/campaignagencyid`,
      { headers }
    );
  }

  getAgenciesByState(state: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/agencybystate?state=${state}`,
      { headers }
    );
  }
}
