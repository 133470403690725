<div id="pendingReviewAgency" class="review__agency-container container">
  <div id="pendingReviewAgency_row" class="row mb-4">
    <div class="review__agency-title col">Review Pending Agencies</div>
    <div id="agencysearch_pendingagency" class="agency__search col">
      <div class="input-group">
        <input
          class="form-control border agency__search-input"
          type="search"
          [(ngModel)]="searchPendingReviewString"
          placeholder="Search"
          id="search-input"
        />
        <span class="input-group-append">
          <button
            (click)="searchPendingReviewAgencies()"
            class="btn-outline-secondary border agency__search-btn"
            type="button"
          >
            <em class="fa fa-search fs-4"></em>
          </button>
        </span>
      </div>
    </div>
  </div>

  <div
    id="agency__review__pending__Loading__container"
    class="review_agency-list"
    *ngIf="isLoading"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''"
  >
    <div
      class="row select__agency-row select__agency-header select__agency-header-main"
    >
      <div id="agency_name_loading" class="col select__agency-header">Agency Name</div>
      <div id="date_created_loading" class="col select__agency-header">Date Created</div>
      <div id="updated_by_loading" class="col select__agency-header">Updated By</div>
      <div id="registration_status_loading" class="col select__agency-header">Registration Status</div>
      <div id="application_pdf_loading" class="col select__agency-header">Application PDF</div>
      <div id="approval_loading" class="col select__agency-header">Approval</div>
    </div>
    <div class="row select__agency-row">
      <div class="text-center col select__agency-column">
        <ameren-loading
          class="d-flex p-10 justify-content-center"
        ></ameren-loading>
        <span>Please wait...</span>
      </div>
    </div>
  </div>

  <div
    id="agency__review__pending__failure__container"
    class="review_agency-list"
    *ngIf="isAPIFailure"
  >
    <div class="row select__agency-row select__agency-header">
      <div id="failed_agency_name" class="col select__agency-header">Agency Name</div>
      <div id="failed_date_created" class="col select__agency-header">Date Created</div>
      <div id="failed_updated_by" class="col select__agency-header">Updated By</div>
      <div id="failed_registration_status" class="col select__agency-header">Registration Status</div>
      <div id="failed_application_status" class="col select__agency-header">Application PDF</div>
      <div id="failed_approval" class="col select__agency-header">Approval</div>
    </div>
    <div class="row select__agency-row">
      <div class="col select__agency-column text-danger">
        <em class="far fa-times-circle"></em>
        Failed to get the agency registration list. Please try again later.
      </div>
    </div>
  </div>

  <div
    id="review__agency__list"
    class="review_agency-list"
    *ngIf="!isLoading && !isAPIFailure"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''"
  >
    <div
      id="agency__review__pending__header"
      class="row select__agency-row select__agency-header select__agency-header-main static-header"
    >
      <div id="agency_name" class="col-3 select__agency-header">Agency Name</div>
      <div id="date_created" class="col select__agency-header">Date Created</div>
      <div id="updated_by" class="col select__agency-header">Updated By</div>
      <div id="registration_status" class="col select__agency-header">Registration Status</div>
      <div id="application_pdf" class="col select__agency-header">Application PDF</div>
      <div id="approval" class="col select__agency-header">Approval</div>
    </div>
    <div>
      <div
        id="agency__review__pending__list"
        class="row select__agency-row"
        *ngFor="let agencylist of pendingReviewAgencyList; let i = index"
      >
        <div class="col-3 select__agency-column">
          <div class="agency__name">
            <a
              class="accordion-toggle"
              data-bs-toggle="collapse"
              href="#pendingAgencyRecord{{ i }}"
              aria-expanded="false"
              aria-controls="agencyRecord"
              (click)="getAgencyDetails(agencylist.registrationID)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path
                  d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"
                />
              </svg>
            </a>

            <span class="p-2">{{ agencylist.agencyName }}</span>
          </div>
        </div>

        <div id="d_date_created_data" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.dateCreated }} </span>
        </div>

        <div  id="d_updated_by_data" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.updatedBy }} </span>
        </div>

        <div id="d_registration_status_data" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.registrationStatus }} </span>
        </div>

        <div id="d_pdf_data" class="col select__agency-column select__agency-column-desktop">
          <button
            class="btn btn-default"
            (click)="generatePDF(agencylist.registrationID)"
          >
            <img src="assets/icon/pdficon.svg" alt="PDF Image" />
            PDF
          </button>
        </div>
        <div id="d_approve_data" class="col select__agency-column select__agency-column-desktop">
          <span>
            <button
              class="btn agency__deny"
              (click)="submitAgencyApproval(agencylist.registrationID, 'Deny')"
            >
              Deny
            </button>
          </span>
          <span>
            <button
              class="btn btn-success agency__approve"
              (click)="
                submitAgencyApproval(agencylist.registrationID, 'Approve')
              "
            >
              Approve
            </button>
          </span>
        </div>

        <div
          class="collapse registration-details"
          id="pendingAgencyRecord{{ i }}"
        >
          <div class="text-center m-4" *ngIf="isLoadingforAgencyById">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>

          <div class="text-danger m-4" *ngIf="isAPIFailureAgencyById">
            <em class="far fa-times-circle"></em>
            Failed to get the agency registration list. Please try again later.
          </div>

          <div
            class="agency__registration-display"
            *ngIf="!isLoadingforAgencyById && !isAPIFailureAgencyById"
          >
            <div class="agency__information">
              <div class="agency__details">
                <div class="card agency__details-card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item agency__details-title">
                      Agency Details
                    </li>
                    <li class="list-group-item" id="agency__records_email">
                      <div class="row">
                        <div class="col agency__records">Email Address</div>
                        <div class="col agency__records">
                          {{ agencylist.emailAddress }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__records_phn">
                      <div class="row">
                        <div class="col agency__records">Phone Number</div>
                        <div class="col agency__records">
                          {{ agencylist.phoneNumber }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__records_fax">
                      <div class="row">
                        <div class="col agency__records">Fax</div>
                        <div class="col agency__records">
                          {{ agencylist.faxNumber }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__records_referrals">
                      <div class="row">
                        <div class="col agency__records">
                          Referrals from Ameren
                        </div>
                        <div class="col agency__records">
                          {{ agencylist.isCustomerReffered }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__records_requester_title">
                      <div class="row">
                        <div class="col agency__records">Requester Title</div>
                        <div class="col agency__records">
                          {{ agencylist.requestorTitle }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__records_requester_name">
                      <div class="row">
                        <div class="col agency__records">Requester Name</div>
                        <div class="col agency__records">
                          {{ agencylist.requestorName }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agency__address">
                <div class="card agency__address-card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item agency__address-title">
                      Address
                    </li>
                    <li class="list-group-item" id="agency__records_mailing_address">
                      <div>
                        <div class="agency__address-header">
                          Mailing Address
                        </div>
                        <div class="text-center agency__records">
                          {{ agencylist.agencyMailingAddressAddressLine1 }}
                          {{ agencylist.agencyMailingAddressCity }}
                          {{ agencylist.agencyMailingAddressState }}
                          {{ agencylist.agencyMailingAddressPostalCode }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__records_agency_address">
                      <div>
                        <div class="agency__address-header">Agency Address</div>
                        <div class="text-center agency__records">
                          {{ agencylist.agencyStreetAddressAddressLine1 }}
                          {{ agencylist.agencyStreetAddressCity }}
                          {{ agencylist.agencyStreetAddressState }}
                          {{ agencylist.agencyStreetAddressPostalCode }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agency__representatives">
                <div class="card agency__representatives-card">
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item agency__representatives-title"
                      style="height: 3rem"
                    >
                      Representatives
                    </li>
                    <li
                      class="list-group-item text-center agency__records"
                      *ngFor="let reps of agencylist.agencyRepresentatives"
                    >
                      {{ reps }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- approved  -->
<div id="approvedReviewAgency" class="review__agency-container container">
  <div class="row mb-4">
    <div class="review__agency-title col">Review Approved Agencies</div>
    <div id="agencysearch_approvedagency" class="agency__search col">
      <div class="input-group">
        <input
          class="form-control border agency__search-input"
          type="search"
          [(ngModel)]="searchApprovedReviewString"
          placeholder="Search"
          id="search-input"
        />
        <span class="input-group-append">
          <button
            (click)="searchApprovedReviewAgencies()"
            class="btn-outline-secondary border agency__search-btn"
            type="button"
          >
            <em class="fa fa-search fs-4"></em>
          </button>
        </span>
      </div>
    </div>
  </div>

  <div
    id="approved__agency__loading__container"
    class="review_agency-list"
    *ngIf="isLoading"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''"
  >
    <div
      class="row select__agency-row select__agency-header select__agency-header-main"
    >
      <div class="col select__agency-header" id="approved__agency__loading__container_agency_name">Agency Name</div>
      <div class="col select__agency-header" id="approved__agency__loading__container_date_created">Date Created</div>
      <div class="col select__agency-header" id="approved__agency__loading__container_updated_by">Updated By</div>
      <div class="col select__agency-header" id="approved__agency__loading__container_reg_status">Registration Status</div>
      <div class="col select__agency-header" id="approved__agency__loading__container_pdf">Application PDF</div>
    </div>
    <div class="row select__agency-row" id="approved__agency__loading__container_loading_message">
      <div class="text-center col select__agency-column">
        <ameren-loading
          class="d-flex p-10 justify-content-center"
        ></ameren-loading>
        <span>Please wait...</span>
      </div>
    </div>
  </div>

  <div
    id="approved__agency__failure__container"
    class="review_agency-list"
    *ngIf="isAPIFailure"
  >
    <div class="row select__agency-row select__agency-header">
      <div class="col select__agency-header" id="approved__agency__failure__container_agency_name">Agency Name</div>
      <div class="col select__agency-header" id="approved__agency__failure__container_date_created">Date Created</div>
      <div class="col select__agency-header" id="approved__agency__failure__container_updated_by">Updated By</div>
      <div class="col select__agency-header" id="approved__agency__failure__container_reg_status">Registration Status</div>
      <div class="col select__agency-header" id="approved__agency__failure__container_pdf">Application PDF</div>
    </div>
    <div class="row select__agency-row" id="approved__agency__failure__container_error_message">
      <div class="col select__agency-column text-danger">
        <em class="far fa-times-circle"></em>
        Failed to get the agency registration list. Please try again later.
      </div>
    </div>
  </div>

  <div
    id="approved__agency__list"
    class="review_agency-list"
    *ngIf="!isLoading && !isAPIFailure"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''"
  >
    <div
      class="row select__agency-row select__agency-header select__agency-header-main static-header"
    >
      <div class="col-3 select__agency-header" id="approved__agency__list_header_agency_name">Agency Name</div>
      <div class="col select__agency-header" id="approved__agency__list_header_date_created">Date Created</div>
      <div class="col select__agency-header" id="approved__agency__list_header_updated_by">Updated By</div>
      <div class="col select__agency-header" id="approved__agency__list_header_reg_status">Registration Status</div>
      <div class="col select__agency-header" id="approved__agency__list_header_pdf">Application PDF</div>
    </div>
    <div>
      <div
        id="agency__review__approved__agency__list"
        class="row select__agency-row"
        *ngFor="let agencylist of approvedReviewAgencyList; let i = index"
      >
        <div class="col-3 select__agency-column" id="approved__agency__list_agency_name">
          <div class="agency__name">
            <a
              class="accordion-toggle"
              data-bs-toggle="collapse"
              href="#approvedAgencyRecord{{ i }}"
              aria-expanded="false"
              aria-controls="agencyRecord"
              (click)="getAgencyDetails(agencylist.registrationID)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path
                  d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"
                />
              </svg>
            </a>

            <span class="p-2">{{ agencylist.agencyName }}</span>
          </div>
        </div>

        <div class="col select__agency-column select__agency-column-desktop" id="agency__review__approved__agency__list_data_date_created">
          <span> {{ agencylist.dateCreated }} </span>
        </div>

        <div id="agency__review__approved__agency__list_data_updated_by" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.updatedBy }} </span>
        </div>

        <div id="agency__review__approved__agency__list_data_reg_status" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.registrationStatus }} </span>
        </div>

        <div id="agency__review__approved__agency__list_data_pdf" class="col select__agency-column select__agency-column-desktop">
          <button
            class="btn btn-default"
            (click)="generatePDF(agencylist.registrationID)"
          >
            <img src="assets/icon/pdficon.svg" alt="PDF Image" />
            PDF
          </button>
        </div>

        <div
          class="collapse registration-details"
          id="approvedAgencyRecord{{ i }}"
        >
          <div class="text-center m-4" *ngIf="isLoadingforAgencyById">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>

          <div class="text-danger m-4" *ngIf="isAPIFailureAgencyById">
            <em class="far fa-times-circle"></em>
            Failed to get the agency registration list. Please try again later.
          </div>

          <div
            class="agency__registration-display"
            *ngIf="!isLoadingforAgencyById && !isAPIFailureAgencyById"
          >
            <div class="agency__information">
              <div class="agency__details">
                <div class="card agency__details-card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item agency__details-title">
                      Agency Details
                    </li>
                    <li class="list-group-item" id="agency__registration-display_email">
                      <div class="row">
                        <div class="col agency__records">Email Address</div>
                        <div class="col agency__records">
                          {{ agencylist.emailAddress }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__registration-display_phn">
                      <div class="row">
                        <div class="col agency__records">Phone Number</div>
                        <div class="col agency__records">
                          {{ agencylist.phoneNumber }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__registration-display_fax">
                      <div class="row">
                        <div class="col agency__records">Fax</div>
                        <div class="col agency__records">
                          {{ agencylist.faxNumber }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__registration-display_referrals">
                      <div class="row">
                        <div class="col agency__records">
                          Referrals from Ameren
                        </div>
                        <div class="col agency__records">
                          {{ agencylist.isCustomerReffered }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__registration-display_requester_title">
                      <div class="row">
                        <div class="col agency__records">Requester Title</div>
                        <div class="col agency__records">
                          {{ agencylist.requestorTitle }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__registration-display_requester_name">
                      <div class="row">
                        <div class="col agency__records">Requester Name</div>
                        <div class="col agency__records">
                          {{ agencylist.requestorName }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agency__address">
                <div class="card agency__address-card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item agency__address-title">
                      Address
                    </li>
                    <li class="list-group-item" id="agency__registration-display_address">
                      <div>
                        <div class="agency__address-header">
                          Mailing Address
                        </div>
                        <div class="text-center agency__records">
                          {{ agencylist.agencyMailingAddressAddressLine1 }}
                          {{ agencylist.agencyMailingAddressCity }}
                          {{ agencylist.agencyMailingAddressState }}
                          {{ agencylist.agencyMailingAddressPostalCode }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agency__registration-display_agency_address">
                      <div>
                        <div class="agency__address-header">Agency Address</div>
                        <div class="text-center agency__records">
                          {{ agencylist.agencyStreetAddressAddressLine1 }}
                          {{ agencylist.agencyStreetAddressCity }}
                          {{ agencylist.agencyStreetAddressState }}
                          {{ agencylist.agencyStreetAddressPostalCode }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agency__representatives">
                <div class="card agency__representatives-card" id="agency__registration-display_reps">
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item agency__representatives-title"
                      style="height: 3rem"
                    >
                      Representatives
                    </li>
                    <li
                      class="list-group-item text-center agency__records"
                      *ngFor="let reps of agencylist.agencyRepresentatives"
                    >
                      {{ reps }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Denied  -->
<div id="deniedReviewAgency" class="review__agency-container container">
  <div class="row mb-4">
    <div class="review__agency-title col">Review Denied Agencies</div>
    <div id="agencysearch_deniedagency" class="agency__search col">
      <div class="input-group">
        <input
          class="form-control border agency__search-input"
          type="search"
          [(ngModel)]="searchDeniedReviewString"
          placeholder="Search"
          id="search-input"
        />
        <span class="input-group-append">
          <button
            (click)="searchDeniedReviewAgencies()"
            class="btn-outline-secondary border agency__search-btn"
            type="button"
          >
            <em class="fa fa-search fs-4"></em>
          </button>
        </span>
      </div>
    </div>
  </div>

  <div
    id="agency__review__denied__loading__container"
    class="review_agency-list"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''"
    *ngIf="isLoading"
  >
    <div
      class="row select__agency-row select__agency-header select__agency-header-main"
    >
      <div class="col select__agency-header" id="agency__review__denied__loading__container_header_agency_name">Agency Name</div>
      <div class="col select__agency-header" id="agency__review__denied__loading__container_date_header_created">Date Created</div>
      <div class="col select__agency-header" id="agency__review__denied__loading__container_header_updated_by">Updated By</div>
      <div class="col select__agency-header"id="agency__review__denied__loading__container_header_reg_status">Registration Status</div>
      <div class="col select__agency-header">Application PDF</div>
    </div>
    <div class="row select__agency-row" id="agency__review__denied__loading__container_message">
      <div class="text-center col select__agency-column">
        <ameren-loading
          class="d-flex p-10 justify-content-center"
        ></ameren-loading>
        <span>Please wait...</span>
      </div>
    </div>
  </div>

  <div
    id="agency__review__denied__failure__container"
    class="review_agency-list"
    *ngIf="isAPIFailure"
  >
    <div class="row select__agency-row select__agency-header">
      <div class="col select__agency-header" id="agency__review__denied__failure__container_header_agency_name">Agency Name</div>
      <div class="col select__agency-header" id="agency__review__denied__failure__container_header_date_created">Date Created</div>
      <div class="col select__agency-header" id="agency__review__denied__failure__container_header_updated_by">Updated By</div>
      <div class="col select__agency-header" id="agency__review__denied__failure__container_header_reg_status">Registration Status</div>
      <div class="col select__agency-header" id="agency__review__denied__failure__container_header_pdf">Application PDF</div>
    </div>
    <div class="row select__agency-row" id="agency__review__denied__failure__container_message">
      <div class="col select__agency-column text-danger">
        <em class="far fa-times-circle"></em>
        Failed to get the agency registration list. Please try again later.
      </div>
    </div>
  </div>

  <div
    id="denied__agency__list"
    class="review_agency-list"
    *ngIf="!isLoading && !isAPIFailure"
    [ngClass]="registrationList.length > 5 ? 'select_agency-border' : ''"
  >
    <div
      id="agency__review__denied__header"
      class="row select__agency-row select__agency-header select__agency-header-main static-header"
    >
      <div class="col-3 select__agency-header" id="denied__agency__list_header_agency_name">Agency Name</div>
      <div class="col select__agency-header" id="denied__agency__list_header_date_created">Date Created</div>
      <div class="col select__agency-header" id="denied__agency__list_header_updated_by">Updated By</div>
      <div class="col select__agency-header" id="denied__agency__list_header_reg_status">Registration Status</div>
      <div class="col select__agency-header" id="denied__agency__list_header_pdf">Application PDF</div>
    </div>
    <div>
      <div
        id="agency__review__denied__list"
        class="row select__agency-row"
        *ngFor="let agencylist of deniedReviewAgencyList; let i = index"
      >
        <div class="col-3 select__agency-column">
          <div class="agency__name">
            <a
              class="accordion-toggle"
              data-bs-toggle="collapse"
              href="#deniedAgencyRecord{{ i }}"
              aria-expanded="false"
              aria-controls="agencyRecord"
              (click)="getAgencyDetails(agencylist.registrationID)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path
                  d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"
                />
              </svg>
            </a>

            <span class="p-2">{{ agencylist.agencyName }}</span>
          </div>
        </div>

        <div id="agency__review__denied__list_data_date_created" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.dateCreated }} </span>
        </div>

        <div id="agency__review__denied__list_data_updated_by" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.updatedBy }} </span>
        </div>

        <div id="agency__review__denied__list_data_reg_status" class="col select__agency-column select__agency-column-desktop">
          <span> {{ agencylist.registrationStatus }} </span>
        </div>

        <div id="agency__review__denied__list_data_pdf" class="col select__agency-column select__agency-column-desktop">
          <button
            class="btn btn-default"
            (click)="generatePDF(agencylist.registrationID)"
          >
            <img src="assets/icon/pdficon.svg" alt="PDF Image" />
            PDF
          </button>
        </div>

        <div
          class="collapse registration-details"
          id="deniedAgencyRecord{{ i }}"
        >
          <div class="text-center m-4" *ngIf="isLoadingforAgencyById">
            <ameren-loading
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>

          <div class="text-danger m-4" *ngIf="isAPIFailureAgencyById">
            <em class="far fa-times-circle"></em>
            Failed to get the agency registration list. Please try again later.
          </div>

          <div
            class="agency__registration-display"
            *ngIf="!isLoadingforAgencyById && !isAPIFailureAgencyById"
          >
            <div class="agency__information">
              <div class="agency__details">
                <div class="card agency__details-card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item agency__details-title">
                      Agency Details
                    </li>
                    <li class="list-group-item" id="agcy_email">
                      <div class="row">
                        <div class="col agency__records">Email Address</div>
                        <div class="col agency__records">
                          {{ agencylist.emailAddress }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agcy_phn">
                      <div class="row">
                        <div class="col agency__records">Phone Number</div>
                        <div class="col agency__records">
                          {{ agencylist.phoneNumber }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agcy_fax">
                      <div class="row">
                        <div class="col agency__records">Fax</div>
                        <div class="col agency__records">
                          {{ agencylist.faxNumber }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agcy_referrals">
                      <div class="row">
                        <div class="col agency__records">
                          Referrals from Ameren
                        </div>
                        <div class="col agency__records">
                          {{ agencylist.isCustomerReffered }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agcy_requester_title">
                      <div class="row">
                        <div class="col agency__records">Requester Title</div>
                        <div class="col agency__records">
                          {{ agencylist.requestorTitle }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agcy_requester_name">
                      <div class="row">
                        <div class="col agency__records">Requester Name</div>
                        <div class="col agency__records">
                          {{ agencylist.requestorName }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agency__address">
                <div class="card agency__address-card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item agency__address-title">
                      Address
                    </li>
                    <li class="list-group-item" id="agcy_address">
                      <div>
                        <div class="agency__address-header">
                          Mailing Address
                        </div>
                        <div class="text-center agency__records">
                          {{ agencylist.agencyMailingAddressAddressLine1 }}
                          {{ agencylist.agencyMailingAddressCity }}
                          {{ agencylist.agencyMailingAddressState }}
                          {{ agencylist.agencyMailingAddressPostalCode }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item" id="agcy_agency_address">
                      <div>
                        <div class="agency__address-header">Agency Address</div>
                        <div class="text-center agency__records">
                          {{ agencylist.agencyStreetAddressAddressLine1 }}
                          {{ agencylist.agencyStreetAddressCity }}
                          {{ agencylist.agencyStreetAddressState }}
                          {{ agencylist.agencyStreetAddressPostalCode }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agency__representatives" id="agcy_reps">
                <div class="card agency__representatives-card">
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item agency__representatives-title"
                      style="height: 3rem"
                    >
                      Representatives
                    </li>
                    <li
                      class="list-group-item text-center agency__records"
                      *ngFor="let reps of agencylist.agencyRepresentatives"
                    >
                      {{ reps }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="agencyReviewModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!isApprovalLoading">
        <div
          class="ameren__modal__success-title"
          *ngIf="!isApprovalLoading && !isApprovalAPIFailure"
        >
          Successfully Submitted!
        </div>
        <div
          class="ameren__modal__failure-title"
          *ngIf="!isApprovalLoading && isApprovalAPIFailure"
        >
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isApprovalLoading" class="text-center">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image">
          <img
            *ngIf="!isApprovalAPIFailure && !isApprovalLoading"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="isApprovalAPIFailure && !isApprovalLoading"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!isApprovalLoading">
          <div
            class="ameren__modal-message"
            *ngIf="!isApprovalLoading && !isApprovalAPIFailure"
          >
            Ameren approval request has been submitted successfully.
          </div>

          <div
            class="ameren__modal-message"
            *ngIf="!isApprovalLoading && isApprovalAPIFailure"
          >
            Ameren approval request has not been submitted successfully. Please
            try again to submit your request.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
