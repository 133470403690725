import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PledgeEntryApiService {
  constructor(private http: HttpClient) {}

  submitPledge(pledgeDetails: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`);

    const body = pledgeDetails;

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/pledges/pledge`,
      body,
      { headers }
    );
  }

  getPledgeTypeByAgencyCSSCode(agencycsscode: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('agencycsscode', agencycsscode);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/agency/pledgetypesbyagencycsscode`,
      {
        headers,
      }
    );
  }

  getPledgeTypeByAgencyId(agencyId: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('agencyId', agencyId);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/agency/pledgetypesbyagencyid`,
      {
        headers,
      }
    );
  }

  getPledgeTypeByState(statecode: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-modified-by-user-id', 'EAP')
      .set('amrnenv', `${environment.cssEnv}`)
      .set('domain', 'private')
      .set('vpcenv', `${environment.environmentName}`)
      .set('statecode', statecode);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/state/pledge-types`,
      {
        headers,
      }
    );
  }
}
