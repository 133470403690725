import * as _ from 'lodash';

export class RelatedAccountModel {
  billAccountNumber: any;
  accountStatus: string;
  statusDate: string;
  openDate: string;
  accountBalance: number;
  addressLine1: any;
  city: string;
  state: string;
  zip: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  fullName: string;
  compressedName: string;
  operatingState: string;

  constructor(accountDetails?: any) {
    this.billAccountNumber = !_.isNil(accountDetails.billAccountNumber)
      ? accountDetails.billAccountNumber
      : 0;
    this.accountStatus = !_.isNil(accountDetails.accountStatus)
      ? accountDetails.accountStatus
      : '';
    this.statusDate = !_.isNil(accountDetails.statusDate)
      ? accountDetails.statusDate
      : '';
    this.openDate = !_.isNil(accountDetails.openDate)
      ? accountDetails.openDate
      : '';
    this.accountBalance = !_.isNil(accountDetails.accountBalance)
      ? accountDetails.accountBalance
      : 0;
    this.addressLine1 = !_.isNil(
      accountDetails.clientAccountInfo?.address?.addressLines[0]
    )
      ? accountDetails.clientAccountInfo?.address?.addressLines[0]
      : '';
    this.city = !_.isNil(accountDetails.clientAccountInfo.address?.city)
      ? accountDetails.clientAccountInfo.address?.city
      : '';
    this.state = !_.isNil(accountDetails.clientAccountInfo.address?.state)
      ? accountDetails.clientAccountInfo.address?.state
      : '';
    this.zip = !_.isNil(accountDetails.clientAccountInfo.address?.zip)
      ? accountDetails.clientAccountInfo.address?.zip
      : '';

    this.firstName = !_.isNil(accountDetails.clientAccountInfo.firstName)
      ? accountDetails.clientAccountInfo.firstName
      : '';
    this.middleName = !_.isNil(accountDetails.clientAccountInfo.middleName)
      ? accountDetails.clientAccountInfo.middleName
      : '';
    this.lastName = !_.isNil(accountDetails.clientAccountInfo.lastName)
      ? accountDetails.clientAccountInfo.lastName
      : '';
    this.nameSuffix = !_.isNil(accountDetails.clientAccountInfo.nameSuffix)
      ? accountDetails.clientAccountInfo.nameSuffix
      : '';
    this.fullName = !_.isNil(accountDetails.clientAccountInfo.fullName)
      ? accountDetails.clientAccountInfo.fullName
      : '';
    this.compressedName = !_.isNil(
      accountDetails.clientAccountInfo.compressedName
    )
      ? accountDetails.clientAccountInfo.compressedName
      : '';
    this.operatingState =
      !_.isNil(accountDetails.operatingState) &&
      accountDetails.operatingState === '17'
        ? 'MO'
        : 'IL';
  }
}
