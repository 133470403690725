import * as _ from 'lodash';

export class BillingPaymentPeriod {
  billPeriodStartDate?: Date;
  billingStartDate: string;
  billingEndDate: string;
  billingPeriod: string;
  priorBalance: string;
  currentBillAmount: string;
  totalBilledAmount: any;
  dueDate: string;
  usage: string;
  serviceCharge: any;
  paymentInformation: string;
  payments: Payment[];
  usages: Usage[];
  billImageUniqueId: string;
  billDate: any;

  constructor(billPeriod: any) {
    this.usages = [];
    this.billDate = !_.isNil(billPeriod.billDate) ? billPeriod.billDate : '';
    this.billImageUniqueId = !_.isNil(billPeriod.billImageUniqueId)
      ? billPeriod.billImageUniqueId
      : '';

    if (billPeriod.charges != null && billPeriod.charges.length > 0) {
      billPeriod.charges.forEach((ch: any) => {
        let usage = new Usage();
        usage.amount = ch.amount;

        switch (ch.description) {
          case 'GAS SERVICE':
            usage.description = 'Gas';
            break;
          case 'ELECTRIC SERVICE':
            usage.description = 'Elec';
            break;
          case 'ELECTRIC LIGHTING':
            usage.description = 'Lighting';
            break;
          default:
            usage.description = ch.description;
            break;
        }

        if (ch.usageDetails != null && ch.usageDetails.length > 0) {
          let usageDetail = ch.usageDetails[0];
          usage.quantity = usageDetail.amount;
          usage.unitOfMeasure = usageDetail.unitOfMeasure;
        }
        this.usages.push(usage);
      });
    }

    if (this.usages && this.usages.length > 0) {
      this.serviceCharge = '';

      this.usages.forEach((data) => {
        if (data.description != 'ARES POR') {
          this.usage = this.retrieveUsage(this.usages);
        }
        this.serviceCharge +=
          data.description + ': ' + '$' + data.amount.toFixed(2) + ' ';
      });
    }

    this.payments = [];
    if (
      billPeriod.paymentDetails != null &&
      billPeriod.paymentDetails.length > 0
    ) {
      billPeriod.paymentDetails.forEach((pmt: any) => {
        let payment = new Payment();
        payment.amount = pmt.amount;
        payment.paymentDate = this.formatDate(pmt.paymentDate);
        payment.comments = pmt.comments;
        this.payments.push(payment);
      });
    }

    this.paymentInformation = '';
    if (this.payments && this.payments.length > 0) {
      for (let i = 0; i < this.payments.length; i++) {
        let payment = this.payments[i];
        this.paymentInformation += `<div>${payment.paymentDate}: ${
          payment.comments
        }: ${'$' + payment.amount.toFixed(2)}<br /></div>`;
      }
    }

    this.billPeriodStartDate = !_.isNil(billPeriod.startDate)
      ? this.getDate(billPeriod.startDate)
      : undefined;
    this.billingStartDate = !_.isNil(billPeriod.startDate)
      ? this.formatDate(billPeriod.startDate)
      : '';
    this.billingEndDate = !_.isNil(billPeriod.endDate)
      ? this.formatDate(billPeriod.endDate)
      : '';
    this.billingPeriod = this.billingStartDate + ' - ' + this.billingEndDate;
    this.priorBalance = !_.isNil(billPeriod.previousBalance)
      ? '$' + billPeriod.previousBalance.toFixed(2)
      : '0';
    this.currentBillAmount = !_.isNil(billPeriod.amount)
      ? '$' + billPeriod.amount.toFixed(2)
      : '0';
    this.totalBilledAmount = `$${(
      billPeriod.previousBalance + billPeriod.amount
    ).toFixed(2)}`;
    this.dueDate = !_.isNil(billPeriod.paymentDueDate)
      ? this.formatDate(billPeriod.paymentDueDate, true)
      : '';
  }

  retrieveUsage(usages: any[]): string {
    let usage: string = '';
    let index = 0;
    usages.forEach((data, i) => {
      if (
        (data.unitOfMeasure != undefined || null || '') &&
        (data.quantity != undefined || null || '')
      ) {
        if (index == 0) {
          usage =
            data.description +
            ': ' +
            data.quantity +
            '' +
            data.unitOfMeasure +
            ' ';
        } else {
          let u =
            data.description + ': ' + data.quantity + '' + data.unitOfMeasure;
          usage = usage.concat(u);
        }
        index = i + 1;
      }
    });
    return usage;
  }

  formatDate(inputDate: string, showFullYear?: boolean) {
    let dateParts = inputDate.split('-');
    let date = new Date(
      parseInt(dateParts[0]),
      parseInt(dateParts[1]) - 1,
      parseInt(dateParts[2])
    );

    if (showFullYear) {
      return (
        date.getMonth() +
        1 +
        '/' +
        date.getDate() +
        '/' +
        date.getFullYear().toString()
      );
    } else {
      return (
        date.getMonth() +
        1 +
        '/' +
        date.getDate() +
        '/' +
        date.getFullYear().toString().slice(-2)
      );
    }
  }

  getDate(inputDate: string) {
    let dateParts = inputDate.split('-');
    return new Date(
      parseInt(dateParts[0]),
      parseInt(dateParts[1]) - 1,
      parseInt(dateParts[2])
    );
  }
}

export class Payment {
  comments: string;
  paymentDate: string;
  amount: number;
}

export class Usage {
  description: string;
  quantity: string;
  unitOfMeasure: string;
  amount: number;
}
