import { AdministrationManagerService } from 'src/app/secure/administration/services/administration-manager.service';

import { Validators } from '@angular/forms';
import { ProfileManagerService } from './../../../../shared/services/profilemanager.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
declare var window: any;
@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.scss']
})
export class CreateMessageComponent implements OnInit {

  constructor(private _fb: FormBuilder, private _profileSvc: ProfileManagerService, private _admSvc: AdministrationManagerService) { }
createForm: FormGroup;
addMessageApiFailure: boolean;
isAddApiMessageResponse: boolean;
isAddAPIFailure: boolean;
isAddLoading: boolean;
AddMsgApiSuccess:boolean;
AddConfrmModal: any;
isAddClicked:boolean;
showPlaceHolder:boolean = true;


  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: 'Arial',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
  
    toolbarHiddenButtons: [
      ['strikeThrough','subscript','superscript','backgroundColor','insertImage',
      'insertVideo','link','unlink','insertHorizontalRule','removeFormat','toggleEditorMode','fontSize','heading']
    ]
};
  ngOnInit(): void {
    this.createForm = this._fb.group({
      htmlContent: new FormControl([]),
      subject: new FormControl(),
      expireDate: new FormControl()
    });

    this.AddConfrmModal = new window.bootstrap.Modal(
      document.getElementById('AddModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.createForm.get('htmlContent')?.valueChanges.subscribe((val)=>{
      if(val && val.length){
        this.showPlaceHolder = false;
      }else{
        this.showPlaceHolder = true;
      }
    })
  }
  onAddMsgCancel(){
this.AddConfrmModal.hide();
  }

  onAddMsg(){
    this.isAddClicked = true;
    if(this.createForm?.value?.htmlContent && this.createForm?.value?.subject){
    this.AddConfrmModal.show();
    this.isAddApiMessageResponse = false;
    this.isAddAPIFailure = false;
    }
  }
  closeAddModal(){
 location.reload();
  }

  postMessage(){
    if(this.createForm.valid){
      this.isAddLoading = true;
      let msgBody = {
        operatingState: this._profileSvc.profile[0].stateProvince,
        createdBy: this._profileSvc.profile[0].stateProvince == 'IL'
        ? 'ILAdmin'
        : 'MOAdmin',
        messageBody: this.createForm.value.htmlContent,
        messageHeader: this.createForm.value.subject || '', 
        expireDate: this.createForm.value.expireDate  || ''  
      }

      this._admSvc.createMessage(msgBody).subscribe({
        next: (data)=>{
          this.AddMsgApiSuccess = true;
          this.isAddApiMessageResponse = true;
          this.isAddAPIFailure = false;
          this.isAddLoading = false;
        },
        error: (err)=>{
          this.isAddAPIFailure = true;
          this.isAddApiMessageResponse = true;
          this.AddMsgApiSuccess = false;
          this.isAddLoading = false;
        }
      })
    }
  }

}
