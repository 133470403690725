import { RegistrationManagerService } from './../../public/registration/services/registration-manager.service';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { EnumRole } from 'src/app/shared/enums/role';
import { combineLatest, Observable, takeUntil, Subject } from 'rxjs';
import { ManageprofileApiManagerService } from './../manageprofile/services/manageprofile-api-manager.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {
  AgencyInfo,
} from 'src/app/shared/models/AgencyInformationByPIN.model';
import { Profile } from 'src/app/shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AdministrationManagerService } from '../administration/services/administration-manager.service';
import { ISelectAgency } from './interfaces/select-agency.interface';
import { SelectAgencyManagerService } from './services/select-agency-manager.service';
import { AgencyService } from 'src/app/shared/services/agency.service';

declare var window: any;

@Component({
  selector: 'app-select-agency',
  templateUrl: './select-agency.component.html',
  styleUrls: ['./select-agency.component.scss'],
})
export class SelectAgencyComponent implements OnInit, AfterViewInit {
  searchString: any;
  isMobile = window.innerWidth <= 800;
  isAPIFailure: boolean;
  isLoading: boolean;
  registrationList: any[] = [];
  selectedAgency: AgencyInfo;
  _profile: Profile[];
  isAdmin: boolean;
  unsubscribe$ = new Subject();
  searchVal: string;
  unfilteredRegistrationList: any[] = [];
  selectedAgencyModel: any;
  isSelectAgencyAPIFailure: boolean;
  isSelectAgencyLoading: boolean;
  userState: number[] = [];
  pinSearchForm: FormGroup;
  isPinSearchLoading: boolean = false;
  agencySearchResults: any[] = [];
  isSubmitLoading: boolean = false;

  constructor(
    private selectAgencyManager: SelectAgencyManagerService,
    private administrationManager: AdministrationManagerService,
    private router: Router,
    private profileManager: ProfileManagerService,
    private mangeProfileService: ManageprofileApiManagerService,
    private agencyService: AgencyService,
    private _fb: FormBuilder,
    private registrationManagerService: RegistrationManagerService
  ) {}

  ngOnInit(): void {
    this.selectedAgencyModel = new window.bootstrap.Modal(
      document.getElementById('selectAgencyModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this._profile = this.profileManager.profile;
    this.userState =
      this.profileManager.profile[0]?.stateProvince === 'MO'
        ? [17]
        : [18, 19, 20, 21];
    if (
      this._profile[0].agencyList?.some(
        (a) =>
          a.role == EnumRole.EAPAMERENADMIN ||
          a.role == EnumRole.EAPAMERENSUPPORT
      )
    ) {
      this.isAdmin = true;
    } else this.isAdmin = false;
    if (this.isAdmin) {
      this.initalizeForm();
    } //this.getAgencyRegistrationList();
    else {
      var reqs: Observable<any>[] = [];

      this._profile[0].agencyList?.forEach((agcy) => {
        this.isLoading = true;
        reqs.push(this.mangeProfileService.retrieveAgencyInfo(agcy.AgencyID));
      });

      combineLatest(reqs)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (resp) => {
            resp.forEach((agency, idx) => {
              if (reqs.length == idx + 1) this.isLoading = false;
              this.registrationList.push({
                agencyName: agency.data.agencyName,
                agencyPin: agency.data.pinNumber,
                address: agency.data.address,
                agencyID: agency.data.agencyID,
                isApproved:
                  this._profile[0].agencyList?.filter(
                    (x) => x.AgencyID == agency.data.agencyID
                  )[0].status != 'eapPending',
              });
              this.unfilteredRegistrationList.push({
                agencyName: agency.data.agencyName,
                agencyPin: agency.data.pinNumber,
                address: agency.data.address,
                agencyID: agency.data.agencyID,
                isApproved:
                  this._profile[0].agencyList?.filter(
                    (x) => x.AgencyID == agency.data.agencyID
                  )[0].status != 'eapPending',
              });
            });
          },

          error: (err) => {
            this.isLoading = false;
            this.isAPIFailure = true;
          },
        });
    }
  }

  searchFilter() {
    if (this.searchVal && this.searchVal.trim() != '')
      this.registrationList = this.unfilteredRegistrationList.filter(
        (a) =>
          a.agencyName.toUpperCase().includes(this.searchVal.toUpperCase()) ||
          a.agencyPin.includes(this.searchVal)
      );
    else this.registrationList = this.unfilteredRegistrationList;
  }

  getAgencyRegistrationList() {
    this.isAPIFailure = false;
    this.isLoading = true;
    this.selectAgencyManager.getEAPAgenciesList().subscribe({
      next: (res: ISelectAgency[]) => {
        this.isAPIFailure = false;
        this.isLoading = false;

        this.registrationList = res.filter(
          (a) =>
            a.agencyPin != '' &&
            a.registrationStatus === 'Approved' &&
            this.userState.includes(a.companyCode)
        );

        this.unfilteredRegistrationList = this.registrationList;
      },
      error: (error: any) => {
        this.isAPIFailure = true;
        this.isLoading = false;
      },
    });
  }

  ngAfterViewInit() {}

  selectAgency(pin: any) {
    this.isSelectAgencyAPIFailure = false;
    this.isSelectAgencyLoading = true;
    this.selectedAgencyModel.show();
    this.administrationManager.getAgencyInformationByPin(pin).subscribe({
      next: (data: any[]) => {
        this.isSelectAgencyAPIFailure = false;
        this.isSelectAgencyLoading = false;
        this.selectedAgencyModel.hide();
        if (data.length === 1) {
          this._profile[0].selectedAgencyName = data[0].agencyName;
          this._profile[0].selectedAgencyPIN = data[0].pinNumber;
          this._profile[0].selectedAgencyID = data[0].agencyID;
          this._profile[0].selectedAgencyAddress =
            data[0].address.addressLines[0] +
            ' ' +
            data[0].address.city +
            ' ' +
            data[0].address.state +
            ' ' +
            data[0].address.zip;
          const agencyDetails = {
            selectedAgencyName: data[0].agencyName,
            pinNumber: data[0].pinNumber,
            agencyID: data[0].agencyID,
            agencyCSSCode: data[0].agencyCSSCode,
            selectedAgencyAddress:
              data[0].address.addressLines[0] +
              ' ' +
              data[0].address.city +
              ' ' +
              data[0].address.state +
              ' ' +
              data[0].address.zip,
          };
          this.agencyService.setSelectedAgency(agencyDetails);
          this.router.navigateByUrl('/agency-pledges');
        }
      },
      error: (error: any) => {
        this.isSelectAgencyAPIFailure = true;
        this.isSelectAgencyLoading = false;
        console.log('err', error);
        this.agencyService.setSelectedAgency('');
        this.selectedAgencyModel.show();
      },
    });
  }

  selectAgencyByAgencyID(agencySelected: any) {
    this.selectedAgency = agencySelected;

    if (!this.isAdmin) {
      this.isSelectAgencyLoading = true;
      this.selectedAgencyModel.show();
      this.submitAgencySelection();
    }
  }

  submitAgencySelection() {
    this.isSubmitLoading = true;
    this.administrationManager
      .getAgencyInformationById(this.selectedAgency.agencyID.toString())
      .subscribe({
        next: (res) => {
          if (this.userState.includes(parseInt(res.companyCode))) {
            const agencyDetails = {
              selectedAgencyName: res.agencyName,
              pinNumber: res.pinNumber,
              agencyID: res.agencyID,
              agencyCSSCode: res.agencyCSSCode,
              selectedAgencyAddress:
                res.address.addressLines.join(' ') +
                ' ' +
                res.address.city +
                ' ' +
                res.address.state +
                ' ' +
                res.address.zip,
              isEFTAllowed: res.isEFTAllowed,
            };
            this.agencyService.setSelectedAgency(agencyDetails);
            this.isSubmitLoading = false;
            this.selectedAgencyModel.hide();
            this.isSelectAgencyLoading = false;
            this.router.navigateByUrl('/agency-pledges');
          }else{
            this.pinSearchForm
            .get('agencypin')
            ?.setErrors({ invalidPin: 'This agency pin is invalid.' });
          }
        },
      });
  }

  initalizeForm() {
    this.pinSearchForm = this._fb.group({
      agencypin: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
        Validators.pattern('[0-9]{5}'),
      ]),
    });
  }

  get agencypin() {
    return this.pinSearchForm.get('agencypin')!;
  }

  validatePin(event: KeyboardEvent) {
    if (this.isPinSearchLoading || event.key == 'Enter') return;
    this.agencySearchResults = [];
    if (this.pinSearchForm.get('agencypin')?.valid) {
      this.isPinSearchLoading = true;
      this.registrationManagerService
        .validateAgencyPin(this.pinSearchForm.get('agencypin')?.value)
        .subscribe({
          next: (data: any) => {
            this.isPinSearchLoading = false;
            if (Array.isArray(data.data)) {
              data.data.forEach((el: any) => {
                if (el.AgencyID > 0 && el.isPinNumberValid) {
                  this.agencySearchResults.push({
                    isFirstUserForAgency: el.isFirstUserForAgency,
                    agencyID: el.AgencyID,
                    address: `${el.agencyAddress.addressLines.join(' ')} ${
                      el.agencyAddress.state
                    } ${el.agencyAddress.city} ${el.agencyAddress.zip}`,
                    agencyName: el.agencyName,
                    shortName: el.agencyName.split(' ')[0].charAt(),
                  });
                }
              });
            }
            if (this.agencySearchResults.length == 1)
              this.selectedAgency = this.agencySearchResults[0];
          },
          error: (error) => {
            this.isPinSearchLoading = false;
            this.selectedAgency = new AgencyInfo();
            if (
              error?.status == '400' &&
              error?.error?.data?.AgencyID == '0' &&
              !error?.error?.data?.isPinNumberValid
            )
              this.pinSearchForm
                .get('agencypin')
                ?.setErrors({ invalidPin: 'This agency pin is invalid.' });
            else
              this.pinSearchForm.get('agencypin')?.setErrors({
                invalidPin:
                  'Sorry, something went wrong. Please try again later.',
              });
          },
        });
    }
  }
}

