<div id="campaign__activity__main-container">
  <div
    *ngIf="isAPIFailure"
    class="mt-6 alert text-center alert-danger campaign__details__error-handling"
    role="alert"
  >
    <em class="fa fa-times-square"></em>
    Sorry, Unable to get the campaign details. Please try again later.
  </div>

  <div class="text-center campaign__details__error-handling" *ngIf="isLoading">
    <ameren-loading class="d-flex p-10 justify-content-center"></ameren-loading>
    <span>Please wait...</span>
  </div>

  <div class="text-center" *ngIf="!isAPIFailure && !isLoading">
    <div class="fs-5" *ngIf="activeCampaignList.length === 0">
      No campaign activities found!
    </div>
  </div>

  <div
    class="campaign__activity-container"
    *ngIf="!isAPIFailure && !isLoading && activeCampaignList.length != 0"
  >
    <div class="row">
      <div class="campaign__activity-title">Campaign Activity</div>
    </div>
    <div *ngFor="let campaign of activeCampaignList" class="m-5">
      <div class="card campaign__activity-card">
        <div class="card-body">
          <div class="row">
            <div class="col p-3">
              <div class="form-group m-2">
                <label class="form-label" for="campaignName"
                  >Campaign Name</label
                >
                <div class="fs-5 fw-bold" id="campaignName">
                  {{ campaign.campaignName }}
                </div>
              </div>
              <div class="form-group m-2 flex-fill">
                <div class="row">
                  <div class="col">
                    <label class="form-label" for="startDate"
                      >Campaign Start Date</label
                    >
                  </div>
                  <div class="col">
                    <label class="form-label" for="endDate"
                      >Campaign End Date</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col fw-bold">{{ campaign.campaignFromDate }}</div>
                  <div class="col fw-bold">{{ campaign.campaignToDate }}</div>
                </div>
              </div>
              <div class="form-group m-2">
                <label class="form-label" for="campaignAmount"
                  >Total Campaign Allocation</label
                >
                <div class="fs-5 fw-bold">
                  {{ campaign.allocated | currency }}
                </div>
              </div>
              <div class="form-group m-2">
                <label class="form-label" for="Used">Used</label>
                <div class="fs-5 fw-bold" id="Used">
                  {{ campaign.used | currency }}
                </div>
              </div>
            </div>
            <div class="col p-5">
              <div class="m-2">
                <div class="form-group m-2">
                  <label class="form-label" for="remaining">Remaining</label>
                  <div class="fs-5 fw-bold" id="remaining">
                    {{ campaign.remainingAmount | currency }}
                  </div>
                </div>

                <div class="form-group m-2">
                  <label class="form-label" for="timeRemaining"
                    >Time Remaining
                  </label>
                  <span
                    class="fs-6 form-text float-end"
                    id="daysCompleted"
                    [ngClass]="
                      campaign.timeRemaining < 30
                        ? 'text-danger'
                        : 'text-primary fw-bold'
                    "
                  >
                    {{
                      campaign.timeRemaining < 0
                        ? 'Campaign is expired'
                        : campaign.timeRemaining + ' days'
                    }}
                  </span>

                  <div class="progress">
                    <div
                      class="progress-bar progress-bar-striped bg-primary"
                      role="progressbar"
                      [style.width]="
                        campaign && campaign
                          ? campaign.percentage + '%'
                          : 0 + '%'
                      "
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="form-group m-2">
                  <label class="form-label" for="financialPercentComplete"
                    >Percentage of Funds spent</label
                  >
                  <output
                    class="fs-5 form-text text-success fw-bold float-end"
                    id="fundSpent"
                    >{{ campaign.campaignFundSpentPercentage + '%' }}</output
                  >
                  <div class="progress">
                    <div
                      id="financialPercentComplete"
                      class="progress-bar progress-bar-striped bg-success"
                      role="progressbar"
                      [style.width]="
                        campaign && campaign.campaignFundSpentPercentage
                          ? campaign.campaignFundSpentPercentage + '%'
                          : 0 + '%'
                      "
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="form-group m-2">
                  <button
                    class="btn btn-primary"
                    [routerLink]="[
                      '/agency-pledges',
                      campaign.campaignAgencyID
                    ]"
                  >
                    Show Pledges
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
