import { Router } from '@angular/router';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { PreferencesManagerService } from './services/preferences-manager.service';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

declare var window: any;

declare var window: any;

@Component({
  selector: 'app-pledge-notification',
  templateUrl: './pledge-notification.component.html',
  styleUrls: ['./pledge-notification.component.scss'],
})
export class PledgeNotificationComponent implements OnInit {
  phoneContactList: any[];
  emailContactList: any[];
  contactList: any[];
  contactsLoading: boolean = true;
  getContactsApiFailure: boolean;
  selectedAccount: string;
  isLoading: boolean;
  isAPIFailure: boolean;
  addEmailModal: any;
  emailForm: FormGroup;
  phoneForm: FormGroup;
  isSubmitted: boolean = false;
  iupdateLoading: boolean;
  isUpdateSuccess: boolean;
  isUpdateFailure: boolean;
  updateNotificationLoadingModel: any;
  addPhoneModal: any;

  constructor(
    private _preferenceMgr: PreferencesManagerService,
    private _clientSvc: ClientService,
    private fb: FormBuilder,
    private _profileSvc: ProfileManagerService,
    private _router: Router
  ) {}

  ngOnInit(): void {
      this._router.navigate(['ineligible-user']);

    this.selectedAccount =
      this._clientSvc.getSelectedClient().billAccountNumber;
    this.initEmailForm();
    this.initPhoneForm();

    this.addEmailModal = new window.bootstrap.Modal(
      document.getElementById('addEmailModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.addPhoneModal = new window.bootstrap.Modal(
      document.getElementById('addPhoneModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.updateNotificationLoadingModel = new window.bootstrap.Modal(
      document.getElementById('updateNotificationLoadingModel'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.selectedAccount =
      this._clientSvc.getSelectedClient().billAccountNumber;
    this._preferenceMgr.getPreferenceList(this.selectedAccount).subscribe({
      next: (data: any[]) => {
        this.contactsLoading = false;
        this.contactList = data;
        let contacts = data.filter((x) => x.deliveryTypeCode == 1);
        this.phoneContactList = [];
        contacts.forEach((contact) => {
          if (
            !this.phoneContactList.some((a) => a.contact == contact.contact)
          ) {
            this.phoneContactList.push({
              contact: contact.contact,
              contactNumber:
                contact.contact.substring(0, 3) +
                '-' +
                contact.contact.substring(3, 6) +
                '-' +
                contact.contact.substring(6, 10),
              enrolled: contact.alerts.some(
                (z: any) =>
                  z.preferenceSubGroupCode == 7 && z.alertStatusCode == 2
              ),
              cssContactId: contact.cssContactId,
            });
          }
        });

        this.emailContactList = [];
        let emailContacts = data.filter((x) => x.deliveryTypeCode == 2);
        emailContacts.forEach((email) => {
          this.emailContactList.push({
            email: email.contact,
            contact: email.contact,
            enrolled: email.alerts.some(
              (z: any) =>
                z.preferenceSubGroupCode == 7 && z.alertStatusCode == 2
            ),
            cssContactId: email.cssContactId,
          });
        });

        this.initEmailForm();
      },
      error: (err) => {
        this.contactsLoading = false;
        this.getContactsApiFailure = false;
      },
    });
  }
  uniqueEmailValidation(emails: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !emails?.find((x) => x == control.value)
        ? null
        : {
            validateEmail: {
              valid: false,
            },
          };
    };
  }

  initEmailForm() {
    this.emailForm = this.fb.group({
      emailAddress: new FormControl(null, [
        Validators.required,
        Validators.email,
        this.uniqueEmailValidation(this.emailContactList?.map((x) => x.email)),
      ]),
    });
  }
  initPhoneForm() {
    this.phoneForm = this.fb.group({
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]{3}.[0-9]{3}.[0-9]{4}$'),
      ]),
    });
  }
  onTogglePhoneAlert(phn: any) {
    phn.enrolled = !phn.enrolled;
    this.updateSubscription(phn, 1);
  }
  onToggleEmailAlert(email: any) {
    email.enrolled = !email.enrolled;
    this.updateSubscription(email, 2);
  }

  updateSubscription(phn: any, deleveryTypeCode: number) {
    let con = this.contactList.filter(
      (x) =>
        x.cssContactId == phn.cssContactId &&
        x.deliveryTypeCode == deleveryTypeCode
    )[0];

    let currentAlert = con.alerts.filter(
      (a: any) => a.preferenceSubGroupCode == 7
    )[0];
    let alert = {
      contact: phn.contact,
      deliveryTypeCode: deleveryTypeCode,
      quietHoursStart: con.quietHoursStart,
      quietHoursEnd: con.quietHoursEnd,
      isOutageQuietHoursIgnored: con.isOutageQuietHoursIgnored,
      alerts: [
        {
          alertStatusCode: phn.enrolled ? 2 : 1,
          preferenceGroupCode: currentAlert.preferenceGroupCode,
          preferenceSubGroupCode: currentAlert.preferenceSubGroupCode,
          preferenceQuantity: currentAlert.preferenceQuantity,
        },
      ],
    };
    this.updateNotificationLoadingModel.show();
    this.iupdateLoading = true;
    this._preferenceMgr
      .updatePreferenceList([alert], this.selectedAccount.toString())
      .subscribe({
        next: (data) => {
          this.isUpdateFailure = false;
          this.iupdateLoading = false;
          this.isUpdateSuccess = true;
          setTimeout(() => {
            this.closeNotoficationModal();
          }, 3000);
        },
        error: (err) => {
          this.isUpdateFailure = true;
          this.iupdateLoading = false;
          this.isUpdateSuccess = false;
          setTimeout(() => {
            this.closeNotoficationModal();
          }, 3000);
        },
      });
  }

  closeNotoficationModal() {
    this.isUpdateFailure = false;
    this.iupdateLoading = false;
    this.isUpdateSuccess = false;
    this.updateNotificationLoadingModel.hide();
  }

  openEmailModal() {
    this.isSubmitted = false;
    this.addEmailModal.show();
  }

  openPhoneModal() {
    this.isSubmitted = false;
    this.addPhoneModal.show();
  }

  closeEmailModal() {
    this.addEmailModal.hide();
  }

  closePhoneModal() {
    this.addPhoneModal.hide();
    location.reload();
  }

  addEmail() {
    this.isAPIFailure = false;
    const email = this.emailForm.controls['emailAddress'].value;

    this.isLoading = true;
    this._preferenceMgr.addEmail(this.selectedAccount, email).subscribe({
      next: () => {
        this.isAPIFailure = false;
        this.isLoading = false;
        this.isSubmitted = true;
        this.ngOnInit();
      },
      error: (error: any) => {
        console.log('err', error);
        this.isAPIFailure = true;
        this.isLoading = false;
      },
    });
  }

  addPhone() {
    this.isAPIFailure = false;
    let phone = '';
    phone = this.phoneForm.controls['phone'].value
      .toString()
      .split('.')
      .join('');
    if (this.phoneContactList.filter((a: any) => a.contact == phone).length) {
      this.phoneForm
        .get('phone')
        ?.setErrors({ validatePhone: 'already exists' });
    } else {
      this.isLoading = true;
      this._preferenceMgr.addPhone(this.selectedAccount, phone)?.subscribe({
        next: () => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.isSubmitted = true;
        },
        error: (error: any) => {
          console.log('err', error);
          this.isAPIFailure = true;
          this.isLoading = false;
        },
      });
    }
  }
}
