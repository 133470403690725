<div class="clientquickview_container">
  <div class="clientquickview__title">
    <p>Client Quick View</p>
  </div>
  <div class="d-flex float-end">
    <button class="btn btn-default" (click)="generateQuickViewPDF()">
      <img src="assets/icon/pdficon.svg" alt="PDF Image" />
      PDF
    </button>
  </div>
  <br />
  <br />

  <div class="clientquickview_container_loading">
    <ameren-loading *ngIf="loading"></ameren-loading>
  </div>
  <div class="clientquickview_container_error" *ngIf="isApiFailed">
    {{ errorMessage }}
  </div>
  <div
    class="clientquickview__tableWrap"
    *ngIf="!loading && clientSummary && !isApiFailed"
  >
    <div class="clientquickview__table">
      <div class="clientquickview__table-body">
        <div class="clientquickview__table-row" id="accountStatus">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Account Status :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.baStatus }}
          </div>
        </div>
        <div class="clientquickview__table-row" id="collectionStatus">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Collection Status :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.collectionStatus }}
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Cash Only :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.cashOnly }}
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            SSN :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            XXX-XX-{{ clientSummary.ssnLast4Digits }}
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Company :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ company }}
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Account Balance :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.currentDayBalance | currency }}
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Ameren is Primary Heating Vendor? :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.isAmerenPrimaryHeatingSource ? 'YES' : 'NO' }}
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Total Customer Payments :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.totalPaymentLast90Days | currency }} (past 90 days)
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Total Energy Assistance Payments :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{
              clientSummary.totalEnergyAssistancePaymentsLast90Days | currency
            }}
            (past 90 days)
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Pending Payments :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.pendingPayments | currency }}
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Pending Pledges :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.pendingPledges | currency }}
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Service Type :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.serviceType }}
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Unbilled Payment Agreement :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.unbilledPaymentAgreementBalance | currency }}
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Unbilled Deposit Balance :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.unbilledDepositBalance | currency }}
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Budget Billing Amount :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.budgetBillingAmount | currency }}
          </div>
        </div>
        <div class="clientquickview__table-row">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Cut Out Non-Pay Count :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.numDisconnects }} (past 12 months)
          </div>
        </div>
        <div class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Broken Payment Agreements :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.numPAGDefaults }} (past 12 months)
          </div>
        </div>
        <div *ngIf="company == 'Illinois'" class="clientquickview__table-row clientquickview_alternaterow">
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Low Income Credit :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.lowIncomeCreditTier }}
          </div>
        </div>
        <div
          class="clientquickview__table-row"
          [ngClass]="
            HasPendingDisconnection || showMinimumDisconnectionPreventionAmount
              ? 'disconnect__notice-warning'
              : ''
          "
        >
          <div
            class="clientquickview__table-body-cell clientquickview_leftcolumn"
          >
            Minimum Payment :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_rightcolumn text-left"
          >
            {{ clientSummary.minDisconnectionPreventionAmount | currency }}
          </div>
        </div>        
        <div
          *ngIf="hasDisconnectionData"
          class="clientquickview__table-row clientquickview_alternaterow"
          [ngClass]="'disconnect__notice-warning'"
        >
          <div
            class="clientquickview__table-body-cell clientquickview_lastrow clientquickview_leftcolumn"
          >
            {{ disconnectionDateLabelText }} :
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_lastrow clientquickview_rightcolumn text-left"
          >
            <span> {{ clientSummary.disconnectionDate }}</span>
          </div>
        </div>
        <div
          *ngIf="IsDisconnected"
          class="clientquickview__table-row"
          [ngClass]="'disconnect__notice-warning'"
        >
          <div
            class="clientquickview__table-body-cell clientquickview_lastrow clientquickview_leftcolumn"
          >
            Reconnection Amount:
          </div>
          <div
            class="clientquickview__table-body-cell clientquickview_lastrow clientquickview_rightcolumn text-left"
          >
            <span>{{ clientSummary.reconnectionAmount | currency }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="paymentagreement_container">
  <div class="clientquickview__title">
    <p>Payment Agreement</p>
  </div>
  <div class="clientquickview_container_loading">
    <ameren-loading *ngIf="paLoading"></ameren-loading>
  </div>
  <div
    class="clientquickview_container_error"
    *ngIf="isPaymentAgreementApiFailed"
  >
    Failed to load payment agreements. Please reload the page to try again.
  </div>
  <div
    class="paymentagreement_tablewrap"
    *ngIf="!paLoading && paymentAgreements && !isPaymentAgreementApiFailed"
  >
    <table
      id="paymentAgreement"
      #paymentAgreementDetails
      aria-describedby="Payment Agreement"
      class="paymentagreement__table"
    >
      <thead class="paymentagreement__table-header">
        <tr>
          <th scope="col">Payment Agreement Options</th>
          <th scope="col">
            <div>
              <span class="column-sort">Required Payment</span
              ><img
                [appSort]="paymentAgreements"
                data-order="asc"
                data-name="requiredPaymentAmount"
                src="../../../assets/icon/sort.svg"
                alt="Sort by Required Payment"
              />
            </div>
          </th>
          <th scope="col">
            <div>
              <span class="column-sort">Payment Due Date</span
              ><img
                [appSort]="paymentAgreements"
                data-order="asc"
                data-name="paymentDueDate"
                src="../../../assets/icon/sort.svg"
                alt="Sort by Payment Due Date"
              />
            </div>
          </th>
          <th scope="col">
            <div>
              <span class="column-sort">Number of Monthly Installments</span
              ><img
                [appSort]="paymentAgreements"
                data-order="asc"
                data-name="numberOfInstallments"
                src="../../../assets/icon/sort.svg"
                alt="Sort by Monthly Installments"
              />
            </div>
          </th>
          <th scope="col">
            <div>
              <span class="column-sort">Installment Amount</span
              ><img
                [appSort]="paymentAgreements"
                data-order="asc"
                data-name="installmentAmount"
                src="../../../assets/icon/sort.svg"
                alt="Sort by Installment Amount"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="paymentagreement__table-body">
        <tr *ngIf="paymentAgreements && paymentAgreements.length === 0">
          <td colspan="5">
            <div class="clientquickview_container_error">
              There are no payment agreements for the account number
            </div>
          </td>
        </tr>
        <tr *ngFor="let pa of paymentAgreements">
          <td>
            <strong
              ><span>{{ pa.optionName }}</span></strong
            >
            <p>{{ pa.optionDescription }}</p>
          </td>
          <td>{{ pa.requiredPaymentAmount | currency }}</td>
          <td>{{ pa.paymentDueDate | date : 'MM/d/y' }}</td>
          <td>
            <span
              *ngIf="
                !(
                  (pa.optionType == PaymentAgreementOptionType.MA ||
                    pa.optionType == PaymentAgreementOptionType.EX) &&
                  pa.numberOfInstallments == '0'
                )
              "
              >{{ pa.numberOfInstallments }}</span
            >
          </td>
          <td>
            <span
              *ngIf="
                !(
                  (pa.optionType == PaymentAgreementOptionType.MA ||
                    pa.optionType == PaymentAgreementOptionType.EX) &&
                  pa.installmentAmount == '0'
                )
              "
              >{{ pa.installmentAmount | dollarAmount }}</span
            >
          </td>
        </tr>
      </tbody>
      <tfoot
        class="paymentagreement__table-footer"
        *ngIf="paymentAgreements && paymentAgreements.length > 0"
      >
        <tr>
          <td colspan="5">
            Above are the current payment options available for today's date,
            and are subject to change. As an Energy Assistance agency, you have
            view-only access to payment options. When the payment agreement
            option is chosen, Ameren account holders must activate the agreement
            through their online account or by calling Ameren {{ company }} at
            {{ callCenterNumber }} first and then the pledge can be entered on
            the account.
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<div class="budgetbillinghistory__container">
  <app-budget-billing
    *ngIf="clientSummary && clientSummary.showBudgetBilling"
  ></app-budget-billing>
</div>

<!-- Only for PDF export -->
<table
  *ngIf="clientSummary"
  class="table table-striped d-none"
  aria-describedby="client quick view"
  #clientQuickViewDetails
>
  <thead *ngIf="false">
    <tr>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Account Status :</td>
      <td>{{ clientSummary.baStatus }}</td>
    </tr>
    <tr>
      <td>Collection Status :</td>
      <td>{{ clientSummary.collectionStatus }}</td>
    </tr>
    <tr>
      <td>Cash Only :</td>
      <td>{{ clientSummary.cashOnly }}</td>
    </tr>
    <tr>
      <td>SSN :</td>
      <td>XXX-XX-{{ clientSummary.ssnLast4Digits }}</td>
    </tr>
    <tr>
      <td>Company :</td>
      <td>{{ company }}</td>
    </tr>
    <tr>
      <td>Account Balance :</td>
      <td>{{ clientSummary.currentDayBalance | currency }}</td>
    </tr>
    <tr>
      <td>Ameren is Primary Heating Vendor? :</td>
      <td>{{ clientSummary.isAmerenPrimaryHeatingSource ? 'YES' : 'NO' }}</td>
    </tr>
    <tr>
      <td>Total Customer Payments :</td>
      <td>
        {{ clientSummary.totalPaymentLast90Days | currency }} (past 90 days)
      </td>
    </tr>
    <tr>
      <td>Total Energy Assistance Payments :</td>
      <td>
        {{ clientSummary.totalEnergyAssistancePaymentsLast90Days | currency }}
        (past 90 days)
      </td>
    </tr>
    <tr>
      <td>Pending Payments :</td>
      <td>{{ clientSummary.pendingPayments | currency }}</td>
    </tr>
    <tr>
      <td>Pending Pledges :</td>
      <td>{{ clientSummary.pendingPledges | currency }}</td>
    </tr>
    <tr>
      <td>Service Type :</td>
      <td>{{ clientSummary.serviceType }}</td>
    </tr>
    <tr>
      <td>Unbilled Payment Agreement :</td>
      <td>{{ clientSummary.unbilledPaymentAgreementBalance | currency }}</td>
    </tr>
    <tr>
      <td>Unbilled Deposit Balance :</td>
      <td>{{ clientSummary.unbilledDepositBalance | currency }}</td>
    </tr>
    <tr>
      <td>Budget Billing Amount :</td>
      <td>{{ clientSummary.budgetBillingAmount | currency }}</td>
    </tr>
    <tr>
      <td>Cut Out Non-Pay Count :</td>
      <td>{{ clientSummary.numDisconnects }} (past 12 months)</td>
    </tr>
    <tr>
      <td>Broken Payment Agreements :</td>
      <td>{{ clientSummary.numPAGDefaults }} (past 12 months)</td>
    </tr>
    <tr
      [ngClass]="
        HasPendingDisconnection || showMinimumDisconnectionPreventionAmount
          ? 'disconnect__notice-warning'
          : ''
      "
    >
      <td>Minimum Payment :</td>
      <td>{{ clientSummary.minDisconnectionPreventionAmount | currency }}</td>
    </tr>
    <tr *ngIf="company == 'Illinois'">
      <td>Low Income Credit :</td>
      <td>{{ clientSummary.lowIncomeCreditTier }}</td>
    </tr>
    <tr *ngIf="hasDisconnectionData" [ngClass]="'disconnect__notice-warning'">
      <td>{{ disconnectionDateLabelText }} :</td>
      <td>
        <span> {{ clientSummary.disconnectionDate }}</span>
      </td>
    </tr>

    <tr *ngIf="IsDisconnected" [ngClass]="'disconnect__notice-warning'">
      <td>Reconnection Amount :</td>
      <td>
        <span>{{ clientSummary.reconnectionAmount | currency }}</span>
      </td>
    </tr>
  </tbody>
</table>
