<div>
  <p class="fs-3 text-center inEligibleTitle fw-bolder mt-5">
    Features Not Available!
  </p>
  <p class="text-center p-2 m-2">
    If you have questions about this message, please call Ameren Missouri Energy
    Assistance at 800.894.5211 or email
    <a href="mailto:eassistance@ameren.com">eassistance@ameren.com</a>
    and call Ameren Illinois Energy Assistance at 888.667.7861 or email
    <a href="mailto:ILEnergyAssistanceTeam@ameren.com"
      >ILEnergyAssistanceTeam@ameren.com</a
    >
  </p>
</div>
