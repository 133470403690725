import { Injectable } from '@angular/core';
import { catchError, throwError, map } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { ClientPledge } from '../model/clientpledge.model';
import { IPledges } from '../interfaces/Ipledge';

import { ClientpledgeApiService } from './clientpledge-api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientpledgeManagerService {
  clientPledgeByAccount: ClientPledge[];
  constructor(private clientPledgeApiService: ClientpledgeApiService) {
    this.clientPledgeByAccount = [];
  }

  getClientPledgeList(clientPledgeList: any) {
    return this.clientPledgeApiService.getClientPledge(clientPledgeList).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Client Pledges cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }

        const clientPledgeByAccount = res.data?.pledgesByAccount;
        this.clientPledgeByAccount = [];

        if (clientPledgeByAccount instanceof Array) {
          clientPledgeByAccount.forEach((data: IPledges) => {
            this.clientPledgeByAccount.push(new ClientPledge(data));
          });
        }

        return this.clientPledgeByAccount;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }
}
