<div class="agencyinformation_container view-none-mobile">
    <div class="agencyinformation__title">Agency Information</div>
    <div class="agencyinformation__body">
        <div *ngIf="!isLoading && !isAPIFailure">
            <p class="agencyinformation__initials">{{agencyInitials}}</p>
            <p class="agencyinformation__details">
                <span class="agencyinformation__name">{{agencyName}}</span>
                <br>
                {{agencyStreetName}}, {{agencyCity}}, {{agencyState}} {{agencyZip}}
            </p>
        </div>
        <div class="text-center p-3" *ngIf="isLoading">
            <ameren-loading class="d-flex justify-content-center"></ameren-loading>
        </div>
        <div *ngIf="isAPIFailure" class="mt-3 alert alert-danger" role="alert">
            <p>Failed to retrive Agency Information. Please try again later</p>
        </div>
    </div>
</div>


<div class="manageview__profile_container view-mobile agency-view">
    <p class="profile__title">Agency Information</p>
    <div class="view__agencyinfo__container agency-container">  
      <div>
        <div *ngIf="isAPIFailure" class="mt-3 alert alert-danger" role="alert">
          <p>Failed to retrive Agency Information. Please try again later</p>
        </div>
      </div>
      
      <div *ngIf="isLoading">
        <ameren-loading class="d-flex justify-content-center"></ameren-loading>
      </div>  

      <div *ngIf="!isLoading && !isAPIFailure">
        <div class="list_container">
          <div class="profile__initials">{{agencyInitials}}</div>
          <div class="list_content">
            <span class="title">{{agencyName}}</span>
            <span class="address">{{agencyStreetName}}, {{agencyCity}}, {{agencyState}} {{agencyZip}}</span>
          </div>
        </div>
      </div>    
   </div>
</div>