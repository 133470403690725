import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client.service';
import { IBudgetBillingHistory } from '../interfaces/Ibudgetbillinghistory';
import { BudgetbillingManagerService } from '../services/budgetbilling-manager.service';
import * as XLSX from 'xlsx';

import { UtilitiesService } from 'src/app/shared/services/utilities.service';
@Component({
  selector: 'app-budget-billing',
  templateUrl: './budget-billing.component.html',
  styleUrls: ['./budget-billing.component.scss'],
})
export class BudgetBillingComponent implements OnInit {
  budgetBillingHistory: IBudgetBillingHistory[];
  budgetBillingHistoryOrig: IBudgetBillingHistory[];
  selectedClient: Client;
  isAPIFailure: boolean = false;
  isLoading: boolean = true;
  @ViewChild('budgetbillinghistory')
  budgetbillinghistory: ElementRef;
  searchString: string;
  isBBRecordsNotFound = false;

  constructor(
    private budgetBillingManager: BudgetbillingManagerService,
    private clientService: ClientService,
    private _utility: UtilitiesService
  ) {
    this.selectedClient = this.clientService.getSelectedClient();
  }

  ngOnInit(): void {
    this.getBudgetBillingHistory();
  }

  getBudgetBillingHistory() {
    this.isLoading = true;
    return this.budgetBillingManager
      .getBudgetBilling(this.selectedClient.billAccountNumber)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.budgetBillingHistory = res;
          this.budgetBillingHistoryOrig = this.budgetBillingHistory;
          this.isBBRecordsNotFound = false;
        },
        error: (error: any) => {
          this.isAPIFailure = true;
          this.isLoading = false;

          error &&
          error.error.errorDetails.budgetBillingHistory.includes(
            'No Budget Billing History'
          )
            ? (this.isBBRecordsNotFound = true)
            : (this.isBBRecordsNotFound = false);
        },
      });
  }

  searchFilter() {
    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.budgetBillingHistory = this.budgetBillingHistoryOrig.filter(
        (data) =>
          data.billDate.toLocaleLowerCase().match(adjustedSearch) ||
          data.budgetBillAmount.toString().match(adjustedSearch) ||
          data.aheadBehindCode.toLocaleLowerCase().match(adjustedSearch) ||
          data.actualUsedAmount.toString().match(adjustedSearch) ||
          data.deferredAmount.toString().match(adjustedSearch)
      );
    } else {
      this.budgetBillingHistory = this.budgetBillingHistoryOrig;
    }
  }

  exportToExcel() {
    let table_elt = document.getElementById('budgetbillinghistory');
    let workbook = XLSX.utils.table_to_book(table_elt);
    this.formatSheet(workbook);
  }

  private formatSheet(workbook: XLSX.WorkBook) {
    let worksheet: XLSX.WorkSheet;
    worksheet = workbook.Sheets['Sheet1'];
    let data = XLSX.utils.sheet_to_json(worksheet);
    let ws = XLSX.utils.aoa_to_sheet([[this.selectedClient.fullName]]);
    XLSX.utils.sheet_add_aoa(
      ws,
      [[this.selectedClient.address.streetAddress]],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          this.selectedClient.address.city +
            ' ' +
            this.selectedClient.address.state +
            this.selectedClient.address.zip,
        ],
      ],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          this._utility.transformAccountNumber(
            this.selectedClient.billAccountNumber.toString()
          ),
        ],
      ],
      { origin: -1 }
    );

    XLSX.utils.sheet_add_json(ws, data, { origin: 'A6' });

    this.formatColumn(ws, 0, 'yyyy-MM-dd');
    this.formatColumn(ws, 1, '00');
    this.formatColumn(ws, 2, '$0.00');
    this.formatColumn(ws, 3, '$0.00');
    this.formatColumn(ws, 5, '$0.00');

    var wscols = [
      { wch: 10 },
      { wch: 17 },
      { wch: 18 },
      { wch: 18 },
      { wch: 12 },
      { wch: 21 },
    ];

    ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, 'BudgetBillingHistory.xlsx');
  }

  formatColumn(worksheet: XLSX.WorkSheet, col: number, fmt: string) {
    const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col });
      if (worksheet[ref] && worksheet[ref].t === 'n') {
        worksheet[ref].z = fmt;
        if (col == 0) worksheet[ref].v = worksheet[ref].v + 1;
      }
    }
  }

  exportToPdf() {
    const budgetbillinghistory = this.budgetbillinghistory.nativeElement;

    let body = [
      {
        type: 'string',
        element: 'Budget Billing History',
        align: 'center',
        x: 0,
        y: 15,
        fontsize: 16,
      },
      {
        type: 'string',
        element: 'Account Number: ' + this._utility.transformAccountNumber(this.selectedClient?.billAccountNumber),
        align: '',
        x: 15,
        y: 10,
        fontsize: 12,
      },
      {
        type: 'string',
        element: 'Client: ' + this.selectedClient?.fullName,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'string',
        element: this.selectedClient?.address?.streetAddress,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'string',
        element:
          this.selectedClient?.address?.city +
          ' ' +
          this.selectedClient?.address?.state +
          ' ' +
          this.selectedClient?.address?.zip,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'table',
        element: budgetbillinghistory,
        align: 'none',
        x: 0,
        y: 5,
        fontsize: 12,
      },
    ];

    this._utility.exportTabletoPDF('budgetbillinghistory.pdf', body);
  }
}
