<div class="pendingameren__users-container view-none-mobile">
    <div class="input-group searchuser mb-3 ms-auto w-25">
      <input
        type="search"
        class="form-control mt-0"
        placeholder="Search"
        aria-label="searchAmerenUser"
        [(ngModel)]="searchAmerenUser"
        aria-describedby="searchAmerenUser"
      />
      <span class="input-group-text" id="searchAmerenUser"
        ><em class="fa fa-search"></em
      ></span>
    </div>
    <div class="pendingameren__users-list">
      <table
        id="pendingamerenUsers"
        aria-describedby="Ameren Users List"
        class="align-middle bg-white"
      >
        <thead class="pendingameren__users__table-header static-header">
          <tr>
            <th scope="col">
              <span
              class="table-head"
              [appSort]="amerenUsers"
              data-order="asc"
              data-name="givenName"
            >
              <span class="column-name">Name</span
              ><em class="fa-solid fa-sort"></em
            ></span>
            </th>
            <th scope="col">
              <span
              class="table-head"
              [appSort]="amerenUsers"
              data-order="asc"
              data-name="userName"
            >
              <span class="column-name">Email</span
              ><em class="fa-solid fa-sort"></em
            ></span>
            </th>
            <th scope="col">
              <span
              class="table-head"
              [appSort]="amerenUsers"
              data-order="asc"
              data-name="accountStatus"
            >
              <span class="column-name">Revoke</span
              ><em class="fa-solid fa-sort"></em
            ></span>
            </th>
            <th scope="col">
              <span
              class="table-head"
              [appSort]="amerenUsers"
              data-order="asc"
              data-name="status"
            >
              <span class="column-name">Pending</span
              ><em class="fa-solid fa-sort"></em
            ></span>
            </th>
            <th scope="col">
              <span
              class="table-head"
              [appSort]="amerenUsers"
              data-order="asc"
              data-name="role"
            >
              <span class="column-name">Support</span
              ><em class="fa-solid fa-sort"></em
            ></span>
            </th>
            <th scope="col">
              <span
              class="table-head"
              [appSort]="amerenUsers"
              data-order="asc"
              data-name="role"
            >
              <span class="column-name">Admin</span
              ><em class="fa-solid fa-sort"></em
            ></span>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="6">
              <ameren-loading
                id="loadingIndicator"
                class="d-flex mt-30 justify-content-center"
              ></ameren-loading>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isAPIFailure && !isLoading">
          <tr>
            <td colspan="6">
              <div
                id="noRecordsFound"
                class="no__pendingameren__users__found mt-3 alert alert-danger"
              >
                Sorry, Unable to retrieve the ameren users information. Please try
                again later.
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isAPIFailure && !isLoading">
          <tr
            *ngIf="
              (amerenUsers && amerenUsers.length === 0) ||
              (amerenUsers | searchFilter : searchAmerenUser).length === 0
            "
          >
            <td colspan="6">
              <div id="noRecordsFound" class="no__pendingameren__users__found">
                There are no users in Revoke/Pending/Support/Admin in the Ameren
                Login.
              </div>
            </td>
          </tr>
          <tr
            *ngFor="
              let user of amerenUsers | searchFilter : searchAmerenUser;
              let i = index
            "
          >
            <td>
              <div class="d-flex align-items-center">
                <div class="ms-3">
                  <p class="fw-normal mb-1 d-flex flex-wrap text-break">
                    {{ user.sn }} {{ user.givenName }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p class="fw-normal mb-1 d-flex flex-wrap text-break">
                {{ user.userName }}
              </p>
            </td>
            <td>
              <span class="d-inline d-flex flex-wrap text-break">
                <input
                  type="radio"
                  id="eapRevokeStatus"
                  (click)="submitAdminRequest($event, user)"
                  name="status{{ i }}"
                  aria-label="Select Revoke Option to Approve"
                  value="eapRevokeStatus"
                  [attr.checked]="
                    user.accountStatus === 'inactive' ? 'checked' : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
            <td>
              <span
                class="d-inline d-flex flex-wrap text-break"
                class="amerenuser_role__and__status"
              >
                <input
                  type="radio"
                  id="eapPendingStatus"
                  (click)="submitAdminRequest($event, user)"
                  name="status{{ i }}"
                  aria-label="Select Pending Option to Submit"
                  value="eapPendingStatus"
                  [attr.checked]="user.status === 'eappending' ? 'checked' : null"
                  [disabled]="true"
                />
              </span>
            </td>
            <td>
              <span class="d-inline d-flex flex-wrap text-break">
                <input
                  type="radio"
                  id="eapAmerenSupport"
                  (click)="submitAdminRequest($event, user)"
                  name="status{{ i }}"
                  aria-label="Select Ameren Support Option to Approve"
                  value="eapAmerenSupport"
                  [attr.checked]="
                    user.role === 'eapamerensupport' &&
                    user.accountStatus === 'active'
                      ? 'checked'
                      : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
            <td>
              <span class="d-inline d-flex flex-wrap text-break">
                <input
                  type="radio"
                  id="eapAmerenAdmin"
                  (click)="submitAdminRequest($event, user)"
                  name="status{{ i }}"
                  aria-label="Select Ameren Admin Option to Approve"
                  value="eapAmerenAdmin"
                  [attr.checked]="
                    user.role === 'eapamerenadmin' &&
                    user.accountStatus === 'active'
                      ? 'checked'
                      : null
                  "
                  [disabled]="isAmerenSupport"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <!--------modal--------->
  <div
    id="pendingamerenModal"
    class="modal fade"
    aria-hidden="true"
    aria-labelledby="pendingamerenModal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" *ngIf="!isApprovalLoading">
          <div
            class="pendingameren__modal__success-title"
            *ngIf="!isApprovalLoading && !isApprovalAPIFailure"
          >
            Successfully Submitted!
          </div>
          <div
            class="pendingameren__modal__failure-title"
            *ngIf="!isApprovalLoading && isApprovalAPIFailure"
          >
            Submission Error!
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div *ngIf="isApprovalLoading" class="text-center">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
          <div class="pendingameren__modal-image">
            <img
              *ngIf="!isApprovalAPIFailure && !isApprovalLoading"
              src="assets/images/success.svg"
              alt="Success Image"
            />
            <img
              *ngIf="isApprovalAPIFailure && !isApprovalLoading"
              src="assets/images/failure.svg"
              alt="Failure Image"
            />
          </div>
          <div class="pendingameren__modal-footer" *ngIf="!isApprovalLoading">
            <div
              class="pendingameren__modal__success-message"
              *ngIf="!isApprovalLoading && !isApprovalAPIFailure"
            >
              Ameren approval request has been submitted successfully.
            </div>
  
            <div
              class="pendingameren__modal__failure-message"
              *ngIf="!isApprovalLoading && isApprovalAPIFailure"
            >
              Ameren approval request has not been submitted successfully. Please
              try again to submit your request.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!------mobile view-------->
  <div class="manageview__profile_container view-mobile">
    <div class="view__profile__container view_profile">
      <div>
        <div class="login_list_container" *ngIf="isLoading">
          <div>
            <ameren-loading
              id="loadingIndicator"
              class="d-flex mt-30 justify-content-center"
            ></ameren-loading>
          </div>
        </div>
        <div
          class="login_list_container"
          *ngIf="amerenUsers && amerenUsers.length === 0"
        >
          <div *ngIf="!isAPIFailure && !isLoading">
            <div id="noRecordsFound" class="no__ameren__users__found">
              There are no users in Revoke/Pending/Support/Admin in the Ameren
              Login.
            </div>
          </div>
        </div>
        <div class="login_list_container" *ngIf="isAPIFailure && !isLoading">
          <div>
            <div
              id="noRecordsFound"
              class="no__ameren__users__found mt-3 alert alert-danger"
            >
              Sorry, Unable to retrieve the pending agency users information.
              Please try again later.
            </div>
          </div>
        </div>
        <div class="ameren_user_container">
          <div
            class="login_list_container"
            *ngFor="let user of amerenUsers; let i = index"
          >
            <div class="user_details">
              <div class="info">
                <span class="label">Name</span>
                <span class="info_data">{{ user.sn }} {{ user.givenName }}</span>
              </div>
              <div class="info">
                <span class="label">User ID/Email</span>
                <span class="info_data">{{ user.userName }}</span>
              </div>
              <div class="info">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    (click)="toggleCloseDetails(user)"
                    *ngIf="user.toggleStatus == true"
                    class="icon-svg"
                    viewBox="0 0 100 100"
                  >
                    <path
                      fill="#1b6cb5"
                      d="M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z"
                    /></svg
                ></span>
  
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    (click)="toggleOpenDetails(user)"
                    *ngIf="!user.toggleStatus"
                    class="icon-svg"
                    viewBox="0 0 100 100"
                  >
                    <path
                      fill="#1b6cb5"
                      d="m 24 30.75 l -12 -12 l 2.15 -2.15 L 24 26.5 l 9.85 -9.85 L 36 18.8 Z"
                    /></svg
                ></span>
              </div>
            </div>
            <div
              class="admin_actions admin_actions_active"
              *ngIf="user.toggleStatus == true"
            >
              <div class="action">
                <span class="title">Revoke</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapRevokeStatus"
                    (click)="submitAdminRequest($event, user)"
                    name="status{{ i }}"
                    aria-label="Select Revoke Option to Approve"
                    value="eapRevokeStatus"
                    [attr.checked]="
                      user.accountStatus === 'inactive' ? 'checked' : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">Pending</span>
                <span
                  class="d-inline d-flex flex-wrap text-break"
                  class="amerenuser_role__and__status"
                >
                  <input
                    type="radio"
                    id="eapPendingStatus"
                    (click)="submitAdminRequest($event, user)"
                    name="status{{ i }}"
                    aria-label="Select Pending Option to Submit"
                    value="eapPendingStatus"
                    [attr.checked]="
                      user.status === 'eappending' ? 'checked' : null
                    "
                    [disabled]="true"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">Support</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapAmerenSupport"
                    (click)="submitAdminRequest($event, user)"
                    name="status{{ i }}"
                    aria-label="Select Ameren Support Option to Approve"
                    value="eapAmerenSupport"
                    [attr.checked]="
                      user.role === 'eapamerensupport' &&
                      user.accountStatus === 'active'
                        ? 'checked'
                        : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
              <div class="action">
                <span class="title">Admin</span>
                <span class="d-inline d-flex flex-wrap text-break">
                  <input
                    type="radio"
                    id="eapAmerenAdmin"
                    (click)="submitAdminRequest($event, user)"
                    name="status{{ i }}"
                    aria-label="Select Ameren Admin Option to Approve"
                    value="eapAmerenAdmin"
                    [attr.checked]="
                      user.role === 'eapamerenadmin' &&
                      user.accountStatus === 'active'
                        ? 'checked'
                        : null
                    "
                    [disabled]="isAmerenSupport"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <div
    id="adminModalConfirmation"
    class="modal fade"
    aria-hidden="true"
    aria-labelledby="agencyReqModal"
    tabindex="-1"
  >
    <div class="modal-dialog text-center">
      <div class="modal-content">
        <div class="modal-header text-center">
          <div class="ameren__modal__success-title">Confirm Your Changes</div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <span>You have made changes to Ameren Logins.</span>
            <span>Do you want to save these changes?</span>
          </div>
          <div class="row mt-4">
            <div class="col-md-4"></div>
  
            <div class="col-md-2">
              <button
                class="btn btn-secondary create_campaign_button"
                id="confirmNo" data-bs-dismiss="modal"
                value="No"
              >
                No
              </button>
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-success create_campaign_button"
                id="confirmYes"
                value="Yes"
              >
                Yes
              </button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>