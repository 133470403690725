import {
  FormGroup,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function RegexValidator(
  regex: RegExp,
  error: ValidationErrors,
  inverseResult: boolean = false
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return {};
    }
    const valid = !inverseResult
      ? regex.test(control.value)
      : !regex.test(control.value);
    return valid ? {} : error;
  };
}
