import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AgencyService } from 'src/app/shared/services/agency.service';

@Component({
  selector: 'app-signout-redirect-callback',
  templateUrl: './signout-redirect-callback.component.html',
  styleUrls: ['./signout-redirect-callback.component.scss'],
})
export class SignoutRedirectCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private _clientSvc: ClientService,
    private agencyService: AgencyService
  ) {}

  ngOnInit() {
    this.authService.LogOutComplete().then(() => {
      this._clientSvc.setSelectedClient();
      this.agencyService.setSelectedAgency();
      this.router.navigate(['/']);
    });
  }
}
