<div class="selected_client_container">
    <div class="selected_client_profile_initials">
        <div class="selected_client_user_initials">{{userInitials}}</div>
        <div class="selected_client_name">{{selectedClient?.fullName}}</div>
        <div class="selected_client_details">
            <ul class="selected_client_details_item">
                <li class="selected_client_details_address_street">
                    {{selectedClient.address.streetAddress}}
                </li>
                <li class="selected_client_details_address">
                    {{selectedClient.address.city}} {{selectedClient.address.state}} {{selectedClient.address.zip}}
                </li>
                <li class="selected_client_details_account">
                    {{selectedClient.billAccountNumber | accountNumber}}
                </li>
            </ul>
        </div>
        <div></div>
        <button (click)="searchNewClient()" class="btn btn-success btn_select_new_client">Search New Client</button>
    </div>
</div>