<div class="clientaccounts__container">
  <div class="clientaccounts__title">Client Accounts</div>

  <div class="ms-auto float-end">
    <button class="btn btn-default" (click)="exportClientAccountsPDF()">
      <img src="assets/icon/pdficon.svg" alt="PDF Image" />
      PDF
    </button>
  </div>
  <br />
  <br />

  <div class="clientaccounts__list">
    <table
      id="clientAccounts"
      #clientAccounts
      aria-describedby="Client Accounts"
      class="clientaccounts align-middle bg-white"
    >
      <thead class="clientaccounts__header">
        <tr>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="clientaccounts"
              data-order="asc"
              data-name="billAccountNumber"
              >Account Number<em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span class="table-head">Address</span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="clientaccounts"
              data-order="asc"
              data-name="accountStatus"
              >Status<em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span class="table-head">Start Date</span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="clientaccounts"
              data-order="asc"
              data-name="accountBalance"
              >Account Balance<em class="fa-solid fa-sort"></em
            ></span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="6">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="isAPIFailure && !isLoading">
        <tr>
          <td colspan="6">
            <div class="mt-3 alert alert-danger">
              Sorry, Unable to retrieve the client account information. Please
              try again later.
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isAPIFailure && !isLoading">
        <tr *ngIf="clientaccounts && clientaccounts.length === 0">
          <td colspan="6">
            <div
              id="noRecordsFound"
              class="no__ameren__users__found text-center"
            >
              There are no client account records found!
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of clientaccounts; let i = index">
          <td>
            <div class="d-flex justify-content-center">
              <div class="ms-3">
                <p class="fw-normal mb-1">
                  <a
                    href="javascript:;"
                    (click)="navigateToClientQuickViewPage(item)"
                    >{{ item.billAccountNumber | accountNumber }}</a
                  >
                </p>
              </div>
            </div>
          </td>
          <td>
            <p class="fw-normal mb-1">
              {{ item.addressLine1 }} <br />
              {{ item.city }}
              {{ item.state }}
              {{ item.zip }}
            </p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.accountStatus }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.openDate }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.accountBalance | currency }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
