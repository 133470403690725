import { EnumRole } from 'src/app/shared/enums/role';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { ClientManagerService } from './../client/services/client-manager.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  AgencyPledges,
  AgencyPledgeStatusCode,
  pledgeObject,
} from './model/agencypledges.model';
import { AgencypledgesManagerService } from './services/agencypledges-manager.service';
import { Client } from 'src/app/shared/models/client';
import { Address } from 'src/app/shared/models/address';
import { ClientService } from 'src/app/shared/services/client.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { CampaignsManagerService } from '../campaigns/services/campaigns-manager.service';
import * as XLSX from 'xlsx';

declare var window: any;

@Component({
  selector: 'app-agencypledges',
  templateUrl: './agencypledges.component.html',
  styleUrls: ['./agencypledges.component.scss'],
})
export class AgencypledgesComponent implements OnInit {
  isLoading: boolean = false;
  isAPIFailure: boolean = false;
  isEFTPaymentLoading: boolean = false;
  isEFTPaymentAPIFailure: boolean = false;
  submitEFtPaymentClicked: boolean = false;
  agencyPledges: AgencyPledges[];
  agencyPledgesOrig: AgencyPledges[];
  agencyPledgeStatusCode = AgencyPledgeStatusCode;
  agencyPledgeStatusCodeKeys: string[] = [];
  searchString: string;
  agencyPledgeTypes: any[] = [];
  showCalendarFilter: boolean = false;
  agencyPledgeList: any;
  selectedAgency: any;
  selectedPledges: pledgeObject[] = [];
  clientPledgeParameters: any = {};
  MissouriPledge: boolean;
  payEFTModal: any;
  clientSelectorModel: any;
  checkAllBoxes: boolean = false;
  selectedPledgesEFT: AgencyPledges[] = [];
  eftPledgeAmount: number = 0;
  agencyCampaignList: any[] = [];
  isClientSelectorLoading: boolean;
  clientSelectorError: boolean;
  selectedPledgeType: any;
  isAgencyAdmin: boolean = false;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  clockImage: any;
  pledgeTypeCode: string = '';
  pledgeStatusCode: string = '';
  agencyPledgeListFilter: any;
  isFiltered: boolean = false;
  pledgeStatusSelectedItemIndex: Number;
  pledgeTypeSelectedItemIndex: Number
  filterByThirtyPastDays: boolean = false;
  filterBySixtyPastDays: boolean = false;
  filterByNinetyPastDays: boolean = false;
  filterByYear: boolean = false;

  constructor(
    private agencyPledgesManager: AgencypledgesManagerService,
    private _clientSvc: ClientService,
    private _router: Router,
    private agencyService: AgencyService,
    private _utility: UtilitiesService,
    private campaignManager: CampaignsManagerService,
    private _clientManagerService: ClientManagerService,
    private route: ActivatedRoute,
    private _profileService: ProfileManagerService
  ) {
    this.agencyService.agencyUpdatedSubject.subscribe(() => {
      this.selectedAgency = this.agencyService.getSelectedAgency();
    });
  }

  async ngOnInit() {
    this.payEFTModal = new window.bootstrap.Modal(
      document.getElementById('payeftModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.clientSelectorModel = new window.bootstrap.Modal(
      document.getElementById('clientSelectorModel'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.selectedPledgeType = params.get('pledge-type');

      if (this.selectedPledgeType) {
        this.selectedPledgeType = this.selectedPledgeType
          .toString()
          .padStart(9, '0');
      }
    });

    this.getAgencySummary();
    this.selectedAgency = this.agencyService.getSelectedAgency();

    this.agencyPledgeStatusCodeKeys = Object.keys(this.agencyPledgeStatusCode);
    await this.getAgencyPledges();
    await this.checkIfAgencyAdmin();
    this.getCampaignByAgency();
  }
  ngAfterViewInit() { }

  checkIfAgencyAdmin() {
    if (this.selectedAgency) {
      this.isAgencyAdmin =
        this._profileService.profile[0].agencyList?.filter(
          (x) =>
            x.AgencyID.toString() == this.selectedAgency.agencyID.toString()
        )[0]?.role == EnumRole.EAPAGENCYADMIN;
    }
  }
  getAgencyPledges() {
    if (this.agencyPledgeList == undefined || !this.isFiltered) {
      let toDate = new Date();
      let toDateString =
        toDate.getFullYear() +
        '-' +
        String(toDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(toDate.getDate()).padStart(2, '0');

      let fromDate = new Date();
      fromDate.setDate(toDate.getDate() - 30);
      let fromDateString =
        fromDate.getFullYear() +
        '-' +
        String(fromDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(fromDate.getDate()).padStart(2, '0');
      this.agencyPledgeList = {
        agencycsscode: this.selectedAgency.agencyCSSCode || '',
        pledgestatuscode: '',
        pledgetypecode: '',
        pledgestartdate: fromDateString,
        pledgeenddate: toDateString,
      };
    }

    this.isLoading = true;
    return this.agencyPledgesManager
      .getAgencyPledgeList(this.agencyPledgeList)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          this.agencyPledges = [];
          this.agencyPledgesOrig = [];
          this.agencyPledges = res;
          if (this.isFiltered && this.pledgeTypeCode != '') {
            this.agencyPledges = this.agencyPledges.filter(
              (data) => data.pledgeTypeCode === this.pledgeTypeCode
            );
          }
          if (this.isFiltered && this.pledgeStatusCode != '' && this.pledgeStatusCode != 'All') {
            this.agencyPledges = this.agencyPledges.filter(
              (data) => data.pledgeStatusDecode === this.pledgeStatusCode
            );
          }
          this.agencyPledgesOrig = res;
          this.getCampaignId(this.agencyPledges);
          this.getAgencyPledgeTypes();
          if (this.selectedPledgeType) {
            this.filterByPledgeType(this.selectedPledgeType, 0);
          }
        },
        error: (error: any) => {
          console.log('err', error);
          this.isAPIFailure = true;
          this.isLoading = false;
        },
      });
  }

  searchFilter() {
    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.agencyPledges = this.agencyPledgesOrig.filter(
        (data) =>
          data.accountNumber.toString().match(adjustedSearch) ||
          data.clientCompleteName.toLocaleLowerCase().match(adjustedSearch) ||
          data.clientAddressLine1.toLocaleLowerCase().match(adjustedSearch) ||
          data.pledgeDate.toLocaleLowerCase().match(adjustedSearch) ||
          data.requesterName.toLocaleLowerCase().match(adjustedSearch) ||
          data.pledgeTypeCode.toLocaleLowerCase().match(adjustedSearch) ||
          data.pledgeAmount.toString().match(adjustedSearch) ||
          data.pledgeStatusCode.toLocaleLowerCase().match(adjustedSearch) ||
          data.pledgeStartDate.toLocaleLowerCase().match(adjustedSearch) ||
          data.pledgeEndDate.toLocaleLowerCase().match(adjustedSearch) ||
          data.campaignID.toLocaleLowerCase().match(adjustedSearch)
      );
    } else {
      this.agencyPledges = this.agencyPledgesOrig;
    }
  }

  navigateToClientQuickViewPage(agencyPledgeRecordId: any) {
    let agencyPledges: AgencyPledges[] = this.agencyPledges.filter(
      (x: any) => x.agencyPledgeRecordId === agencyPledgeRecordId
    );

    if (agencyPledges && agencyPledges.length === 1) {
      let agencyPledge: AgencyPledges = agencyPledges[0];

      this.getRelatedAccountsByBillAccount(agencyPledge.accountNumber);
    }
  }

  getRelatedAccountsByBillAccount(billAccount: string) {
    this.clientSelectorModel.show();
    this.isClientSelectorLoading = true;
    this.clientSelectorError = false;
    this._clientManagerService
      .getRelatedAccountsByBillAccount(billAccount)
      .subscribe({
        next: (resp) => {
          this.clientSelectorModel.hide();
          this.isClientSelectorLoading = false;
          this.clientSelectorError = false;
          this.handleGetRelatedAccountsApiSuccess(resp, billAccount);
        },
        error: (error) => {
          this.isClientSelectorLoading = false;
          this.clientSelectorError = true;
        },
      });
  }

  private handleGetRelatedAccountsApiSuccess(
    res: IGlobalAPIResponse,
    billAccount: string
  ) {
    this.isLoading = false;
    let clientResp: any = res.data.filter(
      (x: any) => x.billAccountNumber == billAccount
    )[0];
    let client = new Client();
    client.address = new Address();
    client.billAccountNumber = clientResp.billAccountNumber;
    client.firstName = clientResp.clientAccountInfo.firstName;
    client.lastName = clientResp.clientAccountInfo.lastName;
    client.fullName = clientResp.clientAccountInfo.fullName;
    client.address.city = clientResp.clientAccountInfo.address.city;
    client.address.state = clientResp.clientAccountInfo.address.state;
    client.address.zip = clientResp.clientAccountInfo.address.zip;
    client.address.streetAddress =
      clientResp.clientAccountInfo.address.addressLines[0];
    this._clientSvc.setSelectedClient(client);
    this._router.navigateByUrl('/client/client-quick-view');
  }

  dateRangeFilter(event: any, isFromDate: boolean, isMobile: boolean) {
    if (event.target.value) {
      let todayDate = new Date();
      let minDate = new Date();
      minDate.setDate(todayDate.getDate() - 365); // can search upto past 1 year

      let selectedDate = new Date(event.target.value.split('-').join('/'));
      if (selectedDate >= minDate && selectedDate <= todayDate) {
        let otherDt: any = '';
        if (isMobile) {
          otherDt = isFromDate
            ? $('#pledgeToDateMobile').val()
            : $('#pledgeFromDateMobile').val();
        } else {
          otherDt = isFromDate
            ? $('#pledgeToDate').val()
            : $('#pledgeFromDate').val();
        }
        if (otherDt) {
          let otherDate = new Date(otherDt.split('-').join('/'));
          let isValidDate: boolean = isFromDate
            ? selectedDate >= minDate && selectedDate < otherDate
            : selectedDate > otherDate && selectedDate <= todayDate;

          if (isValidDate) {
            let selectedDateString =
              selectedDate.getFullYear() +
              '-' +
              String(selectedDate.getMonth() + 1).padStart(2, '0') +
              '-' +
              String(selectedDate.getDate()).padStart(2, '0');

            let otherDateString =
              otherDate.getFullYear() +
              '-' +
              String(otherDate.getMonth() + 1).padStart(2, '0') +
              '-' +
              String(otherDate.getDate()).padStart(2, '0');

            if (isMobile) {
              $('.dropdown-mobile').removeClass('show');
              $('#btnCalendarFilterMobile').attr('aria-expanded', 'false');
              $('#btnCalendarFilterMobile').removeClass('show');
            } else {
              $('.dropdown-desktop').removeClass('show');
              $('#btnCalendarFilter').attr('aria-expanded', 'false');
              $('#btnCalendarFilter').removeClass('show');
            }

            this.agencyPledgeList = {
              agencycsscode: this.selectedAgency.agencyCSSCode || '',
              pledgestatuscode: '',
              pledgetypecode: '',
              pledgestartdate:
                selectedDate < otherDate ? selectedDateString : otherDateString,
              pledgeenddate:
                selectedDate < otherDate ? otherDateString : selectedDateString,
            };
          }
        }
      }
      this.showFilterCriteriaByDateRange();
    }
  }

  filterByPastDays(pastDays: number) {
    if (pastDays == 30) {
      this.filterByThirtyPastDays = true;
      this.filterBySixtyPastDays = false;
      this.filterByNinetyPastDays = false;
      this.filterByYear = false;
    }
    else if (pastDays == 60) {
      this.filterBySixtyPastDays = true;
      this.filterByThirtyPastDays = false;
      this.filterByNinetyPastDays = false;
      this.filterByYear = false;
    }
    else if (pastDays == 90) {
      this.filterByNinetyPastDays = true;
      this.filterBySixtyPastDays = false;
      this.filterByThirtyPastDays = false;
      this.filterByYear = false;
    }
    else {
      this.filterByYear = true;
      this.filterByNinetyPastDays = false;
      this.filterBySixtyPastDays = false;
      this.filterByThirtyPastDays = false;
    }

    let toDate = new Date();
    let toDateString =
      toDate.getFullYear() +
      '-' +
      String(toDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(toDate.getDate()).padStart(2, '0');

    let fromDate = new Date();
    fromDate.setDate(toDate.getDate() - pastDays);
    let fromDateString =
      fromDate.getFullYear() +
      '-' +
      String(fromDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(fromDate.getDate()).padStart(2, '0');

    this.agencyPledgeList = {
      agencycsscode: this.selectedAgency.agencyCSSCode || '',
      pledgestatuscode: '',
      pledgetypecode: '',
      pledgestartdate: fromDateString,
      pledgeenddate: toDateString,
    };
    this.showFilterCriteriaByPastDays(pastDays);
  }

  filterByPledgeType(selectedValue: string, index: number) {
    this.pledgeTypeCode = selectedValue;
    this.pledgeTypeSelectedItemIndex = index;
    $('#lblSelectedCriteria').show();
    $('#lblPeldgeType').text(this.agencyPledgeTypes.find(x => x.pledgeTypeCode === selectedValue).pledgeTypeDecode.toString()).show();
  }

  filterByPledgeStatus(selectedValue: string, index: number) {
    this.pledgeStatusCode = selectedValue;
    this.pledgeStatusSelectedItemIndex = index;
    $('#lblSelectedCriteria').show();
    $('#lblPeldgeStatus').text(selectedValue).show();
  }

  getAgencyPledgeTypes() {
    for (let i = 0; i < this.agencyPledges.length; i++) {
      let agencyPledge = this.agencyPledges[i];
      if (
        this.agencyPledgeTypes &&
        this.agencyPledgeTypes.filter(
          (x: any) => x.pledgeTypeCode === agencyPledge.pledgeTypeCode
        ).length == 0
      ) {
        let agencyPledgeType = {
          pledgeTypeCode: agencyPledge.pledgeTypeCode,
          pledgeTypeDecode: agencyPledge.pledgeTypeDecode,
        };

        this.agencyPledgeTypes.push(agencyPledgeType);
      }
    }
  }

  payViaEFT() {
    this.eftPledgeAmount = 0;
    if (this.selectedPledgesEFT && this.selectedPledgesEFT.length > 0) {
      let pledgeAmount: number = 0;
      this.selectedPledgesEFT.forEach((agencyPledge) => {
        pledgeAmount += agencyPledge.pledgeAmount;
      });
      this.eftPledgeAmount = pledgeAmount;
      this.payEFTModal.show();
    }
  }

  checkAll() {
    this.selectedPledgesEFT = [];
    if (this.checkAllBoxes === false) {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;

        if (element.nativeElement.getAttribute('data-agencypledgerecordid')) {
          let agencyPledgeRecordId: number = parseInt(
            element.nativeElement.getAttribute('data-agencypledgerecordid')
          );
          let agencyPledges: AgencyPledges[] = this.agencyPledges.filter(
            (x: any) => x.agencyPledgeRecordId === agencyPledgeRecordId
          );

          if (agencyPledges && agencyPledges.length === 1 && this.selectedPledgesEFT.findIndex(x=>x.agencyPledgeRecordId === agencyPledges[0].agencyPledgeRecordId)<0) {
            this.selectedPledgesEFT.push(agencyPledges[0]);
          }
        }
      });
      this.checkAllBoxes = true;
    } else {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.checkAllBoxes = false;
    }
  }

  selectAccountNumber(event: any) {
    let agencyPledgeRecordId: number = parseInt(
      event.currentTarget.getAttribute('data-agencypledgerecordid')
    );
    if (event.currentTarget.checked) {
      let agencyPledges: AgencyPledges[] = this.agencyPledgesOrig.filter(
        (x: any) => x.agencyPledgeRecordId === agencyPledgeRecordId
      );

      if (agencyPledges && agencyPledges.length === 1) {
        this.selectedPledgesEFT.push(agencyPledges[0]);
      }
    } else {
      this.selectedPledgesEFT = this.selectedPledgesEFT.filter(
        (x) => x.agencyPledgeRecordId !== agencyPledgeRecordId
      );
    }
  }

  submitEFTPayment() {
    this.submitEFtPaymentClicked = true;
    let pledgeAmountObject: any[] = [];
    this.selectedPledgesEFT.forEach((agencyPledge) => {
      let pledgeAmtobj: any = {
        agencyCode: agencyPledge.agencyCSSCode,
        accountNumber: agencyPledge.accountNumber,
        sequenceNumber: agencyPledge.sequenceID,
        totalPledgeAmount: agencyPledge.pledgeAmount,
      };
      let dupIndex: Number = pledgeAmountObject.findIndex(x=>x.accountNumber == pledgeAmtobj.accountNumber && x.sequenceNumber == pledgeAmtobj.sequenceNumber && x.totalPledgeAmount == pledgeAmtobj.totalPledgeAmount)
      if(dupIndex==-1)
      pledgeAmountObject.push(pledgeAmtobj);
    });

    if (pledgeAmountObject.length > 0) {
      this.isEFTPaymentLoading = true;
      const markEFTPledgesBody = {
        pledges: pledgeAmountObject,
      };
      this.agencyPledgesManager.submitEFTPayment(markEFTPledgesBody).subscribe({
        next: (res: any) => {
          this.isEFTPaymentAPIFailure = false;
          this.isEFTPaymentLoading = false;
          this.selectedPledgesEFT = [];
          this.getAgencyPledges();
        },
        error: (error: any) => {
          this.isEFTPaymentAPIFailure = true;
          this.isEFTPaymentLoading = false;
          this.selectedPledgesEFT = [];
          console.log('error', error);
        },
      });
    }
  }

  closeEFTmodal() {
    if (this.submitEFtPaymentClicked) {
      this.submitEFtPaymentClicked = false;
      this.getAgencyPledges();
    }
  }

  getAgencySummary() {
    this.isLoading = true;
    this.agencyPledgesManager.getUserSummary().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        if (res && res.length == 1) {
          const agencyDetails = {
            selectedAgencyName: res[0].agencyName,
            pinNumber: res[0].pinNumber,
            agencyID: res[0].agencyID,
            agencyCSSCode: res[0].agencyCSSCode,
            selectedAgencyAddress:
              res[0].agencyStreetName +
              ' ' +
              res[0].agencyCity +
              ' ' +
              res[0].agencyState +
              ' ' +
              res[0].agencyZip,
            isEFTAllowed: res[0].isEFTAllowed,
          };
          this.agencyService.setSelectedAgency(agencyDetails);
          this.selectedAgency = this.agencyService.getSelectedAgency();
          this.isAgencyAdmin =
            this._profileService.profile[0].agencyList?.filter(
              (x) =>
                x.AgencyID.toString() == this.selectedAgency.agencyID.toString()
            )[0]?.role == EnumRole.EAPAGENCYADMIN;
          this.getAgencyPledges();
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        console.log('error', error);
      },
    });
  }

  exportAllPledges() {
    this.selectedPledges = [];
    for (let i = 0; i < this.agencyPledges.length; i++) {
      var client =
        this.agencyPledges[i].clientCompleteName +
        ',' +
        ' ' +
        this.agencyPledges[i].clientAddressLine1 +
        ',' +
        ' ' +
        this.agencyPledges[i].clientCity +
        ' ' +
        this.agencyPledges[i].clientState +
        ' ' +
        this.agencyPledges[i].clientPostalCode;

      var pledgesObject = {
        index: i,
        accountNumber: this._utility.transformAccountNumber(
          this.agencyPledges[i].accountNumber
        ),
        client: client,
        clientName: this.agencyPledges[i].clientCompleteName,
        clientPremiseAddress: this.agencyPledges[i].clientPremiseAddress,
        pledgeDate: this._utility.transformDate(
          this.agencyPledges[i].pledgeDate
        ),
        isNearExpiration: this.agencyPledges[i].isNearExpiration,
        clockImage: this.agencyPledges[i].clockImage,
        requesterName: this.agencyPledges[i].requesterName,
        pledgeTypeDecode: this.agencyPledges[i].pledgeTypeDecode,
        pledgeAmount: this.agencyPledges[i].pledgeAmount,
        pledgeStatusDecode: this.agencyPledges[i].pledgeStatusDecode,
        pledgeStartDate: this.agencyPledges[i].pledgeStartDate,
        pledgeEndDate:
          this.agencyPledges[i].pledgeStatusDecode === 'Satisfied'
            ? this.agencyPledges[i].pledgeEndDate
            : '',
        campaignID: this.agencyPledges[i].campaignID,
        campaignName: this.agencyPledges[i].campaignName,
      };
      this.selectedPledges.push(pledgesObject);
    }
    return;
  }

  exportToExcelClientPledges() {

    this.exportAllPledges();

    for (let i = 0; i < this.selectedPledges.length; i++) {
      if (this.selectedPledges[i].clockImage == 1) {
        this.selectedPledges[i].clockImage = '⏰'
      }
    }


    let workbook: XLSX.WorkBook = this._utility.exportJsonToExcelBook(
      this.selectedPledges,
      'Agency Pledges Report',
      false,
      [
        'accountNumber',
        'clientName',
        'clientPremiseAddress',
        'clockImage',
        'pledgeDate',
        'requesterName',
        'pledgeTypeDecode',
        'pledgeAmount',
        'pledgeStatusDecode',
        'pledgeEndDate',
        'campaignID',
        'campaignName',
      ],
      [
        'Account Number',
        'Client Name',
        'Client Address',
        '   ',
        'Pledge Date',
        'Requested By',
        'Pledge Type',
        'Pledge Amount',
        'Status',
        'Date Satisfied',
        'Campaign ID',
        'campaign Name',
      ]
    );


    let worksheet: XLSX.WorkSheet;
    worksheet = workbook.Sheets['data'];
    this._utility.formatColumn(worksheet, 7, '$0.00', 'n');

    XLSX.writeFile(workbook, `Agency Pledges Report.xlsx`);

  }

  exportToPdfClientPledges() {
    let clockImg: any = this._utility.getBase64Image(
      document.getElementById('clock')
    );
    var clientState = this.agencyPledges[0].clientState;
    this.exportAllPledges();
    if (clientState === 'MO') {
      this.MissouriPledge = true;
    } else {
      this.MissouriPledge = false;
    }
    this.selectedPledges.forEach(
      (x) => (x.pledgeAmount = this._utility.transformCurrency(x.pledgeAmount))
    );


    this._utility.exportJsonToPdfFile(
      this.selectedPledges,
      'Agency Pledges Report',
      [
        'accountNumber',
        'clientName',
        'clientPremiseAddress',
        'clockImage',
        'pledgeDate',
        'requesterName',
        'pledgeTypeDecode',
        'pledgeAmount',
        'pledgeStatusDecode',
        'pledgeEndDate',
        'campaignID',
        'campaignName',
      ],
      [
        'Account Number',
        'Client Name',
        'Client Address',
        ' ',
        'Pledge Date',
        'Requested By',
        'Pledge Type',
        'Pledge Amount',
        'Status',
        'Date Satisfied',
        'Campaign ID',
        'campaignName',
      ],
      'Agency Pledge Report',
      true,
      this.MissouriPledge,
      clockImg
    );

  }

  getCampaignByAgency() {
    this.campaignManager
      .getCampaignByAgency(this.selectedAgency.agencyCSSCode)
      .subscribe({
        next: (res: any) => {
          this.agencyCampaignList = res;
        },
        error: (error: any) => {
          console.log('error', error);
        },
      });
  }

  getCampaignId(agencyPledges: any[]) {
    agencyPledges.forEach((res1: any) => {
      this.agencyCampaignList.forEach((res2: any) => {
        if (parseInt(res1.pledgeTypeCode) === res2.campaignAgencyID) {
          res1.campaignID = res2.campaignID;
          res1.campaignName = res2.campaignName;
        }
      });
    });
  }

  applyFilters() {
    this.isFiltered = true;
    this.getAgencyPledges();
  }
  clearFilters() {
    this.isFiltered = false;
    this.pledgeStatusCode = '';
    this.pledgeTypeCode = '';
    this.filterByYear = false;
    this.filterByNinetyPastDays = false;
    this.filterBySixtyPastDays = false;
    this.filterByThirtyPastDays = false;
    this.pledgeTypeSelectedItemIndex = -1;
    this.pledgeStatusSelectedItemIndex = -1;
    this.clearFilterSearchCriteria();
    this.getAgencyPledges();
  }

  showFilterCriteriaByDateRange() {
    let _fromDate: any = '';
    _fromDate = $('#pledgeFromDate').val();
    let _fromDateFromatted = new Date(_fromDate.split('-').join('/'));
    let fromDate =
      String(_fromDateFromatted.getMonth() + 1).padStart(2, '0') + '/' +
      String(_fromDateFromatted.getDate()).padStart(2, '0') + '/' +
      _fromDateFromatted.getFullYear();

    let _toDate: any = '';
    _toDate = $('#pledgeToDate').val();
    let _toDateFromatted = new Date(_toDate.split('-').join('/'));
    let toDate =
      String(_toDateFromatted.getMonth() + 1).padStart(2, '0') + '/' +
      String(_toDateFromatted.getDate()).padStart(2, '0') + '/' +
      _toDateFromatted.getFullYear();
      if(!isNaN(Date.parse(_fromDate)) && !isNaN(Date.parse(_toDate))){
    $('#lblDate').text(fromDate + '  to  ' + toDate).show();
    $('#lblSelectedCriteria').show();
    $('#lblPastDays').text('').hide();
    $('#btnPastThirtyDays').removeClass('calendar-day-button-active');
    $('#btnPastSixtyDays').removeClass('calendar-day-button-active');
    $('#btnPastNinetyDays').removeClass('calendar-day-button-active');
    $('#btnPastYear').removeClass('calendar-day-button-active');
      }
  }

  showFilterCriteriaByPastDays(pastDays: number) {
    $('#pledgeFromDate').val('');
    $('#pledgeToDate').val('');
    $('#lblDate').text('').hide();
    if (pastDays != 365)
      $('#lblPastDays').text('Past ' + pastDays + ' days').show();
    else
      $('#lblPastDays').text('Past 12 months').show();
    $('#lblSelectedCriteria').show();
    if (pastDays == 30) {
      $('#btnPastThirtyDays').addClass('calendar-day-button-active');
    }
    else if (pastDays == 60) {
      $('#btnPastSixtyDays').addClass('calendar-day-button-active');
    }
    else if (pastDays == 90) {
      $('#btnPastNinetyDays').addClass('calendar-day-button-active');
    }
    else
      $('#btnPastYear').addClass('calendar-day-button-active');
  }

  clearFilterSearchCriteria() {
    $('#pledgeFromDate').val('');
    $('#pledgeToDate').val('');
    $('#lblDate').text('').hide();
    $('#lblPastDays').text('').hide();
    $('#lblPeldgeType').text('').hide();
    $('#lblPeldgeStatus').text('').hide();
    $('#lblSelectedCriteria').hide();
  }
}