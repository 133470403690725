import { PaymentAgreementOptionType, PaymentAgreementOptionPhase } from 'src/app/shared/enums/paymentAgreementOptionType';

export class PaymentAgreement {
    optionType?: any;
    optionPhase?: any;
    optionDescription?: any;
    requiredPaymentAmount?: any;
    paymentDueDate?: any;
    installmentAmount?: any;
    numberOfInstallments?: any;
    optionName?: any;
    
    constructor(optionType?: any, optionPhase?: any, optionDescription?: any, requiredPaymentAmount?: any, paymentDueDate?: any, installmentAmount?: any, numberOfInstallments?: any) {
        this.optionType = optionType;
        this.optionPhase = optionPhase;
        this.optionDescription = optionDescription;
        this.requiredPaymentAmount = requiredPaymentAmount;
        this.paymentDueDate = paymentDueDate;
        this.installmentAmount = installmentAmount;
        this.numberOfInstallments = numberOfInstallments;

        switch (this.optionType) {
            case PaymentAgreementOptionType.MA:
                this.optionName = "Minimum Payment Due";
                break;
            case PaymentAgreementOptionType.EX:
                this.optionName = "Short Term Extension";
                break;
            case PaymentAgreementOptionType.PG:
                this.optionName = "Payment Agreement";
                break;
            case PaymentAgreementOptionType.LI:
                this.optionName = "Low Income Payment Agreement";
                break;
            case PaymentAgreementOptionType.LA:
                this.optionName = "Low Income Amended Payment Agreement";
                break;
            case PaymentAgreementOptionType.WR:
                this.optionName = "Winter Reconnect Payment Agreement";
                break;
            case PaymentAgreementOptionType.CW:
                this.optionName = "Cold Weather Rule";
                break;
            case PaymentAgreementOptionType.FB:
                this.optionName = "Final Bill Arrangement";
                break;
            case PaymentAgreementOptionType.SC:
                this.optionName = "Special Circumstance Payment Agreement";
                break;
            case PaymentAgreementOptionType.NR:
                this.optionName = "Non Residential Payment Agreement";
                break;
            case PaymentAgreementOptionType.NR:
                this.optionName = "Non Residential Payment Agreement";
                break;
        }

        switch (this.optionPhase) {
            case PaymentAgreementOptionPhase.RE:
                this.optionName = "Reinstate " + this.optionName;
                break;
            case PaymentAgreementOptionPhase.RG:
            case PaymentAgreementOptionPhase.RD:
                this.optionName = "Renegotiate " + this.optionName;
                break;
        }
    }
}