import { NumberDirective } from './shared/directives/numbers-only.directive';
import { BillingAndPaymentOptionsComponent } from './public/billing-and-payment-options/billing-and-payment-options.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './public/header/header.component';
import { DashboardComponent } from './secure/dashboard/dashboard.component';
import { ManageprofileComponent } from './secure/manageprofile/manageprofile.component';
import { SigninRedirectCallbackComponent } from './public/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './public/signout-redirect-callback/signout-redirect-callback.component';
import { HomeComponent } from './public/home/home.component';
import { OneuiframeworkModule } from 'oneuiframework';
import { MenuComponent } from './public/menu/menu.component';
import { HeroComponent } from './public/hero/hero.component';
import { LoginComponent } from './public/login/login.component';
import { ContactusComponent } from './public/contactus/contactus.component';
import { RegistrationComponent } from './public/registration/registration.component';
import { RegisterNewAgencyComponent } from './public/register-new-agency/register-new-agency.component';
import { PhoneMaskDirective } from './shared/directives/phone-mask.diretive';
import { AgencyReviewComponent } from './secure/agency-review/agency-review.component';
import { CampaignListComponent } from './secure/campaigns/campaign-list/campaign-list.component';
import { AddCampaignComponent } from './secure/campaigns/add-campaign/add-campaign.component';
import { ClientListComponent } from './secure/client-list/client-list.component';
import { FaqComponent } from './public/faq/faq.component';
import { SavingTipsComponent } from './public/saving-tips/saving-tips.component';
import { StateSelectorComponent } from './public/state-selector/state-selector.component';
import { ProgramBannerComponent } from './public/program-banner/program-banner.component';
import { RegistrationSuccessComponent } from './public/registration/registration-success/registration-success.component';
import { AuthService } from './shared/services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CorrelationInterceptor } from './shared/interceptors/correlation-interceptor';
import { AgencyRegistrationSuccessComponent } from './public/register-new-agency/agency-registration-success/agency-registration-success.component';
import { EditProfileComponent } from './secure/manageprofile/edit-profile/edit-profile.component';
import { EditPasswordComponent } from './secure/manageprofile/edit-password/edit-password.component';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { ClientSelectorComponent } from './secure/client/client-selector/client-selector.component';
import { ClientComponent } from './secure/client/client.component';
import { ClientInfoComponent } from './secure/client/client-info/client-info.component';
import { ClientQuickViewComponent } from './secure/client-quick-view/client-quick-view.component';
import { BillPaymentHistoryComponent } from './secure/bill-payment-history/bill-payment-history.component';
import { PledgeNotificationComponent } from './secure/pledge-notification/pledge-notification.component';
import { ClientPledgeComponent } from './secure/client-pledge/client-pledge.component';
import { PledgeEntryComponent } from './secure/pledge-entry/pledge-entry.component';
import { DisconnectHistoryComponent } from './secure/disconnect-history/disconnect-history.component';
import { PendingagencyadminComponent } from './secure/administration/pendingagencyadmin/pendingagencyadmin.component';
import { AdministrationComponent } from './secure/administration/administration.component';
import { AgencyinformationComponent } from './secure/administration/agencyinformation/agencyinformation.component';
import { AmerenuserComponent } from './secure/administration/amerenuser/amerenuser.component';
import { AgencypledgesComponent } from './secure/agencypledges/agencypledges.component';
import { IneligibleUserComponent } from './secure/ineligible-user/ineligible-user.component';
import { AgencyuserComponent } from './secure/administration/agencyuser/agencyuser.component';
import { EmulationComponent } from './public/emulation/emulation.component';
import { DollarAmountPipe } from './shared/pipes/dollarAmount.pipe';
import { SortDirective } from './shared/directives/sort.directive';
import { BudgetBillingComponent } from './secure/client/budget-billing/budget-billing.component';
import { AddAdditionalAgencyComponent } from './secure/administration/add-additional-agency/add-additional-agency.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { RetryInterceptor } from './shared/interceptors/retry-interceptor';
import { SelectAgencyComponent } from './secure/select-agency/select-agency.component';
import { MessageBoardComponent } from './secure/administration/message-board/message-board.component';
import { CampaignDetailsComponent } from './secure/campaigns/campaign-details/campaign-details.component';
import { AccountNumberPipe } from './shared/pipes/accountNumber.pipe';
import { SearchFilterPipe } from './shared/pipes/search-filter';
import { CampaignActivityComponent } from './secure/campaigns/campaign-activity/campaign-activity.component';
import { ClientAccountsComponent } from './secure/client/client-accounts/client-accounts.component';
import { TrainingListComponent } from './secure/training/training-list/training-list.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { PendingAmerenuserComponent } from './secure/administration/pending-amerenuser/pending-amerenuser.component';
import { PendingAgencyUserComponent } from './secure/administration/pending-agency-user/pending-agency-user.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CreateMessageComponent } from './secure/administration/message-board/create-message/create-message.component';
import { EditMessageComponent } from './secure/administration/message-board/edit-message/edit-message.component';
import { SplunkInterceptor } from './shared/interceptors/splunk-interceptor';
import { SilentCallbackComponent } from './public/silent-callback/silent-callback.component';
import { SilentRenewComponent } from './public/silent-renew/silent-renew.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    ManageprofileComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    HomeComponent,
    MenuComponent,
    HeroComponent,
    LoginComponent,
    ContactusComponent,
    RegistrationComponent,
    RegisterNewAgencyComponent,
    PhoneMaskDirective,
    AgencyReviewComponent,
    CampaignListComponent,
    AddCampaignComponent,
    ClientListComponent,
    FaqComponent,
    SavingTipsComponent,
    StateSelectorComponent,
    ProgramBannerComponent,
    RegistrationSuccessComponent,
    BillingAndPaymentOptionsComponent,
    EditPasswordComponent,
    AgencyRegistrationSuccessComponent,
    EditProfileComponent,
    ClientSelectorComponent,
    ClientComponent,
    ClientInfoComponent,
    ClientQuickViewComponent,
    BillPaymentHistoryComponent,
    PledgeNotificationComponent,
    ClientPledgeComponent,
    PledgeEntryComponent,
    DisconnectHistoryComponent,
    PendingagencyadminComponent,
    AdministrationComponent,
    AmerenuserComponent,
    AgencyinformationComponent,
    AgencypledgesComponent,
    IneligibleUserComponent,
    AgencyuserComponent,
    EmulationComponent,
    DollarAmountPipe,
    SortDirective,
    BudgetBillingComponent,
    AddAdditionalAgencyComponent,
    NumberDirective,
    SelectAgencyComponent,
    MessageBoardComponent,
    CampaignDetailsComponent,
    AccountNumberPipe,
    SearchFilterPipe,
    CampaignActivityComponent,
    ClientAccountsComponent,
    TrainingListComponent,
    SafePipe,
    PendingAmerenuserComponent,
    PendingAgencyUserComponent,
    CreateMessageComponent,
    EditMessageComponent,
    AccountNumberPipe,
    SilentCallbackComponent,
    SilentRenewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OneuiframeworkModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module,
    AngularEditorModule,
  ],
  exports: [PhoneMaskDirective, AccountNumberPipe],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SplunkInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    AccountNumberPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
