<div class="pledgenotifiation__container">
    <h1 class="pledgenotification__title">Client Alerts</h1>
    <p>
        Send notification to Client when an Energy Assitance Pledge has been received.
    </p>

    <div class="text-center" *ngIf="contactsLoading">
        <ameren-loading></ameren-loading>
    </div>
    <div class="outline-box" *ngIf="(!contactList || !contactList.length ) && !contactsLoading">
        <h1 *ngIf="!getContactsApiFailure" class="pledgenotification__title">There are no contacts on this account.
            Please add at least one contact.</h1>
        <h1 *ngIf="getContactsApiFailure" class="pledgenotification__title">Failed to load contacts</h1>
    </div>
    <h1 *ngIf="!contactsLoading" class="pledgenotification__title">Phone List</h1>
    <div *ngIf="!contactsLoading" class="pledge_notification_add_phone_btn_container">
        <button class="btn btn-success" (click)="openPhoneModal()">Add Phone</button>
    </div>
    <div class="notification__list" [ngClass]="{'notification_list_no_data':!phoneContactList.length}" *ngIf="contactList">

        <table id="notification_phones" aria-describedby="Email contact list" class="notification align-middle bg-white"
            *ngIf="phoneContactList">
            <thead id="phone_notification_header" class="notification__header static-header">
                <tr>
                    <th class="column-name" id="phn-number-header">
                        <span class="table-head">
                            Phone Number
                        </span>

                    </th>
                    <th class="column-name" id="phn-status-header">
                        <span class="table-head">
                            Status
                        </span>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let phn of phoneContactList; let i = index">
                    <td class="phn-number-data">
                        {{phn.contactNumber}}
                    </td>
                    <td class="phn-toggle-switch-container">
                        <label class="switch">
                            <input type="checkbox" [attr.checked]="phn.enrolled" id="check-{{i}}">
                            <span class="slider round btnSwitch" (click)="onTogglePhoneAlert(phn)">
                                {{phn.enrolled? 'ON' : 'OFF'}}
                            </span>
                        </label>
                    </td>
                </tr>
                <tr *ngIf="!phoneContactList.length">
                    <td colspan="2" class="text-center">
                        There are no phone contacts
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
    <h1 *ngIf="!contactsLoading" class="pledgenotification__title email">Email List</h1>
    <div *ngIf="!contactsLoading" class="pledge_notification_add_email_btn_container">
        <button class="btn btn-success"  (click)="openEmailModal()">Add email</button>
    </div>
    <div class="email_notification_list" [ngClass]="{'notification_list_no_data':!emailContactList.length}"  *ngIf="contactList">

        <table aria-describedby="Email contact list" id="notification_emails"
            class="email_notification align-middle bg-white email_notification_table" *ngIf="emailContactList">
            <thead id="email_notification__header" class="notification__header static-header">
                <tr>
                    <th class="column-name" id="email-header">
                        <span class="table-head">
                            Email
                        </span>

                    </th>
                    <th class="column-name" id="email-status-header">
                        <span class="table-head">
                            Status
                        </span>

                    </th>
                </tr>
            </thead>
              <tbody *ngIf="isAPIFailure && !isLoading">
                <tr>
                  <td colspan="8">
                    <div class="mt-3 alert alert-danger">
                      Sorry, Unable to add Email. Please try again later.
                    </div>
                  </td>
                </tr>
              </tbody>
            <tbody>
                <tr *ngFor="let email of emailContactList; let i = index">
                    <td class="email-data">
                        {{email.email}}
                    </td>
                    <td class="email-toggle-switch-container" id="email_toggle">
                        <label class="switch">
                            <input type="checkbox" [attr.checked]="email.enrolled" id="check-{{i}}">
                            <span class="slider round btnSwitch" (click)="onToggleEmailAlert(email)">
                                {{email.enrolled? 'ON' : 'OFF'}}
                            </span>
                        </label>
                    </td>
                </tr>
                <tr *ngIf="!phoneContactList.length">
                    <td colspan="2" class="text-center">
                        There are no email contacts
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    
  <!-- confirmation modal -->
  <div
  id="addEmailModalConfirmation"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="agencyReqModal"
  tabindex="-1"
>
  <div class="modal-dialog text-center">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="ameren__modal__success-title">Add Email</div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click) = "closeEmailModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="!isLoading">
            <div
              class="ameren__modal__success-title"
              *ngIf="isSubmitted && !isLoading && !isAPIFailure"
            >
              Successfully Submitted!
            </div>
            <div
              class="ameren__modal__failure-title"
              *ngIf="!isLoading && (isAPIFailure)"
            >
              Submission Error!
            </div>
          </div>
          <div class="modal-body">
            <div *ngIf="isLoading" class="text-center">
              <ameren-loading
                id="loadingIndicator"
                class="d-flex p-10 justify-content-center"
              ></ameren-loading>
              <span>Please wait...</span>
            </div>
        <div *ngIf="!isSubmitted && !isLoading && !isAPIFailure" class="row mt-4">
          <div class="col-md-4"></div>
          <form [formGroup]="emailForm" class="needs-validation">
            <div class="col-md-6 m-auto">
                <div class="form-group">
                    <label class="form-label required" for="emailAddressId">
                        Enter your Email Address
                    </label>
                    <input formControlName="emailAddress" placeholder="name@example.com" 
                    id="emailAddressId" type="text" class="form-control" aria-label="Enter your email address" required>
                    <div class="text-field-error mt-3 ameren-font-sm"
                    *ngIf="emailForm?.get('emailAddress')?.dirty && emailForm?.get('emailAddress')?.invalid">
                        <p *ngIf="emailForm?.get('emailAddress')?.errors?.['required']">
                            Email Address is required.
                        </p>
                        <p *ngIf="emailForm?.get('emailAddress')?.errors?.['email']">
                            Invalid Email Address.
                        </p>
                        <p *ngIf="emailForm?.get('emailAddress')?.errors?.['validateEmail']">
                            Email Already Exists
                        </p>
                    </div>
                </div>
                <div class="m-3">
                    <button [disabled]="!emailForm.valid" (click)="addEmail()" class="btn btn-success" type="submit">Submit</button>
                </div>
            </div>
        </form>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>


<div
  id="updateNotificationLoadingModel"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="updateNotificationLoadingModel"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content"> 
        <div class="modal-header" *ngIf="!iupdateLoading">
            <div
              class="ameren__modal__success-title text-success"
              *ngIf="!iupdateLoading && !isUpdateFailure"
            >
              Successfully Updated!
            </div>
            <div
              class="ameren__modal__failure-title text-danger"
              *ngIf="!iupdateLoading && isUpdateFailure"
            >
              Update Error!
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>    
      <div class="modal-body">
        <div class="text-center" *ngIf="iupdateLoading">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image text-center">
            <img
              *ngIf="!isUpdateFailure && !iupdateLoading && isUpdateSuccess"
              src="assets/images/success.svg"
              alt="Success Image"
            />
            <img
              *ngIf="isUpdateFailure && !iupdateLoading"
              src="assets/images/failure.svg"
              alt="Failure Image"
            />
          </div>
      </div>
    </div>
  </div>
</div>


<div
id="addPhoneModalConfirmation"
class="modal fade"
aria-hidden="true"
aria-labelledby="addPhoneModalConfirmation"
tabindex="-1"
>
<div class="modal-dialog text-center">
  <div class="modal-content">
    <div class="modal-header">
        <div *ngIf="!isSubmitted">
Add Phone
        </div>
        <div
          class="ameren__modal__success-title text-success"
          *ngIf="isSubmitted && !isLoading && !isAPIFailure"
        >
          Successfully added!
        </div>
        <div
          class="ameren__modal__failure-title text-danger"
          *ngIf="isSubmitted && !isLoading && isAPIFailure"
        >
          Add new phone Error!
        </div>
        <button *ngIf="!isLoading"
          type="button"
          class="btn-close" (click)="closePhoneModal()"
        ></button>
      </div> 

        <div class="modal-body">
          <div *ngIf="isLoading" class="text-center">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
      <div *ngIf="!isSubmitted && !isLoading && !isAPIFailure" class="row mt-4">
        <div class="col-md-4"></div>
        <form [formGroup]="phoneForm" class="needs-validation">
          <div class="col-md-6 m-auto">
              <div class="form-group">
                  <label class="form-label required" for="phoneId">
                      Enter Phone number
                  </label>
                  <input amerenInputMask="999.999.9999" formControlName="phone"
                   type="text" class="form-control">
                  <div class="text-field-error mt-3 ameren-font-sm"
                  *ngIf="phoneForm?.get('phone')?.dirty && phoneForm?.get('phone')?.invalid">
                      <p *ngIf="phoneForm?.get('emailAddress')?.errors?.['required']">
                          Phone is required.
                      </p>
                      <p *ngIf="phoneForm?.get('phone')?.errors?.['pattern']">
                          Invalid Phone.
                      </p>
                      <p *ngIf="phoneForm?.get('phone')?.errors?.['validatePhone']">
                          Phone Already Exists
                      </p>
                  </div>
              </div>
              <div class="m-3">
                  <button [disabled]="!phoneForm.valid" (click)="addPhone()" class="btn btn-success" type="submit">Submit</button>
              </div>
          </div>
      </form>
        <div class="col-md-4"></div>
      </div>
      <div class="ameren__modal-image text-center" *ngIf="isSubmitted">
        <img
          *ngIf="isSubmitted && !isLoading && !isAPIFailure"
          src="assets/images/success.svg"
          alt="Success Image"
        />
        <img
          *ngIf="isSubmitted && !isLoading && isAPIFailure"
          src="assets/images/failure.svg"
          alt="Failure Image"
        />
      </div>
    
  </div>
</div>
</div>

</div>