import { EnumRole } from 'src/app/shared/enums/role';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileManagerService } from './../../../shared/services/profilemanager.service';
import { TrainingManagerService } from './../services/training-manager.service';
import * as FileSaver from 'file-saver';
import { List } from 'linqts';

declare var window: any;
@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
})
export class TrainingListComponent implements OnInit {
  @ViewChild('selectFile') selectFile: any;
  mymodel: any;
  mymodeloption: any;
  editmodaloption: any;
  trainingMaterialForm!: FormGroup;
  title: string = '';
  description: string = '';
  link: string = '';
  uploadedMaterial: any;
  fileUpload: any;
  xmlDocument: any;
  fileName: string;
  fileType: string;
  isUploaded: boolean = false;
  error = '';
  currentUser: any;
  isApprovalLoading: boolean = false;
  isApprovalAPIFailure: boolean = false;
  isApiMessageResponse: boolean = false;
  isDocument: boolean = false;
  isTitleInvalid = false;
  isDescInvalid = false;
  isTitleLimitInvalid = false;
  isDescLimitInvalid = false;
  trainingList: any[] = [];
  trainingListDto: any[] = [];
  isAdmin: boolean;
  isTrainingListLoading: boolean;
  trainingListApiFailure: boolean;
  isTrainingDownloading: boolean;
  isDocDetailLoading: boolean;
  getDocByIdApiFailure: boolean;
  getDocDetailsByIdApiFailure: boolean;
  dpcLoadingModal:any;
  docDetailsLoadingModal: any;
  deleteDocApiFailure: boolean;
  deleteConfrmModal: any;
  selectedTrainingDocId: number;
  isDeleteLoading: boolean;
  deleteDocApiSuccess: boolean;
  isDeleteApiMessageResponse: boolean;
  documentId: number = 0;
  documentResourceString: string = '';
  page = 1;
  Math: any;
  rowsPerPage = 10;
  totalCount = 0;

  nameLimit = 50;
  descriptionLimit = 500

  constructor(
    private _traningMgrService: TrainingManagerService,
    private _profilceMgrService: ProfileManagerService,
    private _cdr: ChangeDetectorRef
  ) {
    
    this.Math = Math
    this.trainingMaterialForm = new FormGroup({
      title: new FormControl(this.title, [
        Validators.required,
        Validators.maxLength(this.nameLimit),
      ]),
      description: new FormControl(this.description, [
        Validators.required,
        Validators.maxLength(this.descriptionLimit),
      ]),
      link: new FormControl(this.link),
    });
  }

  ngOnInit(): void {
    if (
      this._profilceMgrService.profile[0].agencyList?.some(
        (a) =>
          a.role == EnumRole.EAPAMERENADMIN ||
          a.role == EnumRole.EAPAMERENSUPPORT
      )
    ) {
      this.isAdmin = true;
    }
    this.mymodel = new window.bootstrap.Modal(
      document.getElementById('addtraingmaterial'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.dpcLoadingModal = new window.bootstrap.Modal(
      document.getElementById('dpcLoadingModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.docDetailsLoadingModal = new window.bootstrap.Modal(
      document.getElementById('docDetailsLoadingModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.deleteConfrmModal = new window.bootstrap.Modal(
      document.getElementById('deleteModalConfirmation'),
      {
        backdrop: true,
        keyboard: true,
      }
    );


    this.getTrainingList();
      this.mymodeloption = new window.bootstrap.Modal(
        document.getElementById('addtraingmaterialoption'),
        {
          backdrop: true,
          keyboard: true,
        })

    this.editmodaloption = new window.bootstrap.Modal(
      document.getElementById('edittraingmaterialoption'),
      {
        backdrop: true,
        keyboard: true,
      })
  
      this.getTrainingList()
  }

  getTrainingList() {
    this.isTrainingListLoading = true;
    this._traningMgrService
      .getTrainingList(this._profilceMgrService.profile[0]?.stateProvince || '')
      .subscribe({
        next: (data) => {
          this.isTrainingListLoading = false;
          this.trainingListDto = data;
          this.totalCount = this.trainingListDto.length
          this.loadTraining()
        },
        error: (err) => {
          this.isTrainingListLoading = false;
          this.trainingListApiFailure = true;
        },
      });
  }

  loadTraining(){
    let startIndex: number = (this.page * this.rowsPerPage) - this.rowsPerPage;
    this.trainingList = new List<any>(this.trainingListDto).Skip(startIndex).Take(this.rowsPerPage).ToArray()
    this._cdr.detectChanges()
  }

  loadPerPage(pageNumber: number, pageSize: number){
    this.page = pageNumber
    this.rowsPerPage = pageSize
    this.getTrainingList()
  }

  previousPage(){
    if(this.page > 1){
      this.page = this.page - 1;
    }

    this.getTrainingList();
  }

  nextPage(){
    this.page = this.page + 1;
    this.getTrainingList();
  }
 

  openModal() {
    this.isApprovalAPIFailure = false;
    this.isApiMessageResponse = false;
    this.mymodeloption.show();
  }

  selectOption(action: boolean) {
    this.isDocument = action;
    this.mymodeloption.hide();
    this.mymodel.show();
  }

  add() {
    this.mymodel.show();
    this.isApiMessageResponse = false;
    this.isApprovalAPIFailure = false;
  }

  close() {
    this.trainingMaterialForm.reset();
    this.remove();
    this.getTrainingList();
  }

  remove() {
    this.isUploaded = false;
    this.fileName = '';
    this.fileType = '';
    this.fileUpload = {};
    this.xmlDocument = {};
  }

  uploadFile(event: any) {
    const files = event.target.files;
    this.fileName = files[0].name;
    this.fileType = files[0].type;

    if (
      this.fileType.match('doc.*') ||
      this.fileType.match('docx.*') ||
      this.fileType.match('pdf.*') ||
      this.fileType.match('text.*') ||
      this.fileType.match('txt.*')
    ) {
      this.error = '';
      this.isUploaded = true;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (e) => {
        let fileContent: string = fileReader.result?.toString() || '';
        this.fileUpload = fileContent.substring(
          fileContent.indexOf('base64,') + 7
        );
      };
    } else {
      this.error = 'File format not allowed';
    }
  }

  update(){
    const  documentDescription =  this.trainingMaterialForm.controls['description'].value
    const title =  this.trainingMaterialForm.controls['title'].value
    if(!title){ this.isTitleInvalid = true 
      return
    }
    if(!documentDescription){ this.isDescInvalid = true  
      return
    }
    if(title.length > this.nameLimit) { this.isTitleLimitInvalid = true
      return
    }
    if(documentDescription.length > this.descriptionLimit) { this.isDescLimitInvalid = true
      return
    }

    if(!this.isDocument){ this.fileUpload = this.trainingMaterialForm.controls['link'].value; }

    this.isApprovalLoading = true
    const body = {
      documentType: this.fileType,
      documentName: !this.fileName ? title: this.fileName,
      documentDescription: documentDescription,
      state: this._profilceMgrService.profile[0]?.stateProvince,
      documentResourceString: this.fileUpload.toString(),
      createdByID: this._profilceMgrService.profile[0]?.stateProvince === 'MO' ? 'MOAMADMIN' : 'ILAMADMIN'
     }
     this._traningMgrService.editDocument(body, this.documentId).subscribe({
      next: (data)=>{
        this.isApprovalLoading = false
        this.isApiMessageResponse = true
        this.getTrainingList();
      },
      error: (error)=>{
        this.isApprovalLoading = false
        this.isApiMessageResponse = true
        this.isApprovalAPIFailure = true
      }
    })
  }
 
  submit(){
    if(this.isDocument){
      this.upload()
    }
    else{
      this.fileUpload = this.trainingMaterialForm.controls['link'].value;
      this.fileType = 'video';
      this.fileName = this.trainingMaterialForm.controls['title'].value;
      this.upload();
    }
  }

  clearInvalid(field: any) {
    if (field == 'title')
      (this.isTitleInvalid = false), (this.isTitleLimitInvalid = false);
    if (field == 'description')
      (this.isDescInvalid = false), (this.isDescLimitInvalid = false);
  }

  upload(){
    const  documentDescription =  this.trainingMaterialForm.controls['description'].value
    const title =  this.trainingMaterialForm.controls['title'].value
    if(!title){ this.isTitleInvalid = true 
      return
    }
    if(!documentDescription){ this.isDescInvalid = true  
      return
    }
    if(title.length > this.nameLimit) { this.isTitleLimitInvalid = true
      return
    }
    if(documentDescription.length > this.descriptionLimit) { this.isDescLimitInvalid = true
      return
    }
    this.isApprovalLoading = true

    const body = {
      documentType: this.fileType,
      documentName: this.fileName,
      documentDescription: documentDescription,
      state: this._profilceMgrService.profile[0]?.stateProvince,
      documentResourceString: this.fileUpload.toString(),
      createdByID:
        this._profilceMgrService.profile[0]?.stateProvince === 'MO'
          ? 'MOAMADMIN'
          : 'ILAMADMIN',
    };

    this._traningMgrService.createDocument(body).subscribe({
      next: (data) => {
        this.isApprovalLoading = false;
        this.isApiMessageResponse = true;
      },
      error: () => {
        this.isApprovalLoading = false;
        this.isApiMessageResponse = true;
        this.isApprovalAPIFailure = true;
      },
    });
  }

  iconImage = {
    upload_file: '../../../assets/icon/upload_file.svg',
    img_pdf_src: '../../../assets/icon/pdficon.svg',
    img_excel_src: '../../../assets/icon/excelicon.svg',
    img_word_src: '../../../assets/icon/word.svg',
    img_txt_src: '../../../assets/icon/text.svg',
    img_video_src: '../../../assets/icon/video-icon.svg',
    headline: 'LIHEAP',
  };

  getDocDetails(id: number){
    this.documentId = id
    this.isApiMessageResponse = false;
    this.isApprovalAPIFailure = false;
    this.getDocDetailsByIdApiFailure = false;
    this.isDocDetailLoading = true;
    this.docDetailsLoadingModal.show();
    this._traningMgrService.getTrainingById(id).subscribe({
      next: (data)=>{
        this.fileType = data.docmentType
        this.fileUpload = data.documentResourceString
        this.trainingMaterialForm.controls['title'].setValue(data.documentName)
        this.trainingMaterialForm.controls['description'].setValue(data.documentDescription)
        this.isDocDetailLoading = false;
        this.docDetailsLoadingModal.hide();
        if(data.docmentType.match('doc.*')  ||
           data.docmentType.match('docx.*') ||
           data.docmentType.match('pdf.*')  ||
           data.docmentType.match('text.*') ||
           data.docmentType.match('txt.*')){
           this.isDocument = true;
           this.editmodaloption.show()
        }
        else{
          
          this.isDocument = false;
          this.trainingMaterialForm.controls['link'].setValue(data.documentResourceString)
          this.editmodaloption.show()
        }
      },
      error: (error)=>{
        this.isDocDetailLoading = false;
        this.getDocDetailsByIdApiFailure = true;
      }
    })
  }


  getDocById(id: number) {
    this.isTrainingDownloading = true;
    this.dpcLoadingModal.show();
    this._traningMgrService.getTrainingById(id).subscribe({
      next: (res) => {
        this.isTrainingListLoading = false;
        this.getDocByIdApiFailure = false;
        this.dpcLoadingModal.hide();
        if (res.docmentType == 'txt') {
          FileSaver.saveAs(
            new Blob([res.documentResourceString]),
            res.documentName
          );
        } else {
          const file = this._traningMgrService.base64toBlob(
            res.documentResourceString,
            res.docmentType
          );
          FileSaver.saveAs(file, res.documentName);
        }
      },
      error: (err) => {
        this.getDocByIdApiFailure = true;
        this.isTrainingDownloading = false;
      },
    });
  }

  onDeleteConfirmModal(id: number) {
    this.deleteDocApiSuccess = false;
    this.deleteDocApiFailure = false;
    this.selectedTrainingDocId = id;
    this.deleteConfrmModal.show();
  }

  onDeleteTrainingCancel() {
    this.deleteConfrmModal.hide();
    this.getTrainingList();
  }
  closeDeleteModal() {
    this.isDeleteApiMessageResponse = false;
    this.getTrainingList();
  }
  onDeleteTraining() {
    this.isDeleteLoading = true;
    this._traningMgrService
      .deleteTrainingById(this.selectedTrainingDocId)
      .subscribe({
        next: (res) => {
          this.isDeleteLoading = false;
          this.deleteDocApiFailure = false;
          this.deleteDocApiSuccess = true;
          this.isDeleteApiMessageResponse = true;
        },
        error: (err) => {
          this.deleteDocApiFailure = true;
          this.isDeleteLoading = false;
          this.deleteDocApiSuccess = false;
          this.isDeleteApiMessageResponse = true;
        },
      });
  }
}
