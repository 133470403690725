import { autoTable } from 'jspdf-autotable';
import { CampaignsManagerService } from './../services/campaigns-manager.service';
import { ISelectAgency } from './../../select-agency/interfaces/select-agency.interface';
import { SelectAgencyManagerService } from './../../select-agency/services/select-agency-manager.service';
import { IEAPAgencyInformation } from 'src/app/secure/administration/interfaces/IEAPAgencyInformation';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { PledgeEntryManagerService } from './../../pledge-entry/services/pledge-entry-manager.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AgenciesByState } from '../model/agenciesbystate.model';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss'],
})
export class AddCampaignComponent implements OnInit, AfterViewInit {
  selectedAgency: IEAPAgencyInformation;
  addcampaignForm!: FormGroup;
  campaignTotal = 0;
  feeTotal = 0;
  adminTotal = 0;
  agencies: AgenciesByState[];
  userState: string;
  isLoading: boolean = false;
  apiFailure = false;
  apiSuccess = false;
  pledgeType: any[];

  constructor(
    private _fb: FormBuilder,
    private _pledgeEntrySvc: PledgeEntryManagerService,
    private selectAgencyManager: SelectAgencyManagerService,
    private profileSvc: ProfileManagerService,
    private agencySvc: AgencyService,
    private campaignMgrSvc: CampaignsManagerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userState = this.profileSvc.profile[0]?.stateProvince || '';
    this.initCampaignForm();
    this.selectedAgency = this.agencySvc.getSelectedAgency();

    this._pledgeEntrySvc.getPledgeTypeListByState().subscribe({
      next: (data) => {
        this.getAgencyList();
        this.pledgeType = data;
      },
      error: () => {
        this.isLoading = false;
      },
    });

    this.addcampaignForm
      .get('individualPledge')
      ?.valueChanges.subscribe((val) => {
        if (val == 'Fixed') {
          this.addcampaignForm.get('min')?.clearValidators();
          this.addcampaignForm.get('max')?.clearValidators();
          this.addcampaignForm
            .get('pledge')
            ?.setValidators([
              Validators.required,
              Validators.pattern('^\\d*$'),
            ]);
        } else {
          this.addcampaignForm
            .get('min')
            ?.setValidators([
              Validators.required,
              Validators.pattern('^\\d*$'),
            ]);
          this.addcampaignForm
            .get('max')
            ?.setValidators([
              Validators.required,
              Validators.pattern('^\\d*$'),
            ]);
          this.addcampaignForm.get('pledge')?.clearValidators();
        }
        this.addcampaignForm.get('max')?.updateValueAndValidity();
        this.addcampaignForm.get('min')?.updateValueAndValidity();
        this.addcampaignForm.get('pledge')?.updateValueAndValidity();
      });
  }

  getAgencyList() {
    this.campaignMgrSvc.getAgenciesByState().subscribe((res) => {
      this.agencies = this.sortData(res);
      this.isLoading = false;
    });
  }
  get participatingAgencies(): FormArray {
    return this.addcampaignForm.get('participatingAgencies') as FormArray;
  }

  initCampaignForm() {
    this.addcampaignForm = this._fb.group({
      companyName: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      pledgeType: new FormControl(null, [Validators.required]),
      adminFee: new FormControl(null, {
        validators: [
          Validators.pattern('^\\d*\\.?\\d*$'),
          Validators.max(15),
          Validators.min(0),
        ],
      }),
      individualPledge: new FormControl('Range', [Validators.required]),
      min: new FormControl(null, {
        validators: [Validators.required, Validators.pattern('^\\d*$')],
      }),
      max: new FormControl(null, {
        validators: [Validators.required, Validators.pattern('^\\d*$')],
      }),
      pledge: new FormControl(null),
      campaignStartDate: new FormControl(null, [Validators.required]),
      campaignEndDate: new FormControl(null, [Validators.required]),
      participatingAgencies: this._fb.array([
        this._fb.group({
          agencyName: new FormControl(null, [
            Validators.pattern('^[-_ a-zA-Z0-9]+$'),
          ]),
          allocated: new FormControl(null, [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
          ]),
          fee: new FormControl(null, [Validators.pattern('^\\d*\\.?\\d*$')]),
          totalAdmin: new FormControl(null, [
            Validators.required,
            Validators.pattern('^\\d*\\.?\\d*$'),
          ]),
        }),
      ]),
    });
  }

  addParticipatingAgency() {
    (this.addcampaignForm.get('participatingAgencies') as FormArray).push(
      this._fb.group({
        agencyName: new FormControl(null, [
          Validators.pattern('^[-_ a-zA-Z0-9]+$'),
        ]),
        allocated: new FormControl(null, [
          Validators.required,
          Validators.pattern('^\\d*\\.?\\d*$'),
        ]),
        fee: new FormControl(null, [Validators.pattern('^\\d*\\.?\\d*$')]),
        totalAdmin: new FormControl(null, [
          Validators.required,
          Validators.pattern('^\\d*\\.?\\d*$'),
        ]),
      })
    );
  }

  deleteParticipatingAgency(index: number) {
    (this.addcampaignForm.get('participatingAgencies') as FormArray).removeAt(
      index
    );
  }
  onAllocationChange(idx: number) {
    this.campaignTotal = 0;
    this.feeTotal = 0;
    this.adminTotal = 0;
    let model = this.addcampaignForm.value;
    let adminFeeValue = model.adminFee;

    // Get Allocated Value
    const allocatedValue = (
      this.addcampaignForm.get('participatingAgencies') as FormArray
    )
      .at(idx)
      .get('allocated')?.value;

    // Get and Set the Admin Fee
    let agencyFeeValue = (allocatedValue * (adminFeeValue / 100)).toFixed(2);
    (this.addcampaignForm.get('participatingAgencies') as FormArray)
      .at(idx)
      .get('fee')
      ?.setValue(agencyFeeValue);

    //Set Total Admin
    (this.addcampaignForm.get('participatingAgencies') as FormArray)
      .at(idx)
      .get('totalAdmin')
      ?.setValue(allocatedValue + allocatedValue * (adminFeeValue / 100));

    //Get Campaign Total
    (
      this.addcampaignForm.get('participatingAgencies') as FormArray
    ).controls.forEach((grp, i) => {
      this.campaignTotal =
        this.campaignTotal + parseFloat(grp?.get('allocated')?.value);
    });

    //Get Fee Total
    (
      this.addcampaignForm.get('participatingAgencies') as FormArray
    ).controls.forEach((grp, i) => {
      this.feeTotal = this.feeTotal + parseFloat(grp?.get('fee')?.value);
    });

    //Get Total Sum of Admin
    (
      this.addcampaignForm.get('participatingAgencies') as FormArray
    ).controls.forEach((grp, i) => {
      this.adminTotal =
        this.adminTotal + parseFloat(grp?.get('totalAdmin')?.value);
    });
  }

  addcampaign() {
    let model = this.addcampaignForm.value;
    let campaign: any = {
      campaignName: model.companyName.trim(),
      stateCode: this.userState,
      pledgeTypeCode: model.pledgeType.toString(),
      pledgeTypeDecode: this.pledgeType.filter(
        (x) => x.pledgeTypeCode == model.pledgeType
      )[0].pledgeTypeDescription,
      campaignAmount: this.campaignTotal.toString(),
      adminFee: model.adminFee ? (model.adminFee / 100).toString() : '0',
      isFixedIndividualPledge: (model.individualPledge == 'Fixed').toString(),
      minPledge: model.individualPledge == 'Fixed' ? '0' : model.min.toString(),
      maxPledge: model.individualPledge == 'Fixed' ? '0' : model.max.toString(),
      pledgeAmount:
        model.individualPledge != 'Fixed' ? '0' : model.pledge.toString(), //T00:00:00
      campaignFromDate: model.campaignStartDate,
      campaignToDate: model.campaignEndDate,
      timelinePercentComplete: '100',
      financialPercentComplete: '0',
      campaignAgencies: [],
    };
    model.participatingAgencies.forEach((agcy: any) => {
      campaign.campaignAgencies.push({
        campaignAgencyID: '0',
        campaignAgencyName: this.agencies
          .filter((a) => a.agencyCSSCode == agcy.agencyName)[0]
          .agencyName.trim(),
        agencyCode: agcy.agencyName.toString(),
        campaignAmount: agcy.totalAdmin.toString(),
        agencyAllocation: {
          amount: agcy.allocated.toString(),
          fee: agcy.fee.toString(),
          toCustomerAmount: agcy.totalAdmin.toString(),
        },
        pledge: {
          amount: '0.00',
          fee: '0.00',
          toCustomerAmount: '0.00',
        },
        remaining: {
          amount: agcy.allocated.toString(),
          fee: agcy.fee.toString(),
          toCustomerAmount: agcy.totalAdmin.toString(),
        },
        adminFee: (model.adminFee / 100).toString() || '0.00',
      });
    });

    this.isLoading = true;
    this.campaignMgrSvc.addCampaign(campaign).subscribe({
      next: () => {
        this.apiSuccess = true;
        this.isLoading = false;
        this.apiFailure = false;
        this.campaignTotal = 0;
        this.feeTotal = 0;
        this.adminTotal = 0;
        this.addcampaignForm.reset();
      },
      error: () => {
        this.addcampaignForm.reset();
        this.campaignTotal = 0;
        this.feeTotal = 0;
        this.adminTotal = 0;
        this.apiFailure = true;
        this.isLoading = false;
        this.apiSuccess = false;
      },
    });
  }

  ngAfterViewInit() {
    (
      this.addcampaignForm.get('participatingAgencies') as FormArray
    ).valueChanges.subscribe((data: any) => {
      let participatingAgencyValues = [];
      participatingAgencyValues = data;
      let model = this.addcampaignForm.value;
      let adminFeeValue = model.adminFee;

      participatingAgencyValues.forEach((value: any) => {
        let actualAdminFeeValue = this.actualAdminFee(
          value.allocated,
          adminFeeValue || 0
        );

        value.fee = actualAdminFeeValue;
      });
    });
  }

  calculatePercentageAmount(adminFee: any, campaignTotal: any) {
    adminFee = adminFee / 100;
    let val = campaignTotal * adminFee;
    return val;
  }

  actualToCustomerAmount(allocationAmount: any, agencyAllocationFee: any) {
    let val = allocationAmount + agencyAllocationFee;
    return val;
  }

  actualAdminFee(agencyAllocationValue: any, adminFee: any) {
    let adminFeeValue = adminFee / 100;
    let val = agencyAllocationValue * adminFeeValue;
    return val;
  }

  sortData(res: any[]) {
    let sortingData: any[] = res;
    return sortingData.sort((a: any, b: any) => {
      return a.agencyName
        .toLowerCase()
        .localeCompare(b.agencyName.toLowerCase());
    });
  }
}
