<div class="container emulation">
    <form [formGroup]="emulationForm" class="needs-validation">
        <div class="col-md-5 m-auto">
            <div class="form-group">
                <label class="form-label required" for="emailAddressId">
                    Enter your Email Address
                </label>
                <input formControlName="emailAddress" placeholder="name@example.com" 
                id="emailAddressId" type="text" class="form-control" aria-label="Enter your email address" required>
                <div class="text-field-error mt-3 ameren-font-sm"
                *ngIf="emulationForm?.get('emailAddress')?.dirty && emulationForm?.get('emailAddress')?.invalid">
                    <p *ngIf="emulationForm?.get('emailAddress')?.errors?.['required']">
                        Email Address is required.
                    </p>
                    <p *ngIf="emulationForm?.get('emailAddress')?.errors?.['email']">
                        Invalid Email Address.
                    </p>
                </div>
            </div>
            <div class="m-3">
                <button [disabled]="!emulationForm.valid" class="btn btn-success" (click)="emulateEAPUser()" type="submit">Emulate</button>
            </div>
        </div>
    </form>
</div>
