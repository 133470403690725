<div
  class="pledge-entry-back-btn-container"
  *ngIf="showResponse && pledgeEntryListReview && pledgeEntryListReview.length"
>
  <div class="pledge-entry-back-btn" (click)="onBackClick()">
    <svg
      width="22px"
      height="19px"
      viewBox="0 0 22 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>🎨 Icon Сolor</title>
      <g
        id="New-Login-/-Registration"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Register-Account---validation"
          transform="translate(-62.000000, -156.000000)"
          fill="#1B6CB5"
          fill-rule="nonzero"
        >
          <path
            d="M82.6428483,164.142787 L66.5402438,164.142787 L71.4707758,158.225605 C71.9512075,157.650173 71.8724927,156.79381 71.2970604,156.314736 C70.7202709,155.834304 69.8652654,155.913019 69.3861908,156.488451 L62.6004325,164.631361 C62.5475036,164.695147 62.5203605,164.769791 62.4810031,164.840363 C62.4484315,164.897363 62.4090741,164.94622 62.3846454,165.008649 C62.3235735,165.164722 62.2869304,165.328937 62.2869304,165.49451 C62.2869304,165.495867 62.2855733,165.498581 62.2855733,165.499938 C62.2855733,165.501295 62.2869304,165.50401 62.2869304,165.505367 C62.2869304,165.670939 62.3235735,165.835155 62.3846454,165.991227 C62.4090741,166.053656 62.4484315,166.102514 62.4810031,166.159514 C62.5203605,166.230086 62.5475036,166.304729 62.6004325,166.368515 L69.3861908,174.511425 C69.6549069,174.83307 70.0403379,175 70.4284833,175 C70.7351996,175 71.043273,174.896856 71.2970604,174.685141 C71.8724927,174.206066 71.9512075,173.349704 71.4707758,172.774271 L66.5402438,166.85709 L82.6428483,166.85709 C83.3919961,166.85709 84,166.249086 84,165.499938 C84,164.750791 83.3919961,164.142787 82.6428483,164.142787"
            id="🎨-Icon-Сolor"
          ></path>
        </g>
      </g>
    </svg>
    <span>Back</span>
  </div>
</div>

<div
  class="pledge__entry p-3"
  *ngIf="
    !(showResponse && pledgeEntryListReview && pledgeEntryListReview.length)
  "
>
  <div class="pledgeEntry-page-hearder">Pledge Entry</div>
  <div class="pledge__entry__agency-details">
    <div>
      {{ selectedAgency.selectedAgencyName || selectedAgency.agencyName }}
    </div>
    <div class="pledgeEntry-page-address">
      {{ selectedAgency.selectedAgencyAddress || selectedAddress }}
    </div>
  </div>
  <div class="disconnect__history">
    <div *ngIf="hasDisconnectionData" class="pending__disconnection-date mt-2">
      <span class="text-danger"
        >{{ disconnectionDateLabelText }} :
        {{ clientSummary.disconnectionDate }}</span
      >
    </div>
    <div *ngIf="IsDisconnected" class="reconnection__amount mt-2">
      <span class="text-danger"
        >Reconnection Amount :
        {{ clientSummary.reconnectionAmount | currency }}</span
      >
    </div>
    <div
      *ngIf="
        HasPendingDisconnection || showMinimumDisconnectionPreventionAmount
      "
      class="minimum__payment mt-2"
    >
      <span class="text-danger"
        >Minimum Amount:
        {{ clientSummary.minDisconnectionPreventionAmount | currency }}</span
      >
    </div>
  </div>
  <form [formGroup]="pledgeEntryForm">
    <div class="m-0 d-flex flex-row" formArrayName="pledgeEntries">
      <table
        id="pendingAgencyUsers"
        aria-describedby="Pledge entry table"
        class="align-middle bg-white mt-3 p-3 pledge-table"
      >
        <thead class="pledgeentry-header">
          <tr>
            <th scope="col" class="pledge__text"></th>
            <th scope="col" class="pledge__text">Account Number</th>
            <th scope="col" class="pledge__text">Pledge Amount $</th>
            <th scope="col" class="pledge__text">Pledge Type</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of pledgeEntries.controls; index as idx"
            [formGroupName]="idx"
          >
            <td class="col text-center">
              <button
                *ngIf="pledgeEntries.length > 1"
                class="btn btn-default"
                (click)="deletePledgeEntryRow(idx)"
              >
                <img
                  src="assets/icon/delete-icon.svg"
                  alt="deleteicon"
                  width="22"
                  height="19"
                />
              </button>
            </td>
            <td class="col text-center">
              {{ selectedClient.billAccountNumber | accountNumber }}
            </td>
            <td class="col">
              <div class="form-group">
                <label
                  class="form-label required visually-hidden"
                  for="pledgeAmount"
                >
                  Enter your amount
                </label>
                <input
                  formControlName="pledgeAmount"
                  placeholder="$ Enter amount"
                  id="pledgeAmount"
                  type="number"
                  size="4"
                  class="form-control form-control-sm pledge__input__amount"
                  aria-label="Enter amount"
                  required
                />
                <div
                  class="text-field-error mt-2 ameren-font-sm pledge-amount-error"
                  *ngIf="
                    pledgeEntries.controls[idx]?.get('pledgeAmount')?.dirty &&
                    pledgeEntries.controls[idx]?.get('pledgeAmount')?.invalid
                  "
                >
                  <p
                    *ngIf="pledgeEntries.controls[idx]?.get('pledgeAmount')?.errors?.['required']"
                  >
                    Amount is required.
                  </p>
                  <p
                    *ngIf="
                      pledgeEntries.controls[idx]?.get('pledgeAmount')?.value <=
                      0
                    "
                  >
                    Please enter a valid pledge amount.
                  </p>
                  <p
                    *ngIf="pledgeEntries.controls[idx]?.get('pledgeAmount')?.errors?.['min'] || pledgeEntries.controls[idx]?.get('pledgeAmount')?.errors?.['max']"
                  >
                    <span
                      *ngIf="
                        pledgeEntries.controls[idx]?.get('minPledge')?.value !=
                        pledgeEntries.controls[idx]?.get('maxPledge')?.value
                      "
                    >
                      Pledge amount must be between ${{
                        pledgeEntries.controls[idx]?.get('minPledge')?.value
                      }}
                      - ${{
                        pledgeEntries.controls[idx]?.get('maxPledge')?.value
                      }}
                    </span>
                    <span
                      *ngIf="
                        pledgeEntries.controls[idx]?.get('minPledge')?.value ==
                        pledgeEntries.controls[idx]?.get('maxPledge')?.value
                      "
                    >
                      Pledge amount must be eaqual to ${{
                        pledgeEntries.controls[idx]?.get('minPledge')?.value
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td class="col text-center">
              <div class="form-group">
                <select
                  (change)="onPledgeTypeChange(idx)"
                  class="form-select"
                  aria-label="select pledge type list"
                  formControlName="pledgeTypeCode"
                  required
                  [ngClass]="
                    pledgeEntries.controls[idx]?.get('pledgeAmount')?.touched &&
                    pledgeEntries.controls[idx]?.get('pledgeTypeCode')?.value ==
                      'Select Pledge Type'
                      ? 'text-danger border border-danger'
                      : ''
                  "
                >
                  <option [value]="null" selected disabled>
                    Select Pledge Type
                  </option>
                  <option
                    *ngFor="let pledgeType of pledgeTypes; let i = index"
                    [ngValue]="pledgeType.pledgeTypeCode"
                  >
                    {{ pledgeType.pledgeTypeDescription }}
                    <span
                      *ngIf="
                        pledgeType.isCampaignPledgeType &&
                        !pledgeType.isFixedPledge
                      "
                    >
                      (Min:{{ pledgeType.minPledge | currency }}, Max:
                      {{ pledgeType.maxPLedge | currency }})
                    </span>
                    <span
                      *ngIf="
                        pledgeType.isCampaignPledgeType &&
                        pledgeType.isFixedPledge
                      "
                    >
                      (Fixed: {{ pledgeType.fixPledgeAmount | currency }}
                      )
                    </span>
                    <span *ngIf="pledgeType.isCampaignPledgeType">
                      ({{ pledgeType.remainingBalanceAmount | currency }}
                      remaining)
                    </span>
                  </option>
                </select>
                <div
                  class="text-field-error mt-1 ameren-font-sm"
                  *ngIf="
                    pledgeEntries.controls[idx]?.get('pledgeAmount')?.touched ||
                    (pledgeEntries.controls[idx]?.get('pledgeTypeCode')
                      ?.dirty &&
                      pledgeEntries.controls[idx]?.get('pledgeTypeCode')
                        ?.invalid)
                  "
                >
                  <p
                    *ngIf="pledgeEntries.controls[idx]?.get('pledgeTypeCode')?.errors?.['required']"
                  >
                    Pledge Type is required.
                  </p>
                  <p
                    *ngIf="
                      pledgeEntries.controls[idx]?.get('pledgeTypeCode')
                        ?.value === 'Select Pledge Type'
                    "
                  >
                    Please select a valid pledge type.
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

  <div class="text-right space-box-button mt-3">
    <span
      *ngIf="
        HasPendingDisconnection || showMinimumDisconnectionPreventionAmount
      "
      class="float-start m-2"
      >Amount Remaining: {{ minimumRemainingAmountLeft | currency }}</span
    >

    <span *ngIf="IsDisconnected" class="float-start m-2"
      >Amount Remaining: {{ reconnectionAmountLeft | currency }}</span
    >
    <span class="float-start m-2">Total: {{ totalAmount | currency }}</span>

    <button
      type="button"
      class="add__pledgeEntry-btn add-pledge-btn mb-2"
      (click)="addPledgeEntryRow()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" class="icon">
        <path
          fill="#037000"
          d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z"
        /></svg
      ><small class="m-1">Add Another Pledge</small>
    </button>
    <button
      type="button"
      [disabled]="!pledgeEntryForm.valid"
      class="pledge__entry__submit-btn mb-2 btn btn-sm"
      (click)="approve()"
    >
      <small>Submit</small>
    </button>
  </div>
</div>

<div *ngIf="minimumPledgeAmountError" class="text-danger m-3">
  <em class="far fa-times-circle"></em>
  Please enter a valid pledge amount
</div>

<div *ngIf="pledgeTypeError" class="text-danger m-3">
  <em class="far fa-times-circle"></em>
  Please select a valid pledge type.
</div>

<div *ngIf="isSubmitLoading" class="text-center">
  <ameren-loading class="d-flex p-10 justify-content-center"></ameren-loading>
  <span>Please wait...</span>
</div>

<div
  class="text-center p-3 pledge__response__margin"
  *ngIf="showResponse && pledgeEntryListReview && pledgeEntryListReview.length"
>
  <span class="float-start text-success mb-2" *ngIf="!isAPIFailure"
    ><strong
      >Pledge(s) displayed below where successfully submitted</strong
    ></span
  >
  <span class="float-start text-danger mb-2" *ngIf="isAPIFailure"
    ><strong
      >Pledge(s) displayed below did not submit successfully</strong
    ></span
  >
  <table class="table table-sm" aria-labelledby="agency pledges">
    <thead *ngIf="false">
      <tr>
        <th scope="col">Agency Pledges</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of pledgeEntryListReview"
        [ngClass]="isAPIFailure ? 'row__fail' : 'row__success'"
      >
        <td>
          <strong>Account Number</strong>:
          {{ selectedClient.billAccountNumber | accountNumber }}
        </td>
        <td>
          <strong>Pledge Amount</strong>: {{ item.pledgeAmount | currency }}
        </td>
        <td><strong>Pledge Type</strong>: {{ item.pledgeTypeDescription }}</td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="apiFailureDuetoPledgedAmount" class="text-danger mt-2">
    <em class="far fa-times-circle"></em>
    Pledge type requires that the pledged amounts equal or exceed the minimum
    payment or reconnect amount.
  </div>

  <div *ngIf="isExceedsRemainingAmount" class="text-danger mt-2">
    <em class="far fa-times-circle"></em>
    We cannot accept that pledge amount because it exceeds the remaining amount
    for the campaign.
  </div>
</div>

<!------Modal----->
<div
  id="pledgeEntryApproveModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="ameren__modal__success-title">Approve Pledges</div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <table class="table table-sm" aria-labelledby="pedge entry list">
            <thead *ngIf="false">
              <tr>
                <th scope="col">Agency Pledges</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pledgeEntryListReview">
                <td>
                  <strong>Pledge Amount</strong>:
                  {{ item.pledgeAmount | currency }}
                </td>
                <td>
                  <strong>Pledge Type</strong>:
                  {{ item.pledgeTypeDescription }}
                </td>
              </tr>
            </tbody>
          </table>
          <div>Do you approve the above pledges</div>
          <div class="text-center space-box-button mt-3">
            <button
              type="button"
              class="modal__cancel-btn add-pledge-btn"
              (click)="close()"
            >
              <small class="m-1">Cancel</small>
            </button>
            <button
              type="button"
              class="modal__approve-btn"
              [disabled]="isSubmitLoading"
              (click)="submitPledgeEntry()"
            >
              <small>Approve</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="pledgeEntrySummary"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <div *ngIf="!isAPIFailure && isLoading" class="text-center">
            <ameren-loading
              id="loadingIndicator"
              class="d-flex p-10 justify-content-center"
            ></ameren-loading>
            <span>Please wait...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
