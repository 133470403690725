import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from 'src/app/shared/models/address';
import { Client } from 'src/app/shared/models/client';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { RelatedAccountModel } from '../model/related-accounts.model';
import { ClientManagerService } from '../services/client-manager.service';

@Component({
  selector: 'app-client-accounts',
  templateUrl: './client-accounts.component.html',
  styleUrls: ['./client-accounts.component.scss'],
})
export class ClientAccountsComponent implements OnInit {
  clientaccounts: RelatedAccountModel[] = [];
  selectedClient: any;
  isAPIFailure: boolean;
  isLoading: boolean;
  company: string;
  agencyTitle: string;
  agencyAddress: string;
  @ViewChild('clientAccounts') clientaccount: ElementRef<any>;

  constructor(
    private clientManager: ClientManagerService,
    private clientService: ClientService,
    private _utility: UtilitiesService,
    private authsvc: AuthService,
    private agencyService: AgencyService,
    private _router: Router
  ) {
    this.selectedClient = this.clientService.getSelectedClient();
  }

  ngOnInit(): void {
    this.authsvc.getUser().then((user) => {
      this.company = user?.profile?.['stateProvince'];
    });

    this.getClientAccounts();

    let agency = this.agencyService.getSelectedAgency();
    if (agency) {
      this.agencyTitle = agency.selectedAgencyName || agency.agencyName;
      const address = agency.address
        ? agency.address?.addressLines[0] +
          ',' +
          agency.address.city +
          ',' +
          agency.address.state +
          ',' +
          agency.address.zip
        : '';

      this.agencyAddress = agency.selectedAgencyAddress || address;
    }
  }

  exportClientAccountsPDF() {
    let clientAccountDetails = this.clientaccount.nativeElement;

    let body = [
      {
        type: 'string',
        element: 'CLIENT ACCOUNTS',
        align: 'center',
        x: 0,
        y: 15,
        fontsize: 12,
        fontWeight: 'bold',
        sameRow: false,
        fontColor: 'green',
      },
      {
        type: 'string',
        element: 'AGENCY NAME: ' + this.agencyTitle.toUpperCase(),
        align: '',
        x: 15,
        y: 10,
        fontsize: 12,
        sameRow: false,
      },
      {
        type: 'string',
        element: this.agencyAddress.toUpperCase(),
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
        sameRow: false,
      },

      {
        type: 'string',
        element: 'ACCOUNT NUMBER: ' +  this._utility.transformAccountNumber(this.selectedClient?.billAccountNumber),
        align: '',
        x: 15,
        y: 10,
        fontsize: 12,
      },
      {
        type: 'string',
        element: 'CLIENT: ' + this.selectedClient?.fullName,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'string',
        element: this.selectedClient?.address?.streetAddress,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'string',
        element:
          this.selectedClient?.address?.city +
          ' ' +
          this.selectedClient?.address?.state +
          ' ' +
          this.selectedClient?.address?.zip,
        align: '',
        x: 15,
        y: 0,
        fontsize: 12,
      },
      {
        type: 'table',
        element: clientAccountDetails,
        align: 'none',
        x: 0,
        y: 5,
        fontsize: 12,
      },
    ];

    const isMO = this.company === 'MO' ? true : false;

    this._utility.exportTabletoPDF('ClientAccounts.pdf', body, true, isMO);
  }

  getClientAccounts() {
    this.isLoading = true;
    this.clientManager
      .getRelatedAccountsByBillAccount(this.selectedClient.billAccountNumber)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          let resp: any[] = [];
          resp = res.data;
          let relatedAccount: any[] = [];

          if (resp instanceof Array) {
            resp.forEach((data: any) => {
              relatedAccount.push(new RelatedAccountModel(data));
            });
          }

          this.clientaccounts = relatedAccount.filter(
            (data: any) => data.operatingState === this.company
          );
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error && error.error.errorDetails.errorCode === 100) {
            this.clientaccounts = [];
          } else {
            this.isAPIFailure = true;
          }
        },
      });
  }

  navigateToClientQuickViewPage(data: any) {
    this.handleGetRelatedAccountsApiSuccess(data);
  }

  private handleGetRelatedAccountsApiSuccess(data: any) {
    let client = new Client();
    client.address = new Address();
    client.billAccountNumber = data.billAccountNumber;
    client.firstName = data.firstName;
    client.lastName = data.lastName;
    client.fullName = data.fullName;
    client.address.city = data.city;
    client.address.state = data.state;
    client.address.zip = data.zip;
    client.address.streetAddress = data.addressLine1;
    this.clientService.setSelectedClient(client);
    this._router.navigateByUrl('/client/client-quick-view');
  }
}
