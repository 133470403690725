<div class="clientpledge_message-text mt-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="icon-svg"><path d="M453 776h60V536h-60v240Zm26.982-314q14.018 0 23.518-9.2T513 430q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447 430q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.316q-54 54.316-127 86Q563 976 480.266 976Zm.234-60Q622 916 721 816.5t99-241Q820 434 721.188 335 622.375 236 480 236q-141 0-240.5 98.812Q140 433.625 140 576q0 141 99.5 240.5t241 99.5Zm-.5-340Z"/></svg>
  <small>If no date range is selected, results will display for last 60 days.</small>
</div>
<div class="clientpledge__container">

  <div class="clientpledge__title mt-3">Client Pledge</div>
  <div class="d-flex flex-row clientpledge__search clientpledge-filter-desktop">
    <div class="input-group search_button">
      <input type="text"
             class="form-control"
             placeholder="Search"
             [(ngModel)]="searchPledge"
             aria-label="Search"
             autocomplete="off"
             autocorrect="off"
             autocapitalize="off"
             #input />
      <div class="input-group-btn">
        <button id="searchString-submit"
                class="btn btn-default search_magnify"
                type="submit"
                (click)="searchFilterforClientPledge()">
          <span class="sr-only">Search</span>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Search</title>
            <circle cx="10" cy="10" r="7.5"></circle>
            <line x1="23.04" y1="22.99" x2="15.52" y2="15.47"></line>
          </svg>
        </button>
      </div>
    </div>
    <div class="ms-auto export__options agencypledges-filters">
      <button class="btn btn-default"
              dropdown-toggle
              id="btnCalendarFilter"
              data-bs-toggle="dropdown"
              aria-expanded="false">
        <img src="assets/icon/calendar.svg" alt="Calendar Image" /> Select Date
        <em class="fa-solid fa-sort-down down-margin"></em>
      </button>
      <div class="dropdown-menu calendar-popup-padding dropdown-desktop"
           aria-labelledby="btnCalendarFilter">
        <div class="container">
          <div class="row">
            <div class="col date-picker-header"><span>From</span></div>
            <div class="col date-picker-header"><span>To</span></div>
          </div>
        </div>
        <div class="date-picker-group">
          <div class="date-picker-div date-picker-float input-group date"
               data-provide="datepicker">
            <input id="pledgeFromDate"
                   (change)="dateRangeFilter($event, true, false)"
                   type="date"
                   placeholder="MM/DD/YYYY"
                   data-required-error="Please select a date" />
          </div>
          <div class="date-picker-float date-picker-divider">-</div>
          <div class="date-picker-div date-picker-float input-group date"
               data-provide="datepicker">
            <input id="pledgeToDate"
                   (change)="dateRangeFilter($event, false, false)"
                   type="date"
                   placeholder="MM/DD/YYYY"
                   data-required-error="Please select a date" />
          </div>
        </div>
        <div class="calendar-pastdays-button">
          <button id="btnPastThirtyDays"
                  class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(30)">
            Past 30 days
          </button>
        </div>
        <div class="calendar-pastdays-button">
          <button id="btnPastSixtyDays"
                  class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(60)">
            Past 60 days
          </button>
        </div>
        <div class="calendar-pastdays-button">
          <button id="btnPastNinetyDays"
                  class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(90)">
            Past 90 days
          </button>
        </div>
        <div class="calendar-pastdays-button">
          <button id="btnPastYear"
                  class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(365)">
            Past 12 months
          </button>
        </div>
      </div>
    </div>
    <div>
      &nbsp;
      <button class="btn btn-primary agency-pledges-filter_apply" (click)="applyFilters()"
              id="btnApplyFilter">
        Apply
      </button>
    </div>
    <div>
      &nbsp;
      <button class="btn btn-default agency-pledges-filter_clear" (click)="clearFilters()"
              id="btnClearFilter">
        Clear
      </button>
    </div>
    <div class="ms-auto export__options">
      <button class="btn btn-default" (click)="exportToExcelClientPledges()">
        <img src="assets/icon/excelicon.svg" alt="Excel" />
        Excel
      </button>
      |

      <button class="btn btn-default" (click)="exportToPdfClientPledges()">
        <img src="assets/icon/pdficon.svg" alt="PDF Image" />
        PDF
      </button>
    </div>
  </div>


  <div class="d-flex flex-row clientpledge__search clientpledge-filter-mobile">
    <div class="input-group search_button">
      <input type="text"
             class="form-control"
             placeholder="Search"
             [(ngModel)]="searchPledge"
             aria-label="Search"
             autocomplete="off"
             autocorrect="off"
             autocapitalize="off"
             #input />
      <div class="input-group-btn">
        <button id="searchString-submit"
                class="btn btn-default search_magnify"
                type="submit"
                (click)="searchFilterforClientPledge()">
          <span class="sr-only">Search</span>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Search</title>
            <circle cx="10" cy="10" r="7.5"></circle>
            <line x1="23.04" y1="22.99" x2="15.52" y2="15.47"></line>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row clientpledge__search clientpledge-filter-mobile">
    <div class="export__options agencypledges-filters">
      <button class="btn btn-default"
              dropdown-toggle
              id="btnCalendarFilterMobile"
              data-bs-toggle="dropdown"
              aria-expanded="false">
        <img src="assets/icon/calendar.svg" alt="Calendar Image" /> Select Date
        <em class="fa-solid fa-sort-down down-margin"></em>
      </button>
      <div class="dropdown-menu calendar-popup-padding dropdown-mobile"
           aria-labelledby="btnCalendarFilterMobile">
        <div class="container">
          <div class="row">
            <div class="col date-picker-header"><span>From</span></div>
            <div class="col date-picker-header"><span>To</span></div>
          </div>
        </div>
        <div class="date-picker-group">
          <div class="date-picker-div date-picker-float input-group date"
               data-provide="datepicker">
            <input id="pledgeFromDateMobile"
                   (change)="dateRangeFilter($event, true, false)"
                   type="date"
                   placeholder="MM/DD/YYYY"
                   data-required-error="Please select a date" />
          </div>
          <div class="date-picker-float date-picker-divider">-</div>
          <div class="date-picker-div date-picker-float input-group date"
               data-provide="datepicker">
            <input id="pledgeToDateMobile"
                   (change)="dateRangeFilter($event, false, false)"
                   type="date"
                   placeholder="MM/DD/YYYY"
                   data-required-error="Please select a date" />
          </div>
        </div>
        <div class="calendar-pastdays-button">
          <button class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(30)">
            Past 30 days
          </button>
        </div>
        <div class="calendar-pastdays-button">
          <button class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(60)">
            Past 60 days
          </button>
        </div>
        <div class="calendar-pastdays-button">
          <button class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(90)">
            Past 90 days
          </button>
        </div>
        <div class="calendar-pastdays-button">
          <button class="btn btn-default calendar-day-button"
                  (click)="filterByPastDays(365)">
            Past 12 months
          </button>
        </div>
      </div>
    </div>
    <div>
      &nbsp;
      <button class="btn btn-primary agency-pledges-filter_apply" (click)="applyFilters()"
              id="btnApplyFilter">
        Apply
      </button>
    </div>
    <div class="ms-auto export__options">
      <button class="btn btn-default" (click)="exportToExcelClientPledges()">
        <img src="assets/icon/excelicon.svg" alt="Excel" />
        Excel
      </button>
      |

      <button class="btn btn-default" (click)="exportToPdfClientPledges()">
        <img src="assets/icon/pdficon.svg" alt="PDF Image" />
        PDF
      </button>
    </div>
  </div>
  <div class="client-pledge-search-criteria">
    <table>
      <tbody>
        <tr>
          <td width="150px">
            <label id="lblSelectedCriteria" style="display:none">Selected Criteria</label>
          </td>
          <td width="100px">
            <label id="lblDate" class="badge rounded-pill bg-secondary" style="display:none"></label>
            <label id="lblPastDays" class="badge rounded-pill bg-secondary" style="display:none"></label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clientpledge__list">
    <table id="clientpledge"
           #clientpledge
           aria-describedby="Ameren items List"
           class="clientpledge align-middle bg-white">
      <thead class="clientpledge__header">
        <tr>
          <th scope="col">
            <input #checkboxes type="checkbox" (click)="checkAll()" />
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="campaignName">
              Campaign Name <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="pledgeDate">
              Pledge Date <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="pledgeTypeDecode">
              Pledge Type<em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="agencyName">
              Agency<em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="pledgeAmount">
              Pledge Amount <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="pledgeStatusDecode">
              Status <em class="fa-solid fa-sort"></em>
            </span>
          </th>
          <th scope="col">
            <span class="table-head"
                  [appSort]="clientpledgelist"
                  data-order="asc"
                  data-name="pledgeEndDate">
              Date Satisfied <em class="fa-solid fa-sort"></em>
            </span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="6">
            <ameren-loading id="loadingIndicator"
                            class="d-flex p-10 justify-content-center"></ameren-loading>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="isAPIFailure && !isLoading">
        <tr>
          <td colspan="8">
            <div class="mt-3 alert alert-danger">
              Sorry, Unable to retrieve the client pledge list. Please try again
              later.
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isAPIFailure && !isLoading">
        <tr *ngIf="clientpledgelist && clientpledgelist.length === 0">
          <td colspan="6">
            <div id="noRecordsFound" class="no__ameren__users__found">
              There are no client pledge records found!
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of clientpledgelist; let i = index">
          <td>
            <input #checkboxes
                   type="checkbox"
                   (click)="
                toggleData(
                  i,
                  item.campaignName,
                  item.pledgeDate,
                  item.pledgeTypeDecode,
                  item.agencyName,
                  item.pledgeAmount,
                  item.pledgeStatusDecode,
                  item.pledgeEndDate,
                  item.clockImage
                )
              " />
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="ms-3">
                <p class="fw-normal mb-1">{{ item.campaignName }}</p>
              </div>
            </div>
          </td>
          <td>
            <p class="fw-normal mb-1">
              {{ item.pledgeDate}}
              <img *ngIf="item.isNearExpiration"
                   src="assets/icon/clock-icon.svg"
                   alt="This pledge is within 30 days of expiration" />
            </p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.pledgeTypeDecode }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.agencyName }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.pledgeAmount | currency }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.pledgeStatusDecode }}</p>
          </td>
          <td>
            <p class="fw-normal mb-1">{{ item.pledgeEndDate}}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p class="m-4">
    <img id="clock"
         src="assets/icon/clock-icon.svg"
         alt="This pledge is within 30 days of expiration" />
    indicates pledge within 30 days of expiration.
  </p>
</div>