import { Injectable } from '@angular/core';
import { map, catchError, throwError } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { AgencyRegistrationList } from '../models/agencyregistrationlist.model';
import { AgencyReviewApiService } from './agency-review-api.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyReviewManagerService {
  constructor(private agencyReviewAPIService: AgencyReviewApiService) {}

  getAgencyRegistrationList() {
    return this.agencyReviewAPIService.getAgencyRegistrationList().pipe(
      catchError((error: any) => {
        return throwError(() => error);
      }),
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Agency Registration list cannot be retrieved',
            400
          );
        }

        let regList: any[] = [];

        res.data.forEach((data: any) => {
          regList.push(new AgencyRegistrationList(data));
        });

        return regList;
      })
    );
  }

  updateAgencyRegistration(
    status: string,
    userid: string,
    registrationid: any
  ) {
    return this.agencyReviewAPIService
      .updateAgencyRegistration(status, userid, registrationid)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Update Agency Registration has been failed',
              400
            );
          }

          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getRegistrationById(registrationid: any) {
    return this.agencyReviewAPIService.getRegistrationById(registrationid).pipe(
      catchError((error: any) => {
        return throwError(() => error);
      }),
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Registration details by registration id has been failed',
            400
          );
        }

        return res.data;
      })
    );
  }
}
